@import "../variables";
@import "../theme-colors";

.back-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-form {
  min-height: 100vh;
  overflow-y: auto;
  box-shadow: var(--default-box-shadow);
  background-color: var(--authentication-form-bg);

  .sign-in-sign-up-form {
    padding: 4rem;

    .logo {
      max-width: 170px;
      max-height: 70px;
    }

    .form-row {
      .form-group {
        margin-bottom: 2rem;
      }

      .bluish-text {
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}