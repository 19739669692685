@import "../variables";

.roles-table {
  .minimizing {
    svg {
      height: 18px;
      width: 18px;
    }
  }
}
