@import "../variables";
@import "../theme-colors";

.sidebar {
  z-index: 200;
  position: fixed;
  min-height: 100vh;
  max-height: 100vh;
  font-family: $type-1;
  width: $sidebar-width-lg;
  background: var(--sidebar-bg);
  box-shadow: var(--sidebar-box-shadow);
  transition: width $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function;

  .navbar-brand-wrapper {
    height: $navbar-height;
    width: $sidebar-width-lg;
    background: var(--navbar-brand-bg);
    transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;

    .navbar-brand {
      width: 100%;
      margin-right: 0;
      padding: 0 10px !important;

      img {
        height: 50px;
        margin: auto;
        max-width: 100%;
        vertical-align: middle;
      }

      &.brand-logo-mini {
        display: none;

        img {
          max-width: 100%;
          height: 50px;
          margin: auto;
        }
      }
    }
  }

  .nav {
    overflow: hidden;
    overflow-y: auto;
    flex-wrap: nowrap;
    flex-direction: column;
    max-height: calc(100vh - #{$navbar-height});

    .nav-item {
      padding: 0 $sidebar-menu-padding-x;
      transition: all $default-transition-duration $action-transition-timing-function;

      .collapsing {
        //padding: 0.75rem 0 0 0;
        margin: 0 -1.25rem 0 -1.25rem;
        background-color: $sidebar-submenu-bg-color;
      }

      .collapse {
        z-index: 999;
        //padding: 0.75rem 0 0 0;
        margin: 0 -1.25rem 0 -1.25rem;
        background-color: $sidebar-submenu-bg-color;
      }

      .nav-link {
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: $sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
        color: $sidebar-dark-menu-color;
        transition: $default-transition-duration $action-transition-timing-function;
        transition-property: color;
        -webkit-transition-property: color;

        .icon-wrapper {
          height: 40px;
          min-width: 40px;
          max-width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          background-color: $navbar-brand-bg;
          transition: color $default-transition-duration $action-transition-timing-function;

          svg {
            width: 20px;
            height: 20px;
            color: $sidebar-dark-menu-color;
          }
        }

        .menu-title {
          color: inherit;
          line-height: 1;
          margin-left: 0.9rem;
          display: inline-block;
          vertical-align: middle;
          font-size: $sidebar-menu-font-size;
        }

        i {
          color: $sidebar-dark-menu-color;

          &.menu-arrow {
            opacity: 0.5;
            margin-left: auto;
            display: inline-block;
            color: $sidebar-dark-menu-color;
            transition: $action-transition-duration $action-transition-timing-function;
            font: normal normal 700 $sidebar-arrow-font-size/1 'Font Awesome 5 Free';

            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }

            &:before {
              content: '\f078';
              font-size: inherit;
              color: inherit;
            }

            + .menu-icon {
              margin-left: .25rem;

              .rtl & {
                margin-left: 0;
                margin-right: .25rem;
              }
            }
          }
        }

        &[aria-expanded="true"] {
          .menu-arrow {
            transform: rotate(-180deg);
          }
        }
      }

      &.active {
        .nav-link {
          .icon-wrapper {
            background-color: $brand-color;

            svg {
              color: lighten($brand-color, 35%);
            }
          }

          .menu-title {
            font-family: $type-1;
            color: lighten($brand-color, 35%);
          }

          i {
            color: $sidebar-dark-menu-color;

            &.menu-arrow {
              transform: rotate(-180deg);
            }
          }

          &.collapsed {
            &[aria-expanded="false"] {
              .menu-arrow {
                transform: rotate(-360deg);
              }
            }
          }
        }
      }
    }

    &:not(.sub-menu) {
      > .nav-item {
        &:hover {
          &:not(.nav-category):not(.nav-profile) {
            > .nav-link {
              color: lighten($brand-color, 35%);

              .icon-wrapper {
                svg {
                  color: lighten($brand-color, 35%);
                }
              }
            }
          }
        }
      }
    }

    &.sub-menu {
      list-style: none;
      margin-bottom: 0;

      .nav-item {
        padding: 0;

        .nav-link {
          height: auto;
          border-top: 0;
          line-height: 1;
          position: relative;
          color: $sidebar-dark-menu-color;
          font-size: $sidebar-submenu-font-size;
          padding: 0 1.35rem 0.75rem 1.35rem;

          &::before {
            content: '';
            display: inline-block;
            width: 7px;
            height: 7px;
            margin: 10px 14px;
            border-radius: 50%;
            background-color: $sidebar-dark-menu-color;
          }

          &.active {
            color: lighten($brand-color, 35%);
            background: transparent;

            &::before {
              background-color: $brand-color;
            }
          }

          &:hover {
            color: lighten($brand-color, 35%);

            &::before {
              background-color: lighten($brand-color, 35%);
            }
          }
        }

        &:hover {
          background: transparent;
        }

        &:first-child {
          .nav-link {
            padding-top: 1.2rem;
          }
        }

        &:last-child {
          .nav-link {
            padding-bottom: 1.2rem;
          }
        }
      }
    }

    &.nav-scrolling {
      padding-top: 0.75rem;

      &::-webkit-scrollbar {
        width: $sidebar-scrollbar-width;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: $default-radius;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: $sidebar-scrollbar-thumb-color;
        }
      }
    }
  }
}

// Style for off-canvas menu
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    margin-top: 0;
    position: fixed;
    top: $navbar-height;
    bottom: 0;
    left: -$sidebar-width-lg;
    max-height: calc(100vh - #{$navbar-height});
    transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;

    &.active {
      left: 0;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    }
  }
}