@charset "UTF-8";
@import url("../../../../node_modules/nouislider/distribute/nouislider.min.css");
@import url("../../../../node_modules/animate.css/animate.min.css");
@import url("../../../../node_modules/mapbox-gl/dist/mapbox-gl.css");
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/poppins.ttf);
}
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #4466F2;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #4466F2;
  --secondary: #6c757d;
  --success: #46c35f;
  --info: #57c7d4;
  --warning: #f6e84e;
  --danger: #f96868;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #4466F2;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0f37da;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #E91E63;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbd4fb;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9eaff8;
}

.table-hover .table-primary:hover {
  background-color: #b3c0f9;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b3c0f9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbeed2;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9fe0ac;
}

.table-hover .table-success:hover {
  background-color: #b8e8c1;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b8e8c1;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d0eff3;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a8e2e9;
}

.table-hover .table-info:hover {
  background-color: #bce8ee;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #bce8ee;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf9cd;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #faf3a3;
}

.table-hover .table-warning:hover {
  background-color: #fbf6b5;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbf6b5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fdd5d5;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fcb0b0;
}

.table-hover .table-danger:hover {
  background-color: #fcbdbd;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fcbdbd;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #bbc8fa;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #46c35f;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(70, 195, 95, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #46c35f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2346c35f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #46c35f;
  box-shadow: 0 0 0 0.2rem rgba(70, 195, 95, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #46c35f;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2346c35f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #46c35f;
  box-shadow: 0 0 0 0.2rem rgba(70, 195, 95, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #46c35f;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #46c35f;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #46c35f;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #6dcf80;
  background-color: #6dcf80;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(70, 195, 95, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #46c35f;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #46c35f;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #46c35f;
  box-shadow: 0 0 0 0.2rem rgba(70, 195, 95, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f96868;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(249, 104, 104, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f96868;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f96868' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f96868' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f96868;
  box-shadow: 0 0 0 0.2rem rgba(249, 104, 104, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f96868;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f96868' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f96868' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #f96868;
  box-shadow: 0 0 0 0.2rem rgba(249, 104, 104, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f96868;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f96868;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #f96868;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fb9999;
  background-color: #fb9999;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(249, 104, 104, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f96868;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f96868;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f96868;
  box-shadow: 0 0 0 0.2rem rgba(249, 104, 104, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #4466F2;
  border-color: #4466F2;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #2049f0;
  border-color: #143fef;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 125, 244, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #4466F2;
  border-color: #4466F2;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #143fef;
  border-color: #103ae6;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 125, 244, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #46c35f;
  border-color: #46c35f;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #38ab4f;
  border-color: #34a24a;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 204, 119, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #46c35f;
  border-color: #46c35f;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #34a24a;
  border-color: #319846;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 204, 119, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #57c7d4;
  border-color: #57c7d4;
}
.btn-info:hover {
  color: #212529;
  background-color: #39bdcc;
  border-color: #33b6c5;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 175, 186, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #57c7d4;
  border-color: #57c7d4;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #33b6c5;
  border-color: #30adbb;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 175, 186, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f6e84e;
  border-color: #f6e84e;
}
.btn-warning:hover {
  color: #212529;
  background-color: #f4e32a;
  border-color: #f4e21d;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 203, 72, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f6e84e;
  border-color: #f6e84e;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #f4e21d;
  border-color: #f3e011;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 203, 72, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #f96868;
  border-color: #f96868;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #f84343;
  border-color: #f73737;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 127, 127, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #f96868;
  border-color: #f96868;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #f73737;
  border-color: #f72b2b;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 127, 127, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #4466F2;
  border-color: #4466F2;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #4466F2;
  border-color: #4466F2;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4466F2;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #4466F2;
  border-color: #4466F2;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #46c35f;
  border-color: #46c35f;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #46c35f;
  border-color: #46c35f;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 195, 95, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #46c35f;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #46c35f;
  border-color: #46c35f;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 195, 95, 0.5);
}

.btn-outline-info {
  color: #57c7d4;
  border-color: #57c7d4;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #57c7d4;
  border-color: #57c7d4;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 199, 212, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #57c7d4;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #57c7d4;
  border-color: #57c7d4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 199, 212, 0.5);
}

.btn-outline-warning {
  color: #f6e84e;
  border-color: #f6e84e;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #f6e84e;
  border-color: #f6e84e;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 232, 78, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f6e84e;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f6e84e;
  border-color: #f6e84e;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 232, 78, 0.5);
}

.btn-outline-danger {
  color: #f96868;
  border-color: #f96868;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #f96868;
  border-color: #f96868;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 104, 104, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f96868;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #f96868;
  border-color: #f96868;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 104, 104, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #4466F2;
  text-decoration: none;
}
.btn-link:hover {
  color: #0f37da;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #4466F2;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #4466F2;
  background-color: #4466F2;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #bbc8fa;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ebeffe;
  border-color: #ebeffe;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #4466F2;
  background-color: #4466F2;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(68, 102, 242, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(68, 102, 242, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(68, 102, 242, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(68, 102, 242, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #bbc8fa;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #bbc8fa;
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4466F2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #ebeffe;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4466F2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #ebeffe;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #4466F2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #ebeffe;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #4466F2;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4466F2;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0f37da;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #4466F2;
  border-color: #4466F2;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #4466F2;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #143fef;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 102, 242, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #46c35f;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #34a24a;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(70, 195, 95, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #57c7d4;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #33b6c5;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(87, 199, 212, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #f6e84e;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #f4e21d;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(246, 232, 78, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #f96868;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #f73737;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(249, 104, 104, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #23357e;
  background-color: #dae0fc;
  border-color: #cbd4fb;
}
.alert-primary hr {
  border-top-color: #b3c0f9;
}
.alert-primary .alert-link {
  color: #182456;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #246531;
  background-color: #daf3df;
  border-color: #cbeed2;
}
.alert-success hr {
  border-top-color: #b8e8c1;
}
.alert-success .alert-link {
  color: #173f1f;
}

.alert-info {
  color: #2d676e;
  background-color: #ddf4f6;
  border-color: #d0eff3;
}
.alert-info hr {
  border-top-color: #bce8ee;
}
.alert-info .alert-link {
  color: #1e454a;
}

.alert-warning {
  color: #807929;
  background-color: #fdfadc;
  border-color: #fcf9cd;
}
.alert-warning hr {
  border-top-color: #fbf6b5;
}
.alert-warning .alert-link {
  color: #59541d;
}

.alert-danger {
  color: #813636;
  background-color: #fee1e1;
  border-color: #fdd5d5;
}
.alert-danger hr {
  border-top-color: #fcbdbd;
}
.alert-danger .alert-link {
  color: #5d2727;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #4466F2;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #4466F2;
  border-color: #4466F2;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #23357e;
  background-color: #cbd4fb;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #23357e;
  background-color: #b3c0f9;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #23357e;
  border-color: #23357e;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #246531;
  background-color: #cbeed2;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #246531;
  background-color: #b8e8c1;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #246531;
  border-color: #246531;
}

.list-group-item-info {
  color: #2d676e;
  background-color: #d0eff3;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #2d676e;
  background-color: #bce8ee;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #2d676e;
  border-color: #2d676e;
}

.list-group-item-warning {
  color: #807929;
  background-color: #fcf9cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #807929;
  background-color: #fbf6b5;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #807929;
  border-color: #807929;
}

.list-group-item-danger {
  color: #813636;
  background-color: #fdd5d5;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #813636;
  background-color: #fcbdbd;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #813636;
  border-color: #813636;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4466F2 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #143fef !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #46c35f !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #34a24a !important;
}

.bg-info {
  background-color: #57c7d4 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #33b6c5 !important;
}

.bg-warning {
  background-color: #f6e84e !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f4e21d !important;
}

.bg-danger {
  background-color: #f96868 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f73737 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4466F2 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #46c35f !important;
}

.border-info {
  border-color: #57c7d4 !important;
}

.border-warning {
  border-color: #f6e84e !important;
}

.border-danger {
  border-color: #f96868 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0, .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #4466F2 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0f37da !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #46c35f !important;
}

a.text-success:hover, a.text-success:focus {
  color: #2e8e41 !important;
}

.text-info {
  color: #57c7d4 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #2da3b1 !important;
}

.text-warning {
  color: #f6e84e !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ecd90c !important;
}

.text-danger {
  color: #f96868 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #f61e1e !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}
.dropdownAnimation, .time-picker-input .dropdown .dropdown-menu, .chips-dropdown .dropdown-menu, .bulk-floating-action-wrapper, .dropdown-with-animation .dropdown-menu, .dropdown.btn-tenant-dropdown .dropdown-menu, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  animation-name: dropdownAnimation;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

/*Bounce Spinner Animation*/
@-webkit-keyframes bounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes bounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.sidebar {
  z-index: 200;
  position: fixed;
  min-height: 100vh;
  max-height: 100vh;
  font-family: "Poppins", sans-serif;
  width: 230px;
  background: var(--sidebar-bg);
  box-shadow: var(--sidebar-box-shadow);
  transition: width 0.25s ease-in-out;
  -ms-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}
.sidebar .navbar-brand-wrapper {
  height: 70px;
  width: 230px;
  background: var(--navbar-brand-bg);
  transition: width 0.25s ease-in-out;
  -ms-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}
.sidebar .navbar-brand-wrapper .navbar-brand {
  width: 100%;
  margin-right: 0;
  padding: 0 10px !important;
}
.sidebar .navbar-brand-wrapper .navbar-brand img {
  height: 50px;
  margin: auto;
  max-width: 100%;
  vertical-align: middle;
}
.sidebar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
  display: none;
}
.sidebar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  max-width: 100%;
  height: 50px;
  margin: auto;
}
.sidebar .nav {
  overflow: hidden;
  overflow-y: auto;
  flex-wrap: nowrap;
  flex-direction: column;
  max-height: calc(100vh - 70px);
}
.sidebar .nav .nav-item {
  padding: 0 1.25rem;
  transition: all 0.25s ease-in-out;
}
.sidebar .nav .nav-item .collapsing {
  margin: 0 -1.25rem 0 -1.25rem;
  background-color: #1e2229;
}
.sidebar .nav .nav-item .collapse {
  z-index: 999;
  margin: 0 -1.25rem 0 -1.25rem;
  background-color: #1e2229;
}
.sidebar .nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.5rem 0 0.5rem 0;
  color: #707887;
  transition: 0.25s ease-in-out;
  transition-property: color;
  -webkit-transition-property: color;
}
.sidebar .nav .nav-item .nav-link .icon-wrapper {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #1e2229;
  transition: color 0.25s ease-in-out;
}
.sidebar .nav .nav-item .nav-link .icon-wrapper svg {
  width: 20px;
  height: 20px;
  color: #707887;
}
.sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
  line-height: 1;
  margin-left: 0.9rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.935rem;
}
.sidebar .nav .nav-item .nav-link i {
  color: #707887;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow {
  opacity: 0.5;
  margin-left: auto;
  display: inline-block;
  color: #707887;
  transition: 0.25s ease-in-out;
  font: normal normal 700 0.75rem "Font Awesome 5 Free";
}
.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
  margin-left: 0;
  margin-right: auto;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  content: "";
  font-size: inherit;
  color: inherit;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
  margin-left: 0.25rem;
}
.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
  margin-left: 0;
  margin-right: 0.25rem;
}
.sidebar .nav .nav-item .nav-link[aria-expanded=true] .menu-arrow {
  transform: rotate(-180deg);
}
.sidebar .nav .nav-item.active .nav-link .icon-wrapper {
  background-color: #019AFF;
}
.sidebar .nav .nav-item.active .nav-link .icon-wrapper svg {
  color: #b4e1ff;
}
.sidebar .nav .nav-item.active .nav-link .menu-title {
  font-family: "Poppins", sans-serif;
  color: #b4e1ff;
}
.sidebar .nav .nav-item.active .nav-link i {
  color: #707887;
}
.sidebar .nav .nav-item.active .nav-link i.menu-arrow {
  transform: rotate(-180deg);
}
.sidebar .nav .nav-item.active .nav-link.collapsed[aria-expanded=false] .menu-arrow {
  transform: rotate(-360deg);
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.nav-profile) > .nav-link {
  color: #b4e1ff;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.nav-profile) > .nav-link .icon-wrapper svg {
  color: #b4e1ff;
}
.sidebar .nav.sub-menu {
  list-style: none;
  margin-bottom: 0;
}
.sidebar .nav.sub-menu .nav-item {
  padding: 0;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
  height: auto;
  border-top: 0;
  line-height: 1;
  position: relative;
  color: #707887;
  font-size: 0.8125rem;
  padding: 0 1.35rem 0.75rem 1.35rem;
}
.sidebar .nav.sub-menu .nav-item .nav-link::before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 10px 14px;
  border-radius: 50%;
  background-color: #707887;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #b4e1ff;
  background: transparent;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active::before {
  background-color: #019AFF;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: #b4e1ff;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover::before {
  background-color: #b4e1ff;
}
.sidebar .nav.sub-menu .nav-item:hover {
  background: transparent;
}
.sidebar .nav.sub-menu .nav-item:first-child .nav-link {
  padding-top: 1.2rem;
}
.sidebar .nav.sub-menu .nav-item:last-child .nav-link {
  padding-bottom: 1.2rem;
}
.sidebar .nav.nav-scrolling {
  padding-top: 0.75rem;
}
.sidebar .nav.nav-scrolling::-webkit-scrollbar {
  width: 6px;
}
.sidebar .nav.nav-scrolling::-webkit-scrollbar-track {
  background: transparent;
}
.sidebar .nav.nav-scrolling::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0.25rem;
}
.sidebar .nav.nav-scrolling:hover::-webkit-scrollbar-thumb {
  background: #566076;
}

@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    margin-top: 0;
    position: fixed;
    top: 70px;
    bottom: 0;
    left: -230px;
    max-height: calc(100vh - 70px);
    transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
  }
  .sidebar-offcanvas.active {
    left: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  }
}
:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.navbar {
  font-family: "Poppins", sans-serif;
  background: var(--navbar-bg);
  margin-left: 230px;
  box-shadow: var(--navbar-box-shadow);
  transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}
.navbar .navbar-menu-wrapper {
  padding: 0 2rem;
  height: 70px;
  color: #9c9fa6;
  width: calc(100% - 230px);
  transition: width 0.25s ease-in-out;
  -ms-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}
.navbar .navbar-menu-wrapper .navbar-brand {
  margin-right: 0;
  padding-right: 20px;
}
.navbar .navbar-menu-wrapper .navbar-brand img {
  margin: auto;
  max-width: 32px;
  vertical-align: middle;
}
.navbar .navbar-menu-wrapper .navbar-toggler {
  border: 0;
  height: 70px;
  color: #019AFF;
}
.navbar .navbar-menu-wrapper .navbar-toggler svg {
  stroke-width: 1.6;
}
.navbar .navbar-menu-wrapper .navbar-toggler:focus {
  outline: none;
}
.navbar .navbar-menu-wrapper .count-indicator {
  position: relative;
}
.navbar .navbar-menu-wrapper .count-indicator .count-symbol {
  position: absolute;
  top: 17px;
  right: -3px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 2px solid #ffffff;
}
.navbar .navbar-menu-wrapper .count-indicator:after {
  display: none;
}
.navbar .navbar-menu-wrapper .navbar-nav {
  flex-direction: row;
  align-items: center;
}
.navbar .navbar-menu-wrapper .navbar-nav .topbar-divider {
  width: 0;
  height: calc(4.375rem - 2rem);
  margin: auto 0.2rem;
  border-right: 1px solid var(--default-border-color);
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  margin-left: 1rem;
  margin-right: 1rem;
  color: #019AFF;
  height: 70px;
}
@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link svg {
  stroke-width: 1.6;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
  content: "";
  font-size: 1rem;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: none;
  border-radius: 0.25rem;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu:before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  position: absolute;
  top: -7px;
  right: 15px;
  left: inherit;
  border-bottom: 7px solid var(--dropdown-menu-bg);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
  margin-top: 0;
  min-width: 150px;
  padding: 0.8rem 0;
  position: absolute;
}
.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
  right: auto;
  left: 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
  cursor: pointer;
  margin-bottom: 0;
  font-size: inherit;
  padding: 1rem 2rem 1rem 2rem;
}
.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
  margin-left: 10px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown {
  width: 333px;
  max-width: 333px;
  padding-top: 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown h6 {
  padding-bottom: 1.5rem !important;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown .dropdown-divider {
  margin: 0.5rem 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown .dropdown-item {
  white-space: initial;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown .dropdown-item .media .media-body media-heading {
  white-space: initial !important;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown .dropdown-item .media .media-body span {
  font-size: 97%;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown .dropdown-item .media .media-body span.link {
  opacity: 0.8;
  display: block;
  font-size: 88%;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown .dropdown-items-wrapper {
  overflow-y: auto;
  max-height: 255px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.no-notification-dropdown .no-notification-img {
  height: 130px !important;
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    top: 70px;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img {
  width: 32px;
  height: 32px;
  position: relative;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  bottom: 5px;
  right: -5px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.online {
  background: #46c35f;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.offline {
  background: #f96868;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.busy {
  background: #f6e84e;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
  color: var(--default-font-color);
  margin-right: 1.25rem;
}
.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
  margin-left: 0;
  margin-right: 1.25rem;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text p {
  line-height: 1;
}
@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
    display: none;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link.dropdown-toggle:after {
  line-height: 2;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu {
  width: 250px !important;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu .dropdown-divider {
  margin: 0.5rem 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu .dropdown-item.profile {
  cursor: default;
  font-size: 90%;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu .dropdown-item.profile:hover {
  background: transparent;
  color: var(--default-font-color);
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu .dropdown-item.profile .nav-profile-text p {
  text-overflow: initial !important;
  white-space: normal !important;
  text-wrap: normal !important;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu .dropdown-item svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.7;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu .nav-profile-img {
  margin-right: 1rem;
  position: relative;
  width: 50px;
  height: 50px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .dropdown-menu .nav-profile-img img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
@media (min-width: 992px) {
  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  }
  .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: 0;
    margin-right: auto;
  }
}

@media (max-width: 991px) {
  .navbar {
    margin-left: 0;
    flex-direction: row;
  }
  .navbar .navbar-menu-wrapper {
    width: 100%;
  }

  .sidebar .navbar-brand-wrapper {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 575px) {
  .navbar .navbar-menu-wrapper .navbar-toggler {
    padding-right: 12px;
  }
}
:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.breadcrumb {
  background-color: transparent;
}
.breadcrumb .breadcrumb-item .page-icon {
  width: 14px;
  height: 14px;
  color: #019AFF;
}
.breadcrumb .breadcrumb-item.page-header {
  align-items: center;
}

@media (min-width: 576px) {
  .breadcrumb .breadcrumb-item .page-icon {
    width: 16px;
    height: 16px;
  }
}
:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.pagination {
  border-radius: 0.25rem;
}
.pagination .page-item:focus {
  outline: none;
}
.pagination .page-item .page-link {
  padding: 0;
  margin-right: 10px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
}
.pagination .page-item .page-link:hover {
  background-color: #b4e1ff !important;
}
.pagination .page-item .page-link svg {
  height: 18px;
  width: 18px;
  stroke-width: 1.7;
}
.pagination .page-item .page-link.active {
  color: #ffffff;
  background-color: #019AFF;
}
.pagination .page-item .page-link.active:hover {
  color: #ffffff;
  background-color: #019AFF !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.dropdown .dropdown-toggle:after {
  border: 0;
  content: "";
  width: auto;
  height: auto;
  font-size: 0.75rem;
  vertical-align: baseline;
}
.dropdown .dropdown-menu {
  border: 0;
  left: inherit;
  margin-top: 0.75rem;
  font-size: 0.935rem;
  color: var(--default-font-color);
  background-color: var(--dropdown-menu-bg);
  box-shadow: var(--default-box-shadow);
}
.dropdown .dropdown-menu .dropdown-divider {
  margin: 0;
  border-color: var(--default-border-color);
}
.dropdown .dropdown-menu .dropdown-item {
  font-size: inherit;
  padding: 0.35rem 1.5rem;
  color: var(--default-font-color);
  transition: 0.25s ease-in-out;
}
.dropdown .dropdown-menu .dropdown-item:active {
  background: initial;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  color: #019AFF;
  background-color: var(--base-color);
}
.dropdown .dropdown-menu.dropdown-menu-center {
  right: auto !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -o-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
}
.dropdown.btn-dropdown .dropdown-toggle {
  box-shadow: var(--default-box-shadow);
}
.dropdown.btn-dropdown .dropdown-toggle:after {
  border: 0;
  content: "";
  margin-left: 0.95em;
  display: inline-block;
  vertical-align: inherit;
  transition: all 0.25s ease-in-out;
  font: normal normal 700 0.55rem "Font Awesome 5 Free";
}
.dropdown.btn-dropdown .dropdown-toggle[aria-expanded=true]:after {
  transform: rotate(180deg);
}
.dropdown.btn-dropdown .dropdown-menu {
  margin-top: 0.25rem;
}
.dropdown.btn-dropdown.show .btn-light.dropdown-toggle {
  color: var(--default-font-color);
  border-color: var(--btn-light-bg);
  background-color: var(--btn-light-bg);
}
.dropdown.options-dropdown .btn-option {
  padding: 0;
  width: 31px;
  height: 30px;
  border-radius: 50%;
  color: #019AFF;
}
.dropdown.options-dropdown .btn-option svg {
  width: 14px;
  height: 14px;
}
.dropdown.options-dropdown .btn-option:hover {
  background-color: var(--icon-hover-bg);
}
.dropdown.btn-tenant-dropdown .btn {
  font-size: 0.935rem;
  border-radius: 0.25rem;
  box-shadow: none !important;
  background-color: var(--base-color);
}
.dropdown.btn-tenant-dropdown .btn:hover {
  color: var(--default-font-color);
}
.dropdown.btn-tenant-dropdown .dropdown-menu {
  min-width: 15rem;
}
.dropdown.btn-tenant-dropdown .dropdown-menu:before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  position: absolute;
  top: -7px;
  right: inherit;
  left: 15px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid var(--dropdown-menu-bg);
}
.dropdown.btn-tenant-dropdown .dropdown-menu .dropdown-item {
  white-space: initial;
}
.dropdown.btn-tenant-dropdown .dropdown-menu .dropdown-item.dropdown-title {
  padding: 1.5rem;
}
.dropdown.btn-tenant-dropdown .dropdown-menu .tenant-items {
  padding: 0.5rem 0;
}
.dropdown.btn-tenant-dropdown .dropdown-menu .tenant-items .dropdown-item {
  padding: 0.75rem 1.5rem;
}
@media (max-width: 767px) {
  .dropdown.btn-tenant-dropdown .btn {
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
}

.dropdown-with-animation .dropdown-menu.show {
  top: 100% !important;
}
.dropdown-with-animation .dropdown-menu[x-placement^=left],
.dropdown-with-animation .dropdown-menu[x-placement^=right],
.dropdown-with-animation .dropdown-menu[x-placement^=top] {
  top: auto !important;
  -webkit-animation: none !important;
  animation: none !important;
}
.dropdown-with-animation .dropdown-menu[x-placement^=bottom-start] {
  margin-top: 7px !important;
}
.dropdown-with-animation .dropdown-menu[x-placement^=top-start] {
  top: 0 !important;
  margin-bottom: 7px !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.datatable .table-responsive {
  min-height: 400px;
  background-color: var(--default-card-bg);
}
.datatable .table-responsive::-webkit-scrollbar {
  height: 8px;
}
.datatable .table-responsive::-webkit-scrollbar-thumb {
  background-color: #67c3ff;
}
.datatable .table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #4eb8ff;
}
.datatable .table-responsive::-webkit-scrollbar-track {
  background-color: var(--base-color);
}
.datatable table {
  margin-bottom: 0;
  color: var(--default-font-color);
}
.datatable table thead th.datatable-th {
  border: 0;
  padding: 2rem 1rem;
  color: #6c757d !important;
  vertical-align: top !important;
  white-space: nowrap;
}
.datatable table thead th.datatable-th:first-child {
  padding-left: 2rem;
}
.datatable table thead th.datatable-th:last-child {
  padding-right: 2rem;
}
.datatable table thead th.datatable-th .btn {
  width: 100%;
  padding: 0;
}
.datatable table thead th.datatable-th .btn:hover {
  color: #019AFF !important;
}
.datatable table thead th.datatable-th .btn svg {
  width: 14px;
  height: 14px;
}
.datatable table tbody tr td.datatable-td {
  vertical-align: middle;
  padding: 1.5rem 1rem;
  border-color: var(--default-border-color);
}
.datatable table tbody tr td.datatable-td:first-child {
  padding-left: 2rem;
}
.datatable table tbody tr td.datatable-td:last-child {
  padding-right: 2rem;
}
.datatable table tbody tr td.datatable-td svg {
  height: 14px;
}
.datatable table tbody tr td.datatable-td.table-action {
  display: flex;
  justify-content: flex-end;
}
.datatable table tbody tr:last-child td.datatable-td {
  padding-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .table-view-responsive table, .table-view-responsive thead, .table-view-responsive tbody, .table-view-responsive th, .table-view-responsive td, .table-view-responsive tr {
    display: block;
  }
  .table-view-responsive thead tr {
    display: none;
  }
  .table-view-responsive tr {
    border: 1px solid var(--default-border-color);
  }
  .table-view-responsive tr:first-child {
    border-bottom: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .table-view-responsive tr:last-child {
    border-top: 0;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .table-view-responsive tr td {
    border: 0;
    padding: 1rem 1.5rem !important;
    white-space: normal;
  }
  .table-view-responsive tr td.table-action {
    display: block !important;
  }
  .table-view-responsive tr td:before {
    width: 45%;
    font-weight: bold;
    display: inline-block;
    content: attr(data-title);
  }
}

.no-data-found-wrapper img {
  width: 150px;
  opacity: var(--not-found-opacity);
}

.highlighted {
  background-color: var(--base-color);
}
.highlighted td {
  background-color: var(--base-color);
}

.bulk-floating-action-wrapper {
  z-index: 1031;
  position: fixed;
  top: 44px;
  right: 0;
  left: 0;
}
.bulk-floating-action-wrapper .actions {
  margin: 0 auto;
  width: fit-content;
  box-shadow: var(--default-box-shadow);
  background-color: var(--btn-light-bg);
}
.bulk-floating-action-wrapper .actions .dropdown {
  margin: 0 -2px;
}
.bulk-floating-action-wrapper .actions .dropdown.btn-dropdown .dropdown-toggle {
  padding: 0.8rem;
  box-shadow: none;
  color: #9397A0;
}
.bulk-floating-action-wrapper .actions .dropdown.btn-dropdown .dropdown-toggle::after {
  content: "";
  margin: 0;
}
.bulk-floating-action-wrapper .actions .dropdown.btn-dropdown .dropdown-toggle:hover {
  color: #019AFF;
}
.bulk-floating-action-wrapper .actions .dropdown.btn-dropdown .dropdown-toggle svg {
  stroke-width: 1.5;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-toggle {
  border-radius: 0;
}
.bulk-floating-action-wrapper .actions .dropdown:first-child .dropdown-toggle {
  border-radius: 0.25rem 0 0 0.25rem;
}
.bulk-floating-action-wrapper .actions .dropdown:last-child .dropdown-toggle {
  border-radius: 0 0.25rem 0.25rem 0;
}
.bulk-floating-action-wrapper .actions .dropdown.show .dropdown-toggle {
  color: #ffffff !important;
  background-color: #019AFF !important;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu {
  min-width: max-content;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu .dropdown-item {
  white-space: initial;
  padding: 1rem 2rem 1rem 2rem !important;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select {
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
  box-shadow: var(--default-box-shadow);
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select .dropdown-search-result-wrapper {
  padding: 0.8rem 0;
  max-height: 320px;
  overflow-y: auto;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select .dropdown-search-result-wrapper .dropdown-item {
  font-size: 95% !important;
  color: #898989;
  padding: 1rem 2rem 1rem 2rem !important;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select .dropdown-search-result-wrapper .dropdown-item.active {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select .dropdown-search-result-wrapper .dropdown-item.selected {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select .dropdown-search-result-wrapper .dropdown-item.selected .check-sign {
  display: block;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select .dropdown-search-result-wrapper .dropdown-item:hover {
  background-color: var(--base-color) !important;
  color: #898989 !important;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.search-and-select a .check-sign {
  display: none;
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-menu.dropdown-menu-with-search {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}
@media (min-width: 575px) {
  .bulk-floating-action-wrapper .actions .dropdown .dropdown-menu {
    top: auto !important;
    animation-name: dropdownAnimation;
    animation-duration: 0.25s;
    animation-fill-mode: both;
  }
  @keyframes dropdownAnimation {
    from {
      opacity: 0;
      transform: translate3d(0, -30px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0px, 0);
    }
  }
}
@media (max-width: 575px) {
  .bulk-floating-action-wrapper .actions .dropdown .dropdown-menu {
    top: 0 !important;
  }
}
.bulk-floating-action-wrapper .actions .dropdown .dropdown-search-result-wrapper {
  max-height: 320px;
  overflow-y: auto;
}

.table-expanded-column .expandable-btn {
  transition: 0.5s;
}
.table-expanded-column .expandable-btn[aria-expanded=true] {
  transform: rotateX(180deg);
}

.cursor-default {
  cursor: default !important;
}

.table-expandable-area td {
  padding: 0 !important;
  border-top: 0 !important;
}

@media only screen and (max-width: 767px) {
  .table-view-responsive .table-expandable-area {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .table-view-responsive .table-expandable-area td {
    padding: 0 !important;
  }
  .table-view-responsive .table-expandable-area td:before {
    display: none !important;
  }
}
.remove-datatable-x-padding .datatable table thead th:first-child {
  padding-left: 0;
}
.remove-datatable-x-padding .datatable table thead th:last-child {
  padding-right: 0;
}
.remove-datatable-x-padding .datatable table tbody tr td:first-child {
  padding-left: 0;
}
.remove-datatable-x-padding .datatable table tbody tr td:last-child {
  padding-right: 0;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.tab-pane .content .single-search-wrapper .form-group-with-search .form-control {
  box-shadow: none !important;
  background-color: var(--base-color) !important;
  border-color: var(--default-border-color) !important;
}
.tab-pane .content .single-search-wrapper .form-group-with-search .form-control:focus {
  border-color: #019AFF !important;
}

@media (min-width: 768px) {
  .vertical-tab .tab-menu {
    max-width: 265px !important;
    flex: 0 0 265px !important;
  }
}
.vertical-tab .tab-menu .card {
  height: 100%;
}
.vertical-tab .tab-menu .tab-menu-wrapper {
  padding-top: 2.5rem !important;
}
.vertical-tab .col-md-9 .card {
  height: 100%;
}
@media (min-width: 768px) {
  .vertical-tab .col-md-9 {
    max-width: calc(100% - 265px) !important;
    flex: 0 0 calc(100% - 265px) !important;
  }
}
.vertical-tab .header-icon {
  height: 90px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: var(--vertical-tab-header-bg);
}
.vertical-tab .header-icon .icon-position {
  height: 60px;
  margin-top: 60px;
}
.vertical-tab .header-icon .icon-position .tab-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--vertical-tab-icon-bg);
  box-shadow: var(--vertical-tab-icon-shadow);
}
.vertical-tab .header-icon .icon-position .tab-icon svg {
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
  color: #019AFF;
}
.vertical-tab .nav .tab-item-link {
  color: #8A8A8A;
}
.vertical-tab .nav .tab-item-link .active-icon svg {
  transition: 0.25s ease-in-out !important;
  opacity: 0;
  height: 20px;
}
.vertical-tab .nav .tab-item-link:hover {
  color: var(--default-font-color);
}
.vertical-tab .nav .tab-item-link:hover .active-icon svg {
  opacity: 1;
}
.vertical-tab .nav .tab-item-link.active {
  color: #019AFF;
}
.vertical-tab .nav .tab-item-link.active .active-icon svg {
  opacity: 1;
}
.vertical-tab .tab-content .tab-content-header {
  height: 90px;
}
.vertical-tab .tab-content hr {
  margin: 0 -2rem;
}
.vertical-tab .tab-content .content .datatable table thead th:first-child {
  padding-left: 0;
}
.vertical-tab .tab-content .content .datatable table thead th:last-child {
  padding-right: 0;
}
.vertical-tab .tab-content .content .datatable table tbody tr td:first-child {
  padding-left: 0;
}
.vertical-tab .tab-content .content .datatable table tbody tr td:last-child {
  padding-right: 0;
}

.horizontal-tab .card .nav a {
  color: var(--default-font-color);
  margin-bottom: -2px !important;
}
.horizontal-tab .card .nav a:hover {
  color: #019AFF;
}
.horizontal-tab .card .nav a.active {
  color: #019AFF;
  border-bottom: 3px solid #019AFF;
}
.horizontal-tab .card .nav a svg {
  height: 16px;
  width: 16px;
}
.horizontal-tab .card .nav-tabs {
  border-bottom: 1px solid var(--default-border-color);
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.navbar.fixed-top + .page-body-wrapper {
  padding-top: 70px;
}

@media (min-width: 992px) {
  .sidebar-icon-only .container-scroller {
    position: relative;
  }
  .sidebar-icon-only .navbar {
    margin-left: 70px;
  }
  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px);
  }
  .sidebar-icon-only .sidebar {
    min-height: 100%;
    position: absolute;
    width: 70px;
  }
  .sidebar-icon-only .sidebar .navbar-brand-wrapper {
    z-index: 12;
    position: fixed;
    width: 70px;
  }
  .sidebar-icon-only .sidebar .navbar-brand-wrapper .brand-logo {
    display: none;
  }
  .sidebar-icon-only .sidebar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
  }
  .sidebar-icon-only .sidebar .nav {
    overflow: visible;
    margin-top: 70px;
  }
  .sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
    padding: 0 1rem;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    text-align: center;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title, .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 0 5px 5px 0px;
  }
  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 5px 0 0 5px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0;
    margin-left: 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
    border-radius: 0 5px 0 0px;
  }
  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
    border-radius: 5px 0 0 0;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .collapse {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link {
    display: flex;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    display: flex;
    align-items: center;
    background: var(--sidebar-bg);
    padding: 0.75rem 1.35rem 0.75rem 1.35rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 190px;
    z-index: 1;
    line-height: 1.8;
    margin-left: 0;
    box-shadow: var(--menu-title-shadow);
  }
  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    left: auto;
    right: 70px;
    text-align: left;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title:after {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    display: block;
    background: var(--sidebar-bg);
    margin: 0;
    border-radius: 0 0 5px 0;
    position: absolute;
    left: 70px;
    width: 190px;
    box-shadow: var(--submenu-shadow);
  }
  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse, .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    left: auto;
    right: 70px;
    border-radius: 0 0 0 5px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse .sub-menu .nav-link,
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing .sub-menu .nav-link {
    line-height: 1.15;
    white-space: initial;
  }
  .sidebar-icon-only .sidebar .nav.sub-menu {
    margin: 0 0 20px 0;
    background-color: var(--sidebar-bg);
  }
  .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }
  .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link .icon-wrapper {
    background-color: #1e2229;
  }
  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
    margin-right: auto;
    margin-left: 0;
  }
  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
    text-align: right;
  }
  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
    left: 0;
    right: unset;
  }

  .sidebar-icon-only .main-panel {
    min-height: 1200px;
    margin-left: 70px;
    width: calc(100% - 70px);
  }
}

@media (min-width: 992px) {
  .sidebar-hover-only .navbar {
    margin-left: 70px;
    z-index: 10;
  }
  .sidebar-hover-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px);
  }
  .sidebar-hover-only .sidebar {
    width: 70px;
  }
  .sidebar-hover-only .sidebar .navbar-brand-wrapper {
    width: 70px;
  }
  .sidebar-hover-only .sidebar .navbar-brand-wrapper .brand-logo {
    display: none;
  }
  .sidebar-hover-only .sidebar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
  }
  .sidebar-hover-only .sidebar:hover {
    width: 230px;
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.5);
  }
  .sidebar-hover-only .sidebar:hover .navbar-brand-wrapper {
    width: 230px;
  }
  .sidebar-hover-only .sidebar:hover .nav .nav-item .nav-link .menu-title {
    display: block;
  }
  .sidebar-hover-only .sidebar:hover .nav .nav-item .nav-link i.menu-arrow {
    display: inline-block;
  }
  .sidebar-hover-only .sidebar:hover .nav .nav-item.active .nav-link[aria-expanded=false] .menu-arrow {
    transform: none;
  }
  .sidebar-hover-only .sidebar .navbar-brand-wrapper .brand-logo {
    display: none;
  }
  .sidebar-hover-only .sidebar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
  }
  .sidebar-hover-only .sidebar .nav .nav-item {
    padding: 0 1rem;
  }
  .sidebar-hover-only .sidebar .nav .nav-item .nav-link .menu-title {
    display: none;
  }
  .sidebar-hover-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0;
    margin-left: 0;
  }
  .sidebar-hover-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: none;
  }
  .sidebar-hover-only .sidebar .nav.sub-menu .nav-item {
    padding: 0;
  }
  .sidebar-hover-only .main-panel {
    margin-left: 70px;
    width: calc(100% - 70px);
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}
.rtl .sidebar .nav {
  padding-right: 0;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

body, html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  padding-right: 0 !important;
  font-size: 0.935rem;
  color: var(--default-font-color);
}
body::-webkit-scrollbar, html::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body::-webkit-scrollbar-track, html::-webkit-scrollbar-track {
  background-color: lightgray;
}
body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 5px;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

a {
  text-decoration: none !important;
}
a.text-muted:hover {
  color: #019AFF !important;
}

hr {
  border-color: var(--default-border-color);
}

span {
  font-size: 0.935rem;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}

.container-scroller {
  overflow: hidden;
  min-height: 105vh;
  background-color: var(--base-color);
}

.disabled {
  opacity: 0.6 !important;
  pointer-events: none !important;
}

.loading-opacity {
  opacity: 0.4;
}

.h-fit-content {
  height: fit-content;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.schedule-divider:before {
  content: "";
  width: 20px;
  height: 2px;
  margin: 0 10px 0 8px;
  display: inline-block;
  background-color: #afb1b6;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.width-1 {
  width: 1px !important;
}

.min-width-1 {
  min-width: 1px !important;
}

.max-width-1 {
  max-width: 1px !important;
}

.height-1 {
  height: 1px !important;
}

.min-height-1 {
  min-height: 1px !important;
}

.max-height-1 {
  max-height: 1px !important;
}

.width-2 {
  width: 2px !important;
}

.min-width-2 {
  min-width: 2px !important;
}

.max-width-2 {
  max-width: 2px !important;
}

.height-2 {
  height: 2px !important;
}

.min-height-2 {
  min-height: 2px !important;
}

.max-height-2 {
  max-height: 2px !important;
}

.width-3 {
  width: 3px !important;
}

.min-width-3 {
  min-width: 3px !important;
}

.max-width-3 {
  max-width: 3px !important;
}

.height-3 {
  height: 3px !important;
}

.min-height-3 {
  min-height: 3px !important;
}

.max-height-3 {
  max-height: 3px !important;
}

.width-4 {
  width: 4px !important;
}

.min-width-4 {
  min-width: 4px !important;
}

.max-width-4 {
  max-width: 4px !important;
}

.height-4 {
  height: 4px !important;
}

.min-height-4 {
  min-height: 4px !important;
}

.max-height-4 {
  max-height: 4px !important;
}

.width-5 {
  width: 5px !important;
}

.min-width-5 {
  min-width: 5px !important;
}

.max-width-5 {
  max-width: 5px !important;
}

.height-5 {
  height: 5px !important;
}

.min-height-5 {
  min-height: 5px !important;
}

.max-height-5 {
  max-height: 5px !important;
}

.width-6 {
  width: 6px !important;
}

.min-width-6 {
  min-width: 6px !important;
}

.max-width-6 {
  max-width: 6px !important;
}

.height-6 {
  height: 6px !important;
}

.min-height-6 {
  min-height: 6px !important;
}

.max-height-6 {
  max-height: 6px !important;
}

.width-7 {
  width: 7px !important;
}

.min-width-7 {
  min-width: 7px !important;
}

.max-width-7 {
  max-width: 7px !important;
}

.height-7 {
  height: 7px !important;
}

.min-height-7 {
  min-height: 7px !important;
}

.max-height-7 {
  max-height: 7px !important;
}

.width-8 {
  width: 8px !important;
}

.min-width-8 {
  min-width: 8px !important;
}

.max-width-8 {
  max-width: 8px !important;
}

.height-8 {
  height: 8px !important;
}

.min-height-8 {
  min-height: 8px !important;
}

.max-height-8 {
  max-height: 8px !important;
}

.width-9 {
  width: 9px !important;
}

.min-width-9 {
  min-width: 9px !important;
}

.max-width-9 {
  max-width: 9px !important;
}

.height-9 {
  height: 9px !important;
}

.min-height-9 {
  min-height: 9px !important;
}

.max-height-9 {
  max-height: 9px !important;
}

.width-10 {
  width: 10px !important;
}

.min-width-10 {
  min-width: 10px !important;
}

.max-width-10 {
  max-width: 10px !important;
}

.height-10 {
  height: 10px !important;
}

.min-height-10 {
  min-height: 10px !important;
}

.max-height-10 {
  max-height: 10px !important;
}

.width-11 {
  width: 11px !important;
}

.min-width-11 {
  min-width: 11px !important;
}

.max-width-11 {
  max-width: 11px !important;
}

.height-11 {
  height: 11px !important;
}

.min-height-11 {
  min-height: 11px !important;
}

.max-height-11 {
  max-height: 11px !important;
}

.width-12 {
  width: 12px !important;
}

.min-width-12 {
  min-width: 12px !important;
}

.max-width-12 {
  max-width: 12px !important;
}

.height-12 {
  height: 12px !important;
}

.min-height-12 {
  min-height: 12px !important;
}

.max-height-12 {
  max-height: 12px !important;
}

.width-13 {
  width: 13px !important;
}

.min-width-13 {
  min-width: 13px !important;
}

.max-width-13 {
  max-width: 13px !important;
}

.height-13 {
  height: 13px !important;
}

.min-height-13 {
  min-height: 13px !important;
}

.max-height-13 {
  max-height: 13px !important;
}

.width-14 {
  width: 14px !important;
}

.min-width-14 {
  min-width: 14px !important;
}

.max-width-14 {
  max-width: 14px !important;
}

.height-14 {
  height: 14px !important;
}

.min-height-14 {
  min-height: 14px !important;
}

.max-height-14 {
  max-height: 14px !important;
}

.width-15 {
  width: 15px !important;
}

.min-width-15 {
  min-width: 15px !important;
}

.max-width-15 {
  max-width: 15px !important;
}

.height-15 {
  height: 15px !important;
}

.min-height-15 {
  min-height: 15px !important;
}

.max-height-15 {
  max-height: 15px !important;
}

.width-16 {
  width: 16px !important;
}

.min-width-16 {
  min-width: 16px !important;
}

.max-width-16 {
  max-width: 16px !important;
}

.height-16 {
  height: 16px !important;
}

.min-height-16 {
  min-height: 16px !important;
}

.max-height-16 {
  max-height: 16px !important;
}

.width-17 {
  width: 17px !important;
}

.min-width-17 {
  min-width: 17px !important;
}

.max-width-17 {
  max-width: 17px !important;
}

.height-17 {
  height: 17px !important;
}

.min-height-17 {
  min-height: 17px !important;
}

.max-height-17 {
  max-height: 17px !important;
}

.width-18 {
  width: 18px !important;
}

.min-width-18 {
  min-width: 18px !important;
}

.max-width-18 {
  max-width: 18px !important;
}

.height-18 {
  height: 18px !important;
}

.min-height-18 {
  min-height: 18px !important;
}

.max-height-18 {
  max-height: 18px !important;
}

.width-19 {
  width: 19px !important;
}

.min-width-19 {
  min-width: 19px !important;
}

.max-width-19 {
  max-width: 19px !important;
}

.height-19 {
  height: 19px !important;
}

.min-height-19 {
  min-height: 19px !important;
}

.max-height-19 {
  max-height: 19px !important;
}

.width-20 {
  width: 20px !important;
}

.min-width-20 {
  min-width: 20px !important;
}

.max-width-20 {
  max-width: 20px !important;
}

.height-20 {
  height: 20px !important;
}

.min-height-20 {
  min-height: 20px !important;
}

.max-height-20 {
  max-height: 20px !important;
}

.width-21 {
  width: 21px !important;
}

.min-width-21 {
  min-width: 21px !important;
}

.max-width-21 {
  max-width: 21px !important;
}

.height-21 {
  height: 21px !important;
}

.min-height-21 {
  min-height: 21px !important;
}

.max-height-21 {
  max-height: 21px !important;
}

.width-22 {
  width: 22px !important;
}

.min-width-22 {
  min-width: 22px !important;
}

.max-width-22 {
  max-width: 22px !important;
}

.height-22 {
  height: 22px !important;
}

.min-height-22 {
  min-height: 22px !important;
}

.max-height-22 {
  max-height: 22px !important;
}

.width-23 {
  width: 23px !important;
}

.min-width-23 {
  min-width: 23px !important;
}

.max-width-23 {
  max-width: 23px !important;
}

.height-23 {
  height: 23px !important;
}

.min-height-23 {
  min-height: 23px !important;
}

.max-height-23 {
  max-height: 23px !important;
}

.width-24 {
  width: 24px !important;
}

.min-width-24 {
  min-width: 24px !important;
}

.max-width-24 {
  max-width: 24px !important;
}

.height-24 {
  height: 24px !important;
}

.min-height-24 {
  min-height: 24px !important;
}

.max-height-24 {
  max-height: 24px !important;
}

.width-25 {
  width: 25px !important;
}

.min-width-25 {
  min-width: 25px !important;
}

.max-width-25 {
  max-width: 25px !important;
}

.height-25 {
  height: 25px !important;
}

.min-height-25 {
  min-height: 25px !important;
}

.max-height-25 {
  max-height: 25px !important;
}

.width-26 {
  width: 26px !important;
}

.min-width-26 {
  min-width: 26px !important;
}

.max-width-26 {
  max-width: 26px !important;
}

.height-26 {
  height: 26px !important;
}

.min-height-26 {
  min-height: 26px !important;
}

.max-height-26 {
  max-height: 26px !important;
}

.width-27 {
  width: 27px !important;
}

.min-width-27 {
  min-width: 27px !important;
}

.max-width-27 {
  max-width: 27px !important;
}

.height-27 {
  height: 27px !important;
}

.min-height-27 {
  min-height: 27px !important;
}

.max-height-27 {
  max-height: 27px !important;
}

.width-28 {
  width: 28px !important;
}

.min-width-28 {
  min-width: 28px !important;
}

.max-width-28 {
  max-width: 28px !important;
}

.height-28 {
  height: 28px !important;
}

.min-height-28 {
  min-height: 28px !important;
}

.max-height-28 {
  max-height: 28px !important;
}

.width-29 {
  width: 29px !important;
}

.min-width-29 {
  min-width: 29px !important;
}

.max-width-29 {
  max-width: 29px !important;
}

.height-29 {
  height: 29px !important;
}

.min-height-29 {
  min-height: 29px !important;
}

.max-height-29 {
  max-height: 29px !important;
}

.width-30 {
  width: 30px !important;
}

.min-width-30 {
  min-width: 30px !important;
}

.max-width-30 {
  max-width: 30px !important;
}

.height-30 {
  height: 30px !important;
}

.min-height-30 {
  min-height: 30px !important;
}

.max-height-30 {
  max-height: 30px !important;
}

.width-31 {
  width: 31px !important;
}

.min-width-31 {
  min-width: 31px !important;
}

.max-width-31 {
  max-width: 31px !important;
}

.height-31 {
  height: 31px !important;
}

.min-height-31 {
  min-height: 31px !important;
}

.max-height-31 {
  max-height: 31px !important;
}

.width-32 {
  width: 32px !important;
}

.min-width-32 {
  min-width: 32px !important;
}

.max-width-32 {
  max-width: 32px !important;
}

.height-32 {
  height: 32px !important;
}

.min-height-32 {
  min-height: 32px !important;
}

.max-height-32 {
  max-height: 32px !important;
}

.width-33 {
  width: 33px !important;
}

.min-width-33 {
  min-width: 33px !important;
}

.max-width-33 {
  max-width: 33px !important;
}

.height-33 {
  height: 33px !important;
}

.min-height-33 {
  min-height: 33px !important;
}

.max-height-33 {
  max-height: 33px !important;
}

.width-34 {
  width: 34px !important;
}

.min-width-34 {
  min-width: 34px !important;
}

.max-width-34 {
  max-width: 34px !important;
}

.height-34 {
  height: 34px !important;
}

.min-height-34 {
  min-height: 34px !important;
}

.max-height-34 {
  max-height: 34px !important;
}

.width-35 {
  width: 35px !important;
}

.min-width-35 {
  min-width: 35px !important;
}

.max-width-35 {
  max-width: 35px !important;
}

.height-35 {
  height: 35px !important;
}

.min-height-35 {
  min-height: 35px !important;
}

.max-height-35 {
  max-height: 35px !important;
}

.width-36 {
  width: 36px !important;
}

.min-width-36 {
  min-width: 36px !important;
}

.max-width-36 {
  max-width: 36px !important;
}

.height-36 {
  height: 36px !important;
}

.min-height-36 {
  min-height: 36px !important;
}

.max-height-36 {
  max-height: 36px !important;
}

.width-37 {
  width: 37px !important;
}

.min-width-37 {
  min-width: 37px !important;
}

.max-width-37 {
  max-width: 37px !important;
}

.height-37 {
  height: 37px !important;
}

.min-height-37 {
  min-height: 37px !important;
}

.max-height-37 {
  max-height: 37px !important;
}

.width-38 {
  width: 38px !important;
}

.min-width-38 {
  min-width: 38px !important;
}

.max-width-38 {
  max-width: 38px !important;
}

.height-38 {
  height: 38px !important;
}

.min-height-38 {
  min-height: 38px !important;
}

.max-height-38 {
  max-height: 38px !important;
}

.width-39 {
  width: 39px !important;
}

.min-width-39 {
  min-width: 39px !important;
}

.max-width-39 {
  max-width: 39px !important;
}

.height-39 {
  height: 39px !important;
}

.min-height-39 {
  min-height: 39px !important;
}

.max-height-39 {
  max-height: 39px !important;
}

.width-40 {
  width: 40px !important;
}

.min-width-40 {
  min-width: 40px !important;
}

.max-width-40 {
  max-width: 40px !important;
}

.height-40 {
  height: 40px !important;
}

.min-height-40 {
  min-height: 40px !important;
}

.max-height-40 {
  max-height: 40px !important;
}

.width-41 {
  width: 41px !important;
}

.min-width-41 {
  min-width: 41px !important;
}

.max-width-41 {
  max-width: 41px !important;
}

.height-41 {
  height: 41px !important;
}

.min-height-41 {
  min-height: 41px !important;
}

.max-height-41 {
  max-height: 41px !important;
}

.width-42 {
  width: 42px !important;
}

.min-width-42 {
  min-width: 42px !important;
}

.max-width-42 {
  max-width: 42px !important;
}

.height-42 {
  height: 42px !important;
}

.min-height-42 {
  min-height: 42px !important;
}

.max-height-42 {
  max-height: 42px !important;
}

.width-43 {
  width: 43px !important;
}

.min-width-43 {
  min-width: 43px !important;
}

.max-width-43 {
  max-width: 43px !important;
}

.height-43 {
  height: 43px !important;
}

.min-height-43 {
  min-height: 43px !important;
}

.max-height-43 {
  max-height: 43px !important;
}

.width-44 {
  width: 44px !important;
}

.min-width-44 {
  min-width: 44px !important;
}

.max-width-44 {
  max-width: 44px !important;
}

.height-44 {
  height: 44px !important;
}

.min-height-44 {
  min-height: 44px !important;
}

.max-height-44 {
  max-height: 44px !important;
}

.width-45 {
  width: 45px !important;
}

.min-width-45 {
  min-width: 45px !important;
}

.max-width-45 {
  max-width: 45px !important;
}

.height-45 {
  height: 45px !important;
}

.min-height-45 {
  min-height: 45px !important;
}

.max-height-45 {
  max-height: 45px !important;
}

.width-46 {
  width: 46px !important;
}

.min-width-46 {
  min-width: 46px !important;
}

.max-width-46 {
  max-width: 46px !important;
}

.height-46 {
  height: 46px !important;
}

.min-height-46 {
  min-height: 46px !important;
}

.max-height-46 {
  max-height: 46px !important;
}

.width-47 {
  width: 47px !important;
}

.min-width-47 {
  min-width: 47px !important;
}

.max-width-47 {
  max-width: 47px !important;
}

.height-47 {
  height: 47px !important;
}

.min-height-47 {
  min-height: 47px !important;
}

.max-height-47 {
  max-height: 47px !important;
}

.width-48 {
  width: 48px !important;
}

.min-width-48 {
  min-width: 48px !important;
}

.max-width-48 {
  max-width: 48px !important;
}

.height-48 {
  height: 48px !important;
}

.min-height-48 {
  min-height: 48px !important;
}

.max-height-48 {
  max-height: 48px !important;
}

.width-49 {
  width: 49px !important;
}

.min-width-49 {
  min-width: 49px !important;
}

.max-width-49 {
  max-width: 49px !important;
}

.height-49 {
  height: 49px !important;
}

.min-height-49 {
  min-height: 49px !important;
}

.max-height-49 {
  max-height: 49px !important;
}

.width-50 {
  width: 50px !important;
}

.min-width-50 {
  min-width: 50px !important;
}

.max-width-50 {
  max-width: 50px !important;
}

.height-50 {
  height: 50px !important;
}

.min-height-50 {
  min-height: 50px !important;
}

.max-height-50 {
  max-height: 50px !important;
}

.width-51 {
  width: 51px !important;
}

.min-width-51 {
  min-width: 51px !important;
}

.max-width-51 {
  max-width: 51px !important;
}

.height-51 {
  height: 51px !important;
}

.min-height-51 {
  min-height: 51px !important;
}

.max-height-51 {
  max-height: 51px !important;
}

.width-52 {
  width: 52px !important;
}

.min-width-52 {
  min-width: 52px !important;
}

.max-width-52 {
  max-width: 52px !important;
}

.height-52 {
  height: 52px !important;
}

.min-height-52 {
  min-height: 52px !important;
}

.max-height-52 {
  max-height: 52px !important;
}

.width-53 {
  width: 53px !important;
}

.min-width-53 {
  min-width: 53px !important;
}

.max-width-53 {
  max-width: 53px !important;
}

.height-53 {
  height: 53px !important;
}

.min-height-53 {
  min-height: 53px !important;
}

.max-height-53 {
  max-height: 53px !important;
}

.width-54 {
  width: 54px !important;
}

.min-width-54 {
  min-width: 54px !important;
}

.max-width-54 {
  max-width: 54px !important;
}

.height-54 {
  height: 54px !important;
}

.min-height-54 {
  min-height: 54px !important;
}

.max-height-54 {
  max-height: 54px !important;
}

.width-55 {
  width: 55px !important;
}

.min-width-55 {
  min-width: 55px !important;
}

.max-width-55 {
  max-width: 55px !important;
}

.height-55 {
  height: 55px !important;
}

.min-height-55 {
  min-height: 55px !important;
}

.max-height-55 {
  max-height: 55px !important;
}

.width-56 {
  width: 56px !important;
}

.min-width-56 {
  min-width: 56px !important;
}

.max-width-56 {
  max-width: 56px !important;
}

.height-56 {
  height: 56px !important;
}

.min-height-56 {
  min-height: 56px !important;
}

.max-height-56 {
  max-height: 56px !important;
}

.width-57 {
  width: 57px !important;
}

.min-width-57 {
  min-width: 57px !important;
}

.max-width-57 {
  max-width: 57px !important;
}

.height-57 {
  height: 57px !important;
}

.min-height-57 {
  min-height: 57px !important;
}

.max-height-57 {
  max-height: 57px !important;
}

.width-58 {
  width: 58px !important;
}

.min-width-58 {
  min-width: 58px !important;
}

.max-width-58 {
  max-width: 58px !important;
}

.height-58 {
  height: 58px !important;
}

.min-height-58 {
  min-height: 58px !important;
}

.max-height-58 {
  max-height: 58px !important;
}

.width-59 {
  width: 59px !important;
}

.min-width-59 {
  min-width: 59px !important;
}

.max-width-59 {
  max-width: 59px !important;
}

.height-59 {
  height: 59px !important;
}

.min-height-59 {
  min-height: 59px !important;
}

.max-height-59 {
  max-height: 59px !important;
}

.width-60 {
  width: 60px !important;
}

.min-width-60 {
  min-width: 60px !important;
}

.max-width-60 {
  max-width: 60px !important;
}

.height-60 {
  height: 60px !important;
}

.min-height-60 {
  min-height: 60px !important;
}

.max-height-60 {
  max-height: 60px !important;
}

.width-61 {
  width: 61px !important;
}

.min-width-61 {
  min-width: 61px !important;
}

.max-width-61 {
  max-width: 61px !important;
}

.height-61 {
  height: 61px !important;
}

.min-height-61 {
  min-height: 61px !important;
}

.max-height-61 {
  max-height: 61px !important;
}

.width-62 {
  width: 62px !important;
}

.min-width-62 {
  min-width: 62px !important;
}

.max-width-62 {
  max-width: 62px !important;
}

.height-62 {
  height: 62px !important;
}

.min-height-62 {
  min-height: 62px !important;
}

.max-height-62 {
  max-height: 62px !important;
}

.width-63 {
  width: 63px !important;
}

.min-width-63 {
  min-width: 63px !important;
}

.max-width-63 {
  max-width: 63px !important;
}

.height-63 {
  height: 63px !important;
}

.min-height-63 {
  min-height: 63px !important;
}

.max-height-63 {
  max-height: 63px !important;
}

.width-64 {
  width: 64px !important;
}

.min-width-64 {
  min-width: 64px !important;
}

.max-width-64 {
  max-width: 64px !important;
}

.height-64 {
  height: 64px !important;
}

.min-height-64 {
  min-height: 64px !important;
}

.max-height-64 {
  max-height: 64px !important;
}

.width-65 {
  width: 65px !important;
}

.min-width-65 {
  min-width: 65px !important;
}

.max-width-65 {
  max-width: 65px !important;
}

.height-65 {
  height: 65px !important;
}

.min-height-65 {
  min-height: 65px !important;
}

.max-height-65 {
  max-height: 65px !important;
}

.width-66 {
  width: 66px !important;
}

.min-width-66 {
  min-width: 66px !important;
}

.max-width-66 {
  max-width: 66px !important;
}

.height-66 {
  height: 66px !important;
}

.min-height-66 {
  min-height: 66px !important;
}

.max-height-66 {
  max-height: 66px !important;
}

.width-67 {
  width: 67px !important;
}

.min-width-67 {
  min-width: 67px !important;
}

.max-width-67 {
  max-width: 67px !important;
}

.height-67 {
  height: 67px !important;
}

.min-height-67 {
  min-height: 67px !important;
}

.max-height-67 {
  max-height: 67px !important;
}

.width-68 {
  width: 68px !important;
}

.min-width-68 {
  min-width: 68px !important;
}

.max-width-68 {
  max-width: 68px !important;
}

.height-68 {
  height: 68px !important;
}

.min-height-68 {
  min-height: 68px !important;
}

.max-height-68 {
  max-height: 68px !important;
}

.width-69 {
  width: 69px !important;
}

.min-width-69 {
  min-width: 69px !important;
}

.max-width-69 {
  max-width: 69px !important;
}

.height-69 {
  height: 69px !important;
}

.min-height-69 {
  min-height: 69px !important;
}

.max-height-69 {
  max-height: 69px !important;
}

.width-70 {
  width: 70px !important;
}

.min-width-70 {
  min-width: 70px !important;
}

.max-width-70 {
  max-width: 70px !important;
}

.height-70 {
  height: 70px !important;
}

.min-height-70 {
  min-height: 70px !important;
}

.max-height-70 {
  max-height: 70px !important;
}

.width-71 {
  width: 71px !important;
}

.min-width-71 {
  min-width: 71px !important;
}

.max-width-71 {
  max-width: 71px !important;
}

.height-71 {
  height: 71px !important;
}

.min-height-71 {
  min-height: 71px !important;
}

.max-height-71 {
  max-height: 71px !important;
}

.width-72 {
  width: 72px !important;
}

.min-width-72 {
  min-width: 72px !important;
}

.max-width-72 {
  max-width: 72px !important;
}

.height-72 {
  height: 72px !important;
}

.min-height-72 {
  min-height: 72px !important;
}

.max-height-72 {
  max-height: 72px !important;
}

.width-73 {
  width: 73px !important;
}

.min-width-73 {
  min-width: 73px !important;
}

.max-width-73 {
  max-width: 73px !important;
}

.height-73 {
  height: 73px !important;
}

.min-height-73 {
  min-height: 73px !important;
}

.max-height-73 {
  max-height: 73px !important;
}

.width-74 {
  width: 74px !important;
}

.min-width-74 {
  min-width: 74px !important;
}

.max-width-74 {
  max-width: 74px !important;
}

.height-74 {
  height: 74px !important;
}

.min-height-74 {
  min-height: 74px !important;
}

.max-height-74 {
  max-height: 74px !important;
}

.width-75 {
  width: 75px !important;
}

.min-width-75 {
  min-width: 75px !important;
}

.max-width-75 {
  max-width: 75px !important;
}

.height-75 {
  height: 75px !important;
}

.min-height-75 {
  min-height: 75px !important;
}

.max-height-75 {
  max-height: 75px !important;
}

.width-76 {
  width: 76px !important;
}

.min-width-76 {
  min-width: 76px !important;
}

.max-width-76 {
  max-width: 76px !important;
}

.height-76 {
  height: 76px !important;
}

.min-height-76 {
  min-height: 76px !important;
}

.max-height-76 {
  max-height: 76px !important;
}

.width-77 {
  width: 77px !important;
}

.min-width-77 {
  min-width: 77px !important;
}

.max-width-77 {
  max-width: 77px !important;
}

.height-77 {
  height: 77px !important;
}

.min-height-77 {
  min-height: 77px !important;
}

.max-height-77 {
  max-height: 77px !important;
}

.width-78 {
  width: 78px !important;
}

.min-width-78 {
  min-width: 78px !important;
}

.max-width-78 {
  max-width: 78px !important;
}

.height-78 {
  height: 78px !important;
}

.min-height-78 {
  min-height: 78px !important;
}

.max-height-78 {
  max-height: 78px !important;
}

.width-79 {
  width: 79px !important;
}

.min-width-79 {
  min-width: 79px !important;
}

.max-width-79 {
  max-width: 79px !important;
}

.height-79 {
  height: 79px !important;
}

.min-height-79 {
  min-height: 79px !important;
}

.max-height-79 {
  max-height: 79px !important;
}

.width-80 {
  width: 80px !important;
}

.min-width-80 {
  min-width: 80px !important;
}

.max-width-80 {
  max-width: 80px !important;
}

.height-80 {
  height: 80px !important;
}

.min-height-80 {
  min-height: 80px !important;
}

.max-height-80 {
  max-height: 80px !important;
}

.width-81 {
  width: 81px !important;
}

.min-width-81 {
  min-width: 81px !important;
}

.max-width-81 {
  max-width: 81px !important;
}

.height-81 {
  height: 81px !important;
}

.min-height-81 {
  min-height: 81px !important;
}

.max-height-81 {
  max-height: 81px !important;
}

.width-82 {
  width: 82px !important;
}

.min-width-82 {
  min-width: 82px !important;
}

.max-width-82 {
  max-width: 82px !important;
}

.height-82 {
  height: 82px !important;
}

.min-height-82 {
  min-height: 82px !important;
}

.max-height-82 {
  max-height: 82px !important;
}

.width-83 {
  width: 83px !important;
}

.min-width-83 {
  min-width: 83px !important;
}

.max-width-83 {
  max-width: 83px !important;
}

.height-83 {
  height: 83px !important;
}

.min-height-83 {
  min-height: 83px !important;
}

.max-height-83 {
  max-height: 83px !important;
}

.width-84 {
  width: 84px !important;
}

.min-width-84 {
  min-width: 84px !important;
}

.max-width-84 {
  max-width: 84px !important;
}

.height-84 {
  height: 84px !important;
}

.min-height-84 {
  min-height: 84px !important;
}

.max-height-84 {
  max-height: 84px !important;
}

.width-85 {
  width: 85px !important;
}

.min-width-85 {
  min-width: 85px !important;
}

.max-width-85 {
  max-width: 85px !important;
}

.height-85 {
  height: 85px !important;
}

.min-height-85 {
  min-height: 85px !important;
}

.max-height-85 {
  max-height: 85px !important;
}

.width-86 {
  width: 86px !important;
}

.min-width-86 {
  min-width: 86px !important;
}

.max-width-86 {
  max-width: 86px !important;
}

.height-86 {
  height: 86px !important;
}

.min-height-86 {
  min-height: 86px !important;
}

.max-height-86 {
  max-height: 86px !important;
}

.width-87 {
  width: 87px !important;
}

.min-width-87 {
  min-width: 87px !important;
}

.max-width-87 {
  max-width: 87px !important;
}

.height-87 {
  height: 87px !important;
}

.min-height-87 {
  min-height: 87px !important;
}

.max-height-87 {
  max-height: 87px !important;
}

.width-88 {
  width: 88px !important;
}

.min-width-88 {
  min-width: 88px !important;
}

.max-width-88 {
  max-width: 88px !important;
}

.height-88 {
  height: 88px !important;
}

.min-height-88 {
  min-height: 88px !important;
}

.max-height-88 {
  max-height: 88px !important;
}

.width-89 {
  width: 89px !important;
}

.min-width-89 {
  min-width: 89px !important;
}

.max-width-89 {
  max-width: 89px !important;
}

.height-89 {
  height: 89px !important;
}

.min-height-89 {
  min-height: 89px !important;
}

.max-height-89 {
  max-height: 89px !important;
}

.width-90 {
  width: 90px !important;
}

.min-width-90 {
  min-width: 90px !important;
}

.max-width-90 {
  max-width: 90px !important;
}

.height-90 {
  height: 90px !important;
}

.min-height-90 {
  min-height: 90px !important;
}

.max-height-90 {
  max-height: 90px !important;
}

.width-91 {
  width: 91px !important;
}

.min-width-91 {
  min-width: 91px !important;
}

.max-width-91 {
  max-width: 91px !important;
}

.height-91 {
  height: 91px !important;
}

.min-height-91 {
  min-height: 91px !important;
}

.max-height-91 {
  max-height: 91px !important;
}

.width-92 {
  width: 92px !important;
}

.min-width-92 {
  min-width: 92px !important;
}

.max-width-92 {
  max-width: 92px !important;
}

.height-92 {
  height: 92px !important;
}

.min-height-92 {
  min-height: 92px !important;
}

.max-height-92 {
  max-height: 92px !important;
}

.width-93 {
  width: 93px !important;
}

.min-width-93 {
  min-width: 93px !important;
}

.max-width-93 {
  max-width: 93px !important;
}

.height-93 {
  height: 93px !important;
}

.min-height-93 {
  min-height: 93px !important;
}

.max-height-93 {
  max-height: 93px !important;
}

.width-94 {
  width: 94px !important;
}

.min-width-94 {
  min-width: 94px !important;
}

.max-width-94 {
  max-width: 94px !important;
}

.height-94 {
  height: 94px !important;
}

.min-height-94 {
  min-height: 94px !important;
}

.max-height-94 {
  max-height: 94px !important;
}

.width-95 {
  width: 95px !important;
}

.min-width-95 {
  min-width: 95px !important;
}

.max-width-95 {
  max-width: 95px !important;
}

.height-95 {
  height: 95px !important;
}

.min-height-95 {
  min-height: 95px !important;
}

.max-height-95 {
  max-height: 95px !important;
}

.width-96 {
  width: 96px !important;
}

.min-width-96 {
  min-width: 96px !important;
}

.max-width-96 {
  max-width: 96px !important;
}

.height-96 {
  height: 96px !important;
}

.min-height-96 {
  min-height: 96px !important;
}

.max-height-96 {
  max-height: 96px !important;
}

.width-97 {
  width: 97px !important;
}

.min-width-97 {
  min-width: 97px !important;
}

.max-width-97 {
  max-width: 97px !important;
}

.height-97 {
  height: 97px !important;
}

.min-height-97 {
  min-height: 97px !important;
}

.max-height-97 {
  max-height: 97px !important;
}

.width-98 {
  width: 98px !important;
}

.min-width-98 {
  min-width: 98px !important;
}

.max-width-98 {
  max-width: 98px !important;
}

.height-98 {
  height: 98px !important;
}

.min-height-98 {
  min-height: 98px !important;
}

.max-height-98 {
  max-height: 98px !important;
}

.width-99 {
  width: 99px !important;
}

.min-width-99 {
  min-width: 99px !important;
}

.max-width-99 {
  max-width: 99px !important;
}

.height-99 {
  height: 99px !important;
}

.min-height-99 {
  min-height: 99px !important;
}

.max-height-99 {
  max-height: 99px !important;
}

.width-100 {
  width: 100px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.max-width-100 {
  max-width: 100px !important;
}

.height-100 {
  height: 100px !important;
}

.min-height-100 {
  min-height: 100px !important;
}

.max-height-100 {
  max-height: 100px !important;
}

.width-101 {
  width: 101px !important;
}

.min-width-101 {
  min-width: 101px !important;
}

.max-width-101 {
  max-width: 101px !important;
}

.height-101 {
  height: 101px !important;
}

.min-height-101 {
  min-height: 101px !important;
}

.max-height-101 {
  max-height: 101px !important;
}

.width-102 {
  width: 102px !important;
}

.min-width-102 {
  min-width: 102px !important;
}

.max-width-102 {
  max-width: 102px !important;
}

.height-102 {
  height: 102px !important;
}

.min-height-102 {
  min-height: 102px !important;
}

.max-height-102 {
  max-height: 102px !important;
}

.width-103 {
  width: 103px !important;
}

.min-width-103 {
  min-width: 103px !important;
}

.max-width-103 {
  max-width: 103px !important;
}

.height-103 {
  height: 103px !important;
}

.min-height-103 {
  min-height: 103px !important;
}

.max-height-103 {
  max-height: 103px !important;
}

.width-104 {
  width: 104px !important;
}

.min-width-104 {
  min-width: 104px !important;
}

.max-width-104 {
  max-width: 104px !important;
}

.height-104 {
  height: 104px !important;
}

.min-height-104 {
  min-height: 104px !important;
}

.max-height-104 {
  max-height: 104px !important;
}

.width-105 {
  width: 105px !important;
}

.min-width-105 {
  min-width: 105px !important;
}

.max-width-105 {
  max-width: 105px !important;
}

.height-105 {
  height: 105px !important;
}

.min-height-105 {
  min-height: 105px !important;
}

.max-height-105 {
  max-height: 105px !important;
}

.width-106 {
  width: 106px !important;
}

.min-width-106 {
  min-width: 106px !important;
}

.max-width-106 {
  max-width: 106px !important;
}

.height-106 {
  height: 106px !important;
}

.min-height-106 {
  min-height: 106px !important;
}

.max-height-106 {
  max-height: 106px !important;
}

.width-107 {
  width: 107px !important;
}

.min-width-107 {
  min-width: 107px !important;
}

.max-width-107 {
  max-width: 107px !important;
}

.height-107 {
  height: 107px !important;
}

.min-height-107 {
  min-height: 107px !important;
}

.max-height-107 {
  max-height: 107px !important;
}

.width-108 {
  width: 108px !important;
}

.min-width-108 {
  min-width: 108px !important;
}

.max-width-108 {
  max-width: 108px !important;
}

.height-108 {
  height: 108px !important;
}

.min-height-108 {
  min-height: 108px !important;
}

.max-height-108 {
  max-height: 108px !important;
}

.width-109 {
  width: 109px !important;
}

.min-width-109 {
  min-width: 109px !important;
}

.max-width-109 {
  max-width: 109px !important;
}

.height-109 {
  height: 109px !important;
}

.min-height-109 {
  min-height: 109px !important;
}

.max-height-109 {
  max-height: 109px !important;
}

.width-110 {
  width: 110px !important;
}

.min-width-110 {
  min-width: 110px !important;
}

.max-width-110 {
  max-width: 110px !important;
}

.height-110 {
  height: 110px !important;
}

.min-height-110 {
  min-height: 110px !important;
}

.max-height-110 {
  max-height: 110px !important;
}

.width-111 {
  width: 111px !important;
}

.min-width-111 {
  min-width: 111px !important;
}

.max-width-111 {
  max-width: 111px !important;
}

.height-111 {
  height: 111px !important;
}

.min-height-111 {
  min-height: 111px !important;
}

.max-height-111 {
  max-height: 111px !important;
}

.width-112 {
  width: 112px !important;
}

.min-width-112 {
  min-width: 112px !important;
}

.max-width-112 {
  max-width: 112px !important;
}

.height-112 {
  height: 112px !important;
}

.min-height-112 {
  min-height: 112px !important;
}

.max-height-112 {
  max-height: 112px !important;
}

.width-113 {
  width: 113px !important;
}

.min-width-113 {
  min-width: 113px !important;
}

.max-width-113 {
  max-width: 113px !important;
}

.height-113 {
  height: 113px !important;
}

.min-height-113 {
  min-height: 113px !important;
}

.max-height-113 {
  max-height: 113px !important;
}

.width-114 {
  width: 114px !important;
}

.min-width-114 {
  min-width: 114px !important;
}

.max-width-114 {
  max-width: 114px !important;
}

.height-114 {
  height: 114px !important;
}

.min-height-114 {
  min-height: 114px !important;
}

.max-height-114 {
  max-height: 114px !important;
}

.width-115 {
  width: 115px !important;
}

.min-width-115 {
  min-width: 115px !important;
}

.max-width-115 {
  max-width: 115px !important;
}

.height-115 {
  height: 115px !important;
}

.min-height-115 {
  min-height: 115px !important;
}

.max-height-115 {
  max-height: 115px !important;
}

.width-116 {
  width: 116px !important;
}

.min-width-116 {
  min-width: 116px !important;
}

.max-width-116 {
  max-width: 116px !important;
}

.height-116 {
  height: 116px !important;
}

.min-height-116 {
  min-height: 116px !important;
}

.max-height-116 {
  max-height: 116px !important;
}

.width-117 {
  width: 117px !important;
}

.min-width-117 {
  min-width: 117px !important;
}

.max-width-117 {
  max-width: 117px !important;
}

.height-117 {
  height: 117px !important;
}

.min-height-117 {
  min-height: 117px !important;
}

.max-height-117 {
  max-height: 117px !important;
}

.width-118 {
  width: 118px !important;
}

.min-width-118 {
  min-width: 118px !important;
}

.max-width-118 {
  max-width: 118px !important;
}

.height-118 {
  height: 118px !important;
}

.min-height-118 {
  min-height: 118px !important;
}

.max-height-118 {
  max-height: 118px !important;
}

.width-119 {
  width: 119px !important;
}

.min-width-119 {
  min-width: 119px !important;
}

.max-width-119 {
  max-width: 119px !important;
}

.height-119 {
  height: 119px !important;
}

.min-height-119 {
  min-height: 119px !important;
}

.max-height-119 {
  max-height: 119px !important;
}

.width-120 {
  width: 120px !important;
}

.min-width-120 {
  min-width: 120px !important;
}

.max-width-120 {
  max-width: 120px !important;
}

.height-120 {
  height: 120px !important;
}

.min-height-120 {
  min-height: 120px !important;
}

.max-height-120 {
  max-height: 120px !important;
}

.width-121 {
  width: 121px !important;
}

.min-width-121 {
  min-width: 121px !important;
}

.max-width-121 {
  max-width: 121px !important;
}

.height-121 {
  height: 121px !important;
}

.min-height-121 {
  min-height: 121px !important;
}

.max-height-121 {
  max-height: 121px !important;
}

.width-122 {
  width: 122px !important;
}

.min-width-122 {
  min-width: 122px !important;
}

.max-width-122 {
  max-width: 122px !important;
}

.height-122 {
  height: 122px !important;
}

.min-height-122 {
  min-height: 122px !important;
}

.max-height-122 {
  max-height: 122px !important;
}

.width-123 {
  width: 123px !important;
}

.min-width-123 {
  min-width: 123px !important;
}

.max-width-123 {
  max-width: 123px !important;
}

.height-123 {
  height: 123px !important;
}

.min-height-123 {
  min-height: 123px !important;
}

.max-height-123 {
  max-height: 123px !important;
}

.width-124 {
  width: 124px !important;
}

.min-width-124 {
  min-width: 124px !important;
}

.max-width-124 {
  max-width: 124px !important;
}

.height-124 {
  height: 124px !important;
}

.min-height-124 {
  min-height: 124px !important;
}

.max-height-124 {
  max-height: 124px !important;
}

.width-125 {
  width: 125px !important;
}

.min-width-125 {
  min-width: 125px !important;
}

.max-width-125 {
  max-width: 125px !important;
}

.height-125 {
  height: 125px !important;
}

.min-height-125 {
  min-height: 125px !important;
}

.max-height-125 {
  max-height: 125px !important;
}

.width-126 {
  width: 126px !important;
}

.min-width-126 {
  min-width: 126px !important;
}

.max-width-126 {
  max-width: 126px !important;
}

.height-126 {
  height: 126px !important;
}

.min-height-126 {
  min-height: 126px !important;
}

.max-height-126 {
  max-height: 126px !important;
}

.width-127 {
  width: 127px !important;
}

.min-width-127 {
  min-width: 127px !important;
}

.max-width-127 {
  max-width: 127px !important;
}

.height-127 {
  height: 127px !important;
}

.min-height-127 {
  min-height: 127px !important;
}

.max-height-127 {
  max-height: 127px !important;
}

.width-128 {
  width: 128px !important;
}

.min-width-128 {
  min-width: 128px !important;
}

.max-width-128 {
  max-width: 128px !important;
}

.height-128 {
  height: 128px !important;
}

.min-height-128 {
  min-height: 128px !important;
}

.max-height-128 {
  max-height: 128px !important;
}

.width-129 {
  width: 129px !important;
}

.min-width-129 {
  min-width: 129px !important;
}

.max-width-129 {
  max-width: 129px !important;
}

.height-129 {
  height: 129px !important;
}

.min-height-129 {
  min-height: 129px !important;
}

.max-height-129 {
  max-height: 129px !important;
}

.width-130 {
  width: 130px !important;
}

.min-width-130 {
  min-width: 130px !important;
}

.max-width-130 {
  max-width: 130px !important;
}

.height-130 {
  height: 130px !important;
}

.min-height-130 {
  min-height: 130px !important;
}

.max-height-130 {
  max-height: 130px !important;
}

.width-131 {
  width: 131px !important;
}

.min-width-131 {
  min-width: 131px !important;
}

.max-width-131 {
  max-width: 131px !important;
}

.height-131 {
  height: 131px !important;
}

.min-height-131 {
  min-height: 131px !important;
}

.max-height-131 {
  max-height: 131px !important;
}

.width-132 {
  width: 132px !important;
}

.min-width-132 {
  min-width: 132px !important;
}

.max-width-132 {
  max-width: 132px !important;
}

.height-132 {
  height: 132px !important;
}

.min-height-132 {
  min-height: 132px !important;
}

.max-height-132 {
  max-height: 132px !important;
}

.width-133 {
  width: 133px !important;
}

.min-width-133 {
  min-width: 133px !important;
}

.max-width-133 {
  max-width: 133px !important;
}

.height-133 {
  height: 133px !important;
}

.min-height-133 {
  min-height: 133px !important;
}

.max-height-133 {
  max-height: 133px !important;
}

.width-134 {
  width: 134px !important;
}

.min-width-134 {
  min-width: 134px !important;
}

.max-width-134 {
  max-width: 134px !important;
}

.height-134 {
  height: 134px !important;
}

.min-height-134 {
  min-height: 134px !important;
}

.max-height-134 {
  max-height: 134px !important;
}

.width-135 {
  width: 135px !important;
}

.min-width-135 {
  min-width: 135px !important;
}

.max-width-135 {
  max-width: 135px !important;
}

.height-135 {
  height: 135px !important;
}

.min-height-135 {
  min-height: 135px !important;
}

.max-height-135 {
  max-height: 135px !important;
}

.width-136 {
  width: 136px !important;
}

.min-width-136 {
  min-width: 136px !important;
}

.max-width-136 {
  max-width: 136px !important;
}

.height-136 {
  height: 136px !important;
}

.min-height-136 {
  min-height: 136px !important;
}

.max-height-136 {
  max-height: 136px !important;
}

.width-137 {
  width: 137px !important;
}

.min-width-137 {
  min-width: 137px !important;
}

.max-width-137 {
  max-width: 137px !important;
}

.height-137 {
  height: 137px !important;
}

.min-height-137 {
  min-height: 137px !important;
}

.max-height-137 {
  max-height: 137px !important;
}

.width-138 {
  width: 138px !important;
}

.min-width-138 {
  min-width: 138px !important;
}

.max-width-138 {
  max-width: 138px !important;
}

.height-138 {
  height: 138px !important;
}

.min-height-138 {
  min-height: 138px !important;
}

.max-height-138 {
  max-height: 138px !important;
}

.width-139 {
  width: 139px !important;
}

.min-width-139 {
  min-width: 139px !important;
}

.max-width-139 {
  max-width: 139px !important;
}

.height-139 {
  height: 139px !important;
}

.min-height-139 {
  min-height: 139px !important;
}

.max-height-139 {
  max-height: 139px !important;
}

.width-140 {
  width: 140px !important;
}

.min-width-140 {
  min-width: 140px !important;
}

.max-width-140 {
  max-width: 140px !important;
}

.height-140 {
  height: 140px !important;
}

.min-height-140 {
  min-height: 140px !important;
}

.max-height-140 {
  max-height: 140px !important;
}

.width-141 {
  width: 141px !important;
}

.min-width-141 {
  min-width: 141px !important;
}

.max-width-141 {
  max-width: 141px !important;
}

.height-141 {
  height: 141px !important;
}

.min-height-141 {
  min-height: 141px !important;
}

.max-height-141 {
  max-height: 141px !important;
}

.width-142 {
  width: 142px !important;
}

.min-width-142 {
  min-width: 142px !important;
}

.max-width-142 {
  max-width: 142px !important;
}

.height-142 {
  height: 142px !important;
}

.min-height-142 {
  min-height: 142px !important;
}

.max-height-142 {
  max-height: 142px !important;
}

.width-143 {
  width: 143px !important;
}

.min-width-143 {
  min-width: 143px !important;
}

.max-width-143 {
  max-width: 143px !important;
}

.height-143 {
  height: 143px !important;
}

.min-height-143 {
  min-height: 143px !important;
}

.max-height-143 {
  max-height: 143px !important;
}

.width-144 {
  width: 144px !important;
}

.min-width-144 {
  min-width: 144px !important;
}

.max-width-144 {
  max-width: 144px !important;
}

.height-144 {
  height: 144px !important;
}

.min-height-144 {
  min-height: 144px !important;
}

.max-height-144 {
  max-height: 144px !important;
}

.width-145 {
  width: 145px !important;
}

.min-width-145 {
  min-width: 145px !important;
}

.max-width-145 {
  max-width: 145px !important;
}

.height-145 {
  height: 145px !important;
}

.min-height-145 {
  min-height: 145px !important;
}

.max-height-145 {
  max-height: 145px !important;
}

.width-146 {
  width: 146px !important;
}

.min-width-146 {
  min-width: 146px !important;
}

.max-width-146 {
  max-width: 146px !important;
}

.height-146 {
  height: 146px !important;
}

.min-height-146 {
  min-height: 146px !important;
}

.max-height-146 {
  max-height: 146px !important;
}

.width-147 {
  width: 147px !important;
}

.min-width-147 {
  min-width: 147px !important;
}

.max-width-147 {
  max-width: 147px !important;
}

.height-147 {
  height: 147px !important;
}

.min-height-147 {
  min-height: 147px !important;
}

.max-height-147 {
  max-height: 147px !important;
}

.width-148 {
  width: 148px !important;
}

.min-width-148 {
  min-width: 148px !important;
}

.max-width-148 {
  max-width: 148px !important;
}

.height-148 {
  height: 148px !important;
}

.min-height-148 {
  min-height: 148px !important;
}

.max-height-148 {
  max-height: 148px !important;
}

.width-149 {
  width: 149px !important;
}

.min-width-149 {
  min-width: 149px !important;
}

.max-width-149 {
  max-width: 149px !important;
}

.height-149 {
  height: 149px !important;
}

.min-height-149 {
  min-height: 149px !important;
}

.max-height-149 {
  max-height: 149px !important;
}

.width-150 {
  width: 150px !important;
}

.min-width-150 {
  min-width: 150px !important;
}

.max-width-150 {
  max-width: 150px !important;
}

.height-150 {
  height: 150px !important;
}

.min-height-150 {
  min-height: 150px !important;
}

.max-height-150 {
  max-height: 150px !important;
}

.width-151 {
  width: 151px !important;
}

.min-width-151 {
  min-width: 151px !important;
}

.max-width-151 {
  max-width: 151px !important;
}

.height-151 {
  height: 151px !important;
}

.min-height-151 {
  min-height: 151px !important;
}

.max-height-151 {
  max-height: 151px !important;
}

.width-152 {
  width: 152px !important;
}

.min-width-152 {
  min-width: 152px !important;
}

.max-width-152 {
  max-width: 152px !important;
}

.height-152 {
  height: 152px !important;
}

.min-height-152 {
  min-height: 152px !important;
}

.max-height-152 {
  max-height: 152px !important;
}

.width-153 {
  width: 153px !important;
}

.min-width-153 {
  min-width: 153px !important;
}

.max-width-153 {
  max-width: 153px !important;
}

.height-153 {
  height: 153px !important;
}

.min-height-153 {
  min-height: 153px !important;
}

.max-height-153 {
  max-height: 153px !important;
}

.width-154 {
  width: 154px !important;
}

.min-width-154 {
  min-width: 154px !important;
}

.max-width-154 {
  max-width: 154px !important;
}

.height-154 {
  height: 154px !important;
}

.min-height-154 {
  min-height: 154px !important;
}

.max-height-154 {
  max-height: 154px !important;
}

.width-155 {
  width: 155px !important;
}

.min-width-155 {
  min-width: 155px !important;
}

.max-width-155 {
  max-width: 155px !important;
}

.height-155 {
  height: 155px !important;
}

.min-height-155 {
  min-height: 155px !important;
}

.max-height-155 {
  max-height: 155px !important;
}

.width-156 {
  width: 156px !important;
}

.min-width-156 {
  min-width: 156px !important;
}

.max-width-156 {
  max-width: 156px !important;
}

.height-156 {
  height: 156px !important;
}

.min-height-156 {
  min-height: 156px !important;
}

.max-height-156 {
  max-height: 156px !important;
}

.width-157 {
  width: 157px !important;
}

.min-width-157 {
  min-width: 157px !important;
}

.max-width-157 {
  max-width: 157px !important;
}

.height-157 {
  height: 157px !important;
}

.min-height-157 {
  min-height: 157px !important;
}

.max-height-157 {
  max-height: 157px !important;
}

.width-158 {
  width: 158px !important;
}

.min-width-158 {
  min-width: 158px !important;
}

.max-width-158 {
  max-width: 158px !important;
}

.height-158 {
  height: 158px !important;
}

.min-height-158 {
  min-height: 158px !important;
}

.max-height-158 {
  max-height: 158px !important;
}

.width-159 {
  width: 159px !important;
}

.min-width-159 {
  min-width: 159px !important;
}

.max-width-159 {
  max-width: 159px !important;
}

.height-159 {
  height: 159px !important;
}

.min-height-159 {
  min-height: 159px !important;
}

.max-height-159 {
  max-height: 159px !important;
}

.width-160 {
  width: 160px !important;
}

.min-width-160 {
  min-width: 160px !important;
}

.max-width-160 {
  max-width: 160px !important;
}

.height-160 {
  height: 160px !important;
}

.min-height-160 {
  min-height: 160px !important;
}

.max-height-160 {
  max-height: 160px !important;
}

.width-161 {
  width: 161px !important;
}

.min-width-161 {
  min-width: 161px !important;
}

.max-width-161 {
  max-width: 161px !important;
}

.height-161 {
  height: 161px !important;
}

.min-height-161 {
  min-height: 161px !important;
}

.max-height-161 {
  max-height: 161px !important;
}

.width-162 {
  width: 162px !important;
}

.min-width-162 {
  min-width: 162px !important;
}

.max-width-162 {
  max-width: 162px !important;
}

.height-162 {
  height: 162px !important;
}

.min-height-162 {
  min-height: 162px !important;
}

.max-height-162 {
  max-height: 162px !important;
}

.width-163 {
  width: 163px !important;
}

.min-width-163 {
  min-width: 163px !important;
}

.max-width-163 {
  max-width: 163px !important;
}

.height-163 {
  height: 163px !important;
}

.min-height-163 {
  min-height: 163px !important;
}

.max-height-163 {
  max-height: 163px !important;
}

.width-164 {
  width: 164px !important;
}

.min-width-164 {
  min-width: 164px !important;
}

.max-width-164 {
  max-width: 164px !important;
}

.height-164 {
  height: 164px !important;
}

.min-height-164 {
  min-height: 164px !important;
}

.max-height-164 {
  max-height: 164px !important;
}

.width-165 {
  width: 165px !important;
}

.min-width-165 {
  min-width: 165px !important;
}

.max-width-165 {
  max-width: 165px !important;
}

.height-165 {
  height: 165px !important;
}

.min-height-165 {
  min-height: 165px !important;
}

.max-height-165 {
  max-height: 165px !important;
}

.width-166 {
  width: 166px !important;
}

.min-width-166 {
  min-width: 166px !important;
}

.max-width-166 {
  max-width: 166px !important;
}

.height-166 {
  height: 166px !important;
}

.min-height-166 {
  min-height: 166px !important;
}

.max-height-166 {
  max-height: 166px !important;
}

.width-167 {
  width: 167px !important;
}

.min-width-167 {
  min-width: 167px !important;
}

.max-width-167 {
  max-width: 167px !important;
}

.height-167 {
  height: 167px !important;
}

.min-height-167 {
  min-height: 167px !important;
}

.max-height-167 {
  max-height: 167px !important;
}

.width-168 {
  width: 168px !important;
}

.min-width-168 {
  min-width: 168px !important;
}

.max-width-168 {
  max-width: 168px !important;
}

.height-168 {
  height: 168px !important;
}

.min-height-168 {
  min-height: 168px !important;
}

.max-height-168 {
  max-height: 168px !important;
}

.width-169 {
  width: 169px !important;
}

.min-width-169 {
  min-width: 169px !important;
}

.max-width-169 {
  max-width: 169px !important;
}

.height-169 {
  height: 169px !important;
}

.min-height-169 {
  min-height: 169px !important;
}

.max-height-169 {
  max-height: 169px !important;
}

.width-170 {
  width: 170px !important;
}

.min-width-170 {
  min-width: 170px !important;
}

.max-width-170 {
  max-width: 170px !important;
}

.height-170 {
  height: 170px !important;
}

.min-height-170 {
  min-height: 170px !important;
}

.max-height-170 {
  max-height: 170px !important;
}

.width-171 {
  width: 171px !important;
}

.min-width-171 {
  min-width: 171px !important;
}

.max-width-171 {
  max-width: 171px !important;
}

.height-171 {
  height: 171px !important;
}

.min-height-171 {
  min-height: 171px !important;
}

.max-height-171 {
  max-height: 171px !important;
}

.width-172 {
  width: 172px !important;
}

.min-width-172 {
  min-width: 172px !important;
}

.max-width-172 {
  max-width: 172px !important;
}

.height-172 {
  height: 172px !important;
}

.min-height-172 {
  min-height: 172px !important;
}

.max-height-172 {
  max-height: 172px !important;
}

.width-173 {
  width: 173px !important;
}

.min-width-173 {
  min-width: 173px !important;
}

.max-width-173 {
  max-width: 173px !important;
}

.height-173 {
  height: 173px !important;
}

.min-height-173 {
  min-height: 173px !important;
}

.max-height-173 {
  max-height: 173px !important;
}

.width-174 {
  width: 174px !important;
}

.min-width-174 {
  min-width: 174px !important;
}

.max-width-174 {
  max-width: 174px !important;
}

.height-174 {
  height: 174px !important;
}

.min-height-174 {
  min-height: 174px !important;
}

.max-height-174 {
  max-height: 174px !important;
}

.width-175 {
  width: 175px !important;
}

.min-width-175 {
  min-width: 175px !important;
}

.max-width-175 {
  max-width: 175px !important;
}

.height-175 {
  height: 175px !important;
}

.min-height-175 {
  min-height: 175px !important;
}

.max-height-175 {
  max-height: 175px !important;
}

.width-176 {
  width: 176px !important;
}

.min-width-176 {
  min-width: 176px !important;
}

.max-width-176 {
  max-width: 176px !important;
}

.height-176 {
  height: 176px !important;
}

.min-height-176 {
  min-height: 176px !important;
}

.max-height-176 {
  max-height: 176px !important;
}

.width-177 {
  width: 177px !important;
}

.min-width-177 {
  min-width: 177px !important;
}

.max-width-177 {
  max-width: 177px !important;
}

.height-177 {
  height: 177px !important;
}

.min-height-177 {
  min-height: 177px !important;
}

.max-height-177 {
  max-height: 177px !important;
}

.width-178 {
  width: 178px !important;
}

.min-width-178 {
  min-width: 178px !important;
}

.max-width-178 {
  max-width: 178px !important;
}

.height-178 {
  height: 178px !important;
}

.min-height-178 {
  min-height: 178px !important;
}

.max-height-178 {
  max-height: 178px !important;
}

.width-179 {
  width: 179px !important;
}

.min-width-179 {
  min-width: 179px !important;
}

.max-width-179 {
  max-width: 179px !important;
}

.height-179 {
  height: 179px !important;
}

.min-height-179 {
  min-height: 179px !important;
}

.max-height-179 {
  max-height: 179px !important;
}

.width-180 {
  width: 180px !important;
}

.min-width-180 {
  min-width: 180px !important;
}

.max-width-180 {
  max-width: 180px !important;
}

.height-180 {
  height: 180px !important;
}

.min-height-180 {
  min-height: 180px !important;
}

.max-height-180 {
  max-height: 180px !important;
}

.width-181 {
  width: 181px !important;
}

.min-width-181 {
  min-width: 181px !important;
}

.max-width-181 {
  max-width: 181px !important;
}

.height-181 {
  height: 181px !important;
}

.min-height-181 {
  min-height: 181px !important;
}

.max-height-181 {
  max-height: 181px !important;
}

.width-182 {
  width: 182px !important;
}

.min-width-182 {
  min-width: 182px !important;
}

.max-width-182 {
  max-width: 182px !important;
}

.height-182 {
  height: 182px !important;
}

.min-height-182 {
  min-height: 182px !important;
}

.max-height-182 {
  max-height: 182px !important;
}

.width-183 {
  width: 183px !important;
}

.min-width-183 {
  min-width: 183px !important;
}

.max-width-183 {
  max-width: 183px !important;
}

.height-183 {
  height: 183px !important;
}

.min-height-183 {
  min-height: 183px !important;
}

.max-height-183 {
  max-height: 183px !important;
}

.width-184 {
  width: 184px !important;
}

.min-width-184 {
  min-width: 184px !important;
}

.max-width-184 {
  max-width: 184px !important;
}

.height-184 {
  height: 184px !important;
}

.min-height-184 {
  min-height: 184px !important;
}

.max-height-184 {
  max-height: 184px !important;
}

.width-185 {
  width: 185px !important;
}

.min-width-185 {
  min-width: 185px !important;
}

.max-width-185 {
  max-width: 185px !important;
}

.height-185 {
  height: 185px !important;
}

.min-height-185 {
  min-height: 185px !important;
}

.max-height-185 {
  max-height: 185px !important;
}

.width-186 {
  width: 186px !important;
}

.min-width-186 {
  min-width: 186px !important;
}

.max-width-186 {
  max-width: 186px !important;
}

.height-186 {
  height: 186px !important;
}

.min-height-186 {
  min-height: 186px !important;
}

.max-height-186 {
  max-height: 186px !important;
}

.width-187 {
  width: 187px !important;
}

.min-width-187 {
  min-width: 187px !important;
}

.max-width-187 {
  max-width: 187px !important;
}

.height-187 {
  height: 187px !important;
}

.min-height-187 {
  min-height: 187px !important;
}

.max-height-187 {
  max-height: 187px !important;
}

.width-188 {
  width: 188px !important;
}

.min-width-188 {
  min-width: 188px !important;
}

.max-width-188 {
  max-width: 188px !important;
}

.height-188 {
  height: 188px !important;
}

.min-height-188 {
  min-height: 188px !important;
}

.max-height-188 {
  max-height: 188px !important;
}

.width-189 {
  width: 189px !important;
}

.min-width-189 {
  min-width: 189px !important;
}

.max-width-189 {
  max-width: 189px !important;
}

.height-189 {
  height: 189px !important;
}

.min-height-189 {
  min-height: 189px !important;
}

.max-height-189 {
  max-height: 189px !important;
}

.width-190 {
  width: 190px !important;
}

.min-width-190 {
  min-width: 190px !important;
}

.max-width-190 {
  max-width: 190px !important;
}

.height-190 {
  height: 190px !important;
}

.min-height-190 {
  min-height: 190px !important;
}

.max-height-190 {
  max-height: 190px !important;
}

.width-191 {
  width: 191px !important;
}

.min-width-191 {
  min-width: 191px !important;
}

.max-width-191 {
  max-width: 191px !important;
}

.height-191 {
  height: 191px !important;
}

.min-height-191 {
  min-height: 191px !important;
}

.max-height-191 {
  max-height: 191px !important;
}

.width-192 {
  width: 192px !important;
}

.min-width-192 {
  min-width: 192px !important;
}

.max-width-192 {
  max-width: 192px !important;
}

.height-192 {
  height: 192px !important;
}

.min-height-192 {
  min-height: 192px !important;
}

.max-height-192 {
  max-height: 192px !important;
}

.width-193 {
  width: 193px !important;
}

.min-width-193 {
  min-width: 193px !important;
}

.max-width-193 {
  max-width: 193px !important;
}

.height-193 {
  height: 193px !important;
}

.min-height-193 {
  min-height: 193px !important;
}

.max-height-193 {
  max-height: 193px !important;
}

.width-194 {
  width: 194px !important;
}

.min-width-194 {
  min-width: 194px !important;
}

.max-width-194 {
  max-width: 194px !important;
}

.height-194 {
  height: 194px !important;
}

.min-height-194 {
  min-height: 194px !important;
}

.max-height-194 {
  max-height: 194px !important;
}

.width-195 {
  width: 195px !important;
}

.min-width-195 {
  min-width: 195px !important;
}

.max-width-195 {
  max-width: 195px !important;
}

.height-195 {
  height: 195px !important;
}

.min-height-195 {
  min-height: 195px !important;
}

.max-height-195 {
  max-height: 195px !important;
}

.width-196 {
  width: 196px !important;
}

.min-width-196 {
  min-width: 196px !important;
}

.max-width-196 {
  max-width: 196px !important;
}

.height-196 {
  height: 196px !important;
}

.min-height-196 {
  min-height: 196px !important;
}

.max-height-196 {
  max-height: 196px !important;
}

.width-197 {
  width: 197px !important;
}

.min-width-197 {
  min-width: 197px !important;
}

.max-width-197 {
  max-width: 197px !important;
}

.height-197 {
  height: 197px !important;
}

.min-height-197 {
  min-height: 197px !important;
}

.max-height-197 {
  max-height: 197px !important;
}

.width-198 {
  width: 198px !important;
}

.min-width-198 {
  min-width: 198px !important;
}

.max-width-198 {
  max-width: 198px !important;
}

.height-198 {
  height: 198px !important;
}

.min-height-198 {
  min-height: 198px !important;
}

.max-height-198 {
  max-height: 198px !important;
}

.width-199 {
  width: 199px !important;
}

.min-width-199 {
  min-width: 199px !important;
}

.max-width-199 {
  max-width: 199px !important;
}

.height-199 {
  height: 199px !important;
}

.min-height-199 {
  min-height: 199px !important;
}

.max-height-199 {
  max-height: 199px !important;
}

.width-200 {
  width: 200px !important;
}

.min-width-200 {
  min-width: 200px !important;
}

.max-width-200 {
  max-width: 200px !important;
}

.height-200 {
  height: 200px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.max-height-200 {
  max-height: 200px !important;
}

.width-201 {
  width: 201px !important;
}

.min-width-201 {
  min-width: 201px !important;
}

.max-width-201 {
  max-width: 201px !important;
}

.height-201 {
  height: 201px !important;
}

.min-height-201 {
  min-height: 201px !important;
}

.max-height-201 {
  max-height: 201px !important;
}

.width-202 {
  width: 202px !important;
}

.min-width-202 {
  min-width: 202px !important;
}

.max-width-202 {
  max-width: 202px !important;
}

.height-202 {
  height: 202px !important;
}

.min-height-202 {
  min-height: 202px !important;
}

.max-height-202 {
  max-height: 202px !important;
}

.width-203 {
  width: 203px !important;
}

.min-width-203 {
  min-width: 203px !important;
}

.max-width-203 {
  max-width: 203px !important;
}

.height-203 {
  height: 203px !important;
}

.min-height-203 {
  min-height: 203px !important;
}

.max-height-203 {
  max-height: 203px !important;
}

.width-204 {
  width: 204px !important;
}

.min-width-204 {
  min-width: 204px !important;
}

.max-width-204 {
  max-width: 204px !important;
}

.height-204 {
  height: 204px !important;
}

.min-height-204 {
  min-height: 204px !important;
}

.max-height-204 {
  max-height: 204px !important;
}

.width-205 {
  width: 205px !important;
}

.min-width-205 {
  min-width: 205px !important;
}

.max-width-205 {
  max-width: 205px !important;
}

.height-205 {
  height: 205px !important;
}

.min-height-205 {
  min-height: 205px !important;
}

.max-height-205 {
  max-height: 205px !important;
}

.width-206 {
  width: 206px !important;
}

.min-width-206 {
  min-width: 206px !important;
}

.max-width-206 {
  max-width: 206px !important;
}

.height-206 {
  height: 206px !important;
}

.min-height-206 {
  min-height: 206px !important;
}

.max-height-206 {
  max-height: 206px !important;
}

.width-207 {
  width: 207px !important;
}

.min-width-207 {
  min-width: 207px !important;
}

.max-width-207 {
  max-width: 207px !important;
}

.height-207 {
  height: 207px !important;
}

.min-height-207 {
  min-height: 207px !important;
}

.max-height-207 {
  max-height: 207px !important;
}

.width-208 {
  width: 208px !important;
}

.min-width-208 {
  min-width: 208px !important;
}

.max-width-208 {
  max-width: 208px !important;
}

.height-208 {
  height: 208px !important;
}

.min-height-208 {
  min-height: 208px !important;
}

.max-height-208 {
  max-height: 208px !important;
}

.width-209 {
  width: 209px !important;
}

.min-width-209 {
  min-width: 209px !important;
}

.max-width-209 {
  max-width: 209px !important;
}

.height-209 {
  height: 209px !important;
}

.min-height-209 {
  min-height: 209px !important;
}

.max-height-209 {
  max-height: 209px !important;
}

.width-210 {
  width: 210px !important;
}

.min-width-210 {
  min-width: 210px !important;
}

.max-width-210 {
  max-width: 210px !important;
}

.height-210 {
  height: 210px !important;
}

.min-height-210 {
  min-height: 210px !important;
}

.max-height-210 {
  max-height: 210px !important;
}

.width-211 {
  width: 211px !important;
}

.min-width-211 {
  min-width: 211px !important;
}

.max-width-211 {
  max-width: 211px !important;
}

.height-211 {
  height: 211px !important;
}

.min-height-211 {
  min-height: 211px !important;
}

.max-height-211 {
  max-height: 211px !important;
}

.width-212 {
  width: 212px !important;
}

.min-width-212 {
  min-width: 212px !important;
}

.max-width-212 {
  max-width: 212px !important;
}

.height-212 {
  height: 212px !important;
}

.min-height-212 {
  min-height: 212px !important;
}

.max-height-212 {
  max-height: 212px !important;
}

.width-213 {
  width: 213px !important;
}

.min-width-213 {
  min-width: 213px !important;
}

.max-width-213 {
  max-width: 213px !important;
}

.height-213 {
  height: 213px !important;
}

.min-height-213 {
  min-height: 213px !important;
}

.max-height-213 {
  max-height: 213px !important;
}

.width-214 {
  width: 214px !important;
}

.min-width-214 {
  min-width: 214px !important;
}

.max-width-214 {
  max-width: 214px !important;
}

.height-214 {
  height: 214px !important;
}

.min-height-214 {
  min-height: 214px !important;
}

.max-height-214 {
  max-height: 214px !important;
}

.width-215 {
  width: 215px !important;
}

.min-width-215 {
  min-width: 215px !important;
}

.max-width-215 {
  max-width: 215px !important;
}

.height-215 {
  height: 215px !important;
}

.min-height-215 {
  min-height: 215px !important;
}

.max-height-215 {
  max-height: 215px !important;
}

.width-216 {
  width: 216px !important;
}

.min-width-216 {
  min-width: 216px !important;
}

.max-width-216 {
  max-width: 216px !important;
}

.height-216 {
  height: 216px !important;
}

.min-height-216 {
  min-height: 216px !important;
}

.max-height-216 {
  max-height: 216px !important;
}

.width-217 {
  width: 217px !important;
}

.min-width-217 {
  min-width: 217px !important;
}

.max-width-217 {
  max-width: 217px !important;
}

.height-217 {
  height: 217px !important;
}

.min-height-217 {
  min-height: 217px !important;
}

.max-height-217 {
  max-height: 217px !important;
}

.width-218 {
  width: 218px !important;
}

.min-width-218 {
  min-width: 218px !important;
}

.max-width-218 {
  max-width: 218px !important;
}

.height-218 {
  height: 218px !important;
}

.min-height-218 {
  min-height: 218px !important;
}

.max-height-218 {
  max-height: 218px !important;
}

.width-219 {
  width: 219px !important;
}

.min-width-219 {
  min-width: 219px !important;
}

.max-width-219 {
  max-width: 219px !important;
}

.height-219 {
  height: 219px !important;
}

.min-height-219 {
  min-height: 219px !important;
}

.max-height-219 {
  max-height: 219px !important;
}

.width-220 {
  width: 220px !important;
}

.min-width-220 {
  min-width: 220px !important;
}

.max-width-220 {
  max-width: 220px !important;
}

.height-220 {
  height: 220px !important;
}

.min-height-220 {
  min-height: 220px !important;
}

.max-height-220 {
  max-height: 220px !important;
}

.width-221 {
  width: 221px !important;
}

.min-width-221 {
  min-width: 221px !important;
}

.max-width-221 {
  max-width: 221px !important;
}

.height-221 {
  height: 221px !important;
}

.min-height-221 {
  min-height: 221px !important;
}

.max-height-221 {
  max-height: 221px !important;
}

.width-222 {
  width: 222px !important;
}

.min-width-222 {
  min-width: 222px !important;
}

.max-width-222 {
  max-width: 222px !important;
}

.height-222 {
  height: 222px !important;
}

.min-height-222 {
  min-height: 222px !important;
}

.max-height-222 {
  max-height: 222px !important;
}

.width-223 {
  width: 223px !important;
}

.min-width-223 {
  min-width: 223px !important;
}

.max-width-223 {
  max-width: 223px !important;
}

.height-223 {
  height: 223px !important;
}

.min-height-223 {
  min-height: 223px !important;
}

.max-height-223 {
  max-height: 223px !important;
}

.width-224 {
  width: 224px !important;
}

.min-width-224 {
  min-width: 224px !important;
}

.max-width-224 {
  max-width: 224px !important;
}

.height-224 {
  height: 224px !important;
}

.min-height-224 {
  min-height: 224px !important;
}

.max-height-224 {
  max-height: 224px !important;
}

.width-225 {
  width: 225px !important;
}

.min-width-225 {
  min-width: 225px !important;
}

.max-width-225 {
  max-width: 225px !important;
}

.height-225 {
  height: 225px !important;
}

.min-height-225 {
  min-height: 225px !important;
}

.max-height-225 {
  max-height: 225px !important;
}

.width-226 {
  width: 226px !important;
}

.min-width-226 {
  min-width: 226px !important;
}

.max-width-226 {
  max-width: 226px !important;
}

.height-226 {
  height: 226px !important;
}

.min-height-226 {
  min-height: 226px !important;
}

.max-height-226 {
  max-height: 226px !important;
}

.width-227 {
  width: 227px !important;
}

.min-width-227 {
  min-width: 227px !important;
}

.max-width-227 {
  max-width: 227px !important;
}

.height-227 {
  height: 227px !important;
}

.min-height-227 {
  min-height: 227px !important;
}

.max-height-227 {
  max-height: 227px !important;
}

.width-228 {
  width: 228px !important;
}

.min-width-228 {
  min-width: 228px !important;
}

.max-width-228 {
  max-width: 228px !important;
}

.height-228 {
  height: 228px !important;
}

.min-height-228 {
  min-height: 228px !important;
}

.max-height-228 {
  max-height: 228px !important;
}

.width-229 {
  width: 229px !important;
}

.min-width-229 {
  min-width: 229px !important;
}

.max-width-229 {
  max-width: 229px !important;
}

.height-229 {
  height: 229px !important;
}

.min-height-229 {
  min-height: 229px !important;
}

.max-height-229 {
  max-height: 229px !important;
}

.width-230 {
  width: 230px !important;
}

.min-width-230 {
  min-width: 230px !important;
}

.max-width-230 {
  max-width: 230px !important;
}

.height-230 {
  height: 230px !important;
}

.min-height-230 {
  min-height: 230px !important;
}

.max-height-230 {
  max-height: 230px !important;
}

.width-231 {
  width: 231px !important;
}

.min-width-231 {
  min-width: 231px !important;
}

.max-width-231 {
  max-width: 231px !important;
}

.height-231 {
  height: 231px !important;
}

.min-height-231 {
  min-height: 231px !important;
}

.max-height-231 {
  max-height: 231px !important;
}

.width-232 {
  width: 232px !important;
}

.min-width-232 {
  min-width: 232px !important;
}

.max-width-232 {
  max-width: 232px !important;
}

.height-232 {
  height: 232px !important;
}

.min-height-232 {
  min-height: 232px !important;
}

.max-height-232 {
  max-height: 232px !important;
}

.width-233 {
  width: 233px !important;
}

.min-width-233 {
  min-width: 233px !important;
}

.max-width-233 {
  max-width: 233px !important;
}

.height-233 {
  height: 233px !important;
}

.min-height-233 {
  min-height: 233px !important;
}

.max-height-233 {
  max-height: 233px !important;
}

.width-234 {
  width: 234px !important;
}

.min-width-234 {
  min-width: 234px !important;
}

.max-width-234 {
  max-width: 234px !important;
}

.height-234 {
  height: 234px !important;
}

.min-height-234 {
  min-height: 234px !important;
}

.max-height-234 {
  max-height: 234px !important;
}

.width-235 {
  width: 235px !important;
}

.min-width-235 {
  min-width: 235px !important;
}

.max-width-235 {
  max-width: 235px !important;
}

.height-235 {
  height: 235px !important;
}

.min-height-235 {
  min-height: 235px !important;
}

.max-height-235 {
  max-height: 235px !important;
}

.width-236 {
  width: 236px !important;
}

.min-width-236 {
  min-width: 236px !important;
}

.max-width-236 {
  max-width: 236px !important;
}

.height-236 {
  height: 236px !important;
}

.min-height-236 {
  min-height: 236px !important;
}

.max-height-236 {
  max-height: 236px !important;
}

.width-237 {
  width: 237px !important;
}

.min-width-237 {
  min-width: 237px !important;
}

.max-width-237 {
  max-width: 237px !important;
}

.height-237 {
  height: 237px !important;
}

.min-height-237 {
  min-height: 237px !important;
}

.max-height-237 {
  max-height: 237px !important;
}

.width-238 {
  width: 238px !important;
}

.min-width-238 {
  min-width: 238px !important;
}

.max-width-238 {
  max-width: 238px !important;
}

.height-238 {
  height: 238px !important;
}

.min-height-238 {
  min-height: 238px !important;
}

.max-height-238 {
  max-height: 238px !important;
}

.width-239 {
  width: 239px !important;
}

.min-width-239 {
  min-width: 239px !important;
}

.max-width-239 {
  max-width: 239px !important;
}

.height-239 {
  height: 239px !important;
}

.min-height-239 {
  min-height: 239px !important;
}

.max-height-239 {
  max-height: 239px !important;
}

.width-240 {
  width: 240px !important;
}

.min-width-240 {
  min-width: 240px !important;
}

.max-width-240 {
  max-width: 240px !important;
}

.height-240 {
  height: 240px !important;
}

.min-height-240 {
  min-height: 240px !important;
}

.max-height-240 {
  max-height: 240px !important;
}

.width-241 {
  width: 241px !important;
}

.min-width-241 {
  min-width: 241px !important;
}

.max-width-241 {
  max-width: 241px !important;
}

.height-241 {
  height: 241px !important;
}

.min-height-241 {
  min-height: 241px !important;
}

.max-height-241 {
  max-height: 241px !important;
}

.width-242 {
  width: 242px !important;
}

.min-width-242 {
  min-width: 242px !important;
}

.max-width-242 {
  max-width: 242px !important;
}

.height-242 {
  height: 242px !important;
}

.min-height-242 {
  min-height: 242px !important;
}

.max-height-242 {
  max-height: 242px !important;
}

.width-243 {
  width: 243px !important;
}

.min-width-243 {
  min-width: 243px !important;
}

.max-width-243 {
  max-width: 243px !important;
}

.height-243 {
  height: 243px !important;
}

.min-height-243 {
  min-height: 243px !important;
}

.max-height-243 {
  max-height: 243px !important;
}

.width-244 {
  width: 244px !important;
}

.min-width-244 {
  min-width: 244px !important;
}

.max-width-244 {
  max-width: 244px !important;
}

.height-244 {
  height: 244px !important;
}

.min-height-244 {
  min-height: 244px !important;
}

.max-height-244 {
  max-height: 244px !important;
}

.width-245 {
  width: 245px !important;
}

.min-width-245 {
  min-width: 245px !important;
}

.max-width-245 {
  max-width: 245px !important;
}

.height-245 {
  height: 245px !important;
}

.min-height-245 {
  min-height: 245px !important;
}

.max-height-245 {
  max-height: 245px !important;
}

.width-246 {
  width: 246px !important;
}

.min-width-246 {
  min-width: 246px !important;
}

.max-width-246 {
  max-width: 246px !important;
}

.height-246 {
  height: 246px !important;
}

.min-height-246 {
  min-height: 246px !important;
}

.max-height-246 {
  max-height: 246px !important;
}

.width-247 {
  width: 247px !important;
}

.min-width-247 {
  min-width: 247px !important;
}

.max-width-247 {
  max-width: 247px !important;
}

.height-247 {
  height: 247px !important;
}

.min-height-247 {
  min-height: 247px !important;
}

.max-height-247 {
  max-height: 247px !important;
}

.width-248 {
  width: 248px !important;
}

.min-width-248 {
  min-width: 248px !important;
}

.max-width-248 {
  max-width: 248px !important;
}

.height-248 {
  height: 248px !important;
}

.min-height-248 {
  min-height: 248px !important;
}

.max-height-248 {
  max-height: 248px !important;
}

.width-249 {
  width: 249px !important;
}

.min-width-249 {
  min-width: 249px !important;
}

.max-width-249 {
  max-width: 249px !important;
}

.height-249 {
  height: 249px !important;
}

.min-height-249 {
  min-height: 249px !important;
}

.max-height-249 {
  max-height: 249px !important;
}

.width-250 {
  width: 250px !important;
}

.min-width-250 {
  min-width: 250px !important;
}

.max-width-250 {
  max-width: 250px !important;
}

.height-250 {
  height: 250px !important;
}

.min-height-250 {
  min-height: 250px !important;
}

.max-height-250 {
  max-height: 250px !important;
}

.width-251 {
  width: 251px !important;
}

.min-width-251 {
  min-width: 251px !important;
}

.max-width-251 {
  max-width: 251px !important;
}

.height-251 {
  height: 251px !important;
}

.min-height-251 {
  min-height: 251px !important;
}

.max-height-251 {
  max-height: 251px !important;
}

.width-252 {
  width: 252px !important;
}

.min-width-252 {
  min-width: 252px !important;
}

.max-width-252 {
  max-width: 252px !important;
}

.height-252 {
  height: 252px !important;
}

.min-height-252 {
  min-height: 252px !important;
}

.max-height-252 {
  max-height: 252px !important;
}

.width-253 {
  width: 253px !important;
}

.min-width-253 {
  min-width: 253px !important;
}

.max-width-253 {
  max-width: 253px !important;
}

.height-253 {
  height: 253px !important;
}

.min-height-253 {
  min-height: 253px !important;
}

.max-height-253 {
  max-height: 253px !important;
}

.width-254 {
  width: 254px !important;
}

.min-width-254 {
  min-width: 254px !important;
}

.max-width-254 {
  max-width: 254px !important;
}

.height-254 {
  height: 254px !important;
}

.min-height-254 {
  min-height: 254px !important;
}

.max-height-254 {
  max-height: 254px !important;
}

.width-255 {
  width: 255px !important;
}

.min-width-255 {
  min-width: 255px !important;
}

.max-width-255 {
  max-width: 255px !important;
}

.height-255 {
  height: 255px !important;
}

.min-height-255 {
  min-height: 255px !important;
}

.max-height-255 {
  max-height: 255px !important;
}

.width-256 {
  width: 256px !important;
}

.min-width-256 {
  min-width: 256px !important;
}

.max-width-256 {
  max-width: 256px !important;
}

.height-256 {
  height: 256px !important;
}

.min-height-256 {
  min-height: 256px !important;
}

.max-height-256 {
  max-height: 256px !important;
}

.width-257 {
  width: 257px !important;
}

.min-width-257 {
  min-width: 257px !important;
}

.max-width-257 {
  max-width: 257px !important;
}

.height-257 {
  height: 257px !important;
}

.min-height-257 {
  min-height: 257px !important;
}

.max-height-257 {
  max-height: 257px !important;
}

.width-258 {
  width: 258px !important;
}

.min-width-258 {
  min-width: 258px !important;
}

.max-width-258 {
  max-width: 258px !important;
}

.height-258 {
  height: 258px !important;
}

.min-height-258 {
  min-height: 258px !important;
}

.max-height-258 {
  max-height: 258px !important;
}

.width-259 {
  width: 259px !important;
}

.min-width-259 {
  min-width: 259px !important;
}

.max-width-259 {
  max-width: 259px !important;
}

.height-259 {
  height: 259px !important;
}

.min-height-259 {
  min-height: 259px !important;
}

.max-height-259 {
  max-height: 259px !important;
}

.width-260 {
  width: 260px !important;
}

.min-width-260 {
  min-width: 260px !important;
}

.max-width-260 {
  max-width: 260px !important;
}

.height-260 {
  height: 260px !important;
}

.min-height-260 {
  min-height: 260px !important;
}

.max-height-260 {
  max-height: 260px !important;
}

.width-261 {
  width: 261px !important;
}

.min-width-261 {
  min-width: 261px !important;
}

.max-width-261 {
  max-width: 261px !important;
}

.height-261 {
  height: 261px !important;
}

.min-height-261 {
  min-height: 261px !important;
}

.max-height-261 {
  max-height: 261px !important;
}

.width-262 {
  width: 262px !important;
}

.min-width-262 {
  min-width: 262px !important;
}

.max-width-262 {
  max-width: 262px !important;
}

.height-262 {
  height: 262px !important;
}

.min-height-262 {
  min-height: 262px !important;
}

.max-height-262 {
  max-height: 262px !important;
}

.width-263 {
  width: 263px !important;
}

.min-width-263 {
  min-width: 263px !important;
}

.max-width-263 {
  max-width: 263px !important;
}

.height-263 {
  height: 263px !important;
}

.min-height-263 {
  min-height: 263px !important;
}

.max-height-263 {
  max-height: 263px !important;
}

.width-264 {
  width: 264px !important;
}

.min-width-264 {
  min-width: 264px !important;
}

.max-width-264 {
  max-width: 264px !important;
}

.height-264 {
  height: 264px !important;
}

.min-height-264 {
  min-height: 264px !important;
}

.max-height-264 {
  max-height: 264px !important;
}

.width-265 {
  width: 265px !important;
}

.min-width-265 {
  min-width: 265px !important;
}

.max-width-265 {
  max-width: 265px !important;
}

.height-265 {
  height: 265px !important;
}

.min-height-265 {
  min-height: 265px !important;
}

.max-height-265 {
  max-height: 265px !important;
}

.width-266 {
  width: 266px !important;
}

.min-width-266 {
  min-width: 266px !important;
}

.max-width-266 {
  max-width: 266px !important;
}

.height-266 {
  height: 266px !important;
}

.min-height-266 {
  min-height: 266px !important;
}

.max-height-266 {
  max-height: 266px !important;
}

.width-267 {
  width: 267px !important;
}

.min-width-267 {
  min-width: 267px !important;
}

.max-width-267 {
  max-width: 267px !important;
}

.height-267 {
  height: 267px !important;
}

.min-height-267 {
  min-height: 267px !important;
}

.max-height-267 {
  max-height: 267px !important;
}

.width-268 {
  width: 268px !important;
}

.min-width-268 {
  min-width: 268px !important;
}

.max-width-268 {
  max-width: 268px !important;
}

.height-268 {
  height: 268px !important;
}

.min-height-268 {
  min-height: 268px !important;
}

.max-height-268 {
  max-height: 268px !important;
}

.width-269 {
  width: 269px !important;
}

.min-width-269 {
  min-width: 269px !important;
}

.max-width-269 {
  max-width: 269px !important;
}

.height-269 {
  height: 269px !important;
}

.min-height-269 {
  min-height: 269px !important;
}

.max-height-269 {
  max-height: 269px !important;
}

.width-270 {
  width: 270px !important;
}

.min-width-270 {
  min-width: 270px !important;
}

.max-width-270 {
  max-width: 270px !important;
}

.height-270 {
  height: 270px !important;
}

.min-height-270 {
  min-height: 270px !important;
}

.max-height-270 {
  max-height: 270px !important;
}

.width-271 {
  width: 271px !important;
}

.min-width-271 {
  min-width: 271px !important;
}

.max-width-271 {
  max-width: 271px !important;
}

.height-271 {
  height: 271px !important;
}

.min-height-271 {
  min-height: 271px !important;
}

.max-height-271 {
  max-height: 271px !important;
}

.width-272 {
  width: 272px !important;
}

.min-width-272 {
  min-width: 272px !important;
}

.max-width-272 {
  max-width: 272px !important;
}

.height-272 {
  height: 272px !important;
}

.min-height-272 {
  min-height: 272px !important;
}

.max-height-272 {
  max-height: 272px !important;
}

.width-273 {
  width: 273px !important;
}

.min-width-273 {
  min-width: 273px !important;
}

.max-width-273 {
  max-width: 273px !important;
}

.height-273 {
  height: 273px !important;
}

.min-height-273 {
  min-height: 273px !important;
}

.max-height-273 {
  max-height: 273px !important;
}

.width-274 {
  width: 274px !important;
}

.min-width-274 {
  min-width: 274px !important;
}

.max-width-274 {
  max-width: 274px !important;
}

.height-274 {
  height: 274px !important;
}

.min-height-274 {
  min-height: 274px !important;
}

.max-height-274 {
  max-height: 274px !important;
}

.width-275 {
  width: 275px !important;
}

.min-width-275 {
  min-width: 275px !important;
}

.max-width-275 {
  max-width: 275px !important;
}

.height-275 {
  height: 275px !important;
}

.min-height-275 {
  min-height: 275px !important;
}

.max-height-275 {
  max-height: 275px !important;
}

.width-276 {
  width: 276px !important;
}

.min-width-276 {
  min-width: 276px !important;
}

.max-width-276 {
  max-width: 276px !important;
}

.height-276 {
  height: 276px !important;
}

.min-height-276 {
  min-height: 276px !important;
}

.max-height-276 {
  max-height: 276px !important;
}

.width-277 {
  width: 277px !important;
}

.min-width-277 {
  min-width: 277px !important;
}

.max-width-277 {
  max-width: 277px !important;
}

.height-277 {
  height: 277px !important;
}

.min-height-277 {
  min-height: 277px !important;
}

.max-height-277 {
  max-height: 277px !important;
}

.width-278 {
  width: 278px !important;
}

.min-width-278 {
  min-width: 278px !important;
}

.max-width-278 {
  max-width: 278px !important;
}

.height-278 {
  height: 278px !important;
}

.min-height-278 {
  min-height: 278px !important;
}

.max-height-278 {
  max-height: 278px !important;
}

.width-279 {
  width: 279px !important;
}

.min-width-279 {
  min-width: 279px !important;
}

.max-width-279 {
  max-width: 279px !important;
}

.height-279 {
  height: 279px !important;
}

.min-height-279 {
  min-height: 279px !important;
}

.max-height-279 {
  max-height: 279px !important;
}

.width-280 {
  width: 280px !important;
}

.min-width-280 {
  min-width: 280px !important;
}

.max-width-280 {
  max-width: 280px !important;
}

.height-280 {
  height: 280px !important;
}

.min-height-280 {
  min-height: 280px !important;
}

.max-height-280 {
  max-height: 280px !important;
}

.width-281 {
  width: 281px !important;
}

.min-width-281 {
  min-width: 281px !important;
}

.max-width-281 {
  max-width: 281px !important;
}

.height-281 {
  height: 281px !important;
}

.min-height-281 {
  min-height: 281px !important;
}

.max-height-281 {
  max-height: 281px !important;
}

.width-282 {
  width: 282px !important;
}

.min-width-282 {
  min-width: 282px !important;
}

.max-width-282 {
  max-width: 282px !important;
}

.height-282 {
  height: 282px !important;
}

.min-height-282 {
  min-height: 282px !important;
}

.max-height-282 {
  max-height: 282px !important;
}

.width-283 {
  width: 283px !important;
}

.min-width-283 {
  min-width: 283px !important;
}

.max-width-283 {
  max-width: 283px !important;
}

.height-283 {
  height: 283px !important;
}

.min-height-283 {
  min-height: 283px !important;
}

.max-height-283 {
  max-height: 283px !important;
}

.width-284 {
  width: 284px !important;
}

.min-width-284 {
  min-width: 284px !important;
}

.max-width-284 {
  max-width: 284px !important;
}

.height-284 {
  height: 284px !important;
}

.min-height-284 {
  min-height: 284px !important;
}

.max-height-284 {
  max-height: 284px !important;
}

.width-285 {
  width: 285px !important;
}

.min-width-285 {
  min-width: 285px !important;
}

.max-width-285 {
  max-width: 285px !important;
}

.height-285 {
  height: 285px !important;
}

.min-height-285 {
  min-height: 285px !important;
}

.max-height-285 {
  max-height: 285px !important;
}

.width-286 {
  width: 286px !important;
}

.min-width-286 {
  min-width: 286px !important;
}

.max-width-286 {
  max-width: 286px !important;
}

.height-286 {
  height: 286px !important;
}

.min-height-286 {
  min-height: 286px !important;
}

.max-height-286 {
  max-height: 286px !important;
}

.width-287 {
  width: 287px !important;
}

.min-width-287 {
  min-width: 287px !important;
}

.max-width-287 {
  max-width: 287px !important;
}

.height-287 {
  height: 287px !important;
}

.min-height-287 {
  min-height: 287px !important;
}

.max-height-287 {
  max-height: 287px !important;
}

.width-288 {
  width: 288px !important;
}

.min-width-288 {
  min-width: 288px !important;
}

.max-width-288 {
  max-width: 288px !important;
}

.height-288 {
  height: 288px !important;
}

.min-height-288 {
  min-height: 288px !important;
}

.max-height-288 {
  max-height: 288px !important;
}

.width-289 {
  width: 289px !important;
}

.min-width-289 {
  min-width: 289px !important;
}

.max-width-289 {
  max-width: 289px !important;
}

.height-289 {
  height: 289px !important;
}

.min-height-289 {
  min-height: 289px !important;
}

.max-height-289 {
  max-height: 289px !important;
}

.width-290 {
  width: 290px !important;
}

.min-width-290 {
  min-width: 290px !important;
}

.max-width-290 {
  max-width: 290px !important;
}

.height-290 {
  height: 290px !important;
}

.min-height-290 {
  min-height: 290px !important;
}

.max-height-290 {
  max-height: 290px !important;
}

.width-291 {
  width: 291px !important;
}

.min-width-291 {
  min-width: 291px !important;
}

.max-width-291 {
  max-width: 291px !important;
}

.height-291 {
  height: 291px !important;
}

.min-height-291 {
  min-height: 291px !important;
}

.max-height-291 {
  max-height: 291px !important;
}

.width-292 {
  width: 292px !important;
}

.min-width-292 {
  min-width: 292px !important;
}

.max-width-292 {
  max-width: 292px !important;
}

.height-292 {
  height: 292px !important;
}

.min-height-292 {
  min-height: 292px !important;
}

.max-height-292 {
  max-height: 292px !important;
}

.width-293 {
  width: 293px !important;
}

.min-width-293 {
  min-width: 293px !important;
}

.max-width-293 {
  max-width: 293px !important;
}

.height-293 {
  height: 293px !important;
}

.min-height-293 {
  min-height: 293px !important;
}

.max-height-293 {
  max-height: 293px !important;
}

.width-294 {
  width: 294px !important;
}

.min-width-294 {
  min-width: 294px !important;
}

.max-width-294 {
  max-width: 294px !important;
}

.height-294 {
  height: 294px !important;
}

.min-height-294 {
  min-height: 294px !important;
}

.max-height-294 {
  max-height: 294px !important;
}

.width-295 {
  width: 295px !important;
}

.min-width-295 {
  min-width: 295px !important;
}

.max-width-295 {
  max-width: 295px !important;
}

.height-295 {
  height: 295px !important;
}

.min-height-295 {
  min-height: 295px !important;
}

.max-height-295 {
  max-height: 295px !important;
}

.width-296 {
  width: 296px !important;
}

.min-width-296 {
  min-width: 296px !important;
}

.max-width-296 {
  max-width: 296px !important;
}

.height-296 {
  height: 296px !important;
}

.min-height-296 {
  min-height: 296px !important;
}

.max-height-296 {
  max-height: 296px !important;
}

.width-297 {
  width: 297px !important;
}

.min-width-297 {
  min-width: 297px !important;
}

.max-width-297 {
  max-width: 297px !important;
}

.height-297 {
  height: 297px !important;
}

.min-height-297 {
  min-height: 297px !important;
}

.max-height-297 {
  max-height: 297px !important;
}

.width-298 {
  width: 298px !important;
}

.min-width-298 {
  min-width: 298px !important;
}

.max-width-298 {
  max-width: 298px !important;
}

.height-298 {
  height: 298px !important;
}

.min-height-298 {
  min-height: 298px !important;
}

.max-height-298 {
  max-height: 298px !important;
}

.width-299 {
  width: 299px !important;
}

.min-width-299 {
  min-width: 299px !important;
}

.max-width-299 {
  max-width: 299px !important;
}

.height-299 {
  height: 299px !important;
}

.min-height-299 {
  min-height: 299px !important;
}

.max-height-299 {
  max-height: 299px !important;
}

.width-300 {
  width: 300px !important;
}

.min-width-300 {
  min-width: 300px !important;
}

.max-width-300 {
  max-width: 300px !important;
}

.height-300 {
  height: 300px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

.max-height-300 {
  max-height: 300px !important;
}

.width-301 {
  width: 301px !important;
}

.min-width-301 {
  min-width: 301px !important;
}

.max-width-301 {
  max-width: 301px !important;
}

.height-301 {
  height: 301px !important;
}

.min-height-301 {
  min-height: 301px !important;
}

.max-height-301 {
  max-height: 301px !important;
}

.width-302 {
  width: 302px !important;
}

.min-width-302 {
  min-width: 302px !important;
}

.max-width-302 {
  max-width: 302px !important;
}

.height-302 {
  height: 302px !important;
}

.min-height-302 {
  min-height: 302px !important;
}

.max-height-302 {
  max-height: 302px !important;
}

.width-303 {
  width: 303px !important;
}

.min-width-303 {
  min-width: 303px !important;
}

.max-width-303 {
  max-width: 303px !important;
}

.height-303 {
  height: 303px !important;
}

.min-height-303 {
  min-height: 303px !important;
}

.max-height-303 {
  max-height: 303px !important;
}

.width-304 {
  width: 304px !important;
}

.min-width-304 {
  min-width: 304px !important;
}

.max-width-304 {
  max-width: 304px !important;
}

.height-304 {
  height: 304px !important;
}

.min-height-304 {
  min-height: 304px !important;
}

.max-height-304 {
  max-height: 304px !important;
}

.width-305 {
  width: 305px !important;
}

.min-width-305 {
  min-width: 305px !important;
}

.max-width-305 {
  max-width: 305px !important;
}

.height-305 {
  height: 305px !important;
}

.min-height-305 {
  min-height: 305px !important;
}

.max-height-305 {
  max-height: 305px !important;
}

.width-306 {
  width: 306px !important;
}

.min-width-306 {
  min-width: 306px !important;
}

.max-width-306 {
  max-width: 306px !important;
}

.height-306 {
  height: 306px !important;
}

.min-height-306 {
  min-height: 306px !important;
}

.max-height-306 {
  max-height: 306px !important;
}

.width-307 {
  width: 307px !important;
}

.min-width-307 {
  min-width: 307px !important;
}

.max-width-307 {
  max-width: 307px !important;
}

.height-307 {
  height: 307px !important;
}

.min-height-307 {
  min-height: 307px !important;
}

.max-height-307 {
  max-height: 307px !important;
}

.width-308 {
  width: 308px !important;
}

.min-width-308 {
  min-width: 308px !important;
}

.max-width-308 {
  max-width: 308px !important;
}

.height-308 {
  height: 308px !important;
}

.min-height-308 {
  min-height: 308px !important;
}

.max-height-308 {
  max-height: 308px !important;
}

.width-309 {
  width: 309px !important;
}

.min-width-309 {
  min-width: 309px !important;
}

.max-width-309 {
  max-width: 309px !important;
}

.height-309 {
  height: 309px !important;
}

.min-height-309 {
  min-height: 309px !important;
}

.max-height-309 {
  max-height: 309px !important;
}

.width-310 {
  width: 310px !important;
}

.min-width-310 {
  min-width: 310px !important;
}

.max-width-310 {
  max-width: 310px !important;
}

.height-310 {
  height: 310px !important;
}

.min-height-310 {
  min-height: 310px !important;
}

.max-height-310 {
  max-height: 310px !important;
}

.width-311 {
  width: 311px !important;
}

.min-width-311 {
  min-width: 311px !important;
}

.max-width-311 {
  max-width: 311px !important;
}

.height-311 {
  height: 311px !important;
}

.min-height-311 {
  min-height: 311px !important;
}

.max-height-311 {
  max-height: 311px !important;
}

.width-312 {
  width: 312px !important;
}

.min-width-312 {
  min-width: 312px !important;
}

.max-width-312 {
  max-width: 312px !important;
}

.height-312 {
  height: 312px !important;
}

.min-height-312 {
  min-height: 312px !important;
}

.max-height-312 {
  max-height: 312px !important;
}

.width-313 {
  width: 313px !important;
}

.min-width-313 {
  min-width: 313px !important;
}

.max-width-313 {
  max-width: 313px !important;
}

.height-313 {
  height: 313px !important;
}

.min-height-313 {
  min-height: 313px !important;
}

.max-height-313 {
  max-height: 313px !important;
}

.width-314 {
  width: 314px !important;
}

.min-width-314 {
  min-width: 314px !important;
}

.max-width-314 {
  max-width: 314px !important;
}

.height-314 {
  height: 314px !important;
}

.min-height-314 {
  min-height: 314px !important;
}

.max-height-314 {
  max-height: 314px !important;
}

.width-315 {
  width: 315px !important;
}

.min-width-315 {
  min-width: 315px !important;
}

.max-width-315 {
  max-width: 315px !important;
}

.height-315 {
  height: 315px !important;
}

.min-height-315 {
  min-height: 315px !important;
}

.max-height-315 {
  max-height: 315px !important;
}

.width-316 {
  width: 316px !important;
}

.min-width-316 {
  min-width: 316px !important;
}

.max-width-316 {
  max-width: 316px !important;
}

.height-316 {
  height: 316px !important;
}

.min-height-316 {
  min-height: 316px !important;
}

.max-height-316 {
  max-height: 316px !important;
}

.width-317 {
  width: 317px !important;
}

.min-width-317 {
  min-width: 317px !important;
}

.max-width-317 {
  max-width: 317px !important;
}

.height-317 {
  height: 317px !important;
}

.min-height-317 {
  min-height: 317px !important;
}

.max-height-317 {
  max-height: 317px !important;
}

.width-318 {
  width: 318px !important;
}

.min-width-318 {
  min-width: 318px !important;
}

.max-width-318 {
  max-width: 318px !important;
}

.height-318 {
  height: 318px !important;
}

.min-height-318 {
  min-height: 318px !important;
}

.max-height-318 {
  max-height: 318px !important;
}

.width-319 {
  width: 319px !important;
}

.min-width-319 {
  min-width: 319px !important;
}

.max-width-319 {
  max-width: 319px !important;
}

.height-319 {
  height: 319px !important;
}

.min-height-319 {
  min-height: 319px !important;
}

.max-height-319 {
  max-height: 319px !important;
}

.width-320 {
  width: 320px !important;
}

.min-width-320 {
  min-width: 320px !important;
}

.max-width-320 {
  max-width: 320px !important;
}

.height-320 {
  height: 320px !important;
}

.min-height-320 {
  min-height: 320px !important;
}

.max-height-320 {
  max-height: 320px !important;
}

.width-321 {
  width: 321px !important;
}

.min-width-321 {
  min-width: 321px !important;
}

.max-width-321 {
  max-width: 321px !important;
}

.height-321 {
  height: 321px !important;
}

.min-height-321 {
  min-height: 321px !important;
}

.max-height-321 {
  max-height: 321px !important;
}

.width-322 {
  width: 322px !important;
}

.min-width-322 {
  min-width: 322px !important;
}

.max-width-322 {
  max-width: 322px !important;
}

.height-322 {
  height: 322px !important;
}

.min-height-322 {
  min-height: 322px !important;
}

.max-height-322 {
  max-height: 322px !important;
}

.width-323 {
  width: 323px !important;
}

.min-width-323 {
  min-width: 323px !important;
}

.max-width-323 {
  max-width: 323px !important;
}

.height-323 {
  height: 323px !important;
}

.min-height-323 {
  min-height: 323px !important;
}

.max-height-323 {
  max-height: 323px !important;
}

.width-324 {
  width: 324px !important;
}

.min-width-324 {
  min-width: 324px !important;
}

.max-width-324 {
  max-width: 324px !important;
}

.height-324 {
  height: 324px !important;
}

.min-height-324 {
  min-height: 324px !important;
}

.max-height-324 {
  max-height: 324px !important;
}

.width-325 {
  width: 325px !important;
}

.min-width-325 {
  min-width: 325px !important;
}

.max-width-325 {
  max-width: 325px !important;
}

.height-325 {
  height: 325px !important;
}

.min-height-325 {
  min-height: 325px !important;
}

.max-height-325 {
  max-height: 325px !important;
}

.width-326 {
  width: 326px !important;
}

.min-width-326 {
  min-width: 326px !important;
}

.max-width-326 {
  max-width: 326px !important;
}

.height-326 {
  height: 326px !important;
}

.min-height-326 {
  min-height: 326px !important;
}

.max-height-326 {
  max-height: 326px !important;
}

.width-327 {
  width: 327px !important;
}

.min-width-327 {
  min-width: 327px !important;
}

.max-width-327 {
  max-width: 327px !important;
}

.height-327 {
  height: 327px !important;
}

.min-height-327 {
  min-height: 327px !important;
}

.max-height-327 {
  max-height: 327px !important;
}

.width-328 {
  width: 328px !important;
}

.min-width-328 {
  min-width: 328px !important;
}

.max-width-328 {
  max-width: 328px !important;
}

.height-328 {
  height: 328px !important;
}

.min-height-328 {
  min-height: 328px !important;
}

.max-height-328 {
  max-height: 328px !important;
}

.width-329 {
  width: 329px !important;
}

.min-width-329 {
  min-width: 329px !important;
}

.max-width-329 {
  max-width: 329px !important;
}

.height-329 {
  height: 329px !important;
}

.min-height-329 {
  min-height: 329px !important;
}

.max-height-329 {
  max-height: 329px !important;
}

.width-330 {
  width: 330px !important;
}

.min-width-330 {
  min-width: 330px !important;
}

.max-width-330 {
  max-width: 330px !important;
}

.height-330 {
  height: 330px !important;
}

.min-height-330 {
  min-height: 330px !important;
}

.max-height-330 {
  max-height: 330px !important;
}

.width-331 {
  width: 331px !important;
}

.min-width-331 {
  min-width: 331px !important;
}

.max-width-331 {
  max-width: 331px !important;
}

.height-331 {
  height: 331px !important;
}

.min-height-331 {
  min-height: 331px !important;
}

.max-height-331 {
  max-height: 331px !important;
}

.width-332 {
  width: 332px !important;
}

.min-width-332 {
  min-width: 332px !important;
}

.max-width-332 {
  max-width: 332px !important;
}

.height-332 {
  height: 332px !important;
}

.min-height-332 {
  min-height: 332px !important;
}

.max-height-332 {
  max-height: 332px !important;
}

.width-333 {
  width: 333px !important;
}

.min-width-333 {
  min-width: 333px !important;
}

.max-width-333 {
  max-width: 333px !important;
}

.height-333 {
  height: 333px !important;
}

.min-height-333 {
  min-height: 333px !important;
}

.max-height-333 {
  max-height: 333px !important;
}

.width-334 {
  width: 334px !important;
}

.min-width-334 {
  min-width: 334px !important;
}

.max-width-334 {
  max-width: 334px !important;
}

.height-334 {
  height: 334px !important;
}

.min-height-334 {
  min-height: 334px !important;
}

.max-height-334 {
  max-height: 334px !important;
}

.width-335 {
  width: 335px !important;
}

.min-width-335 {
  min-width: 335px !important;
}

.max-width-335 {
  max-width: 335px !important;
}

.height-335 {
  height: 335px !important;
}

.min-height-335 {
  min-height: 335px !important;
}

.max-height-335 {
  max-height: 335px !important;
}

.width-336 {
  width: 336px !important;
}

.min-width-336 {
  min-width: 336px !important;
}

.max-width-336 {
  max-width: 336px !important;
}

.height-336 {
  height: 336px !important;
}

.min-height-336 {
  min-height: 336px !important;
}

.max-height-336 {
  max-height: 336px !important;
}

.width-337 {
  width: 337px !important;
}

.min-width-337 {
  min-width: 337px !important;
}

.max-width-337 {
  max-width: 337px !important;
}

.height-337 {
  height: 337px !important;
}

.min-height-337 {
  min-height: 337px !important;
}

.max-height-337 {
  max-height: 337px !important;
}

.width-338 {
  width: 338px !important;
}

.min-width-338 {
  min-width: 338px !important;
}

.max-width-338 {
  max-width: 338px !important;
}

.height-338 {
  height: 338px !important;
}

.min-height-338 {
  min-height: 338px !important;
}

.max-height-338 {
  max-height: 338px !important;
}

.width-339 {
  width: 339px !important;
}

.min-width-339 {
  min-width: 339px !important;
}

.max-width-339 {
  max-width: 339px !important;
}

.height-339 {
  height: 339px !important;
}

.min-height-339 {
  min-height: 339px !important;
}

.max-height-339 {
  max-height: 339px !important;
}

.width-340 {
  width: 340px !important;
}

.min-width-340 {
  min-width: 340px !important;
}

.max-width-340 {
  max-width: 340px !important;
}

.height-340 {
  height: 340px !important;
}

.min-height-340 {
  min-height: 340px !important;
}

.max-height-340 {
  max-height: 340px !important;
}

.width-341 {
  width: 341px !important;
}

.min-width-341 {
  min-width: 341px !important;
}

.max-width-341 {
  max-width: 341px !important;
}

.height-341 {
  height: 341px !important;
}

.min-height-341 {
  min-height: 341px !important;
}

.max-height-341 {
  max-height: 341px !important;
}

.width-342 {
  width: 342px !important;
}

.min-width-342 {
  min-width: 342px !important;
}

.max-width-342 {
  max-width: 342px !important;
}

.height-342 {
  height: 342px !important;
}

.min-height-342 {
  min-height: 342px !important;
}

.max-height-342 {
  max-height: 342px !important;
}

.width-343 {
  width: 343px !important;
}

.min-width-343 {
  min-width: 343px !important;
}

.max-width-343 {
  max-width: 343px !important;
}

.height-343 {
  height: 343px !important;
}

.min-height-343 {
  min-height: 343px !important;
}

.max-height-343 {
  max-height: 343px !important;
}

.width-344 {
  width: 344px !important;
}

.min-width-344 {
  min-width: 344px !important;
}

.max-width-344 {
  max-width: 344px !important;
}

.height-344 {
  height: 344px !important;
}

.min-height-344 {
  min-height: 344px !important;
}

.max-height-344 {
  max-height: 344px !important;
}

.width-345 {
  width: 345px !important;
}

.min-width-345 {
  min-width: 345px !important;
}

.max-width-345 {
  max-width: 345px !important;
}

.height-345 {
  height: 345px !important;
}

.min-height-345 {
  min-height: 345px !important;
}

.max-height-345 {
  max-height: 345px !important;
}

.width-346 {
  width: 346px !important;
}

.min-width-346 {
  min-width: 346px !important;
}

.max-width-346 {
  max-width: 346px !important;
}

.height-346 {
  height: 346px !important;
}

.min-height-346 {
  min-height: 346px !important;
}

.max-height-346 {
  max-height: 346px !important;
}

.width-347 {
  width: 347px !important;
}

.min-width-347 {
  min-width: 347px !important;
}

.max-width-347 {
  max-width: 347px !important;
}

.height-347 {
  height: 347px !important;
}

.min-height-347 {
  min-height: 347px !important;
}

.max-height-347 {
  max-height: 347px !important;
}

.width-348 {
  width: 348px !important;
}

.min-width-348 {
  min-width: 348px !important;
}

.max-width-348 {
  max-width: 348px !important;
}

.height-348 {
  height: 348px !important;
}

.min-height-348 {
  min-height: 348px !important;
}

.max-height-348 {
  max-height: 348px !important;
}

.width-349 {
  width: 349px !important;
}

.min-width-349 {
  min-width: 349px !important;
}

.max-width-349 {
  max-width: 349px !important;
}

.height-349 {
  height: 349px !important;
}

.min-height-349 {
  min-height: 349px !important;
}

.max-height-349 {
  max-height: 349px !important;
}

.width-350 {
  width: 350px !important;
}

.min-width-350 {
  min-width: 350px !important;
}

.max-width-350 {
  max-width: 350px !important;
}

.height-350 {
  height: 350px !important;
}

.min-height-350 {
  min-height: 350px !important;
}

.max-height-350 {
  max-height: 350px !important;
}

.width-351 {
  width: 351px !important;
}

.min-width-351 {
  min-width: 351px !important;
}

.max-width-351 {
  max-width: 351px !important;
}

.height-351 {
  height: 351px !important;
}

.min-height-351 {
  min-height: 351px !important;
}

.max-height-351 {
  max-height: 351px !important;
}

.width-352 {
  width: 352px !important;
}

.min-width-352 {
  min-width: 352px !important;
}

.max-width-352 {
  max-width: 352px !important;
}

.height-352 {
  height: 352px !important;
}

.min-height-352 {
  min-height: 352px !important;
}

.max-height-352 {
  max-height: 352px !important;
}

.width-353 {
  width: 353px !important;
}

.min-width-353 {
  min-width: 353px !important;
}

.max-width-353 {
  max-width: 353px !important;
}

.height-353 {
  height: 353px !important;
}

.min-height-353 {
  min-height: 353px !important;
}

.max-height-353 {
  max-height: 353px !important;
}

.width-354 {
  width: 354px !important;
}

.min-width-354 {
  min-width: 354px !important;
}

.max-width-354 {
  max-width: 354px !important;
}

.height-354 {
  height: 354px !important;
}

.min-height-354 {
  min-height: 354px !important;
}

.max-height-354 {
  max-height: 354px !important;
}

.width-355 {
  width: 355px !important;
}

.min-width-355 {
  min-width: 355px !important;
}

.max-width-355 {
  max-width: 355px !important;
}

.height-355 {
  height: 355px !important;
}

.min-height-355 {
  min-height: 355px !important;
}

.max-height-355 {
  max-height: 355px !important;
}

.width-356 {
  width: 356px !important;
}

.min-width-356 {
  min-width: 356px !important;
}

.max-width-356 {
  max-width: 356px !important;
}

.height-356 {
  height: 356px !important;
}

.min-height-356 {
  min-height: 356px !important;
}

.max-height-356 {
  max-height: 356px !important;
}

.width-357 {
  width: 357px !important;
}

.min-width-357 {
  min-width: 357px !important;
}

.max-width-357 {
  max-width: 357px !important;
}

.height-357 {
  height: 357px !important;
}

.min-height-357 {
  min-height: 357px !important;
}

.max-height-357 {
  max-height: 357px !important;
}

.width-358 {
  width: 358px !important;
}

.min-width-358 {
  min-width: 358px !important;
}

.max-width-358 {
  max-width: 358px !important;
}

.height-358 {
  height: 358px !important;
}

.min-height-358 {
  min-height: 358px !important;
}

.max-height-358 {
  max-height: 358px !important;
}

.width-359 {
  width: 359px !important;
}

.min-width-359 {
  min-width: 359px !important;
}

.max-width-359 {
  max-width: 359px !important;
}

.height-359 {
  height: 359px !important;
}

.min-height-359 {
  min-height: 359px !important;
}

.max-height-359 {
  max-height: 359px !important;
}

.width-360 {
  width: 360px !important;
}

.min-width-360 {
  min-width: 360px !important;
}

.max-width-360 {
  max-width: 360px !important;
}

.height-360 {
  height: 360px !important;
}

.min-height-360 {
  min-height: 360px !important;
}

.max-height-360 {
  max-height: 360px !important;
}

.width-361 {
  width: 361px !important;
}

.min-width-361 {
  min-width: 361px !important;
}

.max-width-361 {
  max-width: 361px !important;
}

.height-361 {
  height: 361px !important;
}

.min-height-361 {
  min-height: 361px !important;
}

.max-height-361 {
  max-height: 361px !important;
}

.width-362 {
  width: 362px !important;
}

.min-width-362 {
  min-width: 362px !important;
}

.max-width-362 {
  max-width: 362px !important;
}

.height-362 {
  height: 362px !important;
}

.min-height-362 {
  min-height: 362px !important;
}

.max-height-362 {
  max-height: 362px !important;
}

.width-363 {
  width: 363px !important;
}

.min-width-363 {
  min-width: 363px !important;
}

.max-width-363 {
  max-width: 363px !important;
}

.height-363 {
  height: 363px !important;
}

.min-height-363 {
  min-height: 363px !important;
}

.max-height-363 {
  max-height: 363px !important;
}

.width-364 {
  width: 364px !important;
}

.min-width-364 {
  min-width: 364px !important;
}

.max-width-364 {
  max-width: 364px !important;
}

.height-364 {
  height: 364px !important;
}

.min-height-364 {
  min-height: 364px !important;
}

.max-height-364 {
  max-height: 364px !important;
}

.width-365 {
  width: 365px !important;
}

.min-width-365 {
  min-width: 365px !important;
}

.max-width-365 {
  max-width: 365px !important;
}

.height-365 {
  height: 365px !important;
}

.min-height-365 {
  min-height: 365px !important;
}

.max-height-365 {
  max-height: 365px !important;
}

.width-366 {
  width: 366px !important;
}

.min-width-366 {
  min-width: 366px !important;
}

.max-width-366 {
  max-width: 366px !important;
}

.height-366 {
  height: 366px !important;
}

.min-height-366 {
  min-height: 366px !important;
}

.max-height-366 {
  max-height: 366px !important;
}

.width-367 {
  width: 367px !important;
}

.min-width-367 {
  min-width: 367px !important;
}

.max-width-367 {
  max-width: 367px !important;
}

.height-367 {
  height: 367px !important;
}

.min-height-367 {
  min-height: 367px !important;
}

.max-height-367 {
  max-height: 367px !important;
}

.width-368 {
  width: 368px !important;
}

.min-width-368 {
  min-width: 368px !important;
}

.max-width-368 {
  max-width: 368px !important;
}

.height-368 {
  height: 368px !important;
}

.min-height-368 {
  min-height: 368px !important;
}

.max-height-368 {
  max-height: 368px !important;
}

.width-369 {
  width: 369px !important;
}

.min-width-369 {
  min-width: 369px !important;
}

.max-width-369 {
  max-width: 369px !important;
}

.height-369 {
  height: 369px !important;
}

.min-height-369 {
  min-height: 369px !important;
}

.max-height-369 {
  max-height: 369px !important;
}

.width-370 {
  width: 370px !important;
}

.min-width-370 {
  min-width: 370px !important;
}

.max-width-370 {
  max-width: 370px !important;
}

.height-370 {
  height: 370px !important;
}

.min-height-370 {
  min-height: 370px !important;
}

.max-height-370 {
  max-height: 370px !important;
}

.width-371 {
  width: 371px !important;
}

.min-width-371 {
  min-width: 371px !important;
}

.max-width-371 {
  max-width: 371px !important;
}

.height-371 {
  height: 371px !important;
}

.min-height-371 {
  min-height: 371px !important;
}

.max-height-371 {
  max-height: 371px !important;
}

.width-372 {
  width: 372px !important;
}

.min-width-372 {
  min-width: 372px !important;
}

.max-width-372 {
  max-width: 372px !important;
}

.height-372 {
  height: 372px !important;
}

.min-height-372 {
  min-height: 372px !important;
}

.max-height-372 {
  max-height: 372px !important;
}

.width-373 {
  width: 373px !important;
}

.min-width-373 {
  min-width: 373px !important;
}

.max-width-373 {
  max-width: 373px !important;
}

.height-373 {
  height: 373px !important;
}

.min-height-373 {
  min-height: 373px !important;
}

.max-height-373 {
  max-height: 373px !important;
}

.width-374 {
  width: 374px !important;
}

.min-width-374 {
  min-width: 374px !important;
}

.max-width-374 {
  max-width: 374px !important;
}

.height-374 {
  height: 374px !important;
}

.min-height-374 {
  min-height: 374px !important;
}

.max-height-374 {
  max-height: 374px !important;
}

.width-375 {
  width: 375px !important;
}

.min-width-375 {
  min-width: 375px !important;
}

.max-width-375 {
  max-width: 375px !important;
}

.height-375 {
  height: 375px !important;
}

.min-height-375 {
  min-height: 375px !important;
}

.max-height-375 {
  max-height: 375px !important;
}

.width-376 {
  width: 376px !important;
}

.min-width-376 {
  min-width: 376px !important;
}

.max-width-376 {
  max-width: 376px !important;
}

.height-376 {
  height: 376px !important;
}

.min-height-376 {
  min-height: 376px !important;
}

.max-height-376 {
  max-height: 376px !important;
}

.width-377 {
  width: 377px !important;
}

.min-width-377 {
  min-width: 377px !important;
}

.max-width-377 {
  max-width: 377px !important;
}

.height-377 {
  height: 377px !important;
}

.min-height-377 {
  min-height: 377px !important;
}

.max-height-377 {
  max-height: 377px !important;
}

.width-378 {
  width: 378px !important;
}

.min-width-378 {
  min-width: 378px !important;
}

.max-width-378 {
  max-width: 378px !important;
}

.height-378 {
  height: 378px !important;
}

.min-height-378 {
  min-height: 378px !important;
}

.max-height-378 {
  max-height: 378px !important;
}

.width-379 {
  width: 379px !important;
}

.min-width-379 {
  min-width: 379px !important;
}

.max-width-379 {
  max-width: 379px !important;
}

.height-379 {
  height: 379px !important;
}

.min-height-379 {
  min-height: 379px !important;
}

.max-height-379 {
  max-height: 379px !important;
}

.width-380 {
  width: 380px !important;
}

.min-width-380 {
  min-width: 380px !important;
}

.max-width-380 {
  max-width: 380px !important;
}

.height-380 {
  height: 380px !important;
}

.min-height-380 {
  min-height: 380px !important;
}

.max-height-380 {
  max-height: 380px !important;
}

.width-381 {
  width: 381px !important;
}

.min-width-381 {
  min-width: 381px !important;
}

.max-width-381 {
  max-width: 381px !important;
}

.height-381 {
  height: 381px !important;
}

.min-height-381 {
  min-height: 381px !important;
}

.max-height-381 {
  max-height: 381px !important;
}

.width-382 {
  width: 382px !important;
}

.min-width-382 {
  min-width: 382px !important;
}

.max-width-382 {
  max-width: 382px !important;
}

.height-382 {
  height: 382px !important;
}

.min-height-382 {
  min-height: 382px !important;
}

.max-height-382 {
  max-height: 382px !important;
}

.width-383 {
  width: 383px !important;
}

.min-width-383 {
  min-width: 383px !important;
}

.max-width-383 {
  max-width: 383px !important;
}

.height-383 {
  height: 383px !important;
}

.min-height-383 {
  min-height: 383px !important;
}

.max-height-383 {
  max-height: 383px !important;
}

.width-384 {
  width: 384px !important;
}

.min-width-384 {
  min-width: 384px !important;
}

.max-width-384 {
  max-width: 384px !important;
}

.height-384 {
  height: 384px !important;
}

.min-height-384 {
  min-height: 384px !important;
}

.max-height-384 {
  max-height: 384px !important;
}

.width-385 {
  width: 385px !important;
}

.min-width-385 {
  min-width: 385px !important;
}

.max-width-385 {
  max-width: 385px !important;
}

.height-385 {
  height: 385px !important;
}

.min-height-385 {
  min-height: 385px !important;
}

.max-height-385 {
  max-height: 385px !important;
}

.width-386 {
  width: 386px !important;
}

.min-width-386 {
  min-width: 386px !important;
}

.max-width-386 {
  max-width: 386px !important;
}

.height-386 {
  height: 386px !important;
}

.min-height-386 {
  min-height: 386px !important;
}

.max-height-386 {
  max-height: 386px !important;
}

.width-387 {
  width: 387px !important;
}

.min-width-387 {
  min-width: 387px !important;
}

.max-width-387 {
  max-width: 387px !important;
}

.height-387 {
  height: 387px !important;
}

.min-height-387 {
  min-height: 387px !important;
}

.max-height-387 {
  max-height: 387px !important;
}

.width-388 {
  width: 388px !important;
}

.min-width-388 {
  min-width: 388px !important;
}

.max-width-388 {
  max-width: 388px !important;
}

.height-388 {
  height: 388px !important;
}

.min-height-388 {
  min-height: 388px !important;
}

.max-height-388 {
  max-height: 388px !important;
}

.width-389 {
  width: 389px !important;
}

.min-width-389 {
  min-width: 389px !important;
}

.max-width-389 {
  max-width: 389px !important;
}

.height-389 {
  height: 389px !important;
}

.min-height-389 {
  min-height: 389px !important;
}

.max-height-389 {
  max-height: 389px !important;
}

.width-390 {
  width: 390px !important;
}

.min-width-390 {
  min-width: 390px !important;
}

.max-width-390 {
  max-width: 390px !important;
}

.height-390 {
  height: 390px !important;
}

.min-height-390 {
  min-height: 390px !important;
}

.max-height-390 {
  max-height: 390px !important;
}

.width-391 {
  width: 391px !important;
}

.min-width-391 {
  min-width: 391px !important;
}

.max-width-391 {
  max-width: 391px !important;
}

.height-391 {
  height: 391px !important;
}

.min-height-391 {
  min-height: 391px !important;
}

.max-height-391 {
  max-height: 391px !important;
}

.width-392 {
  width: 392px !important;
}

.min-width-392 {
  min-width: 392px !important;
}

.max-width-392 {
  max-width: 392px !important;
}

.height-392 {
  height: 392px !important;
}

.min-height-392 {
  min-height: 392px !important;
}

.max-height-392 {
  max-height: 392px !important;
}

.width-393 {
  width: 393px !important;
}

.min-width-393 {
  min-width: 393px !important;
}

.max-width-393 {
  max-width: 393px !important;
}

.height-393 {
  height: 393px !important;
}

.min-height-393 {
  min-height: 393px !important;
}

.max-height-393 {
  max-height: 393px !important;
}

.width-394 {
  width: 394px !important;
}

.min-width-394 {
  min-width: 394px !important;
}

.max-width-394 {
  max-width: 394px !important;
}

.height-394 {
  height: 394px !important;
}

.min-height-394 {
  min-height: 394px !important;
}

.max-height-394 {
  max-height: 394px !important;
}

.width-395 {
  width: 395px !important;
}

.min-width-395 {
  min-width: 395px !important;
}

.max-width-395 {
  max-width: 395px !important;
}

.height-395 {
  height: 395px !important;
}

.min-height-395 {
  min-height: 395px !important;
}

.max-height-395 {
  max-height: 395px !important;
}

.width-396 {
  width: 396px !important;
}

.min-width-396 {
  min-width: 396px !important;
}

.max-width-396 {
  max-width: 396px !important;
}

.height-396 {
  height: 396px !important;
}

.min-height-396 {
  min-height: 396px !important;
}

.max-height-396 {
  max-height: 396px !important;
}

.width-397 {
  width: 397px !important;
}

.min-width-397 {
  min-width: 397px !important;
}

.max-width-397 {
  max-width: 397px !important;
}

.height-397 {
  height: 397px !important;
}

.min-height-397 {
  min-height: 397px !important;
}

.max-height-397 {
  max-height: 397px !important;
}

.width-398 {
  width: 398px !important;
}

.min-width-398 {
  min-width: 398px !important;
}

.max-width-398 {
  max-width: 398px !important;
}

.height-398 {
  height: 398px !important;
}

.min-height-398 {
  min-height: 398px !important;
}

.max-height-398 {
  max-height: 398px !important;
}

.width-399 {
  width: 399px !important;
}

.min-width-399 {
  min-width: 399px !important;
}

.max-width-399 {
  max-width: 399px !important;
}

.height-399 {
  height: 399px !important;
}

.min-height-399 {
  min-height: 399px !important;
}

.max-height-399 {
  max-height: 399px !important;
}

.width-400 {
  width: 400px !important;
}

.min-width-400 {
  min-width: 400px !important;
}

.max-width-400 {
  max-width: 400px !important;
}

.height-400 {
  height: 400px !important;
}

.min-height-400 {
  min-height: 400px !important;
}

.max-height-400 {
  max-height: 400px !important;
}

.width-401 {
  width: 401px !important;
}

.min-width-401 {
  min-width: 401px !important;
}

.max-width-401 {
  max-width: 401px !important;
}

.height-401 {
  height: 401px !important;
}

.min-height-401 {
  min-height: 401px !important;
}

.max-height-401 {
  max-height: 401px !important;
}

.width-402 {
  width: 402px !important;
}

.min-width-402 {
  min-width: 402px !important;
}

.max-width-402 {
  max-width: 402px !important;
}

.height-402 {
  height: 402px !important;
}

.min-height-402 {
  min-height: 402px !important;
}

.max-height-402 {
  max-height: 402px !important;
}

.width-403 {
  width: 403px !important;
}

.min-width-403 {
  min-width: 403px !important;
}

.max-width-403 {
  max-width: 403px !important;
}

.height-403 {
  height: 403px !important;
}

.min-height-403 {
  min-height: 403px !important;
}

.max-height-403 {
  max-height: 403px !important;
}

.width-404 {
  width: 404px !important;
}

.min-width-404 {
  min-width: 404px !important;
}

.max-width-404 {
  max-width: 404px !important;
}

.height-404 {
  height: 404px !important;
}

.min-height-404 {
  min-height: 404px !important;
}

.max-height-404 {
  max-height: 404px !important;
}

.width-405 {
  width: 405px !important;
}

.min-width-405 {
  min-width: 405px !important;
}

.max-width-405 {
  max-width: 405px !important;
}

.height-405 {
  height: 405px !important;
}

.min-height-405 {
  min-height: 405px !important;
}

.max-height-405 {
  max-height: 405px !important;
}

.width-406 {
  width: 406px !important;
}

.min-width-406 {
  min-width: 406px !important;
}

.max-width-406 {
  max-width: 406px !important;
}

.height-406 {
  height: 406px !important;
}

.min-height-406 {
  min-height: 406px !important;
}

.max-height-406 {
  max-height: 406px !important;
}

.width-407 {
  width: 407px !important;
}

.min-width-407 {
  min-width: 407px !important;
}

.max-width-407 {
  max-width: 407px !important;
}

.height-407 {
  height: 407px !important;
}

.min-height-407 {
  min-height: 407px !important;
}

.max-height-407 {
  max-height: 407px !important;
}

.width-408 {
  width: 408px !important;
}

.min-width-408 {
  min-width: 408px !important;
}

.max-width-408 {
  max-width: 408px !important;
}

.height-408 {
  height: 408px !important;
}

.min-height-408 {
  min-height: 408px !important;
}

.max-height-408 {
  max-height: 408px !important;
}

.width-409 {
  width: 409px !important;
}

.min-width-409 {
  min-width: 409px !important;
}

.max-width-409 {
  max-width: 409px !important;
}

.height-409 {
  height: 409px !important;
}

.min-height-409 {
  min-height: 409px !important;
}

.max-height-409 {
  max-height: 409px !important;
}

.width-410 {
  width: 410px !important;
}

.min-width-410 {
  min-width: 410px !important;
}

.max-width-410 {
  max-width: 410px !important;
}

.height-410 {
  height: 410px !important;
}

.min-height-410 {
  min-height: 410px !important;
}

.max-height-410 {
  max-height: 410px !important;
}

.width-411 {
  width: 411px !important;
}

.min-width-411 {
  min-width: 411px !important;
}

.max-width-411 {
  max-width: 411px !important;
}

.height-411 {
  height: 411px !important;
}

.min-height-411 {
  min-height: 411px !important;
}

.max-height-411 {
  max-height: 411px !important;
}

.width-412 {
  width: 412px !important;
}

.min-width-412 {
  min-width: 412px !important;
}

.max-width-412 {
  max-width: 412px !important;
}

.height-412 {
  height: 412px !important;
}

.min-height-412 {
  min-height: 412px !important;
}

.max-height-412 {
  max-height: 412px !important;
}

.width-413 {
  width: 413px !important;
}

.min-width-413 {
  min-width: 413px !important;
}

.max-width-413 {
  max-width: 413px !important;
}

.height-413 {
  height: 413px !important;
}

.min-height-413 {
  min-height: 413px !important;
}

.max-height-413 {
  max-height: 413px !important;
}

.width-414 {
  width: 414px !important;
}

.min-width-414 {
  min-width: 414px !important;
}

.max-width-414 {
  max-width: 414px !important;
}

.height-414 {
  height: 414px !important;
}

.min-height-414 {
  min-height: 414px !important;
}

.max-height-414 {
  max-height: 414px !important;
}

.width-415 {
  width: 415px !important;
}

.min-width-415 {
  min-width: 415px !important;
}

.max-width-415 {
  max-width: 415px !important;
}

.height-415 {
  height: 415px !important;
}

.min-height-415 {
  min-height: 415px !important;
}

.max-height-415 {
  max-height: 415px !important;
}

.width-416 {
  width: 416px !important;
}

.min-width-416 {
  min-width: 416px !important;
}

.max-width-416 {
  max-width: 416px !important;
}

.height-416 {
  height: 416px !important;
}

.min-height-416 {
  min-height: 416px !important;
}

.max-height-416 {
  max-height: 416px !important;
}

.width-417 {
  width: 417px !important;
}

.min-width-417 {
  min-width: 417px !important;
}

.max-width-417 {
  max-width: 417px !important;
}

.height-417 {
  height: 417px !important;
}

.min-height-417 {
  min-height: 417px !important;
}

.max-height-417 {
  max-height: 417px !important;
}

.width-418 {
  width: 418px !important;
}

.min-width-418 {
  min-width: 418px !important;
}

.max-width-418 {
  max-width: 418px !important;
}

.height-418 {
  height: 418px !important;
}

.min-height-418 {
  min-height: 418px !important;
}

.max-height-418 {
  max-height: 418px !important;
}

.width-419 {
  width: 419px !important;
}

.min-width-419 {
  min-width: 419px !important;
}

.max-width-419 {
  max-width: 419px !important;
}

.height-419 {
  height: 419px !important;
}

.min-height-419 {
  min-height: 419px !important;
}

.max-height-419 {
  max-height: 419px !important;
}

.width-420 {
  width: 420px !important;
}

.min-width-420 {
  min-width: 420px !important;
}

.max-width-420 {
  max-width: 420px !important;
}

.height-420 {
  height: 420px !important;
}

.min-height-420 {
  min-height: 420px !important;
}

.max-height-420 {
  max-height: 420px !important;
}

.width-421 {
  width: 421px !important;
}

.min-width-421 {
  min-width: 421px !important;
}

.max-width-421 {
  max-width: 421px !important;
}

.height-421 {
  height: 421px !important;
}

.min-height-421 {
  min-height: 421px !important;
}

.max-height-421 {
  max-height: 421px !important;
}

.width-422 {
  width: 422px !important;
}

.min-width-422 {
  min-width: 422px !important;
}

.max-width-422 {
  max-width: 422px !important;
}

.height-422 {
  height: 422px !important;
}

.min-height-422 {
  min-height: 422px !important;
}

.max-height-422 {
  max-height: 422px !important;
}

.width-423 {
  width: 423px !important;
}

.min-width-423 {
  min-width: 423px !important;
}

.max-width-423 {
  max-width: 423px !important;
}

.height-423 {
  height: 423px !important;
}

.min-height-423 {
  min-height: 423px !important;
}

.max-height-423 {
  max-height: 423px !important;
}

.width-424 {
  width: 424px !important;
}

.min-width-424 {
  min-width: 424px !important;
}

.max-width-424 {
  max-width: 424px !important;
}

.height-424 {
  height: 424px !important;
}

.min-height-424 {
  min-height: 424px !important;
}

.max-height-424 {
  max-height: 424px !important;
}

.width-425 {
  width: 425px !important;
}

.min-width-425 {
  min-width: 425px !important;
}

.max-width-425 {
  max-width: 425px !important;
}

.height-425 {
  height: 425px !important;
}

.min-height-425 {
  min-height: 425px !important;
}

.max-height-425 {
  max-height: 425px !important;
}

.width-426 {
  width: 426px !important;
}

.min-width-426 {
  min-width: 426px !important;
}

.max-width-426 {
  max-width: 426px !important;
}

.height-426 {
  height: 426px !important;
}

.min-height-426 {
  min-height: 426px !important;
}

.max-height-426 {
  max-height: 426px !important;
}

.width-427 {
  width: 427px !important;
}

.min-width-427 {
  min-width: 427px !important;
}

.max-width-427 {
  max-width: 427px !important;
}

.height-427 {
  height: 427px !important;
}

.min-height-427 {
  min-height: 427px !important;
}

.max-height-427 {
  max-height: 427px !important;
}

.width-428 {
  width: 428px !important;
}

.min-width-428 {
  min-width: 428px !important;
}

.max-width-428 {
  max-width: 428px !important;
}

.height-428 {
  height: 428px !important;
}

.min-height-428 {
  min-height: 428px !important;
}

.max-height-428 {
  max-height: 428px !important;
}

.width-429 {
  width: 429px !important;
}

.min-width-429 {
  min-width: 429px !important;
}

.max-width-429 {
  max-width: 429px !important;
}

.height-429 {
  height: 429px !important;
}

.min-height-429 {
  min-height: 429px !important;
}

.max-height-429 {
  max-height: 429px !important;
}

.width-430 {
  width: 430px !important;
}

.min-width-430 {
  min-width: 430px !important;
}

.max-width-430 {
  max-width: 430px !important;
}

.height-430 {
  height: 430px !important;
}

.min-height-430 {
  min-height: 430px !important;
}

.max-height-430 {
  max-height: 430px !important;
}

.width-431 {
  width: 431px !important;
}

.min-width-431 {
  min-width: 431px !important;
}

.max-width-431 {
  max-width: 431px !important;
}

.height-431 {
  height: 431px !important;
}

.min-height-431 {
  min-height: 431px !important;
}

.max-height-431 {
  max-height: 431px !important;
}

.width-432 {
  width: 432px !important;
}

.min-width-432 {
  min-width: 432px !important;
}

.max-width-432 {
  max-width: 432px !important;
}

.height-432 {
  height: 432px !important;
}

.min-height-432 {
  min-height: 432px !important;
}

.max-height-432 {
  max-height: 432px !important;
}

.width-433 {
  width: 433px !important;
}

.min-width-433 {
  min-width: 433px !important;
}

.max-width-433 {
  max-width: 433px !important;
}

.height-433 {
  height: 433px !important;
}

.min-height-433 {
  min-height: 433px !important;
}

.max-height-433 {
  max-height: 433px !important;
}

.width-434 {
  width: 434px !important;
}

.min-width-434 {
  min-width: 434px !important;
}

.max-width-434 {
  max-width: 434px !important;
}

.height-434 {
  height: 434px !important;
}

.min-height-434 {
  min-height: 434px !important;
}

.max-height-434 {
  max-height: 434px !important;
}

.width-435 {
  width: 435px !important;
}

.min-width-435 {
  min-width: 435px !important;
}

.max-width-435 {
  max-width: 435px !important;
}

.height-435 {
  height: 435px !important;
}

.min-height-435 {
  min-height: 435px !important;
}

.max-height-435 {
  max-height: 435px !important;
}

.width-436 {
  width: 436px !important;
}

.min-width-436 {
  min-width: 436px !important;
}

.max-width-436 {
  max-width: 436px !important;
}

.height-436 {
  height: 436px !important;
}

.min-height-436 {
  min-height: 436px !important;
}

.max-height-436 {
  max-height: 436px !important;
}

.width-437 {
  width: 437px !important;
}

.min-width-437 {
  min-width: 437px !important;
}

.max-width-437 {
  max-width: 437px !important;
}

.height-437 {
  height: 437px !important;
}

.min-height-437 {
  min-height: 437px !important;
}

.max-height-437 {
  max-height: 437px !important;
}

.width-438 {
  width: 438px !important;
}

.min-width-438 {
  min-width: 438px !important;
}

.max-width-438 {
  max-width: 438px !important;
}

.height-438 {
  height: 438px !important;
}

.min-height-438 {
  min-height: 438px !important;
}

.max-height-438 {
  max-height: 438px !important;
}

.width-439 {
  width: 439px !important;
}

.min-width-439 {
  min-width: 439px !important;
}

.max-width-439 {
  max-width: 439px !important;
}

.height-439 {
  height: 439px !important;
}

.min-height-439 {
  min-height: 439px !important;
}

.max-height-439 {
  max-height: 439px !important;
}

.width-440 {
  width: 440px !important;
}

.min-width-440 {
  min-width: 440px !important;
}

.max-width-440 {
  max-width: 440px !important;
}

.height-440 {
  height: 440px !important;
}

.min-height-440 {
  min-height: 440px !important;
}

.max-height-440 {
  max-height: 440px !important;
}

.width-441 {
  width: 441px !important;
}

.min-width-441 {
  min-width: 441px !important;
}

.max-width-441 {
  max-width: 441px !important;
}

.height-441 {
  height: 441px !important;
}

.min-height-441 {
  min-height: 441px !important;
}

.max-height-441 {
  max-height: 441px !important;
}

.width-442 {
  width: 442px !important;
}

.min-width-442 {
  min-width: 442px !important;
}

.max-width-442 {
  max-width: 442px !important;
}

.height-442 {
  height: 442px !important;
}

.min-height-442 {
  min-height: 442px !important;
}

.max-height-442 {
  max-height: 442px !important;
}

.width-443 {
  width: 443px !important;
}

.min-width-443 {
  min-width: 443px !important;
}

.max-width-443 {
  max-width: 443px !important;
}

.height-443 {
  height: 443px !important;
}

.min-height-443 {
  min-height: 443px !important;
}

.max-height-443 {
  max-height: 443px !important;
}

.width-444 {
  width: 444px !important;
}

.min-width-444 {
  min-width: 444px !important;
}

.max-width-444 {
  max-width: 444px !important;
}

.height-444 {
  height: 444px !important;
}

.min-height-444 {
  min-height: 444px !important;
}

.max-height-444 {
  max-height: 444px !important;
}

.width-445 {
  width: 445px !important;
}

.min-width-445 {
  min-width: 445px !important;
}

.max-width-445 {
  max-width: 445px !important;
}

.height-445 {
  height: 445px !important;
}

.min-height-445 {
  min-height: 445px !important;
}

.max-height-445 {
  max-height: 445px !important;
}

.width-446 {
  width: 446px !important;
}

.min-width-446 {
  min-width: 446px !important;
}

.max-width-446 {
  max-width: 446px !important;
}

.height-446 {
  height: 446px !important;
}

.min-height-446 {
  min-height: 446px !important;
}

.max-height-446 {
  max-height: 446px !important;
}

.width-447 {
  width: 447px !important;
}

.min-width-447 {
  min-width: 447px !important;
}

.max-width-447 {
  max-width: 447px !important;
}

.height-447 {
  height: 447px !important;
}

.min-height-447 {
  min-height: 447px !important;
}

.max-height-447 {
  max-height: 447px !important;
}

.width-448 {
  width: 448px !important;
}

.min-width-448 {
  min-width: 448px !important;
}

.max-width-448 {
  max-width: 448px !important;
}

.height-448 {
  height: 448px !important;
}

.min-height-448 {
  min-height: 448px !important;
}

.max-height-448 {
  max-height: 448px !important;
}

.width-449 {
  width: 449px !important;
}

.min-width-449 {
  min-width: 449px !important;
}

.max-width-449 {
  max-width: 449px !important;
}

.height-449 {
  height: 449px !important;
}

.min-height-449 {
  min-height: 449px !important;
}

.max-height-449 {
  max-height: 449px !important;
}

.width-450 {
  width: 450px !important;
}

.min-width-450 {
  min-width: 450px !important;
}

.max-width-450 {
  max-width: 450px !important;
}

.height-450 {
  height: 450px !important;
}

.min-height-450 {
  min-height: 450px !important;
}

.max-height-450 {
  max-height: 450px !important;
}

.width-451 {
  width: 451px !important;
}

.min-width-451 {
  min-width: 451px !important;
}

.max-width-451 {
  max-width: 451px !important;
}

.height-451 {
  height: 451px !important;
}

.min-height-451 {
  min-height: 451px !important;
}

.max-height-451 {
  max-height: 451px !important;
}

.width-452 {
  width: 452px !important;
}

.min-width-452 {
  min-width: 452px !important;
}

.max-width-452 {
  max-width: 452px !important;
}

.height-452 {
  height: 452px !important;
}

.min-height-452 {
  min-height: 452px !important;
}

.max-height-452 {
  max-height: 452px !important;
}

.width-453 {
  width: 453px !important;
}

.min-width-453 {
  min-width: 453px !important;
}

.max-width-453 {
  max-width: 453px !important;
}

.height-453 {
  height: 453px !important;
}

.min-height-453 {
  min-height: 453px !important;
}

.max-height-453 {
  max-height: 453px !important;
}

.width-454 {
  width: 454px !important;
}

.min-width-454 {
  min-width: 454px !important;
}

.max-width-454 {
  max-width: 454px !important;
}

.height-454 {
  height: 454px !important;
}

.min-height-454 {
  min-height: 454px !important;
}

.max-height-454 {
  max-height: 454px !important;
}

.width-455 {
  width: 455px !important;
}

.min-width-455 {
  min-width: 455px !important;
}

.max-width-455 {
  max-width: 455px !important;
}

.height-455 {
  height: 455px !important;
}

.min-height-455 {
  min-height: 455px !important;
}

.max-height-455 {
  max-height: 455px !important;
}

.width-456 {
  width: 456px !important;
}

.min-width-456 {
  min-width: 456px !important;
}

.max-width-456 {
  max-width: 456px !important;
}

.height-456 {
  height: 456px !important;
}

.min-height-456 {
  min-height: 456px !important;
}

.max-height-456 {
  max-height: 456px !important;
}

.width-457 {
  width: 457px !important;
}

.min-width-457 {
  min-width: 457px !important;
}

.max-width-457 {
  max-width: 457px !important;
}

.height-457 {
  height: 457px !important;
}

.min-height-457 {
  min-height: 457px !important;
}

.max-height-457 {
  max-height: 457px !important;
}

.width-458 {
  width: 458px !important;
}

.min-width-458 {
  min-width: 458px !important;
}

.max-width-458 {
  max-width: 458px !important;
}

.height-458 {
  height: 458px !important;
}

.min-height-458 {
  min-height: 458px !important;
}

.max-height-458 {
  max-height: 458px !important;
}

.width-459 {
  width: 459px !important;
}

.min-width-459 {
  min-width: 459px !important;
}

.max-width-459 {
  max-width: 459px !important;
}

.height-459 {
  height: 459px !important;
}

.min-height-459 {
  min-height: 459px !important;
}

.max-height-459 {
  max-height: 459px !important;
}

.width-460 {
  width: 460px !important;
}

.min-width-460 {
  min-width: 460px !important;
}

.max-width-460 {
  max-width: 460px !important;
}

.height-460 {
  height: 460px !important;
}

.min-height-460 {
  min-height: 460px !important;
}

.max-height-460 {
  max-height: 460px !important;
}

.width-461 {
  width: 461px !important;
}

.min-width-461 {
  min-width: 461px !important;
}

.max-width-461 {
  max-width: 461px !important;
}

.height-461 {
  height: 461px !important;
}

.min-height-461 {
  min-height: 461px !important;
}

.max-height-461 {
  max-height: 461px !important;
}

.width-462 {
  width: 462px !important;
}

.min-width-462 {
  min-width: 462px !important;
}

.max-width-462 {
  max-width: 462px !important;
}

.height-462 {
  height: 462px !important;
}

.min-height-462 {
  min-height: 462px !important;
}

.max-height-462 {
  max-height: 462px !important;
}

.width-463 {
  width: 463px !important;
}

.min-width-463 {
  min-width: 463px !important;
}

.max-width-463 {
  max-width: 463px !important;
}

.height-463 {
  height: 463px !important;
}

.min-height-463 {
  min-height: 463px !important;
}

.max-height-463 {
  max-height: 463px !important;
}

.width-464 {
  width: 464px !important;
}

.min-width-464 {
  min-width: 464px !important;
}

.max-width-464 {
  max-width: 464px !important;
}

.height-464 {
  height: 464px !important;
}

.min-height-464 {
  min-height: 464px !important;
}

.max-height-464 {
  max-height: 464px !important;
}

.width-465 {
  width: 465px !important;
}

.min-width-465 {
  min-width: 465px !important;
}

.max-width-465 {
  max-width: 465px !important;
}

.height-465 {
  height: 465px !important;
}

.min-height-465 {
  min-height: 465px !important;
}

.max-height-465 {
  max-height: 465px !important;
}

.width-466 {
  width: 466px !important;
}

.min-width-466 {
  min-width: 466px !important;
}

.max-width-466 {
  max-width: 466px !important;
}

.height-466 {
  height: 466px !important;
}

.min-height-466 {
  min-height: 466px !important;
}

.max-height-466 {
  max-height: 466px !important;
}

.width-467 {
  width: 467px !important;
}

.min-width-467 {
  min-width: 467px !important;
}

.max-width-467 {
  max-width: 467px !important;
}

.height-467 {
  height: 467px !important;
}

.min-height-467 {
  min-height: 467px !important;
}

.max-height-467 {
  max-height: 467px !important;
}

.width-468 {
  width: 468px !important;
}

.min-width-468 {
  min-width: 468px !important;
}

.max-width-468 {
  max-width: 468px !important;
}

.height-468 {
  height: 468px !important;
}

.min-height-468 {
  min-height: 468px !important;
}

.max-height-468 {
  max-height: 468px !important;
}

.width-469 {
  width: 469px !important;
}

.min-width-469 {
  min-width: 469px !important;
}

.max-width-469 {
  max-width: 469px !important;
}

.height-469 {
  height: 469px !important;
}

.min-height-469 {
  min-height: 469px !important;
}

.max-height-469 {
  max-height: 469px !important;
}

.width-470 {
  width: 470px !important;
}

.min-width-470 {
  min-width: 470px !important;
}

.max-width-470 {
  max-width: 470px !important;
}

.height-470 {
  height: 470px !important;
}

.min-height-470 {
  min-height: 470px !important;
}

.max-height-470 {
  max-height: 470px !important;
}

.width-471 {
  width: 471px !important;
}

.min-width-471 {
  min-width: 471px !important;
}

.max-width-471 {
  max-width: 471px !important;
}

.height-471 {
  height: 471px !important;
}

.min-height-471 {
  min-height: 471px !important;
}

.max-height-471 {
  max-height: 471px !important;
}

.width-472 {
  width: 472px !important;
}

.min-width-472 {
  min-width: 472px !important;
}

.max-width-472 {
  max-width: 472px !important;
}

.height-472 {
  height: 472px !important;
}

.min-height-472 {
  min-height: 472px !important;
}

.max-height-472 {
  max-height: 472px !important;
}

.width-473 {
  width: 473px !important;
}

.min-width-473 {
  min-width: 473px !important;
}

.max-width-473 {
  max-width: 473px !important;
}

.height-473 {
  height: 473px !important;
}

.min-height-473 {
  min-height: 473px !important;
}

.max-height-473 {
  max-height: 473px !important;
}

.width-474 {
  width: 474px !important;
}

.min-width-474 {
  min-width: 474px !important;
}

.max-width-474 {
  max-width: 474px !important;
}

.height-474 {
  height: 474px !important;
}

.min-height-474 {
  min-height: 474px !important;
}

.max-height-474 {
  max-height: 474px !important;
}

.width-475 {
  width: 475px !important;
}

.min-width-475 {
  min-width: 475px !important;
}

.max-width-475 {
  max-width: 475px !important;
}

.height-475 {
  height: 475px !important;
}

.min-height-475 {
  min-height: 475px !important;
}

.max-height-475 {
  max-height: 475px !important;
}

.width-476 {
  width: 476px !important;
}

.min-width-476 {
  min-width: 476px !important;
}

.max-width-476 {
  max-width: 476px !important;
}

.height-476 {
  height: 476px !important;
}

.min-height-476 {
  min-height: 476px !important;
}

.max-height-476 {
  max-height: 476px !important;
}

.width-477 {
  width: 477px !important;
}

.min-width-477 {
  min-width: 477px !important;
}

.max-width-477 {
  max-width: 477px !important;
}

.height-477 {
  height: 477px !important;
}

.min-height-477 {
  min-height: 477px !important;
}

.max-height-477 {
  max-height: 477px !important;
}

.width-478 {
  width: 478px !important;
}

.min-width-478 {
  min-width: 478px !important;
}

.max-width-478 {
  max-width: 478px !important;
}

.height-478 {
  height: 478px !important;
}

.min-height-478 {
  min-height: 478px !important;
}

.max-height-478 {
  max-height: 478px !important;
}

.width-479 {
  width: 479px !important;
}

.min-width-479 {
  min-width: 479px !important;
}

.max-width-479 {
  max-width: 479px !important;
}

.height-479 {
  height: 479px !important;
}

.min-height-479 {
  min-height: 479px !important;
}

.max-height-479 {
  max-height: 479px !important;
}

.width-480 {
  width: 480px !important;
}

.min-width-480 {
  min-width: 480px !important;
}

.max-width-480 {
  max-width: 480px !important;
}

.height-480 {
  height: 480px !important;
}

.min-height-480 {
  min-height: 480px !important;
}

.max-height-480 {
  max-height: 480px !important;
}

.width-481 {
  width: 481px !important;
}

.min-width-481 {
  min-width: 481px !important;
}

.max-width-481 {
  max-width: 481px !important;
}

.height-481 {
  height: 481px !important;
}

.min-height-481 {
  min-height: 481px !important;
}

.max-height-481 {
  max-height: 481px !important;
}

.width-482 {
  width: 482px !important;
}

.min-width-482 {
  min-width: 482px !important;
}

.max-width-482 {
  max-width: 482px !important;
}

.height-482 {
  height: 482px !important;
}

.min-height-482 {
  min-height: 482px !important;
}

.max-height-482 {
  max-height: 482px !important;
}

.width-483 {
  width: 483px !important;
}

.min-width-483 {
  min-width: 483px !important;
}

.max-width-483 {
  max-width: 483px !important;
}

.height-483 {
  height: 483px !important;
}

.min-height-483 {
  min-height: 483px !important;
}

.max-height-483 {
  max-height: 483px !important;
}

.width-484 {
  width: 484px !important;
}

.min-width-484 {
  min-width: 484px !important;
}

.max-width-484 {
  max-width: 484px !important;
}

.height-484 {
  height: 484px !important;
}

.min-height-484 {
  min-height: 484px !important;
}

.max-height-484 {
  max-height: 484px !important;
}

.width-485 {
  width: 485px !important;
}

.min-width-485 {
  min-width: 485px !important;
}

.max-width-485 {
  max-width: 485px !important;
}

.height-485 {
  height: 485px !important;
}

.min-height-485 {
  min-height: 485px !important;
}

.max-height-485 {
  max-height: 485px !important;
}

.width-486 {
  width: 486px !important;
}

.min-width-486 {
  min-width: 486px !important;
}

.max-width-486 {
  max-width: 486px !important;
}

.height-486 {
  height: 486px !important;
}

.min-height-486 {
  min-height: 486px !important;
}

.max-height-486 {
  max-height: 486px !important;
}

.width-487 {
  width: 487px !important;
}

.min-width-487 {
  min-width: 487px !important;
}

.max-width-487 {
  max-width: 487px !important;
}

.height-487 {
  height: 487px !important;
}

.min-height-487 {
  min-height: 487px !important;
}

.max-height-487 {
  max-height: 487px !important;
}

.width-488 {
  width: 488px !important;
}

.min-width-488 {
  min-width: 488px !important;
}

.max-width-488 {
  max-width: 488px !important;
}

.height-488 {
  height: 488px !important;
}

.min-height-488 {
  min-height: 488px !important;
}

.max-height-488 {
  max-height: 488px !important;
}

.width-489 {
  width: 489px !important;
}

.min-width-489 {
  min-width: 489px !important;
}

.max-width-489 {
  max-width: 489px !important;
}

.height-489 {
  height: 489px !important;
}

.min-height-489 {
  min-height: 489px !important;
}

.max-height-489 {
  max-height: 489px !important;
}

.width-490 {
  width: 490px !important;
}

.min-width-490 {
  min-width: 490px !important;
}

.max-width-490 {
  max-width: 490px !important;
}

.height-490 {
  height: 490px !important;
}

.min-height-490 {
  min-height: 490px !important;
}

.max-height-490 {
  max-height: 490px !important;
}

.width-491 {
  width: 491px !important;
}

.min-width-491 {
  min-width: 491px !important;
}

.max-width-491 {
  max-width: 491px !important;
}

.height-491 {
  height: 491px !important;
}

.min-height-491 {
  min-height: 491px !important;
}

.max-height-491 {
  max-height: 491px !important;
}

.width-492 {
  width: 492px !important;
}

.min-width-492 {
  min-width: 492px !important;
}

.max-width-492 {
  max-width: 492px !important;
}

.height-492 {
  height: 492px !important;
}

.min-height-492 {
  min-height: 492px !important;
}

.max-height-492 {
  max-height: 492px !important;
}

.width-493 {
  width: 493px !important;
}

.min-width-493 {
  min-width: 493px !important;
}

.max-width-493 {
  max-width: 493px !important;
}

.height-493 {
  height: 493px !important;
}

.min-height-493 {
  min-height: 493px !important;
}

.max-height-493 {
  max-height: 493px !important;
}

.width-494 {
  width: 494px !important;
}

.min-width-494 {
  min-width: 494px !important;
}

.max-width-494 {
  max-width: 494px !important;
}

.height-494 {
  height: 494px !important;
}

.min-height-494 {
  min-height: 494px !important;
}

.max-height-494 {
  max-height: 494px !important;
}

.width-495 {
  width: 495px !important;
}

.min-width-495 {
  min-width: 495px !important;
}

.max-width-495 {
  max-width: 495px !important;
}

.height-495 {
  height: 495px !important;
}

.min-height-495 {
  min-height: 495px !important;
}

.max-height-495 {
  max-height: 495px !important;
}

.width-496 {
  width: 496px !important;
}

.min-width-496 {
  min-width: 496px !important;
}

.max-width-496 {
  max-width: 496px !important;
}

.height-496 {
  height: 496px !important;
}

.min-height-496 {
  min-height: 496px !important;
}

.max-height-496 {
  max-height: 496px !important;
}

.width-497 {
  width: 497px !important;
}

.min-width-497 {
  min-width: 497px !important;
}

.max-width-497 {
  max-width: 497px !important;
}

.height-497 {
  height: 497px !important;
}

.min-height-497 {
  min-height: 497px !important;
}

.max-height-497 {
  max-height: 497px !important;
}

.width-498 {
  width: 498px !important;
}

.min-width-498 {
  min-width: 498px !important;
}

.max-width-498 {
  max-width: 498px !important;
}

.height-498 {
  height: 498px !important;
}

.min-height-498 {
  min-height: 498px !important;
}

.max-height-498 {
  max-height: 498px !important;
}

.width-499 {
  width: 499px !important;
}

.min-width-499 {
  min-width: 499px !important;
}

.max-width-499 {
  max-width: 499px !important;
}

.height-499 {
  height: 499px !important;
}

.min-height-499 {
  min-height: 499px !important;
}

.max-height-499 {
  max-height: 499px !important;
}

.width-500 {
  width: 500px !important;
}

.min-width-500 {
  min-width: 500px !important;
}

.max-width-500 {
  max-width: 500px !important;
}

.height-500 {
  height: 500px !important;
}

.min-height-500 {
  min-height: 500px !important;
}

.max-height-500 {
  max-height: 500px !important;
}

.radius-1 {
  border-radius: 1px !important;
}

.radius-top-1 {
  border-radius: 1px 1px 0 0 !important;
}

.radius-right-1 {
  border-radius: 0 1px 1px 0 !important;
}

.radius-bottom-1 {
  border-radius: 0 0 1px 1px !important;
}

.radius-left-1 {
  border-radius: 1px 0 0 1px !important;
}

.radius-top-left-1 {
  border-radius: 1px 0 0 0 !important;
}

.radius-top-right-1 {
  border-radius: 0 1px 0 0 !important;
}

.radius-bottom-left-1 {
  border-radius: 0 0 0 1px !important;
}

.radius-bottom-right-1 {
  border-radius: 0 0 1px 0 !important;
}

.radius-2 {
  border-radius: 2px !important;
}

.radius-top-2 {
  border-radius: 2px 2px 0 0 !important;
}

.radius-right-2 {
  border-radius: 0 2px 2px 0 !important;
}

.radius-bottom-2 {
  border-radius: 0 0 2px 2px !important;
}

.radius-left-2 {
  border-radius: 2px 0 0 2px !important;
}

.radius-top-left-2 {
  border-radius: 2px 0 0 0 !important;
}

.radius-top-right-2 {
  border-radius: 0 2px 0 0 !important;
}

.radius-bottom-left-2 {
  border-radius: 0 0 0 2px !important;
}

.radius-bottom-right-2 {
  border-radius: 0 0 2px 0 !important;
}

.radius-3 {
  border-radius: 3px !important;
}

.radius-top-3 {
  border-radius: 3px 3px 0 0 !important;
}

.radius-right-3 {
  border-radius: 0 3px 3px 0 !important;
}

.radius-bottom-3 {
  border-radius: 0 0 3px 3px !important;
}

.radius-left-3 {
  border-radius: 3px 0 0 3px !important;
}

.radius-top-left-3 {
  border-radius: 3px 0 0 0 !important;
}

.radius-top-right-3 {
  border-radius: 0 3px 0 0 !important;
}

.radius-bottom-left-3 {
  border-radius: 0 0 0 3px !important;
}

.radius-bottom-right-3 {
  border-radius: 0 0 3px 0 !important;
}

.radius-4 {
  border-radius: 4px !important;
}

.radius-top-4 {
  border-radius: 4px 4px 0 0 !important;
}

.radius-right-4 {
  border-radius: 0 4px 4px 0 !important;
}

.radius-bottom-4 {
  border-radius: 0 0 4px 4px !important;
}

.radius-left-4 {
  border-radius: 4px 0 0 4px !important;
}

.radius-top-left-4 {
  border-radius: 4px 0 0 0 !important;
}

.radius-top-right-4 {
  border-radius: 0 4px 0 0 !important;
}

.radius-bottom-left-4 {
  border-radius: 0 0 0 4px !important;
}

.radius-bottom-right-4 {
  border-radius: 0 0 4px 0 !important;
}

.radius-5 {
  border-radius: 5px !important;
}

.radius-top-5 {
  border-radius: 5px 5px 0 0 !important;
}

.radius-right-5 {
  border-radius: 0 5px 5px 0 !important;
}

.radius-bottom-5 {
  border-radius: 0 0 5px 5px !important;
}

.radius-left-5 {
  border-radius: 5px 0 0 5px !important;
}

.radius-top-left-5 {
  border-radius: 5px 0 0 0 !important;
}

.radius-top-right-5 {
  border-radius: 0 5px 0 0 !important;
}

.radius-bottom-left-5 {
  border-radius: 0 0 0 5px !important;
}

.radius-bottom-right-5 {
  border-radius: 0 0 5px 0 !important;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-top-6 {
  border-radius: 6px 6px 0 0 !important;
}

.radius-right-6 {
  border-radius: 0 6px 6px 0 !important;
}

.radius-bottom-6 {
  border-radius: 0 0 6px 6px !important;
}

.radius-left-6 {
  border-radius: 6px 0 0 6px !important;
}

.radius-top-left-6 {
  border-radius: 6px 0 0 0 !important;
}

.radius-top-right-6 {
  border-radius: 0 6px 0 0 !important;
}

.radius-bottom-left-6 {
  border-radius: 0 0 0 6px !important;
}

.radius-bottom-right-6 {
  border-radius: 0 0 6px 0 !important;
}

.radius-7 {
  border-radius: 7px !important;
}

.radius-top-7 {
  border-radius: 7px 7px 0 0 !important;
}

.radius-right-7 {
  border-radius: 0 7px 7px 0 !important;
}

.radius-bottom-7 {
  border-radius: 0 0 7px 7px !important;
}

.radius-left-7 {
  border-radius: 7px 0 0 7px !important;
}

.radius-top-left-7 {
  border-radius: 7px 0 0 0 !important;
}

.radius-top-right-7 {
  border-radius: 0 7px 0 0 !important;
}

.radius-bottom-left-7 {
  border-radius: 0 0 0 7px !important;
}

.radius-bottom-right-7 {
  border-radius: 0 0 7px 0 !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.radius-top-8 {
  border-radius: 8px 8px 0 0 !important;
}

.radius-right-8 {
  border-radius: 0 8px 8px 0 !important;
}

.radius-bottom-8 {
  border-radius: 0 0 8px 8px !important;
}

.radius-left-8 {
  border-radius: 8px 0 0 8px !important;
}

.radius-top-left-8 {
  border-radius: 8px 0 0 0 !important;
}

.radius-top-right-8 {
  border-radius: 0 8px 0 0 !important;
}

.radius-bottom-left-8 {
  border-radius: 0 0 0 8px !important;
}

.radius-bottom-right-8 {
  border-radius: 0 0 8px 0 !important;
}

.radius-9 {
  border-radius: 9px !important;
}

.radius-top-9 {
  border-radius: 9px 9px 0 0 !important;
}

.radius-right-9 {
  border-radius: 0 9px 9px 0 !important;
}

.radius-bottom-9 {
  border-radius: 0 0 9px 9px !important;
}

.radius-left-9 {
  border-radius: 9px 0 0 9px !important;
}

.radius-top-left-9 {
  border-radius: 9px 0 0 0 !important;
}

.radius-top-right-9 {
  border-radius: 0 9px 0 0 !important;
}

.radius-bottom-left-9 {
  border-radius: 0 0 0 9px !important;
}

.radius-bottom-right-9 {
  border-radius: 0 0 9px 0 !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-top-10 {
  border-radius: 10px 10px 0 0 !important;
}

.radius-right-10 {
  border-radius: 0 10px 10px 0 !important;
}

.radius-bottom-10 {
  border-radius: 0 0 10px 10px !important;
}

.radius-left-10 {
  border-radius: 10px 0 0 10px !important;
}

.radius-top-left-10 {
  border-radius: 10px 0 0 0 !important;
}

.radius-top-right-10 {
  border-radius: 0 10px 0 0 !important;
}

.radius-bottom-left-10 {
  border-radius: 0 0 0 10px !important;
}

.radius-bottom-right-10 {
  border-radius: 0 0 10px 0 !important;
}

.radius-11 {
  border-radius: 11px !important;
}

.radius-top-11 {
  border-radius: 11px 11px 0 0 !important;
}

.radius-right-11 {
  border-radius: 0 11px 11px 0 !important;
}

.radius-bottom-11 {
  border-radius: 0 0 11px 11px !important;
}

.radius-left-11 {
  border-radius: 11px 0 0 11px !important;
}

.radius-top-left-11 {
  border-radius: 11px 0 0 0 !important;
}

.radius-top-right-11 {
  border-radius: 0 11px 0 0 !important;
}

.radius-bottom-left-11 {
  border-radius: 0 0 0 11px !important;
}

.radius-bottom-right-11 {
  border-radius: 0 0 11px 0 !important;
}

.radius-12 {
  border-radius: 12px !important;
}

.radius-top-12 {
  border-radius: 12px 12px 0 0 !important;
}

.radius-right-12 {
  border-radius: 0 12px 12px 0 !important;
}

.radius-bottom-12 {
  border-radius: 0 0 12px 12px !important;
}

.radius-left-12 {
  border-radius: 12px 0 0 12px !important;
}

.radius-top-left-12 {
  border-radius: 12px 0 0 0 !important;
}

.radius-top-right-12 {
  border-radius: 0 12px 0 0 !important;
}

.radius-bottom-left-12 {
  border-radius: 0 0 0 12px !important;
}

.radius-bottom-right-12 {
  border-radius: 0 0 12px 0 !important;
}

.radius-13 {
  border-radius: 13px !important;
}

.radius-top-13 {
  border-radius: 13px 13px 0 0 !important;
}

.radius-right-13 {
  border-radius: 0 13px 13px 0 !important;
}

.radius-bottom-13 {
  border-radius: 0 0 13px 13px !important;
}

.radius-left-13 {
  border-radius: 13px 0 0 13px !important;
}

.radius-top-left-13 {
  border-radius: 13px 0 0 0 !important;
}

.radius-top-right-13 {
  border-radius: 0 13px 0 0 !important;
}

.radius-bottom-left-13 {
  border-radius: 0 0 0 13px !important;
}

.radius-bottom-right-13 {
  border-radius: 0 0 13px 0 !important;
}

.radius-14 {
  border-radius: 14px !important;
}

.radius-top-14 {
  border-radius: 14px 14px 0 0 !important;
}

.radius-right-14 {
  border-radius: 0 14px 14px 0 !important;
}

.radius-bottom-14 {
  border-radius: 0 0 14px 14px !important;
}

.radius-left-14 {
  border-radius: 14px 0 0 14px !important;
}

.radius-top-left-14 {
  border-radius: 14px 0 0 0 !important;
}

.radius-top-right-14 {
  border-radius: 0 14px 0 0 !important;
}

.radius-bottom-left-14 {
  border-radius: 0 0 0 14px !important;
}

.radius-bottom-right-14 {
  border-radius: 0 0 14px 0 !important;
}

.radius-15 {
  border-radius: 15px !important;
}

.radius-top-15 {
  border-radius: 15px 15px 0 0 !important;
}

.radius-right-15 {
  border-radius: 0 15px 15px 0 !important;
}

.radius-bottom-15 {
  border-radius: 0 0 15px 15px !important;
}

.radius-left-15 {
  border-radius: 15px 0 0 15px !important;
}

.radius-top-left-15 {
  border-radius: 15px 0 0 0 !important;
}

.radius-top-right-15 {
  border-radius: 0 15px 0 0 !important;
}

.radius-bottom-left-15 {
  border-radius: 0 0 0 15px !important;
}

.radius-bottom-right-15 {
  border-radius: 0 0 15px 0 !important;
}

.radius-16 {
  border-radius: 16px !important;
}

.radius-top-16 {
  border-radius: 16px 16px 0 0 !important;
}

.radius-right-16 {
  border-radius: 0 16px 16px 0 !important;
}

.radius-bottom-16 {
  border-radius: 0 0 16px 16px !important;
}

.radius-left-16 {
  border-radius: 16px 0 0 16px !important;
}

.radius-top-left-16 {
  border-radius: 16px 0 0 0 !important;
}

.radius-top-right-16 {
  border-radius: 0 16px 0 0 !important;
}

.radius-bottom-left-16 {
  border-radius: 0 0 0 16px !important;
}

.radius-bottom-right-16 {
  border-radius: 0 0 16px 0 !important;
}

.radius-17 {
  border-radius: 17px !important;
}

.radius-top-17 {
  border-radius: 17px 17px 0 0 !important;
}

.radius-right-17 {
  border-radius: 0 17px 17px 0 !important;
}

.radius-bottom-17 {
  border-radius: 0 0 17px 17px !important;
}

.radius-left-17 {
  border-radius: 17px 0 0 17px !important;
}

.radius-top-left-17 {
  border-radius: 17px 0 0 0 !important;
}

.radius-top-right-17 {
  border-radius: 0 17px 0 0 !important;
}

.radius-bottom-left-17 {
  border-radius: 0 0 0 17px !important;
}

.radius-bottom-right-17 {
  border-radius: 0 0 17px 0 !important;
}

.radius-18 {
  border-radius: 18px !important;
}

.radius-top-18 {
  border-radius: 18px 18px 0 0 !important;
}

.radius-right-18 {
  border-radius: 0 18px 18px 0 !important;
}

.radius-bottom-18 {
  border-radius: 0 0 18px 18px !important;
}

.radius-left-18 {
  border-radius: 18px 0 0 18px !important;
}

.radius-top-left-18 {
  border-radius: 18px 0 0 0 !important;
}

.radius-top-right-18 {
  border-radius: 0 18px 0 0 !important;
}

.radius-bottom-left-18 {
  border-radius: 0 0 0 18px !important;
}

.radius-bottom-right-18 {
  border-radius: 0 0 18px 0 !important;
}

.radius-19 {
  border-radius: 19px !important;
}

.radius-top-19 {
  border-radius: 19px 19px 0 0 !important;
}

.radius-right-19 {
  border-radius: 0 19px 19px 0 !important;
}

.radius-bottom-19 {
  border-radius: 0 0 19px 19px !important;
}

.radius-left-19 {
  border-radius: 19px 0 0 19px !important;
}

.radius-top-left-19 {
  border-radius: 19px 0 0 0 !important;
}

.radius-top-right-19 {
  border-radius: 0 19px 0 0 !important;
}

.radius-bottom-left-19 {
  border-radius: 0 0 0 19px !important;
}

.radius-bottom-right-19 {
  border-radius: 0 0 19px 0 !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-top-20 {
  border-radius: 20px 20px 0 0 !important;
}

.radius-right-20 {
  border-radius: 0 20px 20px 0 !important;
}

.radius-bottom-20 {
  border-radius: 0 0 20px 20px !important;
}

.radius-left-20 {
  border-radius: 20px 0 0 20px !important;
}

.radius-top-left-20 {
  border-radius: 20px 0 0 0 !important;
}

.radius-top-right-20 {
  border-radius: 0 20px 0 0 !important;
}

.radius-bottom-left-20 {
  border-radius: 0 0 0 20px !important;
}

.radius-bottom-right-20 {
  border-radius: 0 0 20px 0 !important;
}

.radius-21 {
  border-radius: 21px !important;
}

.radius-top-21 {
  border-radius: 21px 21px 0 0 !important;
}

.radius-right-21 {
  border-radius: 0 21px 21px 0 !important;
}

.radius-bottom-21 {
  border-radius: 0 0 21px 21px !important;
}

.radius-left-21 {
  border-radius: 21px 0 0 21px !important;
}

.radius-top-left-21 {
  border-radius: 21px 0 0 0 !important;
}

.radius-top-right-21 {
  border-radius: 0 21px 0 0 !important;
}

.radius-bottom-left-21 {
  border-radius: 0 0 0 21px !important;
}

.radius-bottom-right-21 {
  border-radius: 0 0 21px 0 !important;
}

.radius-22 {
  border-radius: 22px !important;
}

.radius-top-22 {
  border-radius: 22px 22px 0 0 !important;
}

.radius-right-22 {
  border-radius: 0 22px 22px 0 !important;
}

.radius-bottom-22 {
  border-radius: 0 0 22px 22px !important;
}

.radius-left-22 {
  border-radius: 22px 0 0 22px !important;
}

.radius-top-left-22 {
  border-radius: 22px 0 0 0 !important;
}

.radius-top-right-22 {
  border-radius: 0 22px 0 0 !important;
}

.radius-bottom-left-22 {
  border-radius: 0 0 0 22px !important;
}

.radius-bottom-right-22 {
  border-radius: 0 0 22px 0 !important;
}

.radius-23 {
  border-radius: 23px !important;
}

.radius-top-23 {
  border-radius: 23px 23px 0 0 !important;
}

.radius-right-23 {
  border-radius: 0 23px 23px 0 !important;
}

.radius-bottom-23 {
  border-radius: 0 0 23px 23px !important;
}

.radius-left-23 {
  border-radius: 23px 0 0 23px !important;
}

.radius-top-left-23 {
  border-radius: 23px 0 0 0 !important;
}

.radius-top-right-23 {
  border-radius: 0 23px 0 0 !important;
}

.radius-bottom-left-23 {
  border-radius: 0 0 0 23px !important;
}

.radius-bottom-right-23 {
  border-radius: 0 0 23px 0 !important;
}

.radius-24 {
  border-radius: 24px !important;
}

.radius-top-24 {
  border-radius: 24px 24px 0 0 !important;
}

.radius-right-24 {
  border-radius: 0 24px 24px 0 !important;
}

.radius-bottom-24 {
  border-radius: 0 0 24px 24px !important;
}

.radius-left-24 {
  border-radius: 24px 0 0 24px !important;
}

.radius-top-left-24 {
  border-radius: 24px 0 0 0 !important;
}

.radius-top-right-24 {
  border-radius: 0 24px 0 0 !important;
}

.radius-bottom-left-24 {
  border-radius: 0 0 0 24px !important;
}

.radius-bottom-right-24 {
  border-radius: 0 0 24px 0 !important;
}

.radius-25 {
  border-radius: 25px !important;
}

.radius-top-25 {
  border-radius: 25px 25px 0 0 !important;
}

.radius-right-25 {
  border-radius: 0 25px 25px 0 !important;
}

.radius-bottom-25 {
  border-radius: 0 0 25px 25px !important;
}

.radius-left-25 {
  border-radius: 25px 0 0 25px !important;
}

.radius-top-left-25 {
  border-radius: 25px 0 0 0 !important;
}

.radius-top-right-25 {
  border-radius: 0 25px 0 0 !important;
}

.radius-bottom-left-25 {
  border-radius: 0 0 0 25px !important;
}

.radius-bottom-right-25 {
  border-radius: 0 0 25px 0 !important;
}

.radius-26 {
  border-radius: 26px !important;
}

.radius-top-26 {
  border-radius: 26px 26px 0 0 !important;
}

.radius-right-26 {
  border-radius: 0 26px 26px 0 !important;
}

.radius-bottom-26 {
  border-radius: 0 0 26px 26px !important;
}

.radius-left-26 {
  border-radius: 26px 0 0 26px !important;
}

.radius-top-left-26 {
  border-radius: 26px 0 0 0 !important;
}

.radius-top-right-26 {
  border-radius: 0 26px 0 0 !important;
}

.radius-bottom-left-26 {
  border-radius: 0 0 0 26px !important;
}

.radius-bottom-right-26 {
  border-radius: 0 0 26px 0 !important;
}

.radius-27 {
  border-radius: 27px !important;
}

.radius-top-27 {
  border-radius: 27px 27px 0 0 !important;
}

.radius-right-27 {
  border-radius: 0 27px 27px 0 !important;
}

.radius-bottom-27 {
  border-radius: 0 0 27px 27px !important;
}

.radius-left-27 {
  border-radius: 27px 0 0 27px !important;
}

.radius-top-left-27 {
  border-radius: 27px 0 0 0 !important;
}

.radius-top-right-27 {
  border-radius: 0 27px 0 0 !important;
}

.radius-bottom-left-27 {
  border-radius: 0 0 0 27px !important;
}

.radius-bottom-right-27 {
  border-radius: 0 0 27px 0 !important;
}

.radius-28 {
  border-radius: 28px !important;
}

.radius-top-28 {
  border-radius: 28px 28px 0 0 !important;
}

.radius-right-28 {
  border-radius: 0 28px 28px 0 !important;
}

.radius-bottom-28 {
  border-radius: 0 0 28px 28px !important;
}

.radius-left-28 {
  border-radius: 28px 0 0 28px !important;
}

.radius-top-left-28 {
  border-radius: 28px 0 0 0 !important;
}

.radius-top-right-28 {
  border-radius: 0 28px 0 0 !important;
}

.radius-bottom-left-28 {
  border-radius: 0 0 0 28px !important;
}

.radius-bottom-right-28 {
  border-radius: 0 0 28px 0 !important;
}

.radius-29 {
  border-radius: 29px !important;
}

.radius-top-29 {
  border-radius: 29px 29px 0 0 !important;
}

.radius-right-29 {
  border-radius: 0 29px 29px 0 !important;
}

.radius-bottom-29 {
  border-radius: 0 0 29px 29px !important;
}

.radius-left-29 {
  border-radius: 29px 0 0 29px !important;
}

.radius-top-left-29 {
  border-radius: 29px 0 0 0 !important;
}

.radius-top-right-29 {
  border-radius: 0 29px 0 0 !important;
}

.radius-bottom-left-29 {
  border-radius: 0 0 0 29px !important;
}

.radius-bottom-right-29 {
  border-radius: 0 0 29px 0 !important;
}

.radius-30 {
  border-radius: 30px !important;
}

.radius-top-30 {
  border-radius: 30px 30px 0 0 !important;
}

.radius-right-30 {
  border-radius: 0 30px 30px 0 !important;
}

.radius-bottom-30 {
  border-radius: 0 0 30px 30px !important;
}

.radius-left-30 {
  border-radius: 30px 0 0 30px !important;
}

.radius-top-left-30 {
  border-radius: 30px 0 0 0 !important;
}

.radius-top-right-30 {
  border-radius: 0 30px 0 0 !important;
}

.radius-bottom-left-30 {
  border-radius: 0 0 0 30px !important;
}

.radius-bottom-right-30 {
  border-radius: 0 0 30px 0 !important;
}

.radius-31 {
  border-radius: 31px !important;
}

.radius-top-31 {
  border-radius: 31px 31px 0 0 !important;
}

.radius-right-31 {
  border-radius: 0 31px 31px 0 !important;
}

.radius-bottom-31 {
  border-radius: 0 0 31px 31px !important;
}

.radius-left-31 {
  border-radius: 31px 0 0 31px !important;
}

.radius-top-left-31 {
  border-radius: 31px 0 0 0 !important;
}

.radius-top-right-31 {
  border-radius: 0 31px 0 0 !important;
}

.radius-bottom-left-31 {
  border-radius: 0 0 0 31px !important;
}

.radius-bottom-right-31 {
  border-radius: 0 0 31px 0 !important;
}

.radius-32 {
  border-radius: 32px !important;
}

.radius-top-32 {
  border-radius: 32px 32px 0 0 !important;
}

.radius-right-32 {
  border-radius: 0 32px 32px 0 !important;
}

.radius-bottom-32 {
  border-radius: 0 0 32px 32px !important;
}

.radius-left-32 {
  border-radius: 32px 0 0 32px !important;
}

.radius-top-left-32 {
  border-radius: 32px 0 0 0 !important;
}

.radius-top-right-32 {
  border-radius: 0 32px 0 0 !important;
}

.radius-bottom-left-32 {
  border-radius: 0 0 0 32px !important;
}

.radius-bottom-right-32 {
  border-radius: 0 0 32px 0 !important;
}

.radius-33 {
  border-radius: 33px !important;
}

.radius-top-33 {
  border-radius: 33px 33px 0 0 !important;
}

.radius-right-33 {
  border-radius: 0 33px 33px 0 !important;
}

.radius-bottom-33 {
  border-radius: 0 0 33px 33px !important;
}

.radius-left-33 {
  border-radius: 33px 0 0 33px !important;
}

.radius-top-left-33 {
  border-radius: 33px 0 0 0 !important;
}

.radius-top-right-33 {
  border-radius: 0 33px 0 0 !important;
}

.radius-bottom-left-33 {
  border-radius: 0 0 0 33px !important;
}

.radius-bottom-right-33 {
  border-radius: 0 0 33px 0 !important;
}

.radius-34 {
  border-radius: 34px !important;
}

.radius-top-34 {
  border-radius: 34px 34px 0 0 !important;
}

.radius-right-34 {
  border-radius: 0 34px 34px 0 !important;
}

.radius-bottom-34 {
  border-radius: 0 0 34px 34px !important;
}

.radius-left-34 {
  border-radius: 34px 0 0 34px !important;
}

.radius-top-left-34 {
  border-radius: 34px 0 0 0 !important;
}

.radius-top-right-34 {
  border-radius: 0 34px 0 0 !important;
}

.radius-bottom-left-34 {
  border-radius: 0 0 0 34px !important;
}

.radius-bottom-right-34 {
  border-radius: 0 0 34px 0 !important;
}

.radius-35 {
  border-radius: 35px !important;
}

.radius-top-35 {
  border-radius: 35px 35px 0 0 !important;
}

.radius-right-35 {
  border-radius: 0 35px 35px 0 !important;
}

.radius-bottom-35 {
  border-radius: 0 0 35px 35px !important;
}

.radius-left-35 {
  border-radius: 35px 0 0 35px !important;
}

.radius-top-left-35 {
  border-radius: 35px 0 0 0 !important;
}

.radius-top-right-35 {
  border-radius: 0 35px 0 0 !important;
}

.radius-bottom-left-35 {
  border-radius: 0 0 0 35px !important;
}

.radius-bottom-right-35 {
  border-radius: 0 0 35px 0 !important;
}

.radius-36 {
  border-radius: 36px !important;
}

.radius-top-36 {
  border-radius: 36px 36px 0 0 !important;
}

.radius-right-36 {
  border-radius: 0 36px 36px 0 !important;
}

.radius-bottom-36 {
  border-radius: 0 0 36px 36px !important;
}

.radius-left-36 {
  border-radius: 36px 0 0 36px !important;
}

.radius-top-left-36 {
  border-radius: 36px 0 0 0 !important;
}

.radius-top-right-36 {
  border-radius: 0 36px 0 0 !important;
}

.radius-bottom-left-36 {
  border-radius: 0 0 0 36px !important;
}

.radius-bottom-right-36 {
  border-radius: 0 0 36px 0 !important;
}

.radius-37 {
  border-radius: 37px !important;
}

.radius-top-37 {
  border-radius: 37px 37px 0 0 !important;
}

.radius-right-37 {
  border-radius: 0 37px 37px 0 !important;
}

.radius-bottom-37 {
  border-radius: 0 0 37px 37px !important;
}

.radius-left-37 {
  border-radius: 37px 0 0 37px !important;
}

.radius-top-left-37 {
  border-radius: 37px 0 0 0 !important;
}

.radius-top-right-37 {
  border-radius: 0 37px 0 0 !important;
}

.radius-bottom-left-37 {
  border-radius: 0 0 0 37px !important;
}

.radius-bottom-right-37 {
  border-radius: 0 0 37px 0 !important;
}

.radius-38 {
  border-radius: 38px !important;
}

.radius-top-38 {
  border-radius: 38px 38px 0 0 !important;
}

.radius-right-38 {
  border-radius: 0 38px 38px 0 !important;
}

.radius-bottom-38 {
  border-radius: 0 0 38px 38px !important;
}

.radius-left-38 {
  border-radius: 38px 0 0 38px !important;
}

.radius-top-left-38 {
  border-radius: 38px 0 0 0 !important;
}

.radius-top-right-38 {
  border-radius: 0 38px 0 0 !important;
}

.radius-bottom-left-38 {
  border-radius: 0 0 0 38px !important;
}

.radius-bottom-right-38 {
  border-radius: 0 0 38px 0 !important;
}

.radius-39 {
  border-radius: 39px !important;
}

.radius-top-39 {
  border-radius: 39px 39px 0 0 !important;
}

.radius-right-39 {
  border-radius: 0 39px 39px 0 !important;
}

.radius-bottom-39 {
  border-radius: 0 0 39px 39px !important;
}

.radius-left-39 {
  border-radius: 39px 0 0 39px !important;
}

.radius-top-left-39 {
  border-radius: 39px 0 0 0 !important;
}

.radius-top-right-39 {
  border-radius: 0 39px 0 0 !important;
}

.radius-bottom-left-39 {
  border-radius: 0 0 0 39px !important;
}

.radius-bottom-right-39 {
  border-radius: 0 0 39px 0 !important;
}

.radius-40 {
  border-radius: 40px !important;
}

.radius-top-40 {
  border-radius: 40px 40px 0 0 !important;
}

.radius-right-40 {
  border-radius: 0 40px 40px 0 !important;
}

.radius-bottom-40 {
  border-radius: 0 0 40px 40px !important;
}

.radius-left-40 {
  border-radius: 40px 0 0 40px !important;
}

.radius-top-left-40 {
  border-radius: 40px 0 0 0 !important;
}

.radius-top-right-40 {
  border-radius: 0 40px 0 0 !important;
}

.radius-bottom-left-40 {
  border-radius: 0 0 0 40px !important;
}

.radius-bottom-right-40 {
  border-radius: 0 0 40px 0 !important;
}

.radius-41 {
  border-radius: 41px !important;
}

.radius-top-41 {
  border-radius: 41px 41px 0 0 !important;
}

.radius-right-41 {
  border-radius: 0 41px 41px 0 !important;
}

.radius-bottom-41 {
  border-radius: 0 0 41px 41px !important;
}

.radius-left-41 {
  border-radius: 41px 0 0 41px !important;
}

.radius-top-left-41 {
  border-radius: 41px 0 0 0 !important;
}

.radius-top-right-41 {
  border-radius: 0 41px 0 0 !important;
}

.radius-bottom-left-41 {
  border-radius: 0 0 0 41px !important;
}

.radius-bottom-right-41 {
  border-radius: 0 0 41px 0 !important;
}

.radius-42 {
  border-radius: 42px !important;
}

.radius-top-42 {
  border-radius: 42px 42px 0 0 !important;
}

.radius-right-42 {
  border-radius: 0 42px 42px 0 !important;
}

.radius-bottom-42 {
  border-radius: 0 0 42px 42px !important;
}

.radius-left-42 {
  border-radius: 42px 0 0 42px !important;
}

.radius-top-left-42 {
  border-radius: 42px 0 0 0 !important;
}

.radius-top-right-42 {
  border-radius: 0 42px 0 0 !important;
}

.radius-bottom-left-42 {
  border-radius: 0 0 0 42px !important;
}

.radius-bottom-right-42 {
  border-radius: 0 0 42px 0 !important;
}

.radius-43 {
  border-radius: 43px !important;
}

.radius-top-43 {
  border-radius: 43px 43px 0 0 !important;
}

.radius-right-43 {
  border-radius: 0 43px 43px 0 !important;
}

.radius-bottom-43 {
  border-radius: 0 0 43px 43px !important;
}

.radius-left-43 {
  border-radius: 43px 0 0 43px !important;
}

.radius-top-left-43 {
  border-radius: 43px 0 0 0 !important;
}

.radius-top-right-43 {
  border-radius: 0 43px 0 0 !important;
}

.radius-bottom-left-43 {
  border-radius: 0 0 0 43px !important;
}

.radius-bottom-right-43 {
  border-radius: 0 0 43px 0 !important;
}

.radius-44 {
  border-radius: 44px !important;
}

.radius-top-44 {
  border-radius: 44px 44px 0 0 !important;
}

.radius-right-44 {
  border-radius: 0 44px 44px 0 !important;
}

.radius-bottom-44 {
  border-radius: 0 0 44px 44px !important;
}

.radius-left-44 {
  border-radius: 44px 0 0 44px !important;
}

.radius-top-left-44 {
  border-radius: 44px 0 0 0 !important;
}

.radius-top-right-44 {
  border-radius: 0 44px 0 0 !important;
}

.radius-bottom-left-44 {
  border-radius: 0 0 0 44px !important;
}

.radius-bottom-right-44 {
  border-radius: 0 0 44px 0 !important;
}

.radius-45 {
  border-radius: 45px !important;
}

.radius-top-45 {
  border-radius: 45px 45px 0 0 !important;
}

.radius-right-45 {
  border-radius: 0 45px 45px 0 !important;
}

.radius-bottom-45 {
  border-radius: 0 0 45px 45px !important;
}

.radius-left-45 {
  border-radius: 45px 0 0 45px !important;
}

.radius-top-left-45 {
  border-radius: 45px 0 0 0 !important;
}

.radius-top-right-45 {
  border-radius: 0 45px 0 0 !important;
}

.radius-bottom-left-45 {
  border-radius: 0 0 0 45px !important;
}

.radius-bottom-right-45 {
  border-radius: 0 0 45px 0 !important;
}

.radius-46 {
  border-radius: 46px !important;
}

.radius-top-46 {
  border-radius: 46px 46px 0 0 !important;
}

.radius-right-46 {
  border-radius: 0 46px 46px 0 !important;
}

.radius-bottom-46 {
  border-radius: 0 0 46px 46px !important;
}

.radius-left-46 {
  border-radius: 46px 0 0 46px !important;
}

.radius-top-left-46 {
  border-radius: 46px 0 0 0 !important;
}

.radius-top-right-46 {
  border-radius: 0 46px 0 0 !important;
}

.radius-bottom-left-46 {
  border-radius: 0 0 0 46px !important;
}

.radius-bottom-right-46 {
  border-radius: 0 0 46px 0 !important;
}

.radius-47 {
  border-radius: 47px !important;
}

.radius-top-47 {
  border-radius: 47px 47px 0 0 !important;
}

.radius-right-47 {
  border-radius: 0 47px 47px 0 !important;
}

.radius-bottom-47 {
  border-radius: 0 0 47px 47px !important;
}

.radius-left-47 {
  border-radius: 47px 0 0 47px !important;
}

.radius-top-left-47 {
  border-radius: 47px 0 0 0 !important;
}

.radius-top-right-47 {
  border-radius: 0 47px 0 0 !important;
}

.radius-bottom-left-47 {
  border-radius: 0 0 0 47px !important;
}

.radius-bottom-right-47 {
  border-radius: 0 0 47px 0 !important;
}

.radius-48 {
  border-radius: 48px !important;
}

.radius-top-48 {
  border-radius: 48px 48px 0 0 !important;
}

.radius-right-48 {
  border-radius: 0 48px 48px 0 !important;
}

.radius-bottom-48 {
  border-radius: 0 0 48px 48px !important;
}

.radius-left-48 {
  border-radius: 48px 0 0 48px !important;
}

.radius-top-left-48 {
  border-radius: 48px 0 0 0 !important;
}

.radius-top-right-48 {
  border-radius: 0 48px 0 0 !important;
}

.radius-bottom-left-48 {
  border-radius: 0 0 0 48px !important;
}

.radius-bottom-right-48 {
  border-radius: 0 0 48px 0 !important;
}

.radius-49 {
  border-radius: 49px !important;
}

.radius-top-49 {
  border-radius: 49px 49px 0 0 !important;
}

.radius-right-49 {
  border-radius: 0 49px 49px 0 !important;
}

.radius-bottom-49 {
  border-radius: 0 0 49px 49px !important;
}

.radius-left-49 {
  border-radius: 49px 0 0 49px !important;
}

.radius-top-left-49 {
  border-radius: 49px 0 0 0 !important;
}

.radius-top-right-49 {
  border-radius: 0 49px 0 0 !important;
}

.radius-bottom-left-49 {
  border-radius: 0 0 0 49px !important;
}

.radius-bottom-right-49 {
  border-radius: 0 0 49px 0 !important;
}

.radius-50 {
  border-radius: 50px !important;
}

.radius-top-50 {
  border-radius: 50px 50px 0 0 !important;
}

.radius-right-50 {
  border-radius: 0 50px 50px 0 !important;
}

.radius-bottom-50 {
  border-radius: 0 0 50px 50px !important;
}

.radius-left-50 {
  border-radius: 50px 0 0 50px !important;
}

.radius-top-left-50 {
  border-radius: 50px 0 0 0 !important;
}

.radius-top-right-50 {
  border-radius: 0 50px 0 0 !important;
}

.radius-bottom-left-50 {
  border-radius: 0 0 0 50px !important;
}

.radius-bottom-right-50 {
  border-radius: 0 0 50px 0 !important;
}

.radius-51 {
  border-radius: 51px !important;
}

.radius-top-51 {
  border-radius: 51px 51px 0 0 !important;
}

.radius-right-51 {
  border-radius: 0 51px 51px 0 !important;
}

.radius-bottom-51 {
  border-radius: 0 0 51px 51px !important;
}

.radius-left-51 {
  border-radius: 51px 0 0 51px !important;
}

.radius-top-left-51 {
  border-radius: 51px 0 0 0 !important;
}

.radius-top-right-51 {
  border-radius: 0 51px 0 0 !important;
}

.radius-bottom-left-51 {
  border-radius: 0 0 0 51px !important;
}

.radius-bottom-right-51 {
  border-radius: 0 0 51px 0 !important;
}

.radius-52 {
  border-radius: 52px !important;
}

.radius-top-52 {
  border-radius: 52px 52px 0 0 !important;
}

.radius-right-52 {
  border-radius: 0 52px 52px 0 !important;
}

.radius-bottom-52 {
  border-radius: 0 0 52px 52px !important;
}

.radius-left-52 {
  border-radius: 52px 0 0 52px !important;
}

.radius-top-left-52 {
  border-radius: 52px 0 0 0 !important;
}

.radius-top-right-52 {
  border-radius: 0 52px 0 0 !important;
}

.radius-bottom-left-52 {
  border-radius: 0 0 0 52px !important;
}

.radius-bottom-right-52 {
  border-radius: 0 0 52px 0 !important;
}

.radius-53 {
  border-radius: 53px !important;
}

.radius-top-53 {
  border-radius: 53px 53px 0 0 !important;
}

.radius-right-53 {
  border-radius: 0 53px 53px 0 !important;
}

.radius-bottom-53 {
  border-radius: 0 0 53px 53px !important;
}

.radius-left-53 {
  border-radius: 53px 0 0 53px !important;
}

.radius-top-left-53 {
  border-radius: 53px 0 0 0 !important;
}

.radius-top-right-53 {
  border-radius: 0 53px 0 0 !important;
}

.radius-bottom-left-53 {
  border-radius: 0 0 0 53px !important;
}

.radius-bottom-right-53 {
  border-radius: 0 0 53px 0 !important;
}

.radius-54 {
  border-radius: 54px !important;
}

.radius-top-54 {
  border-radius: 54px 54px 0 0 !important;
}

.radius-right-54 {
  border-radius: 0 54px 54px 0 !important;
}

.radius-bottom-54 {
  border-radius: 0 0 54px 54px !important;
}

.radius-left-54 {
  border-radius: 54px 0 0 54px !important;
}

.radius-top-left-54 {
  border-radius: 54px 0 0 0 !important;
}

.radius-top-right-54 {
  border-radius: 0 54px 0 0 !important;
}

.radius-bottom-left-54 {
  border-radius: 0 0 0 54px !important;
}

.radius-bottom-right-54 {
  border-radius: 0 0 54px 0 !important;
}

.radius-55 {
  border-radius: 55px !important;
}

.radius-top-55 {
  border-radius: 55px 55px 0 0 !important;
}

.radius-right-55 {
  border-radius: 0 55px 55px 0 !important;
}

.radius-bottom-55 {
  border-radius: 0 0 55px 55px !important;
}

.radius-left-55 {
  border-radius: 55px 0 0 55px !important;
}

.radius-top-left-55 {
  border-radius: 55px 0 0 0 !important;
}

.radius-top-right-55 {
  border-radius: 0 55px 0 0 !important;
}

.radius-bottom-left-55 {
  border-radius: 0 0 0 55px !important;
}

.radius-bottom-right-55 {
  border-radius: 0 0 55px 0 !important;
}

.radius-56 {
  border-radius: 56px !important;
}

.radius-top-56 {
  border-radius: 56px 56px 0 0 !important;
}

.radius-right-56 {
  border-radius: 0 56px 56px 0 !important;
}

.radius-bottom-56 {
  border-radius: 0 0 56px 56px !important;
}

.radius-left-56 {
  border-radius: 56px 0 0 56px !important;
}

.radius-top-left-56 {
  border-radius: 56px 0 0 0 !important;
}

.radius-top-right-56 {
  border-radius: 0 56px 0 0 !important;
}

.radius-bottom-left-56 {
  border-radius: 0 0 0 56px !important;
}

.radius-bottom-right-56 {
  border-radius: 0 0 56px 0 !important;
}

.radius-57 {
  border-radius: 57px !important;
}

.radius-top-57 {
  border-radius: 57px 57px 0 0 !important;
}

.radius-right-57 {
  border-radius: 0 57px 57px 0 !important;
}

.radius-bottom-57 {
  border-radius: 0 0 57px 57px !important;
}

.radius-left-57 {
  border-radius: 57px 0 0 57px !important;
}

.radius-top-left-57 {
  border-radius: 57px 0 0 0 !important;
}

.radius-top-right-57 {
  border-radius: 0 57px 0 0 !important;
}

.radius-bottom-left-57 {
  border-radius: 0 0 0 57px !important;
}

.radius-bottom-right-57 {
  border-radius: 0 0 57px 0 !important;
}

.radius-58 {
  border-radius: 58px !important;
}

.radius-top-58 {
  border-radius: 58px 58px 0 0 !important;
}

.radius-right-58 {
  border-radius: 0 58px 58px 0 !important;
}

.radius-bottom-58 {
  border-radius: 0 0 58px 58px !important;
}

.radius-left-58 {
  border-radius: 58px 0 0 58px !important;
}

.radius-top-left-58 {
  border-radius: 58px 0 0 0 !important;
}

.radius-top-right-58 {
  border-radius: 0 58px 0 0 !important;
}

.radius-bottom-left-58 {
  border-radius: 0 0 0 58px !important;
}

.radius-bottom-right-58 {
  border-radius: 0 0 58px 0 !important;
}

.radius-59 {
  border-radius: 59px !important;
}

.radius-top-59 {
  border-radius: 59px 59px 0 0 !important;
}

.radius-right-59 {
  border-radius: 0 59px 59px 0 !important;
}

.radius-bottom-59 {
  border-radius: 0 0 59px 59px !important;
}

.radius-left-59 {
  border-radius: 59px 0 0 59px !important;
}

.radius-top-left-59 {
  border-radius: 59px 0 0 0 !important;
}

.radius-top-right-59 {
  border-radius: 0 59px 0 0 !important;
}

.radius-bottom-left-59 {
  border-radius: 0 0 0 59px !important;
}

.radius-bottom-right-59 {
  border-radius: 0 0 59px 0 !important;
}

.radius-60 {
  border-radius: 60px !important;
}

.radius-top-60 {
  border-radius: 60px 60px 0 0 !important;
}

.radius-right-60 {
  border-radius: 0 60px 60px 0 !important;
}

.radius-bottom-60 {
  border-radius: 0 0 60px 60px !important;
}

.radius-left-60 {
  border-radius: 60px 0 0 60px !important;
}

.radius-top-left-60 {
  border-radius: 60px 0 0 0 !important;
}

.radius-top-right-60 {
  border-radius: 0 60px 0 0 !important;
}

.radius-bottom-left-60 {
  border-radius: 0 0 0 60px !important;
}

.radius-bottom-right-60 {
  border-radius: 0 0 60px 0 !important;
}

.radius-61 {
  border-radius: 61px !important;
}

.radius-top-61 {
  border-radius: 61px 61px 0 0 !important;
}

.radius-right-61 {
  border-radius: 0 61px 61px 0 !important;
}

.radius-bottom-61 {
  border-radius: 0 0 61px 61px !important;
}

.radius-left-61 {
  border-radius: 61px 0 0 61px !important;
}

.radius-top-left-61 {
  border-radius: 61px 0 0 0 !important;
}

.radius-top-right-61 {
  border-radius: 0 61px 0 0 !important;
}

.radius-bottom-left-61 {
  border-radius: 0 0 0 61px !important;
}

.radius-bottom-right-61 {
  border-radius: 0 0 61px 0 !important;
}

.radius-62 {
  border-radius: 62px !important;
}

.radius-top-62 {
  border-radius: 62px 62px 0 0 !important;
}

.radius-right-62 {
  border-radius: 0 62px 62px 0 !important;
}

.radius-bottom-62 {
  border-radius: 0 0 62px 62px !important;
}

.radius-left-62 {
  border-radius: 62px 0 0 62px !important;
}

.radius-top-left-62 {
  border-radius: 62px 0 0 0 !important;
}

.radius-top-right-62 {
  border-radius: 0 62px 0 0 !important;
}

.radius-bottom-left-62 {
  border-radius: 0 0 0 62px !important;
}

.radius-bottom-right-62 {
  border-radius: 0 0 62px 0 !important;
}

.radius-63 {
  border-radius: 63px !important;
}

.radius-top-63 {
  border-radius: 63px 63px 0 0 !important;
}

.radius-right-63 {
  border-radius: 0 63px 63px 0 !important;
}

.radius-bottom-63 {
  border-radius: 0 0 63px 63px !important;
}

.radius-left-63 {
  border-radius: 63px 0 0 63px !important;
}

.radius-top-left-63 {
  border-radius: 63px 0 0 0 !important;
}

.radius-top-right-63 {
  border-radius: 0 63px 0 0 !important;
}

.radius-bottom-left-63 {
  border-radius: 0 0 0 63px !important;
}

.radius-bottom-right-63 {
  border-radius: 0 0 63px 0 !important;
}

.radius-64 {
  border-radius: 64px !important;
}

.radius-top-64 {
  border-radius: 64px 64px 0 0 !important;
}

.radius-right-64 {
  border-radius: 0 64px 64px 0 !important;
}

.radius-bottom-64 {
  border-radius: 0 0 64px 64px !important;
}

.radius-left-64 {
  border-radius: 64px 0 0 64px !important;
}

.radius-top-left-64 {
  border-radius: 64px 0 0 0 !important;
}

.radius-top-right-64 {
  border-radius: 0 64px 0 0 !important;
}

.radius-bottom-left-64 {
  border-radius: 0 0 0 64px !important;
}

.radius-bottom-right-64 {
  border-radius: 0 0 64px 0 !important;
}

.radius-65 {
  border-radius: 65px !important;
}

.radius-top-65 {
  border-radius: 65px 65px 0 0 !important;
}

.radius-right-65 {
  border-radius: 0 65px 65px 0 !important;
}

.radius-bottom-65 {
  border-radius: 0 0 65px 65px !important;
}

.radius-left-65 {
  border-radius: 65px 0 0 65px !important;
}

.radius-top-left-65 {
  border-radius: 65px 0 0 0 !important;
}

.radius-top-right-65 {
  border-radius: 0 65px 0 0 !important;
}

.radius-bottom-left-65 {
  border-radius: 0 0 0 65px !important;
}

.radius-bottom-right-65 {
  border-radius: 0 0 65px 0 !important;
}

.radius-66 {
  border-radius: 66px !important;
}

.radius-top-66 {
  border-radius: 66px 66px 0 0 !important;
}

.radius-right-66 {
  border-radius: 0 66px 66px 0 !important;
}

.radius-bottom-66 {
  border-radius: 0 0 66px 66px !important;
}

.radius-left-66 {
  border-radius: 66px 0 0 66px !important;
}

.radius-top-left-66 {
  border-radius: 66px 0 0 0 !important;
}

.radius-top-right-66 {
  border-radius: 0 66px 0 0 !important;
}

.radius-bottom-left-66 {
  border-radius: 0 0 0 66px !important;
}

.radius-bottom-right-66 {
  border-radius: 0 0 66px 0 !important;
}

.radius-67 {
  border-radius: 67px !important;
}

.radius-top-67 {
  border-radius: 67px 67px 0 0 !important;
}

.radius-right-67 {
  border-radius: 0 67px 67px 0 !important;
}

.radius-bottom-67 {
  border-radius: 0 0 67px 67px !important;
}

.radius-left-67 {
  border-radius: 67px 0 0 67px !important;
}

.radius-top-left-67 {
  border-radius: 67px 0 0 0 !important;
}

.radius-top-right-67 {
  border-radius: 0 67px 0 0 !important;
}

.radius-bottom-left-67 {
  border-radius: 0 0 0 67px !important;
}

.radius-bottom-right-67 {
  border-radius: 0 0 67px 0 !important;
}

.radius-68 {
  border-radius: 68px !important;
}

.radius-top-68 {
  border-radius: 68px 68px 0 0 !important;
}

.radius-right-68 {
  border-radius: 0 68px 68px 0 !important;
}

.radius-bottom-68 {
  border-radius: 0 0 68px 68px !important;
}

.radius-left-68 {
  border-radius: 68px 0 0 68px !important;
}

.radius-top-left-68 {
  border-radius: 68px 0 0 0 !important;
}

.radius-top-right-68 {
  border-radius: 0 68px 0 0 !important;
}

.radius-bottom-left-68 {
  border-radius: 0 0 0 68px !important;
}

.radius-bottom-right-68 {
  border-radius: 0 0 68px 0 !important;
}

.radius-69 {
  border-radius: 69px !important;
}

.radius-top-69 {
  border-radius: 69px 69px 0 0 !important;
}

.radius-right-69 {
  border-radius: 0 69px 69px 0 !important;
}

.radius-bottom-69 {
  border-radius: 0 0 69px 69px !important;
}

.radius-left-69 {
  border-radius: 69px 0 0 69px !important;
}

.radius-top-left-69 {
  border-radius: 69px 0 0 0 !important;
}

.radius-top-right-69 {
  border-radius: 0 69px 0 0 !important;
}

.radius-bottom-left-69 {
  border-radius: 0 0 0 69px !important;
}

.radius-bottom-right-69 {
  border-radius: 0 0 69px 0 !important;
}

.radius-70 {
  border-radius: 70px !important;
}

.radius-top-70 {
  border-radius: 70px 70px 0 0 !important;
}

.radius-right-70 {
  border-radius: 0 70px 70px 0 !important;
}

.radius-bottom-70 {
  border-radius: 0 0 70px 70px !important;
}

.radius-left-70 {
  border-radius: 70px 0 0 70px !important;
}

.radius-top-left-70 {
  border-radius: 70px 0 0 0 !important;
}

.radius-top-right-70 {
  border-radius: 0 70px 0 0 !important;
}

.radius-bottom-left-70 {
  border-radius: 0 0 0 70px !important;
}

.radius-bottom-right-70 {
  border-radius: 0 0 70px 0 !important;
}

.radius-71 {
  border-radius: 71px !important;
}

.radius-top-71 {
  border-radius: 71px 71px 0 0 !important;
}

.radius-right-71 {
  border-radius: 0 71px 71px 0 !important;
}

.radius-bottom-71 {
  border-radius: 0 0 71px 71px !important;
}

.radius-left-71 {
  border-radius: 71px 0 0 71px !important;
}

.radius-top-left-71 {
  border-radius: 71px 0 0 0 !important;
}

.radius-top-right-71 {
  border-radius: 0 71px 0 0 !important;
}

.radius-bottom-left-71 {
  border-radius: 0 0 0 71px !important;
}

.radius-bottom-right-71 {
  border-radius: 0 0 71px 0 !important;
}

.radius-72 {
  border-radius: 72px !important;
}

.radius-top-72 {
  border-radius: 72px 72px 0 0 !important;
}

.radius-right-72 {
  border-radius: 0 72px 72px 0 !important;
}

.radius-bottom-72 {
  border-radius: 0 0 72px 72px !important;
}

.radius-left-72 {
  border-radius: 72px 0 0 72px !important;
}

.radius-top-left-72 {
  border-radius: 72px 0 0 0 !important;
}

.radius-top-right-72 {
  border-radius: 0 72px 0 0 !important;
}

.radius-bottom-left-72 {
  border-radius: 0 0 0 72px !important;
}

.radius-bottom-right-72 {
  border-radius: 0 0 72px 0 !important;
}

.radius-73 {
  border-radius: 73px !important;
}

.radius-top-73 {
  border-radius: 73px 73px 0 0 !important;
}

.radius-right-73 {
  border-radius: 0 73px 73px 0 !important;
}

.radius-bottom-73 {
  border-radius: 0 0 73px 73px !important;
}

.radius-left-73 {
  border-radius: 73px 0 0 73px !important;
}

.radius-top-left-73 {
  border-radius: 73px 0 0 0 !important;
}

.radius-top-right-73 {
  border-radius: 0 73px 0 0 !important;
}

.radius-bottom-left-73 {
  border-radius: 0 0 0 73px !important;
}

.radius-bottom-right-73 {
  border-radius: 0 0 73px 0 !important;
}

.radius-74 {
  border-radius: 74px !important;
}

.radius-top-74 {
  border-radius: 74px 74px 0 0 !important;
}

.radius-right-74 {
  border-radius: 0 74px 74px 0 !important;
}

.radius-bottom-74 {
  border-radius: 0 0 74px 74px !important;
}

.radius-left-74 {
  border-radius: 74px 0 0 74px !important;
}

.radius-top-left-74 {
  border-radius: 74px 0 0 0 !important;
}

.radius-top-right-74 {
  border-radius: 0 74px 0 0 !important;
}

.radius-bottom-left-74 {
  border-radius: 0 0 0 74px !important;
}

.radius-bottom-right-74 {
  border-radius: 0 0 74px 0 !important;
}

.radius-75 {
  border-radius: 75px !important;
}

.radius-top-75 {
  border-radius: 75px 75px 0 0 !important;
}

.radius-right-75 {
  border-radius: 0 75px 75px 0 !important;
}

.radius-bottom-75 {
  border-radius: 0 0 75px 75px !important;
}

.radius-left-75 {
  border-radius: 75px 0 0 75px !important;
}

.radius-top-left-75 {
  border-radius: 75px 0 0 0 !important;
}

.radius-top-right-75 {
  border-radius: 0 75px 0 0 !important;
}

.radius-bottom-left-75 {
  border-radius: 0 0 0 75px !important;
}

.radius-bottom-right-75 {
  border-radius: 0 0 75px 0 !important;
}

.radius-76 {
  border-radius: 76px !important;
}

.radius-top-76 {
  border-radius: 76px 76px 0 0 !important;
}

.radius-right-76 {
  border-radius: 0 76px 76px 0 !important;
}

.radius-bottom-76 {
  border-radius: 0 0 76px 76px !important;
}

.radius-left-76 {
  border-radius: 76px 0 0 76px !important;
}

.radius-top-left-76 {
  border-radius: 76px 0 0 0 !important;
}

.radius-top-right-76 {
  border-radius: 0 76px 0 0 !important;
}

.radius-bottom-left-76 {
  border-radius: 0 0 0 76px !important;
}

.radius-bottom-right-76 {
  border-radius: 0 0 76px 0 !important;
}

.radius-77 {
  border-radius: 77px !important;
}

.radius-top-77 {
  border-radius: 77px 77px 0 0 !important;
}

.radius-right-77 {
  border-radius: 0 77px 77px 0 !important;
}

.radius-bottom-77 {
  border-radius: 0 0 77px 77px !important;
}

.radius-left-77 {
  border-radius: 77px 0 0 77px !important;
}

.radius-top-left-77 {
  border-radius: 77px 0 0 0 !important;
}

.radius-top-right-77 {
  border-radius: 0 77px 0 0 !important;
}

.radius-bottom-left-77 {
  border-radius: 0 0 0 77px !important;
}

.radius-bottom-right-77 {
  border-radius: 0 0 77px 0 !important;
}

.radius-78 {
  border-radius: 78px !important;
}

.radius-top-78 {
  border-radius: 78px 78px 0 0 !important;
}

.radius-right-78 {
  border-radius: 0 78px 78px 0 !important;
}

.radius-bottom-78 {
  border-radius: 0 0 78px 78px !important;
}

.radius-left-78 {
  border-radius: 78px 0 0 78px !important;
}

.radius-top-left-78 {
  border-radius: 78px 0 0 0 !important;
}

.radius-top-right-78 {
  border-radius: 0 78px 0 0 !important;
}

.radius-bottom-left-78 {
  border-radius: 0 0 0 78px !important;
}

.radius-bottom-right-78 {
  border-radius: 0 0 78px 0 !important;
}

.radius-79 {
  border-radius: 79px !important;
}

.radius-top-79 {
  border-radius: 79px 79px 0 0 !important;
}

.radius-right-79 {
  border-radius: 0 79px 79px 0 !important;
}

.radius-bottom-79 {
  border-radius: 0 0 79px 79px !important;
}

.radius-left-79 {
  border-radius: 79px 0 0 79px !important;
}

.radius-top-left-79 {
  border-radius: 79px 0 0 0 !important;
}

.radius-top-right-79 {
  border-radius: 0 79px 0 0 !important;
}

.radius-bottom-left-79 {
  border-radius: 0 0 0 79px !important;
}

.radius-bottom-right-79 {
  border-radius: 0 0 79px 0 !important;
}

.radius-80 {
  border-radius: 80px !important;
}

.radius-top-80 {
  border-radius: 80px 80px 0 0 !important;
}

.radius-right-80 {
  border-radius: 0 80px 80px 0 !important;
}

.radius-bottom-80 {
  border-radius: 0 0 80px 80px !important;
}

.radius-left-80 {
  border-radius: 80px 0 0 80px !important;
}

.radius-top-left-80 {
  border-radius: 80px 0 0 0 !important;
}

.radius-top-right-80 {
  border-radius: 0 80px 0 0 !important;
}

.radius-bottom-left-80 {
  border-radius: 0 0 0 80px !important;
}

.radius-bottom-right-80 {
  border-radius: 0 0 80px 0 !important;
}

.radius-81 {
  border-radius: 81px !important;
}

.radius-top-81 {
  border-radius: 81px 81px 0 0 !important;
}

.radius-right-81 {
  border-radius: 0 81px 81px 0 !important;
}

.radius-bottom-81 {
  border-radius: 0 0 81px 81px !important;
}

.radius-left-81 {
  border-radius: 81px 0 0 81px !important;
}

.radius-top-left-81 {
  border-radius: 81px 0 0 0 !important;
}

.radius-top-right-81 {
  border-radius: 0 81px 0 0 !important;
}

.radius-bottom-left-81 {
  border-radius: 0 0 0 81px !important;
}

.radius-bottom-right-81 {
  border-radius: 0 0 81px 0 !important;
}

.radius-82 {
  border-radius: 82px !important;
}

.radius-top-82 {
  border-radius: 82px 82px 0 0 !important;
}

.radius-right-82 {
  border-radius: 0 82px 82px 0 !important;
}

.radius-bottom-82 {
  border-radius: 0 0 82px 82px !important;
}

.radius-left-82 {
  border-radius: 82px 0 0 82px !important;
}

.radius-top-left-82 {
  border-radius: 82px 0 0 0 !important;
}

.radius-top-right-82 {
  border-radius: 0 82px 0 0 !important;
}

.radius-bottom-left-82 {
  border-radius: 0 0 0 82px !important;
}

.radius-bottom-right-82 {
  border-radius: 0 0 82px 0 !important;
}

.radius-83 {
  border-radius: 83px !important;
}

.radius-top-83 {
  border-radius: 83px 83px 0 0 !important;
}

.radius-right-83 {
  border-radius: 0 83px 83px 0 !important;
}

.radius-bottom-83 {
  border-radius: 0 0 83px 83px !important;
}

.radius-left-83 {
  border-radius: 83px 0 0 83px !important;
}

.radius-top-left-83 {
  border-radius: 83px 0 0 0 !important;
}

.radius-top-right-83 {
  border-radius: 0 83px 0 0 !important;
}

.radius-bottom-left-83 {
  border-radius: 0 0 0 83px !important;
}

.radius-bottom-right-83 {
  border-radius: 0 0 83px 0 !important;
}

.radius-84 {
  border-radius: 84px !important;
}

.radius-top-84 {
  border-radius: 84px 84px 0 0 !important;
}

.radius-right-84 {
  border-radius: 0 84px 84px 0 !important;
}

.radius-bottom-84 {
  border-radius: 0 0 84px 84px !important;
}

.radius-left-84 {
  border-radius: 84px 0 0 84px !important;
}

.radius-top-left-84 {
  border-radius: 84px 0 0 0 !important;
}

.radius-top-right-84 {
  border-radius: 0 84px 0 0 !important;
}

.radius-bottom-left-84 {
  border-radius: 0 0 0 84px !important;
}

.radius-bottom-right-84 {
  border-radius: 0 0 84px 0 !important;
}

.radius-85 {
  border-radius: 85px !important;
}

.radius-top-85 {
  border-radius: 85px 85px 0 0 !important;
}

.radius-right-85 {
  border-radius: 0 85px 85px 0 !important;
}

.radius-bottom-85 {
  border-radius: 0 0 85px 85px !important;
}

.radius-left-85 {
  border-radius: 85px 0 0 85px !important;
}

.radius-top-left-85 {
  border-radius: 85px 0 0 0 !important;
}

.radius-top-right-85 {
  border-radius: 0 85px 0 0 !important;
}

.radius-bottom-left-85 {
  border-radius: 0 0 0 85px !important;
}

.radius-bottom-right-85 {
  border-radius: 0 0 85px 0 !important;
}

.radius-86 {
  border-radius: 86px !important;
}

.radius-top-86 {
  border-radius: 86px 86px 0 0 !important;
}

.radius-right-86 {
  border-radius: 0 86px 86px 0 !important;
}

.radius-bottom-86 {
  border-radius: 0 0 86px 86px !important;
}

.radius-left-86 {
  border-radius: 86px 0 0 86px !important;
}

.radius-top-left-86 {
  border-radius: 86px 0 0 0 !important;
}

.radius-top-right-86 {
  border-radius: 0 86px 0 0 !important;
}

.radius-bottom-left-86 {
  border-radius: 0 0 0 86px !important;
}

.radius-bottom-right-86 {
  border-radius: 0 0 86px 0 !important;
}

.radius-87 {
  border-radius: 87px !important;
}

.radius-top-87 {
  border-radius: 87px 87px 0 0 !important;
}

.radius-right-87 {
  border-radius: 0 87px 87px 0 !important;
}

.radius-bottom-87 {
  border-radius: 0 0 87px 87px !important;
}

.radius-left-87 {
  border-radius: 87px 0 0 87px !important;
}

.radius-top-left-87 {
  border-radius: 87px 0 0 0 !important;
}

.radius-top-right-87 {
  border-radius: 0 87px 0 0 !important;
}

.radius-bottom-left-87 {
  border-radius: 0 0 0 87px !important;
}

.radius-bottom-right-87 {
  border-radius: 0 0 87px 0 !important;
}

.radius-88 {
  border-radius: 88px !important;
}

.radius-top-88 {
  border-radius: 88px 88px 0 0 !important;
}

.radius-right-88 {
  border-radius: 0 88px 88px 0 !important;
}

.radius-bottom-88 {
  border-radius: 0 0 88px 88px !important;
}

.radius-left-88 {
  border-radius: 88px 0 0 88px !important;
}

.radius-top-left-88 {
  border-radius: 88px 0 0 0 !important;
}

.radius-top-right-88 {
  border-radius: 0 88px 0 0 !important;
}

.radius-bottom-left-88 {
  border-radius: 0 0 0 88px !important;
}

.radius-bottom-right-88 {
  border-radius: 0 0 88px 0 !important;
}

.radius-89 {
  border-radius: 89px !important;
}

.radius-top-89 {
  border-radius: 89px 89px 0 0 !important;
}

.radius-right-89 {
  border-radius: 0 89px 89px 0 !important;
}

.radius-bottom-89 {
  border-radius: 0 0 89px 89px !important;
}

.radius-left-89 {
  border-radius: 89px 0 0 89px !important;
}

.radius-top-left-89 {
  border-radius: 89px 0 0 0 !important;
}

.radius-top-right-89 {
  border-radius: 0 89px 0 0 !important;
}

.radius-bottom-left-89 {
  border-radius: 0 0 0 89px !important;
}

.radius-bottom-right-89 {
  border-radius: 0 0 89px 0 !important;
}

.radius-90 {
  border-radius: 90px !important;
}

.radius-top-90 {
  border-radius: 90px 90px 0 0 !important;
}

.radius-right-90 {
  border-radius: 0 90px 90px 0 !important;
}

.radius-bottom-90 {
  border-radius: 0 0 90px 90px !important;
}

.radius-left-90 {
  border-radius: 90px 0 0 90px !important;
}

.radius-top-left-90 {
  border-radius: 90px 0 0 0 !important;
}

.radius-top-right-90 {
  border-radius: 0 90px 0 0 !important;
}

.radius-bottom-left-90 {
  border-radius: 0 0 0 90px !important;
}

.radius-bottom-right-90 {
  border-radius: 0 0 90px 0 !important;
}

.radius-91 {
  border-radius: 91px !important;
}

.radius-top-91 {
  border-radius: 91px 91px 0 0 !important;
}

.radius-right-91 {
  border-radius: 0 91px 91px 0 !important;
}

.radius-bottom-91 {
  border-radius: 0 0 91px 91px !important;
}

.radius-left-91 {
  border-radius: 91px 0 0 91px !important;
}

.radius-top-left-91 {
  border-radius: 91px 0 0 0 !important;
}

.radius-top-right-91 {
  border-radius: 0 91px 0 0 !important;
}

.radius-bottom-left-91 {
  border-radius: 0 0 0 91px !important;
}

.radius-bottom-right-91 {
  border-radius: 0 0 91px 0 !important;
}

.radius-92 {
  border-radius: 92px !important;
}

.radius-top-92 {
  border-radius: 92px 92px 0 0 !important;
}

.radius-right-92 {
  border-radius: 0 92px 92px 0 !important;
}

.radius-bottom-92 {
  border-radius: 0 0 92px 92px !important;
}

.radius-left-92 {
  border-radius: 92px 0 0 92px !important;
}

.radius-top-left-92 {
  border-radius: 92px 0 0 0 !important;
}

.radius-top-right-92 {
  border-radius: 0 92px 0 0 !important;
}

.radius-bottom-left-92 {
  border-radius: 0 0 0 92px !important;
}

.radius-bottom-right-92 {
  border-radius: 0 0 92px 0 !important;
}

.radius-93 {
  border-radius: 93px !important;
}

.radius-top-93 {
  border-radius: 93px 93px 0 0 !important;
}

.radius-right-93 {
  border-radius: 0 93px 93px 0 !important;
}

.radius-bottom-93 {
  border-radius: 0 0 93px 93px !important;
}

.radius-left-93 {
  border-radius: 93px 0 0 93px !important;
}

.radius-top-left-93 {
  border-radius: 93px 0 0 0 !important;
}

.radius-top-right-93 {
  border-radius: 0 93px 0 0 !important;
}

.radius-bottom-left-93 {
  border-radius: 0 0 0 93px !important;
}

.radius-bottom-right-93 {
  border-radius: 0 0 93px 0 !important;
}

.radius-94 {
  border-radius: 94px !important;
}

.radius-top-94 {
  border-radius: 94px 94px 0 0 !important;
}

.radius-right-94 {
  border-radius: 0 94px 94px 0 !important;
}

.radius-bottom-94 {
  border-radius: 0 0 94px 94px !important;
}

.radius-left-94 {
  border-radius: 94px 0 0 94px !important;
}

.radius-top-left-94 {
  border-radius: 94px 0 0 0 !important;
}

.radius-top-right-94 {
  border-radius: 0 94px 0 0 !important;
}

.radius-bottom-left-94 {
  border-radius: 0 0 0 94px !important;
}

.radius-bottom-right-94 {
  border-radius: 0 0 94px 0 !important;
}

.radius-95 {
  border-radius: 95px !important;
}

.radius-top-95 {
  border-radius: 95px 95px 0 0 !important;
}

.radius-right-95 {
  border-radius: 0 95px 95px 0 !important;
}

.radius-bottom-95 {
  border-radius: 0 0 95px 95px !important;
}

.radius-left-95 {
  border-radius: 95px 0 0 95px !important;
}

.radius-top-left-95 {
  border-radius: 95px 0 0 0 !important;
}

.radius-top-right-95 {
  border-radius: 0 95px 0 0 !important;
}

.radius-bottom-left-95 {
  border-radius: 0 0 0 95px !important;
}

.radius-bottom-right-95 {
  border-radius: 0 0 95px 0 !important;
}

.radius-96 {
  border-radius: 96px !important;
}

.radius-top-96 {
  border-radius: 96px 96px 0 0 !important;
}

.radius-right-96 {
  border-radius: 0 96px 96px 0 !important;
}

.radius-bottom-96 {
  border-radius: 0 0 96px 96px !important;
}

.radius-left-96 {
  border-radius: 96px 0 0 96px !important;
}

.radius-top-left-96 {
  border-radius: 96px 0 0 0 !important;
}

.radius-top-right-96 {
  border-radius: 0 96px 0 0 !important;
}

.radius-bottom-left-96 {
  border-radius: 0 0 0 96px !important;
}

.radius-bottom-right-96 {
  border-radius: 0 0 96px 0 !important;
}

.radius-97 {
  border-radius: 97px !important;
}

.radius-top-97 {
  border-radius: 97px 97px 0 0 !important;
}

.radius-right-97 {
  border-radius: 0 97px 97px 0 !important;
}

.radius-bottom-97 {
  border-radius: 0 0 97px 97px !important;
}

.radius-left-97 {
  border-radius: 97px 0 0 97px !important;
}

.radius-top-left-97 {
  border-radius: 97px 0 0 0 !important;
}

.radius-top-right-97 {
  border-radius: 0 97px 0 0 !important;
}

.radius-bottom-left-97 {
  border-radius: 0 0 0 97px !important;
}

.radius-bottom-right-97 {
  border-radius: 0 0 97px 0 !important;
}

.radius-98 {
  border-radius: 98px !important;
}

.radius-top-98 {
  border-radius: 98px 98px 0 0 !important;
}

.radius-right-98 {
  border-radius: 0 98px 98px 0 !important;
}

.radius-bottom-98 {
  border-radius: 0 0 98px 98px !important;
}

.radius-left-98 {
  border-radius: 98px 0 0 98px !important;
}

.radius-top-left-98 {
  border-radius: 98px 0 0 0 !important;
}

.radius-top-right-98 {
  border-radius: 0 98px 0 0 !important;
}

.radius-bottom-left-98 {
  border-radius: 0 0 0 98px !important;
}

.radius-bottom-right-98 {
  border-radius: 0 0 98px 0 !important;
}

.radius-99 {
  border-radius: 99px !important;
}

.radius-top-99 {
  border-radius: 99px 99px 0 0 !important;
}

.radius-right-99 {
  border-radius: 0 99px 99px 0 !important;
}

.radius-bottom-99 {
  border-radius: 0 0 99px 99px !important;
}

.radius-left-99 {
  border-radius: 99px 0 0 99px !important;
}

.radius-top-left-99 {
  border-radius: 99px 0 0 0 !important;
}

.radius-top-right-99 {
  border-radius: 0 99px 0 0 !important;
}

.radius-bottom-left-99 {
  border-radius: 0 0 0 99px !important;
}

.radius-bottom-right-99 {
  border-radius: 0 0 99px 0 !important;
}

.radius-100 {
  border-radius: 100px !important;
}

.radius-top-100 {
  border-radius: 100px 100px 0 0 !important;
}

.radius-right-100 {
  border-radius: 0 100px 100px 0 !important;
}

.radius-bottom-100 {
  border-radius: 0 0 100px 100px !important;
}

.radius-left-100 {
  border-radius: 100px 0 0 100px !important;
}

.radius-top-left-100 {
  border-radius: 100px 0 0 0 !important;
}

.radius-top-right-100 {
  border-radius: 0 100px 0 0 !important;
}

.radius-bottom-left-100 {
  border-radius: 0 0 0 100px !important;
}

.radius-bottom-right-100 {
  border-radius: 0 0 100px 0 !important;
}

.top-1 {
  top: 1px !important;
}

.right-1 {
  right: 1px !important;
}

.bottom-1 {
  bottom: 1px !important;
}

.left-1 {
  left: 1px !important;
}

.top-2 {
  top: 2px !important;
}

.right-2 {
  right: 2px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.left-2 {
  left: 2px !important;
}

.top-3 {
  top: 3px !important;
}

.right-3 {
  right: 3px !important;
}

.bottom-3 {
  bottom: 3px !important;
}

.left-3 {
  left: 3px !important;
}

.top-4 {
  top: 4px !important;
}

.right-4 {
  right: 4px !important;
}

.bottom-4 {
  bottom: 4px !important;
}

.left-4 {
  left: 4px !important;
}

.top-5 {
  top: 5px !important;
}

.right-5 {
  right: 5px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.left-5 {
  left: 5px !important;
}

.top-6 {
  top: 6px !important;
}

.right-6 {
  right: 6px !important;
}

.bottom-6 {
  bottom: 6px !important;
}

.left-6 {
  left: 6px !important;
}

.top-7 {
  top: 7px !important;
}

.right-7 {
  right: 7px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.left-7 {
  left: 7px !important;
}

.top-8 {
  top: 8px !important;
}

.right-8 {
  right: 8px !important;
}

.bottom-8 {
  bottom: 8px !important;
}

.left-8 {
  left: 8px !important;
}

.top-9 {
  top: 9px !important;
}

.right-9 {
  right: 9px !important;
}

.bottom-9 {
  bottom: 9px !important;
}

.left-9 {
  left: 9px !important;
}

.top-10 {
  top: 10px !important;
}

.right-10 {
  right: 10px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.left-10 {
  left: 10px !important;
}

.top-11 {
  top: 11px !important;
}

.right-11 {
  right: 11px !important;
}

.bottom-11 {
  bottom: 11px !important;
}

.left-11 {
  left: 11px !important;
}

.top-12 {
  top: 12px !important;
}

.right-12 {
  right: 12px !important;
}

.bottom-12 {
  bottom: 12px !important;
}

.left-12 {
  left: 12px !important;
}

.top-13 {
  top: 13px !important;
}

.right-13 {
  right: 13px !important;
}

.bottom-13 {
  bottom: 13px !important;
}

.left-13 {
  left: 13px !important;
}

.top-14 {
  top: 14px !important;
}

.right-14 {
  right: 14px !important;
}

.bottom-14 {
  bottom: 14px !important;
}

.left-14 {
  left: 14px !important;
}

.top-15 {
  top: 15px !important;
}

.right-15 {
  right: 15px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.left-15 {
  left: 15px !important;
}

.top-16 {
  top: 16px !important;
}

.right-16 {
  right: 16px !important;
}

.bottom-16 {
  bottom: 16px !important;
}

.left-16 {
  left: 16px !important;
}

.top-17 {
  top: 17px !important;
}

.right-17 {
  right: 17px !important;
}

.bottom-17 {
  bottom: 17px !important;
}

.left-17 {
  left: 17px !important;
}

.top-18 {
  top: 18px !important;
}

.right-18 {
  right: 18px !important;
}

.bottom-18 {
  bottom: 18px !important;
}

.left-18 {
  left: 18px !important;
}

.top-19 {
  top: 19px !important;
}

.right-19 {
  right: 19px !important;
}

.bottom-19 {
  bottom: 19px !important;
}

.left-19 {
  left: 19px !important;
}

.top-20 {
  top: 20px !important;
}

.right-20 {
  right: 20px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.left-20 {
  left: 20px !important;
}

.top-21 {
  top: 21px !important;
}

.right-21 {
  right: 21px !important;
}

.bottom-21 {
  bottom: 21px !important;
}

.left-21 {
  left: 21px !important;
}

.top-22 {
  top: 22px !important;
}

.right-22 {
  right: 22px !important;
}

.bottom-22 {
  bottom: 22px !important;
}

.left-22 {
  left: 22px !important;
}

.top-23 {
  top: 23px !important;
}

.right-23 {
  right: 23px !important;
}

.bottom-23 {
  bottom: 23px !important;
}

.left-23 {
  left: 23px !important;
}

.top-24 {
  top: 24px !important;
}

.right-24 {
  right: 24px !important;
}

.bottom-24 {
  bottom: 24px !important;
}

.left-24 {
  left: 24px !important;
}

.top-25 {
  top: 25px !important;
}

.right-25 {
  right: 25px !important;
}

.bottom-25 {
  bottom: 25px !important;
}

.left-25 {
  left: 25px !important;
}

.top-26 {
  top: 26px !important;
}

.right-26 {
  right: 26px !important;
}

.bottom-26 {
  bottom: 26px !important;
}

.left-26 {
  left: 26px !important;
}

.top-27 {
  top: 27px !important;
}

.right-27 {
  right: 27px !important;
}

.bottom-27 {
  bottom: 27px !important;
}

.left-27 {
  left: 27px !important;
}

.top-28 {
  top: 28px !important;
}

.right-28 {
  right: 28px !important;
}

.bottom-28 {
  bottom: 28px !important;
}

.left-28 {
  left: 28px !important;
}

.top-29 {
  top: 29px !important;
}

.right-29 {
  right: 29px !important;
}

.bottom-29 {
  bottom: 29px !important;
}

.left-29 {
  left: 29px !important;
}

.top-30 {
  top: 30px !important;
}

.right-30 {
  right: 30px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.left-30 {
  left: 30px !important;
}

.top-31 {
  top: 31px !important;
}

.right-31 {
  right: 31px !important;
}

.bottom-31 {
  bottom: 31px !important;
}

.left-31 {
  left: 31px !important;
}

.top-32 {
  top: 32px !important;
}

.right-32 {
  right: 32px !important;
}

.bottom-32 {
  bottom: 32px !important;
}

.left-32 {
  left: 32px !important;
}

.top-33 {
  top: 33px !important;
}

.right-33 {
  right: 33px !important;
}

.bottom-33 {
  bottom: 33px !important;
}

.left-33 {
  left: 33px !important;
}

.top-34 {
  top: 34px !important;
}

.right-34 {
  right: 34px !important;
}

.bottom-34 {
  bottom: 34px !important;
}

.left-34 {
  left: 34px !important;
}

.top-35 {
  top: 35px !important;
}

.right-35 {
  right: 35px !important;
}

.bottom-35 {
  bottom: 35px !important;
}

.left-35 {
  left: 35px !important;
}

.top-36 {
  top: 36px !important;
}

.right-36 {
  right: 36px !important;
}

.bottom-36 {
  bottom: 36px !important;
}

.left-36 {
  left: 36px !important;
}

.top-37 {
  top: 37px !important;
}

.right-37 {
  right: 37px !important;
}

.bottom-37 {
  bottom: 37px !important;
}

.left-37 {
  left: 37px !important;
}

.top-38 {
  top: 38px !important;
}

.right-38 {
  right: 38px !important;
}

.bottom-38 {
  bottom: 38px !important;
}

.left-38 {
  left: 38px !important;
}

.top-39 {
  top: 39px !important;
}

.right-39 {
  right: 39px !important;
}

.bottom-39 {
  bottom: 39px !important;
}

.left-39 {
  left: 39px !important;
}

.top-40 {
  top: 40px !important;
}

.right-40 {
  right: 40px !important;
}

.bottom-40 {
  bottom: 40px !important;
}

.left-40 {
  left: 40px !important;
}

.top-41 {
  top: 41px !important;
}

.right-41 {
  right: 41px !important;
}

.bottom-41 {
  bottom: 41px !important;
}

.left-41 {
  left: 41px !important;
}

.top-42 {
  top: 42px !important;
}

.right-42 {
  right: 42px !important;
}

.bottom-42 {
  bottom: 42px !important;
}

.left-42 {
  left: 42px !important;
}

.top-43 {
  top: 43px !important;
}

.right-43 {
  right: 43px !important;
}

.bottom-43 {
  bottom: 43px !important;
}

.left-43 {
  left: 43px !important;
}

.top-44 {
  top: 44px !important;
}

.right-44 {
  right: 44px !important;
}

.bottom-44 {
  bottom: 44px !important;
}

.left-44 {
  left: 44px !important;
}

.top-45 {
  top: 45px !important;
}

.right-45 {
  right: 45px !important;
}

.bottom-45 {
  bottom: 45px !important;
}

.left-45 {
  left: 45px !important;
}

.top-46 {
  top: 46px !important;
}

.right-46 {
  right: 46px !important;
}

.bottom-46 {
  bottom: 46px !important;
}

.left-46 {
  left: 46px !important;
}

.top-47 {
  top: 47px !important;
}

.right-47 {
  right: 47px !important;
}

.bottom-47 {
  bottom: 47px !important;
}

.left-47 {
  left: 47px !important;
}

.top-48 {
  top: 48px !important;
}

.right-48 {
  right: 48px !important;
}

.bottom-48 {
  bottom: 48px !important;
}

.left-48 {
  left: 48px !important;
}

.top-49 {
  top: 49px !important;
}

.right-49 {
  right: 49px !important;
}

.bottom-49 {
  bottom: 49px !important;
}

.left-49 {
  left: 49px !important;
}

.top-50 {
  top: 50px !important;
}

.right-50 {
  right: 50px !important;
}

.bottom-50 {
  bottom: 50px !important;
}

.left-50 {
  left: 50px !important;
}

.top-51 {
  top: 51px !important;
}

.right-51 {
  right: 51px !important;
}

.bottom-51 {
  bottom: 51px !important;
}

.left-51 {
  left: 51px !important;
}

.top-52 {
  top: 52px !important;
}

.right-52 {
  right: 52px !important;
}

.bottom-52 {
  bottom: 52px !important;
}

.left-52 {
  left: 52px !important;
}

.top-53 {
  top: 53px !important;
}

.right-53 {
  right: 53px !important;
}

.bottom-53 {
  bottom: 53px !important;
}

.left-53 {
  left: 53px !important;
}

.top-54 {
  top: 54px !important;
}

.right-54 {
  right: 54px !important;
}

.bottom-54 {
  bottom: 54px !important;
}

.left-54 {
  left: 54px !important;
}

.top-55 {
  top: 55px !important;
}

.right-55 {
  right: 55px !important;
}

.bottom-55 {
  bottom: 55px !important;
}

.left-55 {
  left: 55px !important;
}

.top-56 {
  top: 56px !important;
}

.right-56 {
  right: 56px !important;
}

.bottom-56 {
  bottom: 56px !important;
}

.left-56 {
  left: 56px !important;
}

.top-57 {
  top: 57px !important;
}

.right-57 {
  right: 57px !important;
}

.bottom-57 {
  bottom: 57px !important;
}

.left-57 {
  left: 57px !important;
}

.top-58 {
  top: 58px !important;
}

.right-58 {
  right: 58px !important;
}

.bottom-58 {
  bottom: 58px !important;
}

.left-58 {
  left: 58px !important;
}

.top-59 {
  top: 59px !important;
}

.right-59 {
  right: 59px !important;
}

.bottom-59 {
  bottom: 59px !important;
}

.left-59 {
  left: 59px !important;
}

.top-60 {
  top: 60px !important;
}

.right-60 {
  right: 60px !important;
}

.bottom-60 {
  bottom: 60px !important;
}

.left-60 {
  left: 60px !important;
}

.top-61 {
  top: 61px !important;
}

.right-61 {
  right: 61px !important;
}

.bottom-61 {
  bottom: 61px !important;
}

.left-61 {
  left: 61px !important;
}

.top-62 {
  top: 62px !important;
}

.right-62 {
  right: 62px !important;
}

.bottom-62 {
  bottom: 62px !important;
}

.left-62 {
  left: 62px !important;
}

.top-63 {
  top: 63px !important;
}

.right-63 {
  right: 63px !important;
}

.bottom-63 {
  bottom: 63px !important;
}

.left-63 {
  left: 63px !important;
}

.top-64 {
  top: 64px !important;
}

.right-64 {
  right: 64px !important;
}

.bottom-64 {
  bottom: 64px !important;
}

.left-64 {
  left: 64px !important;
}

.top-65 {
  top: 65px !important;
}

.right-65 {
  right: 65px !important;
}

.bottom-65 {
  bottom: 65px !important;
}

.left-65 {
  left: 65px !important;
}

.top-66 {
  top: 66px !important;
}

.right-66 {
  right: 66px !important;
}

.bottom-66 {
  bottom: 66px !important;
}

.left-66 {
  left: 66px !important;
}

.top-67 {
  top: 67px !important;
}

.right-67 {
  right: 67px !important;
}

.bottom-67 {
  bottom: 67px !important;
}

.left-67 {
  left: 67px !important;
}

.top-68 {
  top: 68px !important;
}

.right-68 {
  right: 68px !important;
}

.bottom-68 {
  bottom: 68px !important;
}

.left-68 {
  left: 68px !important;
}

.top-69 {
  top: 69px !important;
}

.right-69 {
  right: 69px !important;
}

.bottom-69 {
  bottom: 69px !important;
}

.left-69 {
  left: 69px !important;
}

.top-70 {
  top: 70px !important;
}

.right-70 {
  right: 70px !important;
}

.bottom-70 {
  bottom: 70px !important;
}

.left-70 {
  left: 70px !important;
}

.top-71 {
  top: 71px !important;
}

.right-71 {
  right: 71px !important;
}

.bottom-71 {
  bottom: 71px !important;
}

.left-71 {
  left: 71px !important;
}

.top-72 {
  top: 72px !important;
}

.right-72 {
  right: 72px !important;
}

.bottom-72 {
  bottom: 72px !important;
}

.left-72 {
  left: 72px !important;
}

.top-73 {
  top: 73px !important;
}

.right-73 {
  right: 73px !important;
}

.bottom-73 {
  bottom: 73px !important;
}

.left-73 {
  left: 73px !important;
}

.top-74 {
  top: 74px !important;
}

.right-74 {
  right: 74px !important;
}

.bottom-74 {
  bottom: 74px !important;
}

.left-74 {
  left: 74px !important;
}

.top-75 {
  top: 75px !important;
}

.right-75 {
  right: 75px !important;
}

.bottom-75 {
  bottom: 75px !important;
}

.left-75 {
  left: 75px !important;
}

.top-76 {
  top: 76px !important;
}

.right-76 {
  right: 76px !important;
}

.bottom-76 {
  bottom: 76px !important;
}

.left-76 {
  left: 76px !important;
}

.top-77 {
  top: 77px !important;
}

.right-77 {
  right: 77px !important;
}

.bottom-77 {
  bottom: 77px !important;
}

.left-77 {
  left: 77px !important;
}

.top-78 {
  top: 78px !important;
}

.right-78 {
  right: 78px !important;
}

.bottom-78 {
  bottom: 78px !important;
}

.left-78 {
  left: 78px !important;
}

.top-79 {
  top: 79px !important;
}

.right-79 {
  right: 79px !important;
}

.bottom-79 {
  bottom: 79px !important;
}

.left-79 {
  left: 79px !important;
}

.top-80 {
  top: 80px !important;
}

.right-80 {
  right: 80px !important;
}

.bottom-80 {
  bottom: 80px !important;
}

.left-80 {
  left: 80px !important;
}

.top-81 {
  top: 81px !important;
}

.right-81 {
  right: 81px !important;
}

.bottom-81 {
  bottom: 81px !important;
}

.left-81 {
  left: 81px !important;
}

.top-82 {
  top: 82px !important;
}

.right-82 {
  right: 82px !important;
}

.bottom-82 {
  bottom: 82px !important;
}

.left-82 {
  left: 82px !important;
}

.top-83 {
  top: 83px !important;
}

.right-83 {
  right: 83px !important;
}

.bottom-83 {
  bottom: 83px !important;
}

.left-83 {
  left: 83px !important;
}

.top-84 {
  top: 84px !important;
}

.right-84 {
  right: 84px !important;
}

.bottom-84 {
  bottom: 84px !important;
}

.left-84 {
  left: 84px !important;
}

.top-85 {
  top: 85px !important;
}

.right-85 {
  right: 85px !important;
}

.bottom-85 {
  bottom: 85px !important;
}

.left-85 {
  left: 85px !important;
}

.top-86 {
  top: 86px !important;
}

.right-86 {
  right: 86px !important;
}

.bottom-86 {
  bottom: 86px !important;
}

.left-86 {
  left: 86px !important;
}

.top-87 {
  top: 87px !important;
}

.right-87 {
  right: 87px !important;
}

.bottom-87 {
  bottom: 87px !important;
}

.left-87 {
  left: 87px !important;
}

.top-88 {
  top: 88px !important;
}

.right-88 {
  right: 88px !important;
}

.bottom-88 {
  bottom: 88px !important;
}

.left-88 {
  left: 88px !important;
}

.top-89 {
  top: 89px !important;
}

.right-89 {
  right: 89px !important;
}

.bottom-89 {
  bottom: 89px !important;
}

.left-89 {
  left: 89px !important;
}

.top-90 {
  top: 90px !important;
}

.right-90 {
  right: 90px !important;
}

.bottom-90 {
  bottom: 90px !important;
}

.left-90 {
  left: 90px !important;
}

.top-91 {
  top: 91px !important;
}

.right-91 {
  right: 91px !important;
}

.bottom-91 {
  bottom: 91px !important;
}

.left-91 {
  left: 91px !important;
}

.top-92 {
  top: 92px !important;
}

.right-92 {
  right: 92px !important;
}

.bottom-92 {
  bottom: 92px !important;
}

.left-92 {
  left: 92px !important;
}

.top-93 {
  top: 93px !important;
}

.right-93 {
  right: 93px !important;
}

.bottom-93 {
  bottom: 93px !important;
}

.left-93 {
  left: 93px !important;
}

.top-94 {
  top: 94px !important;
}

.right-94 {
  right: 94px !important;
}

.bottom-94 {
  bottom: 94px !important;
}

.left-94 {
  left: 94px !important;
}

.top-95 {
  top: 95px !important;
}

.right-95 {
  right: 95px !important;
}

.bottom-95 {
  bottom: 95px !important;
}

.left-95 {
  left: 95px !important;
}

.top-96 {
  top: 96px !important;
}

.right-96 {
  right: 96px !important;
}

.bottom-96 {
  bottom: 96px !important;
}

.left-96 {
  left: 96px !important;
}

.top-97 {
  top: 97px !important;
}

.right-97 {
  right: 97px !important;
}

.bottom-97 {
  bottom: 97px !important;
}

.left-97 {
  left: 97px !important;
}

.top-98 {
  top: 98px !important;
}

.right-98 {
  right: 98px !important;
}

.bottom-98 {
  bottom: 98px !important;
}

.left-98 {
  left: 98px !important;
}

.top-99 {
  top: 99px !important;
}

.right-99 {
  right: 99px !important;
}

.bottom-99 {
  bottom: 99px !important;
}

.left-99 {
  left: 99px !important;
}

.top-100 {
  top: 100px !important;
}

.right-100 {
  right: 100px !important;
}

.bottom-100 {
  bottom: 100px !important;
}

.left-100 {
  left: 100px !important;
}

.top-101 {
  top: 101px !important;
}

.right-101 {
  right: 101px !important;
}

.bottom-101 {
  bottom: 101px !important;
}

.left-101 {
  left: 101px !important;
}

.top-102 {
  top: 102px !important;
}

.right-102 {
  right: 102px !important;
}

.bottom-102 {
  bottom: 102px !important;
}

.left-102 {
  left: 102px !important;
}

.top-103 {
  top: 103px !important;
}

.right-103 {
  right: 103px !important;
}

.bottom-103 {
  bottom: 103px !important;
}

.left-103 {
  left: 103px !important;
}

.top-104 {
  top: 104px !important;
}

.right-104 {
  right: 104px !important;
}

.bottom-104 {
  bottom: 104px !important;
}

.left-104 {
  left: 104px !important;
}

.top-105 {
  top: 105px !important;
}

.right-105 {
  right: 105px !important;
}

.bottom-105 {
  bottom: 105px !important;
}

.left-105 {
  left: 105px !important;
}

.top-106 {
  top: 106px !important;
}

.right-106 {
  right: 106px !important;
}

.bottom-106 {
  bottom: 106px !important;
}

.left-106 {
  left: 106px !important;
}

.top-107 {
  top: 107px !important;
}

.right-107 {
  right: 107px !important;
}

.bottom-107 {
  bottom: 107px !important;
}

.left-107 {
  left: 107px !important;
}

.top-108 {
  top: 108px !important;
}

.right-108 {
  right: 108px !important;
}

.bottom-108 {
  bottom: 108px !important;
}

.left-108 {
  left: 108px !important;
}

.top-109 {
  top: 109px !important;
}

.right-109 {
  right: 109px !important;
}

.bottom-109 {
  bottom: 109px !important;
}

.left-109 {
  left: 109px !important;
}

.top-110 {
  top: 110px !important;
}

.right-110 {
  right: 110px !important;
}

.bottom-110 {
  bottom: 110px !important;
}

.left-110 {
  left: 110px !important;
}

.top-111 {
  top: 111px !important;
}

.right-111 {
  right: 111px !important;
}

.bottom-111 {
  bottom: 111px !important;
}

.left-111 {
  left: 111px !important;
}

.top-112 {
  top: 112px !important;
}

.right-112 {
  right: 112px !important;
}

.bottom-112 {
  bottom: 112px !important;
}

.left-112 {
  left: 112px !important;
}

.top-113 {
  top: 113px !important;
}

.right-113 {
  right: 113px !important;
}

.bottom-113 {
  bottom: 113px !important;
}

.left-113 {
  left: 113px !important;
}

.top-114 {
  top: 114px !important;
}

.right-114 {
  right: 114px !important;
}

.bottom-114 {
  bottom: 114px !important;
}

.left-114 {
  left: 114px !important;
}

.top-115 {
  top: 115px !important;
}

.right-115 {
  right: 115px !important;
}

.bottom-115 {
  bottom: 115px !important;
}

.left-115 {
  left: 115px !important;
}

.top-116 {
  top: 116px !important;
}

.right-116 {
  right: 116px !important;
}

.bottom-116 {
  bottom: 116px !important;
}

.left-116 {
  left: 116px !important;
}

.top-117 {
  top: 117px !important;
}

.right-117 {
  right: 117px !important;
}

.bottom-117 {
  bottom: 117px !important;
}

.left-117 {
  left: 117px !important;
}

.top-118 {
  top: 118px !important;
}

.right-118 {
  right: 118px !important;
}

.bottom-118 {
  bottom: 118px !important;
}

.left-118 {
  left: 118px !important;
}

.top-119 {
  top: 119px !important;
}

.right-119 {
  right: 119px !important;
}

.bottom-119 {
  bottom: 119px !important;
}

.left-119 {
  left: 119px !important;
}

.top-120 {
  top: 120px !important;
}

.right-120 {
  right: 120px !important;
}

.bottom-120 {
  bottom: 120px !important;
}

.left-120 {
  left: 120px !important;
}

.top-121 {
  top: 121px !important;
}

.right-121 {
  right: 121px !important;
}

.bottom-121 {
  bottom: 121px !important;
}

.left-121 {
  left: 121px !important;
}

.top-122 {
  top: 122px !important;
}

.right-122 {
  right: 122px !important;
}

.bottom-122 {
  bottom: 122px !important;
}

.left-122 {
  left: 122px !important;
}

.top-123 {
  top: 123px !important;
}

.right-123 {
  right: 123px !important;
}

.bottom-123 {
  bottom: 123px !important;
}

.left-123 {
  left: 123px !important;
}

.top-124 {
  top: 124px !important;
}

.right-124 {
  right: 124px !important;
}

.bottom-124 {
  bottom: 124px !important;
}

.left-124 {
  left: 124px !important;
}

.top-125 {
  top: 125px !important;
}

.right-125 {
  right: 125px !important;
}

.bottom-125 {
  bottom: 125px !important;
}

.left-125 {
  left: 125px !important;
}

.top-126 {
  top: 126px !important;
}

.right-126 {
  right: 126px !important;
}

.bottom-126 {
  bottom: 126px !important;
}

.left-126 {
  left: 126px !important;
}

.top-127 {
  top: 127px !important;
}

.right-127 {
  right: 127px !important;
}

.bottom-127 {
  bottom: 127px !important;
}

.left-127 {
  left: 127px !important;
}

.top-128 {
  top: 128px !important;
}

.right-128 {
  right: 128px !important;
}

.bottom-128 {
  bottom: 128px !important;
}

.left-128 {
  left: 128px !important;
}

.top-129 {
  top: 129px !important;
}

.right-129 {
  right: 129px !important;
}

.bottom-129 {
  bottom: 129px !important;
}

.left-129 {
  left: 129px !important;
}

.top-130 {
  top: 130px !important;
}

.right-130 {
  right: 130px !important;
}

.bottom-130 {
  bottom: 130px !important;
}

.left-130 {
  left: 130px !important;
}

.top-131 {
  top: 131px !important;
}

.right-131 {
  right: 131px !important;
}

.bottom-131 {
  bottom: 131px !important;
}

.left-131 {
  left: 131px !important;
}

.top-132 {
  top: 132px !important;
}

.right-132 {
  right: 132px !important;
}

.bottom-132 {
  bottom: 132px !important;
}

.left-132 {
  left: 132px !important;
}

.top-133 {
  top: 133px !important;
}

.right-133 {
  right: 133px !important;
}

.bottom-133 {
  bottom: 133px !important;
}

.left-133 {
  left: 133px !important;
}

.top-134 {
  top: 134px !important;
}

.right-134 {
  right: 134px !important;
}

.bottom-134 {
  bottom: 134px !important;
}

.left-134 {
  left: 134px !important;
}

.top-135 {
  top: 135px !important;
}

.right-135 {
  right: 135px !important;
}

.bottom-135 {
  bottom: 135px !important;
}

.left-135 {
  left: 135px !important;
}

.top-136 {
  top: 136px !important;
}

.right-136 {
  right: 136px !important;
}

.bottom-136 {
  bottom: 136px !important;
}

.left-136 {
  left: 136px !important;
}

.top-137 {
  top: 137px !important;
}

.right-137 {
  right: 137px !important;
}

.bottom-137 {
  bottom: 137px !important;
}

.left-137 {
  left: 137px !important;
}

.top-138 {
  top: 138px !important;
}

.right-138 {
  right: 138px !important;
}

.bottom-138 {
  bottom: 138px !important;
}

.left-138 {
  left: 138px !important;
}

.top-139 {
  top: 139px !important;
}

.right-139 {
  right: 139px !important;
}

.bottom-139 {
  bottom: 139px !important;
}

.left-139 {
  left: 139px !important;
}

.top-140 {
  top: 140px !important;
}

.right-140 {
  right: 140px !important;
}

.bottom-140 {
  bottom: 140px !important;
}

.left-140 {
  left: 140px !important;
}

.top-141 {
  top: 141px !important;
}

.right-141 {
  right: 141px !important;
}

.bottom-141 {
  bottom: 141px !important;
}

.left-141 {
  left: 141px !important;
}

.top-142 {
  top: 142px !important;
}

.right-142 {
  right: 142px !important;
}

.bottom-142 {
  bottom: 142px !important;
}

.left-142 {
  left: 142px !important;
}

.top-143 {
  top: 143px !important;
}

.right-143 {
  right: 143px !important;
}

.bottom-143 {
  bottom: 143px !important;
}

.left-143 {
  left: 143px !important;
}

.top-144 {
  top: 144px !important;
}

.right-144 {
  right: 144px !important;
}

.bottom-144 {
  bottom: 144px !important;
}

.left-144 {
  left: 144px !important;
}

.top-145 {
  top: 145px !important;
}

.right-145 {
  right: 145px !important;
}

.bottom-145 {
  bottom: 145px !important;
}

.left-145 {
  left: 145px !important;
}

.top-146 {
  top: 146px !important;
}

.right-146 {
  right: 146px !important;
}

.bottom-146 {
  bottom: 146px !important;
}

.left-146 {
  left: 146px !important;
}

.top-147 {
  top: 147px !important;
}

.right-147 {
  right: 147px !important;
}

.bottom-147 {
  bottom: 147px !important;
}

.left-147 {
  left: 147px !important;
}

.top-148 {
  top: 148px !important;
}

.right-148 {
  right: 148px !important;
}

.bottom-148 {
  bottom: 148px !important;
}

.left-148 {
  left: 148px !important;
}

.top-149 {
  top: 149px !important;
}

.right-149 {
  right: 149px !important;
}

.bottom-149 {
  bottom: 149px !important;
}

.left-149 {
  left: 149px !important;
}

.top-150 {
  top: 150px !important;
}

.right-150 {
  right: 150px !important;
}

.bottom-150 {
  bottom: 150px !important;
}

.left-150 {
  left: 150px !important;
}

.top-151 {
  top: 151px !important;
}

.right-151 {
  right: 151px !important;
}

.bottom-151 {
  bottom: 151px !important;
}

.left-151 {
  left: 151px !important;
}

.top-152 {
  top: 152px !important;
}

.right-152 {
  right: 152px !important;
}

.bottom-152 {
  bottom: 152px !important;
}

.left-152 {
  left: 152px !important;
}

.top-153 {
  top: 153px !important;
}

.right-153 {
  right: 153px !important;
}

.bottom-153 {
  bottom: 153px !important;
}

.left-153 {
  left: 153px !important;
}

.top-154 {
  top: 154px !important;
}

.right-154 {
  right: 154px !important;
}

.bottom-154 {
  bottom: 154px !important;
}

.left-154 {
  left: 154px !important;
}

.top-155 {
  top: 155px !important;
}

.right-155 {
  right: 155px !important;
}

.bottom-155 {
  bottom: 155px !important;
}

.left-155 {
  left: 155px !important;
}

.top-156 {
  top: 156px !important;
}

.right-156 {
  right: 156px !important;
}

.bottom-156 {
  bottom: 156px !important;
}

.left-156 {
  left: 156px !important;
}

.top-157 {
  top: 157px !important;
}

.right-157 {
  right: 157px !important;
}

.bottom-157 {
  bottom: 157px !important;
}

.left-157 {
  left: 157px !important;
}

.top-158 {
  top: 158px !important;
}

.right-158 {
  right: 158px !important;
}

.bottom-158 {
  bottom: 158px !important;
}

.left-158 {
  left: 158px !important;
}

.top-159 {
  top: 159px !important;
}

.right-159 {
  right: 159px !important;
}

.bottom-159 {
  bottom: 159px !important;
}

.left-159 {
  left: 159px !important;
}

.top-160 {
  top: 160px !important;
}

.right-160 {
  right: 160px !important;
}

.bottom-160 {
  bottom: 160px !important;
}

.left-160 {
  left: 160px !important;
}

.top-161 {
  top: 161px !important;
}

.right-161 {
  right: 161px !important;
}

.bottom-161 {
  bottom: 161px !important;
}

.left-161 {
  left: 161px !important;
}

.top-162 {
  top: 162px !important;
}

.right-162 {
  right: 162px !important;
}

.bottom-162 {
  bottom: 162px !important;
}

.left-162 {
  left: 162px !important;
}

.top-163 {
  top: 163px !important;
}

.right-163 {
  right: 163px !important;
}

.bottom-163 {
  bottom: 163px !important;
}

.left-163 {
  left: 163px !important;
}

.top-164 {
  top: 164px !important;
}

.right-164 {
  right: 164px !important;
}

.bottom-164 {
  bottom: 164px !important;
}

.left-164 {
  left: 164px !important;
}

.top-165 {
  top: 165px !important;
}

.right-165 {
  right: 165px !important;
}

.bottom-165 {
  bottom: 165px !important;
}

.left-165 {
  left: 165px !important;
}

.top-166 {
  top: 166px !important;
}

.right-166 {
  right: 166px !important;
}

.bottom-166 {
  bottom: 166px !important;
}

.left-166 {
  left: 166px !important;
}

.top-167 {
  top: 167px !important;
}

.right-167 {
  right: 167px !important;
}

.bottom-167 {
  bottom: 167px !important;
}

.left-167 {
  left: 167px !important;
}

.top-168 {
  top: 168px !important;
}

.right-168 {
  right: 168px !important;
}

.bottom-168 {
  bottom: 168px !important;
}

.left-168 {
  left: 168px !important;
}

.top-169 {
  top: 169px !important;
}

.right-169 {
  right: 169px !important;
}

.bottom-169 {
  bottom: 169px !important;
}

.left-169 {
  left: 169px !important;
}

.top-170 {
  top: 170px !important;
}

.right-170 {
  right: 170px !important;
}

.bottom-170 {
  bottom: 170px !important;
}

.left-170 {
  left: 170px !important;
}

.top-171 {
  top: 171px !important;
}

.right-171 {
  right: 171px !important;
}

.bottom-171 {
  bottom: 171px !important;
}

.left-171 {
  left: 171px !important;
}

.top-172 {
  top: 172px !important;
}

.right-172 {
  right: 172px !important;
}

.bottom-172 {
  bottom: 172px !important;
}

.left-172 {
  left: 172px !important;
}

.top-173 {
  top: 173px !important;
}

.right-173 {
  right: 173px !important;
}

.bottom-173 {
  bottom: 173px !important;
}

.left-173 {
  left: 173px !important;
}

.top-174 {
  top: 174px !important;
}

.right-174 {
  right: 174px !important;
}

.bottom-174 {
  bottom: 174px !important;
}

.left-174 {
  left: 174px !important;
}

.top-175 {
  top: 175px !important;
}

.right-175 {
  right: 175px !important;
}

.bottom-175 {
  bottom: 175px !important;
}

.left-175 {
  left: 175px !important;
}

.top-176 {
  top: 176px !important;
}

.right-176 {
  right: 176px !important;
}

.bottom-176 {
  bottom: 176px !important;
}

.left-176 {
  left: 176px !important;
}

.top-177 {
  top: 177px !important;
}

.right-177 {
  right: 177px !important;
}

.bottom-177 {
  bottom: 177px !important;
}

.left-177 {
  left: 177px !important;
}

.top-178 {
  top: 178px !important;
}

.right-178 {
  right: 178px !important;
}

.bottom-178 {
  bottom: 178px !important;
}

.left-178 {
  left: 178px !important;
}

.top-179 {
  top: 179px !important;
}

.right-179 {
  right: 179px !important;
}

.bottom-179 {
  bottom: 179px !important;
}

.left-179 {
  left: 179px !important;
}

.top-180 {
  top: 180px !important;
}

.right-180 {
  right: 180px !important;
}

.bottom-180 {
  bottom: 180px !important;
}

.left-180 {
  left: 180px !important;
}

.top-181 {
  top: 181px !important;
}

.right-181 {
  right: 181px !important;
}

.bottom-181 {
  bottom: 181px !important;
}

.left-181 {
  left: 181px !important;
}

.top-182 {
  top: 182px !important;
}

.right-182 {
  right: 182px !important;
}

.bottom-182 {
  bottom: 182px !important;
}

.left-182 {
  left: 182px !important;
}

.top-183 {
  top: 183px !important;
}

.right-183 {
  right: 183px !important;
}

.bottom-183 {
  bottom: 183px !important;
}

.left-183 {
  left: 183px !important;
}

.top-184 {
  top: 184px !important;
}

.right-184 {
  right: 184px !important;
}

.bottom-184 {
  bottom: 184px !important;
}

.left-184 {
  left: 184px !important;
}

.top-185 {
  top: 185px !important;
}

.right-185 {
  right: 185px !important;
}

.bottom-185 {
  bottom: 185px !important;
}

.left-185 {
  left: 185px !important;
}

.top-186 {
  top: 186px !important;
}

.right-186 {
  right: 186px !important;
}

.bottom-186 {
  bottom: 186px !important;
}

.left-186 {
  left: 186px !important;
}

.top-187 {
  top: 187px !important;
}

.right-187 {
  right: 187px !important;
}

.bottom-187 {
  bottom: 187px !important;
}

.left-187 {
  left: 187px !important;
}

.top-188 {
  top: 188px !important;
}

.right-188 {
  right: 188px !important;
}

.bottom-188 {
  bottom: 188px !important;
}

.left-188 {
  left: 188px !important;
}

.top-189 {
  top: 189px !important;
}

.right-189 {
  right: 189px !important;
}

.bottom-189 {
  bottom: 189px !important;
}

.left-189 {
  left: 189px !important;
}

.top-190 {
  top: 190px !important;
}

.right-190 {
  right: 190px !important;
}

.bottom-190 {
  bottom: 190px !important;
}

.left-190 {
  left: 190px !important;
}

.top-191 {
  top: 191px !important;
}

.right-191 {
  right: 191px !important;
}

.bottom-191 {
  bottom: 191px !important;
}

.left-191 {
  left: 191px !important;
}

.top-192 {
  top: 192px !important;
}

.right-192 {
  right: 192px !important;
}

.bottom-192 {
  bottom: 192px !important;
}

.left-192 {
  left: 192px !important;
}

.top-193 {
  top: 193px !important;
}

.right-193 {
  right: 193px !important;
}

.bottom-193 {
  bottom: 193px !important;
}

.left-193 {
  left: 193px !important;
}

.top-194 {
  top: 194px !important;
}

.right-194 {
  right: 194px !important;
}

.bottom-194 {
  bottom: 194px !important;
}

.left-194 {
  left: 194px !important;
}

.top-195 {
  top: 195px !important;
}

.right-195 {
  right: 195px !important;
}

.bottom-195 {
  bottom: 195px !important;
}

.left-195 {
  left: 195px !important;
}

.top-196 {
  top: 196px !important;
}

.right-196 {
  right: 196px !important;
}

.bottom-196 {
  bottom: 196px !important;
}

.left-196 {
  left: 196px !important;
}

.top-197 {
  top: 197px !important;
}

.right-197 {
  right: 197px !important;
}

.bottom-197 {
  bottom: 197px !important;
}

.left-197 {
  left: 197px !important;
}

.top-198 {
  top: 198px !important;
}

.right-198 {
  right: 198px !important;
}

.bottom-198 {
  bottom: 198px !important;
}

.left-198 {
  left: 198px !important;
}

.top-199 {
  top: 199px !important;
}

.right-199 {
  right: 199px !important;
}

.bottom-199 {
  bottom: 199px !important;
}

.left-199 {
  left: 199px !important;
}

.top-200 {
  top: 200px !important;
}

.right-200 {
  right: 200px !important;
}

.bottom-200 {
  bottom: 200px !important;
}

.left-200 {
  left: 200px !important;
}

.top-201 {
  top: 201px !important;
}

.right-201 {
  right: 201px !important;
}

.bottom-201 {
  bottom: 201px !important;
}

.left-201 {
  left: 201px !important;
}

.top-202 {
  top: 202px !important;
}

.right-202 {
  right: 202px !important;
}

.bottom-202 {
  bottom: 202px !important;
}

.left-202 {
  left: 202px !important;
}

.top-203 {
  top: 203px !important;
}

.right-203 {
  right: 203px !important;
}

.bottom-203 {
  bottom: 203px !important;
}

.left-203 {
  left: 203px !important;
}

.top-204 {
  top: 204px !important;
}

.right-204 {
  right: 204px !important;
}

.bottom-204 {
  bottom: 204px !important;
}

.left-204 {
  left: 204px !important;
}

.top-205 {
  top: 205px !important;
}

.right-205 {
  right: 205px !important;
}

.bottom-205 {
  bottom: 205px !important;
}

.left-205 {
  left: 205px !important;
}

.top-206 {
  top: 206px !important;
}

.right-206 {
  right: 206px !important;
}

.bottom-206 {
  bottom: 206px !important;
}

.left-206 {
  left: 206px !important;
}

.top-207 {
  top: 207px !important;
}

.right-207 {
  right: 207px !important;
}

.bottom-207 {
  bottom: 207px !important;
}

.left-207 {
  left: 207px !important;
}

.top-208 {
  top: 208px !important;
}

.right-208 {
  right: 208px !important;
}

.bottom-208 {
  bottom: 208px !important;
}

.left-208 {
  left: 208px !important;
}

.top-209 {
  top: 209px !important;
}

.right-209 {
  right: 209px !important;
}

.bottom-209 {
  bottom: 209px !important;
}

.left-209 {
  left: 209px !important;
}

.top-210 {
  top: 210px !important;
}

.right-210 {
  right: 210px !important;
}

.bottom-210 {
  bottom: 210px !important;
}

.left-210 {
  left: 210px !important;
}

.top-211 {
  top: 211px !important;
}

.right-211 {
  right: 211px !important;
}

.bottom-211 {
  bottom: 211px !important;
}

.left-211 {
  left: 211px !important;
}

.top-212 {
  top: 212px !important;
}

.right-212 {
  right: 212px !important;
}

.bottom-212 {
  bottom: 212px !important;
}

.left-212 {
  left: 212px !important;
}

.top-213 {
  top: 213px !important;
}

.right-213 {
  right: 213px !important;
}

.bottom-213 {
  bottom: 213px !important;
}

.left-213 {
  left: 213px !important;
}

.top-214 {
  top: 214px !important;
}

.right-214 {
  right: 214px !important;
}

.bottom-214 {
  bottom: 214px !important;
}

.left-214 {
  left: 214px !important;
}

.top-215 {
  top: 215px !important;
}

.right-215 {
  right: 215px !important;
}

.bottom-215 {
  bottom: 215px !important;
}

.left-215 {
  left: 215px !important;
}

.top-216 {
  top: 216px !important;
}

.right-216 {
  right: 216px !important;
}

.bottom-216 {
  bottom: 216px !important;
}

.left-216 {
  left: 216px !important;
}

.top-217 {
  top: 217px !important;
}

.right-217 {
  right: 217px !important;
}

.bottom-217 {
  bottom: 217px !important;
}

.left-217 {
  left: 217px !important;
}

.top-218 {
  top: 218px !important;
}

.right-218 {
  right: 218px !important;
}

.bottom-218 {
  bottom: 218px !important;
}

.left-218 {
  left: 218px !important;
}

.top-219 {
  top: 219px !important;
}

.right-219 {
  right: 219px !important;
}

.bottom-219 {
  bottom: 219px !important;
}

.left-219 {
  left: 219px !important;
}

.top-220 {
  top: 220px !important;
}

.right-220 {
  right: 220px !important;
}

.bottom-220 {
  bottom: 220px !important;
}

.left-220 {
  left: 220px !important;
}

.top-221 {
  top: 221px !important;
}

.right-221 {
  right: 221px !important;
}

.bottom-221 {
  bottom: 221px !important;
}

.left-221 {
  left: 221px !important;
}

.top-222 {
  top: 222px !important;
}

.right-222 {
  right: 222px !important;
}

.bottom-222 {
  bottom: 222px !important;
}

.left-222 {
  left: 222px !important;
}

.top-223 {
  top: 223px !important;
}

.right-223 {
  right: 223px !important;
}

.bottom-223 {
  bottom: 223px !important;
}

.left-223 {
  left: 223px !important;
}

.top-224 {
  top: 224px !important;
}

.right-224 {
  right: 224px !important;
}

.bottom-224 {
  bottom: 224px !important;
}

.left-224 {
  left: 224px !important;
}

.top-225 {
  top: 225px !important;
}

.right-225 {
  right: 225px !important;
}

.bottom-225 {
  bottom: 225px !important;
}

.left-225 {
  left: 225px !important;
}

.top-226 {
  top: 226px !important;
}

.right-226 {
  right: 226px !important;
}

.bottom-226 {
  bottom: 226px !important;
}

.left-226 {
  left: 226px !important;
}

.top-227 {
  top: 227px !important;
}

.right-227 {
  right: 227px !important;
}

.bottom-227 {
  bottom: 227px !important;
}

.left-227 {
  left: 227px !important;
}

.top-228 {
  top: 228px !important;
}

.right-228 {
  right: 228px !important;
}

.bottom-228 {
  bottom: 228px !important;
}

.left-228 {
  left: 228px !important;
}

.top-229 {
  top: 229px !important;
}

.right-229 {
  right: 229px !important;
}

.bottom-229 {
  bottom: 229px !important;
}

.left-229 {
  left: 229px !important;
}

.top-230 {
  top: 230px !important;
}

.right-230 {
  right: 230px !important;
}

.bottom-230 {
  bottom: 230px !important;
}

.left-230 {
  left: 230px !important;
}

.top-231 {
  top: 231px !important;
}

.right-231 {
  right: 231px !important;
}

.bottom-231 {
  bottom: 231px !important;
}

.left-231 {
  left: 231px !important;
}

.top-232 {
  top: 232px !important;
}

.right-232 {
  right: 232px !important;
}

.bottom-232 {
  bottom: 232px !important;
}

.left-232 {
  left: 232px !important;
}

.top-233 {
  top: 233px !important;
}

.right-233 {
  right: 233px !important;
}

.bottom-233 {
  bottom: 233px !important;
}

.left-233 {
  left: 233px !important;
}

.top-234 {
  top: 234px !important;
}

.right-234 {
  right: 234px !important;
}

.bottom-234 {
  bottom: 234px !important;
}

.left-234 {
  left: 234px !important;
}

.top-235 {
  top: 235px !important;
}

.right-235 {
  right: 235px !important;
}

.bottom-235 {
  bottom: 235px !important;
}

.left-235 {
  left: 235px !important;
}

.top-236 {
  top: 236px !important;
}

.right-236 {
  right: 236px !important;
}

.bottom-236 {
  bottom: 236px !important;
}

.left-236 {
  left: 236px !important;
}

.top-237 {
  top: 237px !important;
}

.right-237 {
  right: 237px !important;
}

.bottom-237 {
  bottom: 237px !important;
}

.left-237 {
  left: 237px !important;
}

.top-238 {
  top: 238px !important;
}

.right-238 {
  right: 238px !important;
}

.bottom-238 {
  bottom: 238px !important;
}

.left-238 {
  left: 238px !important;
}

.top-239 {
  top: 239px !important;
}

.right-239 {
  right: 239px !important;
}

.bottom-239 {
  bottom: 239px !important;
}

.left-239 {
  left: 239px !important;
}

.top-240 {
  top: 240px !important;
}

.right-240 {
  right: 240px !important;
}

.bottom-240 {
  bottom: 240px !important;
}

.left-240 {
  left: 240px !important;
}

.top-241 {
  top: 241px !important;
}

.right-241 {
  right: 241px !important;
}

.bottom-241 {
  bottom: 241px !important;
}

.left-241 {
  left: 241px !important;
}

.top-242 {
  top: 242px !important;
}

.right-242 {
  right: 242px !important;
}

.bottom-242 {
  bottom: 242px !important;
}

.left-242 {
  left: 242px !important;
}

.top-243 {
  top: 243px !important;
}

.right-243 {
  right: 243px !important;
}

.bottom-243 {
  bottom: 243px !important;
}

.left-243 {
  left: 243px !important;
}

.top-244 {
  top: 244px !important;
}

.right-244 {
  right: 244px !important;
}

.bottom-244 {
  bottom: 244px !important;
}

.left-244 {
  left: 244px !important;
}

.top-245 {
  top: 245px !important;
}

.right-245 {
  right: 245px !important;
}

.bottom-245 {
  bottom: 245px !important;
}

.left-245 {
  left: 245px !important;
}

.top-246 {
  top: 246px !important;
}

.right-246 {
  right: 246px !important;
}

.bottom-246 {
  bottom: 246px !important;
}

.left-246 {
  left: 246px !important;
}

.top-247 {
  top: 247px !important;
}

.right-247 {
  right: 247px !important;
}

.bottom-247 {
  bottom: 247px !important;
}

.left-247 {
  left: 247px !important;
}

.top-248 {
  top: 248px !important;
}

.right-248 {
  right: 248px !important;
}

.bottom-248 {
  bottom: 248px !important;
}

.left-248 {
  left: 248px !important;
}

.top-249 {
  top: 249px !important;
}

.right-249 {
  right: 249px !important;
}

.bottom-249 {
  bottom: 249px !important;
}

.left-249 {
  left: 249px !important;
}

.top-250 {
  top: 250px !important;
}

.right-250 {
  right: 250px !important;
}

.bottom-250 {
  bottom: 250px !important;
}

.left-250 {
  left: 250px !important;
}

.top-251 {
  top: 251px !important;
}

.right-251 {
  right: 251px !important;
}

.bottom-251 {
  bottom: 251px !important;
}

.left-251 {
  left: 251px !important;
}

.top-252 {
  top: 252px !important;
}

.right-252 {
  right: 252px !important;
}

.bottom-252 {
  bottom: 252px !important;
}

.left-252 {
  left: 252px !important;
}

.top-253 {
  top: 253px !important;
}

.right-253 {
  right: 253px !important;
}

.bottom-253 {
  bottom: 253px !important;
}

.left-253 {
  left: 253px !important;
}

.top-254 {
  top: 254px !important;
}

.right-254 {
  right: 254px !important;
}

.bottom-254 {
  bottom: 254px !important;
}

.left-254 {
  left: 254px !important;
}

.top-255 {
  top: 255px !important;
}

.right-255 {
  right: 255px !important;
}

.bottom-255 {
  bottom: 255px !important;
}

.left-255 {
  left: 255px !important;
}

.top-256 {
  top: 256px !important;
}

.right-256 {
  right: 256px !important;
}

.bottom-256 {
  bottom: 256px !important;
}

.left-256 {
  left: 256px !important;
}

.top-257 {
  top: 257px !important;
}

.right-257 {
  right: 257px !important;
}

.bottom-257 {
  bottom: 257px !important;
}

.left-257 {
  left: 257px !important;
}

.top-258 {
  top: 258px !important;
}

.right-258 {
  right: 258px !important;
}

.bottom-258 {
  bottom: 258px !important;
}

.left-258 {
  left: 258px !important;
}

.top-259 {
  top: 259px !important;
}

.right-259 {
  right: 259px !important;
}

.bottom-259 {
  bottom: 259px !important;
}

.left-259 {
  left: 259px !important;
}

.top-260 {
  top: 260px !important;
}

.right-260 {
  right: 260px !important;
}

.bottom-260 {
  bottom: 260px !important;
}

.left-260 {
  left: 260px !important;
}

.top-261 {
  top: 261px !important;
}

.right-261 {
  right: 261px !important;
}

.bottom-261 {
  bottom: 261px !important;
}

.left-261 {
  left: 261px !important;
}

.top-262 {
  top: 262px !important;
}

.right-262 {
  right: 262px !important;
}

.bottom-262 {
  bottom: 262px !important;
}

.left-262 {
  left: 262px !important;
}

.top-263 {
  top: 263px !important;
}

.right-263 {
  right: 263px !important;
}

.bottom-263 {
  bottom: 263px !important;
}

.left-263 {
  left: 263px !important;
}

.top-264 {
  top: 264px !important;
}

.right-264 {
  right: 264px !important;
}

.bottom-264 {
  bottom: 264px !important;
}

.left-264 {
  left: 264px !important;
}

.top-265 {
  top: 265px !important;
}

.right-265 {
  right: 265px !important;
}

.bottom-265 {
  bottom: 265px !important;
}

.left-265 {
  left: 265px !important;
}

.top-266 {
  top: 266px !important;
}

.right-266 {
  right: 266px !important;
}

.bottom-266 {
  bottom: 266px !important;
}

.left-266 {
  left: 266px !important;
}

.top-267 {
  top: 267px !important;
}

.right-267 {
  right: 267px !important;
}

.bottom-267 {
  bottom: 267px !important;
}

.left-267 {
  left: 267px !important;
}

.top-268 {
  top: 268px !important;
}

.right-268 {
  right: 268px !important;
}

.bottom-268 {
  bottom: 268px !important;
}

.left-268 {
  left: 268px !important;
}

.top-269 {
  top: 269px !important;
}

.right-269 {
  right: 269px !important;
}

.bottom-269 {
  bottom: 269px !important;
}

.left-269 {
  left: 269px !important;
}

.top-270 {
  top: 270px !important;
}

.right-270 {
  right: 270px !important;
}

.bottom-270 {
  bottom: 270px !important;
}

.left-270 {
  left: 270px !important;
}

.top-271 {
  top: 271px !important;
}

.right-271 {
  right: 271px !important;
}

.bottom-271 {
  bottom: 271px !important;
}

.left-271 {
  left: 271px !important;
}

.top-272 {
  top: 272px !important;
}

.right-272 {
  right: 272px !important;
}

.bottom-272 {
  bottom: 272px !important;
}

.left-272 {
  left: 272px !important;
}

.top-273 {
  top: 273px !important;
}

.right-273 {
  right: 273px !important;
}

.bottom-273 {
  bottom: 273px !important;
}

.left-273 {
  left: 273px !important;
}

.top-274 {
  top: 274px !important;
}

.right-274 {
  right: 274px !important;
}

.bottom-274 {
  bottom: 274px !important;
}

.left-274 {
  left: 274px !important;
}

.top-275 {
  top: 275px !important;
}

.right-275 {
  right: 275px !important;
}

.bottom-275 {
  bottom: 275px !important;
}

.left-275 {
  left: 275px !important;
}

.top-276 {
  top: 276px !important;
}

.right-276 {
  right: 276px !important;
}

.bottom-276 {
  bottom: 276px !important;
}

.left-276 {
  left: 276px !important;
}

.top-277 {
  top: 277px !important;
}

.right-277 {
  right: 277px !important;
}

.bottom-277 {
  bottom: 277px !important;
}

.left-277 {
  left: 277px !important;
}

.top-278 {
  top: 278px !important;
}

.right-278 {
  right: 278px !important;
}

.bottom-278 {
  bottom: 278px !important;
}

.left-278 {
  left: 278px !important;
}

.top-279 {
  top: 279px !important;
}

.right-279 {
  right: 279px !important;
}

.bottom-279 {
  bottom: 279px !important;
}

.left-279 {
  left: 279px !important;
}

.top-280 {
  top: 280px !important;
}

.right-280 {
  right: 280px !important;
}

.bottom-280 {
  bottom: 280px !important;
}

.left-280 {
  left: 280px !important;
}

.top-281 {
  top: 281px !important;
}

.right-281 {
  right: 281px !important;
}

.bottom-281 {
  bottom: 281px !important;
}

.left-281 {
  left: 281px !important;
}

.top-282 {
  top: 282px !important;
}

.right-282 {
  right: 282px !important;
}

.bottom-282 {
  bottom: 282px !important;
}

.left-282 {
  left: 282px !important;
}

.top-283 {
  top: 283px !important;
}

.right-283 {
  right: 283px !important;
}

.bottom-283 {
  bottom: 283px !important;
}

.left-283 {
  left: 283px !important;
}

.top-284 {
  top: 284px !important;
}

.right-284 {
  right: 284px !important;
}

.bottom-284 {
  bottom: 284px !important;
}

.left-284 {
  left: 284px !important;
}

.top-285 {
  top: 285px !important;
}

.right-285 {
  right: 285px !important;
}

.bottom-285 {
  bottom: 285px !important;
}

.left-285 {
  left: 285px !important;
}

.top-286 {
  top: 286px !important;
}

.right-286 {
  right: 286px !important;
}

.bottom-286 {
  bottom: 286px !important;
}

.left-286 {
  left: 286px !important;
}

.top-287 {
  top: 287px !important;
}

.right-287 {
  right: 287px !important;
}

.bottom-287 {
  bottom: 287px !important;
}

.left-287 {
  left: 287px !important;
}

.top-288 {
  top: 288px !important;
}

.right-288 {
  right: 288px !important;
}

.bottom-288 {
  bottom: 288px !important;
}

.left-288 {
  left: 288px !important;
}

.top-289 {
  top: 289px !important;
}

.right-289 {
  right: 289px !important;
}

.bottom-289 {
  bottom: 289px !important;
}

.left-289 {
  left: 289px !important;
}

.top-290 {
  top: 290px !important;
}

.right-290 {
  right: 290px !important;
}

.bottom-290 {
  bottom: 290px !important;
}

.left-290 {
  left: 290px !important;
}

.top-291 {
  top: 291px !important;
}

.right-291 {
  right: 291px !important;
}

.bottom-291 {
  bottom: 291px !important;
}

.left-291 {
  left: 291px !important;
}

.top-292 {
  top: 292px !important;
}

.right-292 {
  right: 292px !important;
}

.bottom-292 {
  bottom: 292px !important;
}

.left-292 {
  left: 292px !important;
}

.top-293 {
  top: 293px !important;
}

.right-293 {
  right: 293px !important;
}

.bottom-293 {
  bottom: 293px !important;
}

.left-293 {
  left: 293px !important;
}

.top-294 {
  top: 294px !important;
}

.right-294 {
  right: 294px !important;
}

.bottom-294 {
  bottom: 294px !important;
}

.left-294 {
  left: 294px !important;
}

.top-295 {
  top: 295px !important;
}

.right-295 {
  right: 295px !important;
}

.bottom-295 {
  bottom: 295px !important;
}

.left-295 {
  left: 295px !important;
}

.top-296 {
  top: 296px !important;
}

.right-296 {
  right: 296px !important;
}

.bottom-296 {
  bottom: 296px !important;
}

.left-296 {
  left: 296px !important;
}

.top-297 {
  top: 297px !important;
}

.right-297 {
  right: 297px !important;
}

.bottom-297 {
  bottom: 297px !important;
}

.left-297 {
  left: 297px !important;
}

.top-298 {
  top: 298px !important;
}

.right-298 {
  right: 298px !important;
}

.bottom-298 {
  bottom: 298px !important;
}

.left-298 {
  left: 298px !important;
}

.top-299 {
  top: 299px !important;
}

.right-299 {
  right: 299px !important;
}

.bottom-299 {
  bottom: 299px !important;
}

.left-299 {
  left: 299px !important;
}

.top-300 {
  top: 300px !important;
}

.right-300 {
  right: 300px !important;
}

.bottom-300 {
  bottom: 300px !important;
}

.left-300 {
  left: 300px !important;
}

.top-301 {
  top: 301px !important;
}

.right-301 {
  right: 301px !important;
}

.bottom-301 {
  bottom: 301px !important;
}

.left-301 {
  left: 301px !important;
}

.top-302 {
  top: 302px !important;
}

.right-302 {
  right: 302px !important;
}

.bottom-302 {
  bottom: 302px !important;
}

.left-302 {
  left: 302px !important;
}

.top-303 {
  top: 303px !important;
}

.right-303 {
  right: 303px !important;
}

.bottom-303 {
  bottom: 303px !important;
}

.left-303 {
  left: 303px !important;
}

.top-304 {
  top: 304px !important;
}

.right-304 {
  right: 304px !important;
}

.bottom-304 {
  bottom: 304px !important;
}

.left-304 {
  left: 304px !important;
}

.top-305 {
  top: 305px !important;
}

.right-305 {
  right: 305px !important;
}

.bottom-305 {
  bottom: 305px !important;
}

.left-305 {
  left: 305px !important;
}

.top-306 {
  top: 306px !important;
}

.right-306 {
  right: 306px !important;
}

.bottom-306 {
  bottom: 306px !important;
}

.left-306 {
  left: 306px !important;
}

.top-307 {
  top: 307px !important;
}

.right-307 {
  right: 307px !important;
}

.bottom-307 {
  bottom: 307px !important;
}

.left-307 {
  left: 307px !important;
}

.top-308 {
  top: 308px !important;
}

.right-308 {
  right: 308px !important;
}

.bottom-308 {
  bottom: 308px !important;
}

.left-308 {
  left: 308px !important;
}

.top-309 {
  top: 309px !important;
}

.right-309 {
  right: 309px !important;
}

.bottom-309 {
  bottom: 309px !important;
}

.left-309 {
  left: 309px !important;
}

.top-310 {
  top: 310px !important;
}

.right-310 {
  right: 310px !important;
}

.bottom-310 {
  bottom: 310px !important;
}

.left-310 {
  left: 310px !important;
}

.top-311 {
  top: 311px !important;
}

.right-311 {
  right: 311px !important;
}

.bottom-311 {
  bottom: 311px !important;
}

.left-311 {
  left: 311px !important;
}

.top-312 {
  top: 312px !important;
}

.right-312 {
  right: 312px !important;
}

.bottom-312 {
  bottom: 312px !important;
}

.left-312 {
  left: 312px !important;
}

.top-313 {
  top: 313px !important;
}

.right-313 {
  right: 313px !important;
}

.bottom-313 {
  bottom: 313px !important;
}

.left-313 {
  left: 313px !important;
}

.top-314 {
  top: 314px !important;
}

.right-314 {
  right: 314px !important;
}

.bottom-314 {
  bottom: 314px !important;
}

.left-314 {
  left: 314px !important;
}

.top-315 {
  top: 315px !important;
}

.right-315 {
  right: 315px !important;
}

.bottom-315 {
  bottom: 315px !important;
}

.left-315 {
  left: 315px !important;
}

.top-316 {
  top: 316px !important;
}

.right-316 {
  right: 316px !important;
}

.bottom-316 {
  bottom: 316px !important;
}

.left-316 {
  left: 316px !important;
}

.top-317 {
  top: 317px !important;
}

.right-317 {
  right: 317px !important;
}

.bottom-317 {
  bottom: 317px !important;
}

.left-317 {
  left: 317px !important;
}

.top-318 {
  top: 318px !important;
}

.right-318 {
  right: 318px !important;
}

.bottom-318 {
  bottom: 318px !important;
}

.left-318 {
  left: 318px !important;
}

.top-319 {
  top: 319px !important;
}

.right-319 {
  right: 319px !important;
}

.bottom-319 {
  bottom: 319px !important;
}

.left-319 {
  left: 319px !important;
}

.top-320 {
  top: 320px !important;
}

.right-320 {
  right: 320px !important;
}

.bottom-320 {
  bottom: 320px !important;
}

.left-320 {
  left: 320px !important;
}

.top-321 {
  top: 321px !important;
}

.right-321 {
  right: 321px !important;
}

.bottom-321 {
  bottom: 321px !important;
}

.left-321 {
  left: 321px !important;
}

.top-322 {
  top: 322px !important;
}

.right-322 {
  right: 322px !important;
}

.bottom-322 {
  bottom: 322px !important;
}

.left-322 {
  left: 322px !important;
}

.top-323 {
  top: 323px !important;
}

.right-323 {
  right: 323px !important;
}

.bottom-323 {
  bottom: 323px !important;
}

.left-323 {
  left: 323px !important;
}

.top-324 {
  top: 324px !important;
}

.right-324 {
  right: 324px !important;
}

.bottom-324 {
  bottom: 324px !important;
}

.left-324 {
  left: 324px !important;
}

.top-325 {
  top: 325px !important;
}

.right-325 {
  right: 325px !important;
}

.bottom-325 {
  bottom: 325px !important;
}

.left-325 {
  left: 325px !important;
}

.top-326 {
  top: 326px !important;
}

.right-326 {
  right: 326px !important;
}

.bottom-326 {
  bottom: 326px !important;
}

.left-326 {
  left: 326px !important;
}

.top-327 {
  top: 327px !important;
}

.right-327 {
  right: 327px !important;
}

.bottom-327 {
  bottom: 327px !important;
}

.left-327 {
  left: 327px !important;
}

.top-328 {
  top: 328px !important;
}

.right-328 {
  right: 328px !important;
}

.bottom-328 {
  bottom: 328px !important;
}

.left-328 {
  left: 328px !important;
}

.top-329 {
  top: 329px !important;
}

.right-329 {
  right: 329px !important;
}

.bottom-329 {
  bottom: 329px !important;
}

.left-329 {
  left: 329px !important;
}

.top-330 {
  top: 330px !important;
}

.right-330 {
  right: 330px !important;
}

.bottom-330 {
  bottom: 330px !important;
}

.left-330 {
  left: 330px !important;
}

.top-331 {
  top: 331px !important;
}

.right-331 {
  right: 331px !important;
}

.bottom-331 {
  bottom: 331px !important;
}

.left-331 {
  left: 331px !important;
}

.top-332 {
  top: 332px !important;
}

.right-332 {
  right: 332px !important;
}

.bottom-332 {
  bottom: 332px !important;
}

.left-332 {
  left: 332px !important;
}

.top-333 {
  top: 333px !important;
}

.right-333 {
  right: 333px !important;
}

.bottom-333 {
  bottom: 333px !important;
}

.left-333 {
  left: 333px !important;
}

.top-334 {
  top: 334px !important;
}

.right-334 {
  right: 334px !important;
}

.bottom-334 {
  bottom: 334px !important;
}

.left-334 {
  left: 334px !important;
}

.top-335 {
  top: 335px !important;
}

.right-335 {
  right: 335px !important;
}

.bottom-335 {
  bottom: 335px !important;
}

.left-335 {
  left: 335px !important;
}

.top-336 {
  top: 336px !important;
}

.right-336 {
  right: 336px !important;
}

.bottom-336 {
  bottom: 336px !important;
}

.left-336 {
  left: 336px !important;
}

.top-337 {
  top: 337px !important;
}

.right-337 {
  right: 337px !important;
}

.bottom-337 {
  bottom: 337px !important;
}

.left-337 {
  left: 337px !important;
}

.top-338 {
  top: 338px !important;
}

.right-338 {
  right: 338px !important;
}

.bottom-338 {
  bottom: 338px !important;
}

.left-338 {
  left: 338px !important;
}

.top-339 {
  top: 339px !important;
}

.right-339 {
  right: 339px !important;
}

.bottom-339 {
  bottom: 339px !important;
}

.left-339 {
  left: 339px !important;
}

.top-340 {
  top: 340px !important;
}

.right-340 {
  right: 340px !important;
}

.bottom-340 {
  bottom: 340px !important;
}

.left-340 {
  left: 340px !important;
}

.top-341 {
  top: 341px !important;
}

.right-341 {
  right: 341px !important;
}

.bottom-341 {
  bottom: 341px !important;
}

.left-341 {
  left: 341px !important;
}

.top-342 {
  top: 342px !important;
}

.right-342 {
  right: 342px !important;
}

.bottom-342 {
  bottom: 342px !important;
}

.left-342 {
  left: 342px !important;
}

.top-343 {
  top: 343px !important;
}

.right-343 {
  right: 343px !important;
}

.bottom-343 {
  bottom: 343px !important;
}

.left-343 {
  left: 343px !important;
}

.top-344 {
  top: 344px !important;
}

.right-344 {
  right: 344px !important;
}

.bottom-344 {
  bottom: 344px !important;
}

.left-344 {
  left: 344px !important;
}

.top-345 {
  top: 345px !important;
}

.right-345 {
  right: 345px !important;
}

.bottom-345 {
  bottom: 345px !important;
}

.left-345 {
  left: 345px !important;
}

.top-346 {
  top: 346px !important;
}

.right-346 {
  right: 346px !important;
}

.bottom-346 {
  bottom: 346px !important;
}

.left-346 {
  left: 346px !important;
}

.top-347 {
  top: 347px !important;
}

.right-347 {
  right: 347px !important;
}

.bottom-347 {
  bottom: 347px !important;
}

.left-347 {
  left: 347px !important;
}

.top-348 {
  top: 348px !important;
}

.right-348 {
  right: 348px !important;
}

.bottom-348 {
  bottom: 348px !important;
}

.left-348 {
  left: 348px !important;
}

.top-349 {
  top: 349px !important;
}

.right-349 {
  right: 349px !important;
}

.bottom-349 {
  bottom: 349px !important;
}

.left-349 {
  left: 349px !important;
}

.top-350 {
  top: 350px !important;
}

.right-350 {
  right: 350px !important;
}

.bottom-350 {
  bottom: 350px !important;
}

.left-350 {
  left: 350px !important;
}

.top-351 {
  top: 351px !important;
}

.right-351 {
  right: 351px !important;
}

.bottom-351 {
  bottom: 351px !important;
}

.left-351 {
  left: 351px !important;
}

.top-352 {
  top: 352px !important;
}

.right-352 {
  right: 352px !important;
}

.bottom-352 {
  bottom: 352px !important;
}

.left-352 {
  left: 352px !important;
}

.top-353 {
  top: 353px !important;
}

.right-353 {
  right: 353px !important;
}

.bottom-353 {
  bottom: 353px !important;
}

.left-353 {
  left: 353px !important;
}

.top-354 {
  top: 354px !important;
}

.right-354 {
  right: 354px !important;
}

.bottom-354 {
  bottom: 354px !important;
}

.left-354 {
  left: 354px !important;
}

.top-355 {
  top: 355px !important;
}

.right-355 {
  right: 355px !important;
}

.bottom-355 {
  bottom: 355px !important;
}

.left-355 {
  left: 355px !important;
}

.top-356 {
  top: 356px !important;
}

.right-356 {
  right: 356px !important;
}

.bottom-356 {
  bottom: 356px !important;
}

.left-356 {
  left: 356px !important;
}

.top-357 {
  top: 357px !important;
}

.right-357 {
  right: 357px !important;
}

.bottom-357 {
  bottom: 357px !important;
}

.left-357 {
  left: 357px !important;
}

.top-358 {
  top: 358px !important;
}

.right-358 {
  right: 358px !important;
}

.bottom-358 {
  bottom: 358px !important;
}

.left-358 {
  left: 358px !important;
}

.top-359 {
  top: 359px !important;
}

.right-359 {
  right: 359px !important;
}

.bottom-359 {
  bottom: 359px !important;
}

.left-359 {
  left: 359px !important;
}

.top-360 {
  top: 360px !important;
}

.right-360 {
  right: 360px !important;
}

.bottom-360 {
  bottom: 360px !important;
}

.left-360 {
  left: 360px !important;
}

.top-361 {
  top: 361px !important;
}

.right-361 {
  right: 361px !important;
}

.bottom-361 {
  bottom: 361px !important;
}

.left-361 {
  left: 361px !important;
}

.top-362 {
  top: 362px !important;
}

.right-362 {
  right: 362px !important;
}

.bottom-362 {
  bottom: 362px !important;
}

.left-362 {
  left: 362px !important;
}

.top-363 {
  top: 363px !important;
}

.right-363 {
  right: 363px !important;
}

.bottom-363 {
  bottom: 363px !important;
}

.left-363 {
  left: 363px !important;
}

.top-364 {
  top: 364px !important;
}

.right-364 {
  right: 364px !important;
}

.bottom-364 {
  bottom: 364px !important;
}

.left-364 {
  left: 364px !important;
}

.top-365 {
  top: 365px !important;
}

.right-365 {
  right: 365px !important;
}

.bottom-365 {
  bottom: 365px !important;
}

.left-365 {
  left: 365px !important;
}

.top-366 {
  top: 366px !important;
}

.right-366 {
  right: 366px !important;
}

.bottom-366 {
  bottom: 366px !important;
}

.left-366 {
  left: 366px !important;
}

.top-367 {
  top: 367px !important;
}

.right-367 {
  right: 367px !important;
}

.bottom-367 {
  bottom: 367px !important;
}

.left-367 {
  left: 367px !important;
}

.top-368 {
  top: 368px !important;
}

.right-368 {
  right: 368px !important;
}

.bottom-368 {
  bottom: 368px !important;
}

.left-368 {
  left: 368px !important;
}

.top-369 {
  top: 369px !important;
}

.right-369 {
  right: 369px !important;
}

.bottom-369 {
  bottom: 369px !important;
}

.left-369 {
  left: 369px !important;
}

.top-370 {
  top: 370px !important;
}

.right-370 {
  right: 370px !important;
}

.bottom-370 {
  bottom: 370px !important;
}

.left-370 {
  left: 370px !important;
}

.top-371 {
  top: 371px !important;
}

.right-371 {
  right: 371px !important;
}

.bottom-371 {
  bottom: 371px !important;
}

.left-371 {
  left: 371px !important;
}

.top-372 {
  top: 372px !important;
}

.right-372 {
  right: 372px !important;
}

.bottom-372 {
  bottom: 372px !important;
}

.left-372 {
  left: 372px !important;
}

.top-373 {
  top: 373px !important;
}

.right-373 {
  right: 373px !important;
}

.bottom-373 {
  bottom: 373px !important;
}

.left-373 {
  left: 373px !important;
}

.top-374 {
  top: 374px !important;
}

.right-374 {
  right: 374px !important;
}

.bottom-374 {
  bottom: 374px !important;
}

.left-374 {
  left: 374px !important;
}

.top-375 {
  top: 375px !important;
}

.right-375 {
  right: 375px !important;
}

.bottom-375 {
  bottom: 375px !important;
}

.left-375 {
  left: 375px !important;
}

.top-376 {
  top: 376px !important;
}

.right-376 {
  right: 376px !important;
}

.bottom-376 {
  bottom: 376px !important;
}

.left-376 {
  left: 376px !important;
}

.top-377 {
  top: 377px !important;
}

.right-377 {
  right: 377px !important;
}

.bottom-377 {
  bottom: 377px !important;
}

.left-377 {
  left: 377px !important;
}

.top-378 {
  top: 378px !important;
}

.right-378 {
  right: 378px !important;
}

.bottom-378 {
  bottom: 378px !important;
}

.left-378 {
  left: 378px !important;
}

.top-379 {
  top: 379px !important;
}

.right-379 {
  right: 379px !important;
}

.bottom-379 {
  bottom: 379px !important;
}

.left-379 {
  left: 379px !important;
}

.top-380 {
  top: 380px !important;
}

.right-380 {
  right: 380px !important;
}

.bottom-380 {
  bottom: 380px !important;
}

.left-380 {
  left: 380px !important;
}

.top-381 {
  top: 381px !important;
}

.right-381 {
  right: 381px !important;
}

.bottom-381 {
  bottom: 381px !important;
}

.left-381 {
  left: 381px !important;
}

.top-382 {
  top: 382px !important;
}

.right-382 {
  right: 382px !important;
}

.bottom-382 {
  bottom: 382px !important;
}

.left-382 {
  left: 382px !important;
}

.top-383 {
  top: 383px !important;
}

.right-383 {
  right: 383px !important;
}

.bottom-383 {
  bottom: 383px !important;
}

.left-383 {
  left: 383px !important;
}

.top-384 {
  top: 384px !important;
}

.right-384 {
  right: 384px !important;
}

.bottom-384 {
  bottom: 384px !important;
}

.left-384 {
  left: 384px !important;
}

.top-385 {
  top: 385px !important;
}

.right-385 {
  right: 385px !important;
}

.bottom-385 {
  bottom: 385px !important;
}

.left-385 {
  left: 385px !important;
}

.top-386 {
  top: 386px !important;
}

.right-386 {
  right: 386px !important;
}

.bottom-386 {
  bottom: 386px !important;
}

.left-386 {
  left: 386px !important;
}

.top-387 {
  top: 387px !important;
}

.right-387 {
  right: 387px !important;
}

.bottom-387 {
  bottom: 387px !important;
}

.left-387 {
  left: 387px !important;
}

.top-388 {
  top: 388px !important;
}

.right-388 {
  right: 388px !important;
}

.bottom-388 {
  bottom: 388px !important;
}

.left-388 {
  left: 388px !important;
}

.top-389 {
  top: 389px !important;
}

.right-389 {
  right: 389px !important;
}

.bottom-389 {
  bottom: 389px !important;
}

.left-389 {
  left: 389px !important;
}

.top-390 {
  top: 390px !important;
}

.right-390 {
  right: 390px !important;
}

.bottom-390 {
  bottom: 390px !important;
}

.left-390 {
  left: 390px !important;
}

.top-391 {
  top: 391px !important;
}

.right-391 {
  right: 391px !important;
}

.bottom-391 {
  bottom: 391px !important;
}

.left-391 {
  left: 391px !important;
}

.top-392 {
  top: 392px !important;
}

.right-392 {
  right: 392px !important;
}

.bottom-392 {
  bottom: 392px !important;
}

.left-392 {
  left: 392px !important;
}

.top-393 {
  top: 393px !important;
}

.right-393 {
  right: 393px !important;
}

.bottom-393 {
  bottom: 393px !important;
}

.left-393 {
  left: 393px !important;
}

.top-394 {
  top: 394px !important;
}

.right-394 {
  right: 394px !important;
}

.bottom-394 {
  bottom: 394px !important;
}

.left-394 {
  left: 394px !important;
}

.top-395 {
  top: 395px !important;
}

.right-395 {
  right: 395px !important;
}

.bottom-395 {
  bottom: 395px !important;
}

.left-395 {
  left: 395px !important;
}

.top-396 {
  top: 396px !important;
}

.right-396 {
  right: 396px !important;
}

.bottom-396 {
  bottom: 396px !important;
}

.left-396 {
  left: 396px !important;
}

.top-397 {
  top: 397px !important;
}

.right-397 {
  right: 397px !important;
}

.bottom-397 {
  bottom: 397px !important;
}

.left-397 {
  left: 397px !important;
}

.top-398 {
  top: 398px !important;
}

.right-398 {
  right: 398px !important;
}

.bottom-398 {
  bottom: 398px !important;
}

.left-398 {
  left: 398px !important;
}

.top-399 {
  top: 399px !important;
}

.right-399 {
  right: 399px !important;
}

.bottom-399 {
  bottom: 399px !important;
}

.left-399 {
  left: 399px !important;
}

.top-400 {
  top: 400px !important;
}

.right-400 {
  right: 400px !important;
}

.bottom-400 {
  bottom: 400px !important;
}

.left-400 {
  left: 400px !important;
}

.top-401 {
  top: 401px !important;
}

.right-401 {
  right: 401px !important;
}

.bottom-401 {
  bottom: 401px !important;
}

.left-401 {
  left: 401px !important;
}

.top-402 {
  top: 402px !important;
}

.right-402 {
  right: 402px !important;
}

.bottom-402 {
  bottom: 402px !important;
}

.left-402 {
  left: 402px !important;
}

.top-403 {
  top: 403px !important;
}

.right-403 {
  right: 403px !important;
}

.bottom-403 {
  bottom: 403px !important;
}

.left-403 {
  left: 403px !important;
}

.top-404 {
  top: 404px !important;
}

.right-404 {
  right: 404px !important;
}

.bottom-404 {
  bottom: 404px !important;
}

.left-404 {
  left: 404px !important;
}

.top-405 {
  top: 405px !important;
}

.right-405 {
  right: 405px !important;
}

.bottom-405 {
  bottom: 405px !important;
}

.left-405 {
  left: 405px !important;
}

.top-406 {
  top: 406px !important;
}

.right-406 {
  right: 406px !important;
}

.bottom-406 {
  bottom: 406px !important;
}

.left-406 {
  left: 406px !important;
}

.top-407 {
  top: 407px !important;
}

.right-407 {
  right: 407px !important;
}

.bottom-407 {
  bottom: 407px !important;
}

.left-407 {
  left: 407px !important;
}

.top-408 {
  top: 408px !important;
}

.right-408 {
  right: 408px !important;
}

.bottom-408 {
  bottom: 408px !important;
}

.left-408 {
  left: 408px !important;
}

.top-409 {
  top: 409px !important;
}

.right-409 {
  right: 409px !important;
}

.bottom-409 {
  bottom: 409px !important;
}

.left-409 {
  left: 409px !important;
}

.top-410 {
  top: 410px !important;
}

.right-410 {
  right: 410px !important;
}

.bottom-410 {
  bottom: 410px !important;
}

.left-410 {
  left: 410px !important;
}

.top-411 {
  top: 411px !important;
}

.right-411 {
  right: 411px !important;
}

.bottom-411 {
  bottom: 411px !important;
}

.left-411 {
  left: 411px !important;
}

.top-412 {
  top: 412px !important;
}

.right-412 {
  right: 412px !important;
}

.bottom-412 {
  bottom: 412px !important;
}

.left-412 {
  left: 412px !important;
}

.top-413 {
  top: 413px !important;
}

.right-413 {
  right: 413px !important;
}

.bottom-413 {
  bottom: 413px !important;
}

.left-413 {
  left: 413px !important;
}

.top-414 {
  top: 414px !important;
}

.right-414 {
  right: 414px !important;
}

.bottom-414 {
  bottom: 414px !important;
}

.left-414 {
  left: 414px !important;
}

.top-415 {
  top: 415px !important;
}

.right-415 {
  right: 415px !important;
}

.bottom-415 {
  bottom: 415px !important;
}

.left-415 {
  left: 415px !important;
}

.top-416 {
  top: 416px !important;
}

.right-416 {
  right: 416px !important;
}

.bottom-416 {
  bottom: 416px !important;
}

.left-416 {
  left: 416px !important;
}

.top-417 {
  top: 417px !important;
}

.right-417 {
  right: 417px !important;
}

.bottom-417 {
  bottom: 417px !important;
}

.left-417 {
  left: 417px !important;
}

.top-418 {
  top: 418px !important;
}

.right-418 {
  right: 418px !important;
}

.bottom-418 {
  bottom: 418px !important;
}

.left-418 {
  left: 418px !important;
}

.top-419 {
  top: 419px !important;
}

.right-419 {
  right: 419px !important;
}

.bottom-419 {
  bottom: 419px !important;
}

.left-419 {
  left: 419px !important;
}

.top-420 {
  top: 420px !important;
}

.right-420 {
  right: 420px !important;
}

.bottom-420 {
  bottom: 420px !important;
}

.left-420 {
  left: 420px !important;
}

.top-421 {
  top: 421px !important;
}

.right-421 {
  right: 421px !important;
}

.bottom-421 {
  bottom: 421px !important;
}

.left-421 {
  left: 421px !important;
}

.top-422 {
  top: 422px !important;
}

.right-422 {
  right: 422px !important;
}

.bottom-422 {
  bottom: 422px !important;
}

.left-422 {
  left: 422px !important;
}

.top-423 {
  top: 423px !important;
}

.right-423 {
  right: 423px !important;
}

.bottom-423 {
  bottom: 423px !important;
}

.left-423 {
  left: 423px !important;
}

.top-424 {
  top: 424px !important;
}

.right-424 {
  right: 424px !important;
}

.bottom-424 {
  bottom: 424px !important;
}

.left-424 {
  left: 424px !important;
}

.top-425 {
  top: 425px !important;
}

.right-425 {
  right: 425px !important;
}

.bottom-425 {
  bottom: 425px !important;
}

.left-425 {
  left: 425px !important;
}

.top-426 {
  top: 426px !important;
}

.right-426 {
  right: 426px !important;
}

.bottom-426 {
  bottom: 426px !important;
}

.left-426 {
  left: 426px !important;
}

.top-427 {
  top: 427px !important;
}

.right-427 {
  right: 427px !important;
}

.bottom-427 {
  bottom: 427px !important;
}

.left-427 {
  left: 427px !important;
}

.top-428 {
  top: 428px !important;
}

.right-428 {
  right: 428px !important;
}

.bottom-428 {
  bottom: 428px !important;
}

.left-428 {
  left: 428px !important;
}

.top-429 {
  top: 429px !important;
}

.right-429 {
  right: 429px !important;
}

.bottom-429 {
  bottom: 429px !important;
}

.left-429 {
  left: 429px !important;
}

.top-430 {
  top: 430px !important;
}

.right-430 {
  right: 430px !important;
}

.bottom-430 {
  bottom: 430px !important;
}

.left-430 {
  left: 430px !important;
}

.top-431 {
  top: 431px !important;
}

.right-431 {
  right: 431px !important;
}

.bottom-431 {
  bottom: 431px !important;
}

.left-431 {
  left: 431px !important;
}

.top-432 {
  top: 432px !important;
}

.right-432 {
  right: 432px !important;
}

.bottom-432 {
  bottom: 432px !important;
}

.left-432 {
  left: 432px !important;
}

.top-433 {
  top: 433px !important;
}

.right-433 {
  right: 433px !important;
}

.bottom-433 {
  bottom: 433px !important;
}

.left-433 {
  left: 433px !important;
}

.top-434 {
  top: 434px !important;
}

.right-434 {
  right: 434px !important;
}

.bottom-434 {
  bottom: 434px !important;
}

.left-434 {
  left: 434px !important;
}

.top-435 {
  top: 435px !important;
}

.right-435 {
  right: 435px !important;
}

.bottom-435 {
  bottom: 435px !important;
}

.left-435 {
  left: 435px !important;
}

.top-436 {
  top: 436px !important;
}

.right-436 {
  right: 436px !important;
}

.bottom-436 {
  bottom: 436px !important;
}

.left-436 {
  left: 436px !important;
}

.top-437 {
  top: 437px !important;
}

.right-437 {
  right: 437px !important;
}

.bottom-437 {
  bottom: 437px !important;
}

.left-437 {
  left: 437px !important;
}

.top-438 {
  top: 438px !important;
}

.right-438 {
  right: 438px !important;
}

.bottom-438 {
  bottom: 438px !important;
}

.left-438 {
  left: 438px !important;
}

.top-439 {
  top: 439px !important;
}

.right-439 {
  right: 439px !important;
}

.bottom-439 {
  bottom: 439px !important;
}

.left-439 {
  left: 439px !important;
}

.top-440 {
  top: 440px !important;
}

.right-440 {
  right: 440px !important;
}

.bottom-440 {
  bottom: 440px !important;
}

.left-440 {
  left: 440px !important;
}

.top-441 {
  top: 441px !important;
}

.right-441 {
  right: 441px !important;
}

.bottom-441 {
  bottom: 441px !important;
}

.left-441 {
  left: 441px !important;
}

.top-442 {
  top: 442px !important;
}

.right-442 {
  right: 442px !important;
}

.bottom-442 {
  bottom: 442px !important;
}

.left-442 {
  left: 442px !important;
}

.top-443 {
  top: 443px !important;
}

.right-443 {
  right: 443px !important;
}

.bottom-443 {
  bottom: 443px !important;
}

.left-443 {
  left: 443px !important;
}

.top-444 {
  top: 444px !important;
}

.right-444 {
  right: 444px !important;
}

.bottom-444 {
  bottom: 444px !important;
}

.left-444 {
  left: 444px !important;
}

.top-445 {
  top: 445px !important;
}

.right-445 {
  right: 445px !important;
}

.bottom-445 {
  bottom: 445px !important;
}

.left-445 {
  left: 445px !important;
}

.top-446 {
  top: 446px !important;
}

.right-446 {
  right: 446px !important;
}

.bottom-446 {
  bottom: 446px !important;
}

.left-446 {
  left: 446px !important;
}

.top-447 {
  top: 447px !important;
}

.right-447 {
  right: 447px !important;
}

.bottom-447 {
  bottom: 447px !important;
}

.left-447 {
  left: 447px !important;
}

.top-448 {
  top: 448px !important;
}

.right-448 {
  right: 448px !important;
}

.bottom-448 {
  bottom: 448px !important;
}

.left-448 {
  left: 448px !important;
}

.top-449 {
  top: 449px !important;
}

.right-449 {
  right: 449px !important;
}

.bottom-449 {
  bottom: 449px !important;
}

.left-449 {
  left: 449px !important;
}

.top-450 {
  top: 450px !important;
}

.right-450 {
  right: 450px !important;
}

.bottom-450 {
  bottom: 450px !important;
}

.left-450 {
  left: 450px !important;
}

.top-451 {
  top: 451px !important;
}

.right-451 {
  right: 451px !important;
}

.bottom-451 {
  bottom: 451px !important;
}

.left-451 {
  left: 451px !important;
}

.top-452 {
  top: 452px !important;
}

.right-452 {
  right: 452px !important;
}

.bottom-452 {
  bottom: 452px !important;
}

.left-452 {
  left: 452px !important;
}

.top-453 {
  top: 453px !important;
}

.right-453 {
  right: 453px !important;
}

.bottom-453 {
  bottom: 453px !important;
}

.left-453 {
  left: 453px !important;
}

.top-454 {
  top: 454px !important;
}

.right-454 {
  right: 454px !important;
}

.bottom-454 {
  bottom: 454px !important;
}

.left-454 {
  left: 454px !important;
}

.top-455 {
  top: 455px !important;
}

.right-455 {
  right: 455px !important;
}

.bottom-455 {
  bottom: 455px !important;
}

.left-455 {
  left: 455px !important;
}

.top-456 {
  top: 456px !important;
}

.right-456 {
  right: 456px !important;
}

.bottom-456 {
  bottom: 456px !important;
}

.left-456 {
  left: 456px !important;
}

.top-457 {
  top: 457px !important;
}

.right-457 {
  right: 457px !important;
}

.bottom-457 {
  bottom: 457px !important;
}

.left-457 {
  left: 457px !important;
}

.top-458 {
  top: 458px !important;
}

.right-458 {
  right: 458px !important;
}

.bottom-458 {
  bottom: 458px !important;
}

.left-458 {
  left: 458px !important;
}

.top-459 {
  top: 459px !important;
}

.right-459 {
  right: 459px !important;
}

.bottom-459 {
  bottom: 459px !important;
}

.left-459 {
  left: 459px !important;
}

.top-460 {
  top: 460px !important;
}

.right-460 {
  right: 460px !important;
}

.bottom-460 {
  bottom: 460px !important;
}

.left-460 {
  left: 460px !important;
}

.top-461 {
  top: 461px !important;
}

.right-461 {
  right: 461px !important;
}

.bottom-461 {
  bottom: 461px !important;
}

.left-461 {
  left: 461px !important;
}

.top-462 {
  top: 462px !important;
}

.right-462 {
  right: 462px !important;
}

.bottom-462 {
  bottom: 462px !important;
}

.left-462 {
  left: 462px !important;
}

.top-463 {
  top: 463px !important;
}

.right-463 {
  right: 463px !important;
}

.bottom-463 {
  bottom: 463px !important;
}

.left-463 {
  left: 463px !important;
}

.top-464 {
  top: 464px !important;
}

.right-464 {
  right: 464px !important;
}

.bottom-464 {
  bottom: 464px !important;
}

.left-464 {
  left: 464px !important;
}

.top-465 {
  top: 465px !important;
}

.right-465 {
  right: 465px !important;
}

.bottom-465 {
  bottom: 465px !important;
}

.left-465 {
  left: 465px !important;
}

.top-466 {
  top: 466px !important;
}

.right-466 {
  right: 466px !important;
}

.bottom-466 {
  bottom: 466px !important;
}

.left-466 {
  left: 466px !important;
}

.top-467 {
  top: 467px !important;
}

.right-467 {
  right: 467px !important;
}

.bottom-467 {
  bottom: 467px !important;
}

.left-467 {
  left: 467px !important;
}

.top-468 {
  top: 468px !important;
}

.right-468 {
  right: 468px !important;
}

.bottom-468 {
  bottom: 468px !important;
}

.left-468 {
  left: 468px !important;
}

.top-469 {
  top: 469px !important;
}

.right-469 {
  right: 469px !important;
}

.bottom-469 {
  bottom: 469px !important;
}

.left-469 {
  left: 469px !important;
}

.top-470 {
  top: 470px !important;
}

.right-470 {
  right: 470px !important;
}

.bottom-470 {
  bottom: 470px !important;
}

.left-470 {
  left: 470px !important;
}

.top-471 {
  top: 471px !important;
}

.right-471 {
  right: 471px !important;
}

.bottom-471 {
  bottom: 471px !important;
}

.left-471 {
  left: 471px !important;
}

.top-472 {
  top: 472px !important;
}

.right-472 {
  right: 472px !important;
}

.bottom-472 {
  bottom: 472px !important;
}

.left-472 {
  left: 472px !important;
}

.top-473 {
  top: 473px !important;
}

.right-473 {
  right: 473px !important;
}

.bottom-473 {
  bottom: 473px !important;
}

.left-473 {
  left: 473px !important;
}

.top-474 {
  top: 474px !important;
}

.right-474 {
  right: 474px !important;
}

.bottom-474 {
  bottom: 474px !important;
}

.left-474 {
  left: 474px !important;
}

.top-475 {
  top: 475px !important;
}

.right-475 {
  right: 475px !important;
}

.bottom-475 {
  bottom: 475px !important;
}

.left-475 {
  left: 475px !important;
}

.top-476 {
  top: 476px !important;
}

.right-476 {
  right: 476px !important;
}

.bottom-476 {
  bottom: 476px !important;
}

.left-476 {
  left: 476px !important;
}

.top-477 {
  top: 477px !important;
}

.right-477 {
  right: 477px !important;
}

.bottom-477 {
  bottom: 477px !important;
}

.left-477 {
  left: 477px !important;
}

.top-478 {
  top: 478px !important;
}

.right-478 {
  right: 478px !important;
}

.bottom-478 {
  bottom: 478px !important;
}

.left-478 {
  left: 478px !important;
}

.top-479 {
  top: 479px !important;
}

.right-479 {
  right: 479px !important;
}

.bottom-479 {
  bottom: 479px !important;
}

.left-479 {
  left: 479px !important;
}

.top-480 {
  top: 480px !important;
}

.right-480 {
  right: 480px !important;
}

.bottom-480 {
  bottom: 480px !important;
}

.left-480 {
  left: 480px !important;
}

.top-481 {
  top: 481px !important;
}

.right-481 {
  right: 481px !important;
}

.bottom-481 {
  bottom: 481px !important;
}

.left-481 {
  left: 481px !important;
}

.top-482 {
  top: 482px !important;
}

.right-482 {
  right: 482px !important;
}

.bottom-482 {
  bottom: 482px !important;
}

.left-482 {
  left: 482px !important;
}

.top-483 {
  top: 483px !important;
}

.right-483 {
  right: 483px !important;
}

.bottom-483 {
  bottom: 483px !important;
}

.left-483 {
  left: 483px !important;
}

.top-484 {
  top: 484px !important;
}

.right-484 {
  right: 484px !important;
}

.bottom-484 {
  bottom: 484px !important;
}

.left-484 {
  left: 484px !important;
}

.top-485 {
  top: 485px !important;
}

.right-485 {
  right: 485px !important;
}

.bottom-485 {
  bottom: 485px !important;
}

.left-485 {
  left: 485px !important;
}

.top-486 {
  top: 486px !important;
}

.right-486 {
  right: 486px !important;
}

.bottom-486 {
  bottom: 486px !important;
}

.left-486 {
  left: 486px !important;
}

.top-487 {
  top: 487px !important;
}

.right-487 {
  right: 487px !important;
}

.bottom-487 {
  bottom: 487px !important;
}

.left-487 {
  left: 487px !important;
}

.top-488 {
  top: 488px !important;
}

.right-488 {
  right: 488px !important;
}

.bottom-488 {
  bottom: 488px !important;
}

.left-488 {
  left: 488px !important;
}

.top-489 {
  top: 489px !important;
}

.right-489 {
  right: 489px !important;
}

.bottom-489 {
  bottom: 489px !important;
}

.left-489 {
  left: 489px !important;
}

.top-490 {
  top: 490px !important;
}

.right-490 {
  right: 490px !important;
}

.bottom-490 {
  bottom: 490px !important;
}

.left-490 {
  left: 490px !important;
}

.top-491 {
  top: 491px !important;
}

.right-491 {
  right: 491px !important;
}

.bottom-491 {
  bottom: 491px !important;
}

.left-491 {
  left: 491px !important;
}

.top-492 {
  top: 492px !important;
}

.right-492 {
  right: 492px !important;
}

.bottom-492 {
  bottom: 492px !important;
}

.left-492 {
  left: 492px !important;
}

.top-493 {
  top: 493px !important;
}

.right-493 {
  right: 493px !important;
}

.bottom-493 {
  bottom: 493px !important;
}

.left-493 {
  left: 493px !important;
}

.top-494 {
  top: 494px !important;
}

.right-494 {
  right: 494px !important;
}

.bottom-494 {
  bottom: 494px !important;
}

.left-494 {
  left: 494px !important;
}

.top-495 {
  top: 495px !important;
}

.right-495 {
  right: 495px !important;
}

.bottom-495 {
  bottom: 495px !important;
}

.left-495 {
  left: 495px !important;
}

.top-496 {
  top: 496px !important;
}

.right-496 {
  right: 496px !important;
}

.bottom-496 {
  bottom: 496px !important;
}

.left-496 {
  left: 496px !important;
}

.top-497 {
  top: 497px !important;
}

.right-497 {
  right: 497px !important;
}

.bottom-497 {
  bottom: 497px !important;
}

.left-497 {
  left: 497px !important;
}

.top-498 {
  top: 498px !important;
}

.right-498 {
  right: 498px !important;
}

.bottom-498 {
  bottom: 498px !important;
}

.left-498 {
  left: 498px !important;
}

.top-499 {
  top: 499px !important;
}

.right-499 {
  right: 499px !important;
}

.bottom-499 {
  bottom: 499px !important;
}

.left-499 {
  left: 499px !important;
}

.top-500 {
  top: 500px !important;
}

.right-500 {
  right: 500px !important;
}

.bottom-500 {
  bottom: 500px !important;
}

.left-500 {
  left: 500px !important;
}

.spinner-bounce.brand-color span {
  background-color: #019AFF !important;
}
.spinner-bounce.white-color span {
  background-color: #ffffff !important;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}

.border, .border-top, .border-right, .border-bottom, .border-left {
  border-color: var(--default-border-color) !important;
}

.spinner-border, .spinner-grow {
  vertical-align: middle;
}

.spinner-border {
  border-width: 0.1em !important;
}

.shadow {
  box-shadow: var(--default-box-shadow) !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.page-body-wrapper {
  display: flex;
  flex-direction: row;
  padding: 70px 0 0 0;
  min-height: calc(100vh - 70px);
}

.main-panel {
  display: flex;
  flex-direction: column;
  margin-left: 230px;
  width: calc(100% - 230px);
  min-height: calc(100vh - 70px);
  transition: width 0.25s ease-in-out, margin 0.25s ease-in-out;
}
@media (max-width: 991px) {
  .main-panel {
    width: 100%;
    margin-left: 0;
  }
}

.content-wrapper {
  width: 100%;
  flex-grow: 1;
  background: var(--app-background-color);
  padding: 2rem;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.btn {
  font-size: inherit;
  line-height: initial;
  color: var(--default-font-color);
  padding: 0.45rem 1.2rem;
}
.btn.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.btn:focus {
  box-shadow: none !important;
}
.btn.btn-with-shadow {
  box-shadow: var(--default-box-shadow);
}
.btn.btn-primary {
  color: #ffffff;
  border-color: #4466F2;
  background-color: #4466F2;
  transition: all 0.25s ease-in-out;
}
.btn.btn-primary:hover {
  background-color: #2850f0;
}
.btn.btn-primary:disabled:hover {
  background-color: #4466F2;
}
.btn.btn-light {
  border-color: var(--btn-light-color);
  background-color: var(--btn-light-color);
  transition: all 0.25s ease-in-out;
}
.btn.btn-light:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.btn.btn-light:focus {
  color: var(--default-font-color);
}
.btn.btn-light:disabled:hover {
  color: #212529;
  background-color: var(--btn-light-color);
}
.btn.btn-secondary {
  border-color: var(--btn-secondary-bg);
  background-color: var(--btn-secondary-bg);
}
.btn.btn-secondary:disabled:hover {
  background-color: var(--btn-secondary-bg);
}
.btn.btn-secondary:hover {
  background-color: var(--btn-secondary-hover-bg);
}
.btn.btn-dark {
  color: #ffffff;
  border-color: var(--btn-dark-color);
  background-color: var(--btn-dark-color);
}
.btn.btn-dark:hover {
  background-color: var(--btn-dark-hover-color);
}
.btn.btn-dark:disabled:hover {
  background-color: var(--btn-dark-color);
}
.btn.btn-success {
  color: #ffffff;
}
.btn.btn-danger {
  color: #ffffff;
  border-color: #fc2c10;
  background-color: #fc2c10;
  transition: all 0.25s ease-in-out;
}
.btn.btn-danger:hover {
  background-color: #f52003;
}
.btn.btn-danger:disabled:hover {
  background-color: #fc2c10;
}
.btn.btn-secondary {
  color: #ffffff;
}
.btn.btn-info {
  color: #ffffff;
  border-color: #38a4f8;
  background-color: #38a4f8;
  transition: all 0.25s ease-in-out;
}
.btn.btn-info:hover {
  background-color: #098df4;
}
.btn.btn-info:disabled:hover {
  background-color: #38a4f8;
}
.btn.btn-warning {
  color: #ffffff;
  border-color: #FC6510;
  background-color: #FC6510;
  transition: all 0.25s ease-in-out;
}
.btn.btn-warning:disabled:hover {
  background-color: #FC6510;
}
.btn.btn-warning:hover {
  background-color: #d64f03;
}
.btn.btn-outline-primary {
  color: #4466F2;
}
.btn.btn-outline-primary:hover {
  color: #ffffff;
}
.btn.btn-outline-primary:disabled:hover {
  color: #4466F2;
}
.btn.btn-outline-success {
  color: #46c35f;
}
.btn.btn-outline-success:hover {
  color: #ffffff;
}
.btn.btn-outline-success:disabled:hover {
  color: #46c35f;
}
.btn.btn-outline-danger {
  color: #fc2c10;
}
.btn.btn-outline-danger:hover {
  color: #ffffff;
}
.btn.btn-outline-danger:disabled:hover {
  color: #fc2c10;
}
.btn.btn-outline-info {
  color: #38a4f8;
  border-color: #38a4f8;
}
.btn.btn-outline-info:hover {
  color: #ffffff;
}
.btn.btn-outline-info:disabled:hover {
  color: #38a4f8;
}
.btn.btn-outline-warning {
  color: #FC6510;
  border-color: #FC6510;
}
.btn.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #FC6510;
}
.btn.btn-outline-warning:disabled:hover {
  color: #FC6510;
  background-color: transparent;
}
.btn.btn-load-more-data {
  font-size: 95%;
  color: #8A8A8A;
  padding: 0.5rem 1.5rem;
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.btn.btn-load-more-data:hover {
  color: #019AFF;
  background: var(--base-color);
}
.btn.btn-load-more-data:focus {
  box-shadow: var(--default-box-shadow) !important;
}
.btn.btn-transparent-primary {
  color: #4466F2;
  width: max-content;
  border-color: transparent;
  background-color: transparent;
  transition: all 0.25s ease-in-out;
}
.btn.btn-transparent-primary:hover {
  color: #ffffff;
  background-color: #4466F2;
}
.btn.btn-transparent-primary:disabled:hover {
  background-color: transparent;
}

.btn-group.btn-group-action .btn {
  margin-right: 2px;
  border-radius: 50% !important;
  padding: 0.4rem 0.5rem;
}
.btn-group.btn-group-action .btn svg {
  width: 17px;
  height: 17px;
}
.btn-group.btn-group-action .btn:hover {
  border-radius: 50%;
  color: #019AFF;
  background-color: var(--icon-hover-bg);
}

.btn-primary {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-primary:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-primary:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.btn-secondary {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-secondary:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-secondary:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.btn-warning {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-warning:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-warning:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.btn-success {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-success:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-success:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.btn-info {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-info:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-info:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.btn-danger {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-danger:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-danger:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.btn-dark {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-dark:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-dark:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.btn-light {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}
.btn-light:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
}
.btn-light:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.editor .btn-light {
  box-shadow: none !important;
}
.editor .btn-light:hover {
  box-shadow: none !important;
}
.editor .btn-light:focus {
  box-shadow: none !important;
}

.bulk-floating-action-wrapper .btn-light {
  box-shadow: none !important;
}
.bulk-floating-action-wrapper .btn-light:hover {
  box-shadow: none !important;
}
.bulk-floating-action-wrapper .btn-light:focus {
  box-shadow: none !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

label {
  color: var(--default-font-color) !important;
}

.col-form-label {
  padding-top: calc(0.375rem + 4px);
  padding-bottom: calc(0.375rem + 3px);
}

::placeholder {
  color: #afb1b6 !important;
}

select {
  height: auto !important;
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
  background-position-x: 97% !important;
  background-position-y: 50% !important;
  color: var(--default-font-color) !important;
}
select.custom-select {
  color: var(--default-font-color) !important;
  background-color: var(--form-control-bg) !important;
}
select:focus {
  box-shadow: none !important;
  border-color: #4466F2 !important;
}

textarea {
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  display: block;
  width: 100%;
  outline: none;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus {
  box-shadow: none;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  border-color: #4466F2 !important;
}

.form-control {
  height: auto !important;
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
}
.form-control:focus {
  box-shadow: none;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  border-color: #4466F2 !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: var(--bg-input-readonly);
}

.form-group-with-icon {
  display: flex;
  align-items: center;
  position: relative;
}
.form-group-with-icon .form-control {
  background-color: var(--form-control-bg);
}
.form-group-with-icon .form-control.text-right-padding {
  padding-right: 44px !important;
}
.form-group-with-icon .form-control-feedback {
  display: inline-block;
  position: absolute;
  right: 0;
  padding: 0 14px;
  color: #bcbec2;
  transition: all 0.25s ease-in-out;
}
.form-group-with-icon .form-control-feedback.disabled {
  color: #ecedee;
}
.form-group-with-icon .form-control-feedback:hover {
  color: #019AFF;
}

.custom-control .custom-control-label {
  color: #8A8A8A !important;
  padding-top: 1px !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.18rem;
}

.custom-checkbox-default .custom-control-label:before {
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='blue' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-default .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-default .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-primary .custom-control-label:before {
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='blue' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label {
  color: #019AFF !important;
}
.custom-checkbox-primary .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-primary .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-success .custom-control-label:before {
  border-color: #46c35f;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='green' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #46c35f;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-label {
  color: #46c35f !important;
}
.custom-checkbox-success .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-success .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-warning .custom-control-label:before {
  border-color: #f2a654;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='orange' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #f2a654;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-label {
  color: #f2a654 !important;
}
.custom-checkbox-warning .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-warning .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-info .custom-control-label:before {
  border-color: rebeccapurple;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='rebeccapurple' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: rebeccapurple;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-label {
  color: rebeccapurple !important;
}
.custom-checkbox-info .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-info .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.customized-checkbox input {
  opacity: 0;
  position: absolute;
}
.customized-checkbox input + label {
  padding: 0;
  cursor: pointer;
  position: relative;
}
.customized-checkbox input + label:before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  background: transparent;
  border: 1px solid;
  border-radius: 0.18rem;
}
.customized-checkbox input:disabled + label {
  opacity: 0.7;
  cursor: auto;
}
.customized-checkbox input:disabled + label:before {
  opacity: 0.5;
}
.customized-checkbox input:checked + label:after {
  content: "";
  position: absolute;
  left: 4.5px;
  top: 9px;
  width: 2.45px;
  height: 2.45px;
  transform: rotate(45deg);
}
.customized-checkbox.checkbox-default input + label:before {
  border-color: #adb5bd;
}
.customized-checkbox.checkbox-default input:checked + label:before {
  border-color: #019AFF;
}
.customized-checkbox.checkbox-default input:checked + label:after {
  background: #019AFF;
  box-shadow: 2px 0 0 #019AFF, 4px 0 0 #019AFF, 4px -2px 0 #019AFF, 4px -4px 0 #019AFF, 4px -6px 0 #019AFF, 4px -8px 0 #019AFF;
}
.customized-checkbox.checkbox-success input + label:before {
  border-color: #46c35f;
}
.customized-checkbox.checkbox-success input:checked + label:before {
  border-color: #46c35f;
}
.customized-checkbox.checkbox-success input:checked + label:after {
  background: #46c35f;
  box-shadow: 2px 0 0 #46c35f, 4px 0 0 #46c35f, 4px -2px 0 #46c35f, 4px -4px 0 #46c35f, 4px -6px 0 #46c35f, 4px -8px 0 #46c35f;
}
.customized-checkbox.checkbox-warning input + label:before {
  border-color: #FC6510;
}
.customized-checkbox.checkbox-warning input:checked + label:before {
  border-color: #FC6510;
}
.customized-checkbox.checkbox-warning input:checked + label:after {
  background: #FC6510;
  box-shadow: 2px 0 0 #FC6510, 4px 0 0 #FC6510, 4px -2px 0 #FC6510, 4px -4px 0 #FC6510, 4px -6px 0 #FC6510, 4px -8px 0 #FC6510;
}
.customized-checkbox.checkbox-info input + label:before {
  border-color: #38a4f8;
}
.customized-checkbox.checkbox-info input:checked + label:before {
  border-color: #38a4f8;
}
.customized-checkbox.checkbox-info input:checked + label:after {
  background: #38a4f8;
  box-shadow: 2px 0 0 #38a4f8, 4px 0 0 #38a4f8, 4px -2px 0 #38a4f8, 4px -4px 0 #38a4f8, 4px -6px 0 #38a4f8, 4px -8px 0 #38a4f8;
}

.customized-radio {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin: 0 1rem 0 0;
  display: inline-block;
}
.customized-radio.disabled {
  opacity: 0.7;
}
.customized-radio.disabled .outside {
  background-color: var(--bg-input-readonly);
}
.customized-radio input {
  opacity: 0;
  width: 1px;
  height: 1px;
}
.customized-radio .outside {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  border: 1px solid;
  border-radius: 50%;
  background: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.customized-radio .outside .inside {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
}
.customized-radio input:checked + .outside .inside {
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  animation: radio-select 0.1s linear;
  -webkit-animation: radio-select 0.1s linear;
}
.customized-radio.mini {
  padding-left: 20px;
}
.customized-radio.mini .outside {
  width: 16px;
  height: 16px;
  margin-top: -8px;
}
.customized-radio.mini .outside .inside {
  top: 2.8px;
  left: 3px;
  width: 8px;
  height: 8px;
}
.customized-radio.radio-default .outside {
  border-color: #019AFF;
}
.customized-radio.radio-default .outside .inside {
  background: #019AFF;
}
.customized-radio.radio-primary .outside {
  border-color: #019AFF;
}
.customized-radio.radio-primary .outside .inside {
  background: #019AFF;
}
.customized-radio.radio-success .outside {
  border-color: #46c35f;
}
.customized-radio.radio-success .outside .inside {
  background: #46c35f;
}
.customized-radio.radio-warning .outside {
  border-color: #FC6510;
}
.customized-radio.radio-warning .outside .inside {
  background: #FC6510;
}
.customized-radio.radio-info .outside {
  border-color: #38a4f8;
}
.customized-radio.radio-info .outside .inside {
  background: #38a4f8;
}

.radio-button-group .btn-group {
  border-radius: 0.3rem;
  display: initial !important;
}
.radio-button-group .btn-group:focus {
  outline: none;
}
.radio-button-group .btn-group .btn {
  padding: 10px 1.2rem;
  background: transparent;
}
.radio-button-group .btn-group .btn.focus {
  box-shadow: none;
}
.radio-button-group .btn-group .btn.active {
  box-shadow: none;
  border-radius: 0.3rem;
  background-color: #019AFF;
  border: 1px solid #019AFF !important;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.radio-button-group .btn-group .btn.active span {
  color: #ffffff;
}

.border-switch {
  min-height: initial;
  color: #019AFF;
  padding-left: 0;
}
.border-switch .border-switch-control-input {
  display: none;
}
.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator {
  border-color: #019AFF;
}
.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator::after {
  left: 14px;
  background-color: #019AFF;
}
.border-switch .border-switch-control-indicator {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 20px;
  border-radius: 16px;
  transition: 0.3s;
  border: 2px solid #ccc;
}
.border-switch .border-switch-control-indicator::after {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.3s;
  top: 2px;
  left: 2px;
  background: #ccc;
}

.chips-container {
  cursor: pointer;
  overflow-y: auto;
  min-height: 70px;
  max-height: 350px;
  border-radius: 5px;
  color: #898989;
  background-color: var(--base-color);
}
.chips-container .add {
  transition: color 0.25s ease-in-out;
}
.chips-container .add:hover {
  color: #565656;
}
.chips-container .chips {
  font-size: 90%;
  border-radius: 22px;
  background-color: var(--chips-bg);
  box-shadow: var(--default-box-shadow);
  padding: 0.35rem 0.45rem 0.35rem 1rem;
}
.chips-container .chips .delete-chips {
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--base-color);
}

.chips-dropdown .dropdown-menu {
  top: auto !important;
}

.wizard-steps {
  font-size: 90% !important;
}
.wizard-steps hr {
  width: 100% !important;
  margin: 0 !important;
}
.wizard-steps .nav-link {
  padding: 0;
}
.wizard-steps .tab-step {
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: var(--base-color);
  position: relative;
  color: #9397A0;
  padding-right: 16px;
}
.wizard-steps .tab-step:hover {
  color: #019AFF;
}
.wizard-steps .tab-step-init {
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: var(--base-color);
  position: relative;
  color: #9397A0;
  padding: 9px 16px 0 16px;
}
.wizard-steps .tab-step-init:hover {
  color: #019AFF;
}
.wizard-steps .tab-step:before {
  width: 20px;
  height: 42px;
  border-radius: 0 20px 20px 0;
  background-color: var(--tab-step-before-bg);
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  margin-top: -1px !important;
  content: "";
}
.wizard-steps .nav-pills .nav-link.active, .wizard-steps .nav-pills .show > .nav-link {
  background: none;
}
.wizard-steps .nav-link.active .tab-step, .wizard-steps .nav-link.active .tab-step-init {
  color: #ffffff;
  background-color: #019AFF;
}
.wizard-steps .nav-pills .nav-item {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: -13px !important;
}
.wizard-steps .nav-pills .nav-item:first-child {
  margin-left: 0 !important;
  z-index: 105;
}
.wizard-steps .nav-pills .nav-item:nth-child(2) {
  z-index: 18;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(3) {
  z-index: 17;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(4) {
  z-index: 16;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(5) {
  z-index: 15;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(6) {
  z-index: 14;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(7) {
  z-index: 13;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(8) {
  z-index: 12;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(9) {
  z-index: 11;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(10) {
  z-index: 10;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(11) {
  z-index: 9;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(12) {
  z-index: 8;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(13) {
  z-index: 7;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(14) {
  z-index: 6;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(15) {
  z-index: 5;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(16) {
  z-index: 4;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(17) {
  z-index: 3;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(18) {
  z-index: 2;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(19) {
  z-index: 1;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(20) {
  z-index: 0;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(21) {
  z-index: -1;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(22) {
  z-index: -2;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(23) {
  z-index: -3;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(24) {
  z-index: -4;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(25) {
  z-index: -5;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(26) {
  z-index: -6;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(27) {
  z-index: -7;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(28) {
  z-index: -8;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(29) {
  z-index: -9;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(30) {
  z-index: -10;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(31) {
  z-index: -11;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(32) {
  z-index: -12;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(33) {
  z-index: -13;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(34) {
  z-index: -14;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(35) {
  z-index: -15;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(36) {
  z-index: -16;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(37) {
  z-index: -17;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(38) {
  z-index: -18;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(39) {
  z-index: -19;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(40) {
  z-index: -20;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(41) {
  z-index: -21;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(42) {
  z-index: -22;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(43) {
  z-index: -23;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(44) {
  z-index: -24;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(45) {
  z-index: -25;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(46) {
  z-index: -26;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(47) {
  z-index: -27;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(48) {
  z-index: -28;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(49) {
  z-index: -29;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(50) {
  z-index: -30;
  margin-left: -10px;
}

.note-frame {
  height: initial !important;
  border: 1px solid var(--default-border-color) !important;
}
.note-frame.card {
  height: initial !important;
}
.note-frame .card-header {
  padding: 1rem;
  background: transparent;
  border-color: var(--default-border-color);
}
.note-frame .card-header.note-toolbar {
  padding: 5px;
}
.note-frame .card-header .note-btn-group {
  margin-top: 0 !important;
}
.note-frame .card-header .note-btn-group .dropdown-toggle, .note-frame .card-header .note-btn-group .note-btn {
  color: var(--default-font-color);
  border-color: var(--default-border-color);
  background-color: var(--btn-light-bg) !important;
}
.note-frame .card-header .note-btn-group .note-dropdown-menu {
  border-color: var(--default-border-color);
  background-color: var(--btn-light-bg) !important;
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item pre {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-palette .note-palette-title {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-palette .note-color-reset:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-dimension-display {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .dropdown-item .note-icon-menu-check {
  visibility: initial;
  font-style: initial;
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .dropdown-item.checked {
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .note-icon-menu-check::before {
  display: none;
}
.note-frame .note-palette .note-color-reset:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .note-editable {
  padding: 1rem !important;
  color: var(--default-font-color) !important;
  background-color: var(--form-control-bg) !important;
}
.note-frame .note-editable.card-block::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.note-frame .note-editable.card-block::-webkit-scrollbar-track {
  background: transparent;
}
.note-frame .note-editable.card-block::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.note-frame .note-editable.card-block:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.note-frame .note-editable.card-block:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}
.note-frame .note-codable {
  height: 300px !important;
}
.note-frame .note-codable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.note-frame .note-codable::-webkit-scrollbar-track {
  background: transparent;
}
.note-frame .note-codable::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.note-frame .note-codable:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.note-frame .note-codable:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}
.note-frame .note-placeholder {
  padding: 1rem !important;
}
.note-frame .note-statusbar {
  background-color: var(--default-border-color) !important;
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top: 1px solid var(--default-border-color) !important;
}

.custom-file:focus {
  outline: none !important;
}
.custom-file .custom-file-input {
  z-index: 1;
  height: auto;
}
.custom-file .custom-file-input:focus ~ .custom-file-label {
  border-color: var(--default-border-color) !important;
}
.custom-file .custom-file-input:disabled ~ .custom-file-label {
  pointer-events: none;
  background-color: var(--bg-input-readonly);
}
.custom-file .custom-file-label {
  z-index: 2;
  height: auto;
  padding: 8px 14px !important;
  line-height: 1.5;
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--default-border-color);
  font-size: 100% !important;
  background-color: var(--form-control-bg);
}
.custom-file .custom-file-label:focus {
  border-color: var(--default-border-color);
}
.custom-file .custom-file-label::after {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 10px 14px !important;
  line-height: 1.5;
  height: auto;
  background: #019AFF;
  color: #ffffff;
}

.vue-tel-input {
  border-radius: 5px !important;
  border-color: var(--default-border-color) !important;
}
.vue-tel-input:focus, .vue-tel-input:focus-within {
  box-shadow: none !important;
  border-color: #019AFF !important;
}
.vue-tel-input .vti__dropdown {
  border-radius: 5px 0 0 5px !important;
  padding: 10px 14px !important;
  background-color: var(--form-control-bg) !important;
}
.vue-tel-input .vti__dropdown:focus {
  outline: none !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list {
  border-color: var(--default-border-color) !important;
  background-color: var(--form-control-bg) !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar-track {
  background: transparent;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--scrollbar-thumb-color);
}
.vue-tel-input .vti__dropdown .vti__dropdown-list .vti__dropdown-item {
  font-size: 95% !important;
  color: #898989 !important;
  padding: 1rem 2rem 1rem 2rem !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list .vti__dropdown-item.highlighted {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.vue-tel-input .vti__input {
  border-radius: 0 5px 5px 0 !important;
  background-color: var(--form-control-bg) !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.custom-image-upload-wrapper {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 0.25rem;
  background-color: var(--base-color);
}
.custom-image-upload-wrapper .image-area {
  width: 150px;
  height: 150px;
  border-radius: 0.25rem;
}
.custom-image-upload-wrapper .image-area img {
  width: auto;
  max-height: 100%;
  border-radius: 0.25rem;
}
.custom-image-upload-wrapper .input-area {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  padding: 5px 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  transition: all 0.25s ease-in-out;
}
.custom-image-upload-wrapper .input-area #upload-label {
  width: 100%;
  font-size: 90%;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
  color: #ffffff !important;
}
.custom-image-upload-wrapper:hover .input-area {
  opacity: 1;
}
.custom-image-upload-wrapper.circle {
  overflow: hidden;
  border-radius: 50%;
}
.custom-image-upload-wrapper.circle .image-area {
  border-radius: 50%;
}
.custom-image-upload-wrapper.circle .image-area img {
  border-radius: 50%;
}
.custom-image-upload-wrapper.small-wrapper {
  width: 120px;
  height: 120px;
}
.custom-image-upload-wrapper.small-wrapper .image-area {
  width: 120px;
  height: 120px;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.modal {
  padding-right: 0 !important;
}
.modal .modal-dialog .modal-content {
  border: 0;
  cursor: default !important;
  border-radius: 0.25rem;
  max-height: calc(100% - 18rem) !important;
  background-color: var(--modal-header-footer-bg);
}
@media (min-width: 576px) {
  .modal .modal-dialog .modal-content {
    min-height: 325px !important;
  }
}
.modal .modal-dialog .modal-content .modal-header {
  padding: 1.688rem;
  border-color: var(--default-border-color);
  background-color: var(--modal-header-footer-bg);
}
.modal .modal-dialog .modal-content .modal-header svg {
  height: 18px;
  width: 18px;
}
.modal .modal-dialog .modal-content .modal-header .close {
  color: var(--default-font-color);
}
.modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
.modal .modal-dialog .modal-content .modal-header .close span svg {
  height: 32px;
  width: 32px;
  padding: 0.438rem;
}
.modal .modal-dialog .modal-content .modal-header .close span svg:hover {
  border-radius: 50%;
  background-color: var(--icon-hover-bg);
}
.modal .modal-dialog .modal-content .modal-header .close.position-absolute {
  right: 0;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 1.688rem;
  background-color: var(--modal-body-bg);
}
.modal .modal-dialog .modal-content .modal-body.modal-alert {
  border-radius: 5px !important;
}
.modal .modal-dialog .modal-content .modal-body.modal-alert svg {
  stroke-width: 1.5 !important;
  height: 61px;
  width: 61px;
}
.modal .modal-dialog .modal-content .modal-body.modal-alert .btn {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.modal .modal-dialog .modal-content .modal-footer {
  padding: 1.688rem;
  border-color: var(--default-border-color);
  background-color: var(--modal-header-footer-bg);
}
.modal .modal-dialog .modal-content .modal-footer * {
  margin: 0;
}
@media (min-width: 576px) {
  .modal .modal-dialog.modal-default {
    max-width: 620px;
  }
}
.modal .modal-dialog.modal-fullwidth {
  max-width: 100%;
}
.modal .modal-dialog.modal-fullwidth .modal-content {
  border-radius: 0;
}
.modal .modal-dialog.modal-dialog-top {
  max-height: 100vh;
  margin: 0 auto !important;
}
.modal .modal-dialog.modal-dialog-top .modal-content {
  max-height: 100vh;
}
@media (min-width: 576px) {
  .modal .modal-dialog.modal-dialog-centered .modal-content {
    max-height: 450px !important;
  }
}
.modal .modal-dialog.full-screen-modal-dialog {
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content {
  display: flex;
  border-radius: 0;
  position: relative;
  pointer-events: auto;
  flex-direction: column;
  min-width: 100% !important;
  min-height: 100% !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-clip: padding-box;
  background-color: var(--modal-body-bg);
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content .modal-header {
  padding: 1.688rem;
  border-color: var(--default-border-color);
  background-color: var(--modal-header-footer-bg);
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content .modal-header .close {
  color: var(--default-font-color);
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content .modal-header .close span svg {
  height: 32px;
  width: 32px;
  padding: 0.438rem;
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content .modal-header .close span svg:hover {
  border-radius: 50%;
  background-color: var(--icon-hover-bg);
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content .modal-header .close.position-absolute {
  right: 0;
}
.modal .modal-dialog.full-screen-modal-dialog .modal-content .modal-body {
  padding: 1.688rem;
}

.modal-sm.modal-dialog {
  max-width: 380px;
}
.modal-sm.modal-dialog .modal-content {
  min-height: auto !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.card {
  background-color: var(--default-card-bg);
}
.card .card-header {
  border-color: var(--default-border-color);
}
.card .card-header .form-group-with-search {
  margin-bottom: 0;
  margin-left: auto;
}
.card .card-body {
  padding: 2rem;
}
.card .card-body.body-hoverable {
  height: 240px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.card .card-body.body-hoverable:hover {
  transform: scale(1.01);
  box-shadow: var(--default-box-shadow);
}
.card .card-body.body-hoverable svg {
  height: 50px;
  width: 50px;
  color: #ffffff;
  stroke-width: 0.7;
}
.card .card-footer {
  border-color: var(--default-border-color);
}
.card.card-with-shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}
.card.card-with-search .input-search-box {
  width: 220px;
}
.card.template-preview-card .template-preview-wrapper {
  height: 300px;
  font-size: 50%;
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  box-shadow: var(--default-box-shadow);
  transition: 0.25s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-clip: border-box;
  cursor: pointer !important;
}
.card.template-preview-card .template-preview-wrapper .v-html-wrapper {
  zoom: 40%;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
@media (min-width: 992px) and (max-width: 1350px) {
  .card.template-preview-card .template-preview-wrapper .v-html-wrapper {
    zoom: 30%;
  }
}
.card.template-preview-card .template-preview-wrapper .v-html-wrapper img {
  max-width: 100% !important;
}
.card.template-preview-card .template-preview-wrapper .v-html-wrapper table {
  width: 100% !important;
  max-width: 100% !important;
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  border: none;
  height: 300px;
  font-size: 0.935rem;
  box-shadow: var(--default-box-shadow);
  border-radius: 0.25rem;
  transition: 0.25s;
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--default-card-bg);
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options ul li {
  border: 0;
  border-radius: 0;
  background-color: var(--default-card-bg);
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options ul li a {
  color: var(--default-font-color);
  transition: 0.25s;
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options ul li a:hover {
  color: #019AFF;
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options ul li a svg {
  height: 20px;
  width: 20px;
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options ul li:first-child {
  padding-top: 2rem;
}
.card.template-preview-card .template-preview-wrapper .card-overlay-options ul li:last-child {
  padding-bottom: 2rem;
}
.card.template-preview-card .template-preview-wrapper:hover .card-overlay-options {
  opacity: 0.7;
  transform: scale(1.03) !important;
}

.chart-data-list .data-group-item {
  border: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  background-color: transparent;
}
.chart-data-list .data-group-item .square {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 0.2rem;
}
.chart-data-list .data-group-item .value {
  font-size: 90%;
  margin-left: 5px;
  color: var(--default-font-color);
}

.orgchart-container {
  border: none !important;
  width: 100% !important;
}
.orgchart-container .orgchart {
  background-image: none;
}
.orgchart-container .orgchart .node {
  width: 150px;
  border: none !important;
  padding: 0;
  margin: 0 3px;
  border-radius: 4px;
  box-shadow: var(--default-box-shadow) !important;
}
.orgchart-container .orgchart .node:hover {
  background-color: transparent !important;
  cursor: pointer !important;
}
.orgchart-container .orgchart .node .title {
  background-color: #019AFF !important;
  border: none !important;
}
.orgchart-container .orgchart .node .title .symbol {
  display: none !important;
}
.orgchart-container .orgchart .node .content {
  border: none !important;
  color: var(--default-font-color) !important;
  background-color: var(--chips-bg) !important;
}
.orgchart-container .orgchart .lines .downLine {
  background-color: #019AFF;
}
.orgchart-container .orgchart .lines .topLine {
  border-color: #019AFF;
}
.orgchart-container .orgchart .lines .leftLine {
  border-color: #019AFF;
}
.orgchart-container .orgchart .lines .rightLine {
  border-color: #019AFF;
}
.orgchart-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.orgchart-container::-webkit-scrollbar-track {
  background: transparent;
}
.orgchart-container::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.orgchart-container:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.orgchart-container:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

/*Badges*/
.badge {
  color: #ffffff;
  font-size: 12px;
  padding: 0.55rem 1.2rem;
}
.badge.badge-pill {
  border-radius: 20px !important;
}
.badge.badge-circle {
  padding: 0;
  width: 29px;
  max-width: 29px;
  height: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.badge.badge-lg {
  font-size: 14px;
  padding: 0.7rem 1.5rem;
}
.badge.badge-lg.badge-circle {
  padding: 0;
  width: 35px;
  max-width: 35px;
  height: 35px;
}
.badge.badge-sm {
  font-size: 11px;
  padding: 0.45rem 1rem;
}
.badge.badge-sm.badge-circle {
  padding: 0;
  width: 25px;
  max-width: 25px;
  height: 25px;
}
.badge.badge-primary {
  background-color: #019AFF;
}
.badge.badge-purple {
  background-color: #964ed8;
}
.badge.badge-warning {
  background-color: #FC6510;
}
.badge.badge-danger {
  background-color: #fc2c10;
}
.badge.badge-light {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.badge.badge-info {
  background-color: #38a4f8;
}
.badge.badge-secondary {
  background-color: #9397A0;
}
.badge.badge-muted {
  background-color: #afb1b6;
}

/*Notes*/
.note-title svg {
  color: #f6e84e;
  height: 20px;
  width: 20px;
}

.note {
  border-radius: 0.25rem;
}
.note.note-warning {
  background-color: var(--note-bg-color);
  color: #707887 !important;
}
.note.note-blue {
  color: var(--note-font-color);
  background-color: rgba(236, 236, 255, 0.9);
}
.note.note-red {
  color: var(--note-font-color);
  background-color: rgba(255, 233, 233, 0.9);
}
.note.note-purple {
  color: var(--note-font-color);
  background-color: rgba(255, 231, 255, 0.9);
}
.note.note-success {
  color: var(--note-font-color);
  background-color: rgba(211, 255, 220, 0.9);
}
.note.note-info {
  color: var(--note-font-color);
  background-color: rgba(212, 249, 255, 0.9);
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

label {
  color: var(--default-font-color) !important;
}

.col-form-label {
  padding-top: calc(0.375rem + 4px);
  padding-bottom: calc(0.375rem + 3px);
}

::placeholder {
  color: #afb1b6 !important;
}

select {
  height: auto !important;
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
  background-position-x: 97% !important;
  background-position-y: 50% !important;
  color: var(--default-font-color) !important;
}
select.custom-select {
  color: var(--default-font-color) !important;
  background-color: var(--form-control-bg) !important;
}
select:focus {
  box-shadow: none !important;
  border-color: #4466F2 !important;
}

textarea {
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  display: block;
  width: 100%;
  outline: none;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus {
  box-shadow: none;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  border-color: #4466F2 !important;
}

.form-control {
  height: auto !important;
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
}
.form-control:focus {
  box-shadow: none;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  border-color: #4466F2 !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: var(--bg-input-readonly);
}

.form-group-with-icon {
  display: flex;
  align-items: center;
  position: relative;
}
.form-group-with-icon .form-control {
  background-color: var(--form-control-bg);
}
.form-group-with-icon .form-control.text-right-padding {
  padding-right: 44px !important;
}
.form-group-with-icon .form-control-feedback {
  display: inline-block;
  position: absolute;
  right: 0;
  padding: 0 14px;
  color: #bcbec2;
  transition: all 0.25s ease-in-out;
}
.form-group-with-icon .form-control-feedback.disabled {
  color: #ecedee;
}
.form-group-with-icon .form-control-feedback:hover {
  color: #019AFF;
}

.custom-control .custom-control-label {
  color: #8A8A8A !important;
  padding-top: 1px !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.18rem;
}

.custom-checkbox-default .custom-control-label:before {
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='blue' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-default .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-default .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-primary .custom-control-label:before {
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='blue' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label {
  color: #019AFF !important;
}
.custom-checkbox-primary .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-primary .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-success .custom-control-label:before {
  border-color: #46c35f;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='green' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #46c35f;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-label {
  color: #46c35f !important;
}
.custom-checkbox-success .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-success .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-warning .custom-control-label:before {
  border-color: #f2a654;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='orange' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #f2a654;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-label {
  color: #f2a654 !important;
}
.custom-checkbox-warning .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-warning .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-info .custom-control-label:before {
  border-color: rebeccapurple;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='rebeccapurple' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: rebeccapurple;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-label {
  color: rebeccapurple !important;
}
.custom-checkbox-info .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-info .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.customized-checkbox input {
  opacity: 0;
  position: absolute;
}
.customized-checkbox input + label {
  padding: 0;
  cursor: pointer;
  position: relative;
}
.customized-checkbox input + label:before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  background: transparent;
  border: 1px solid;
  border-radius: 0.18rem;
}
.customized-checkbox input:disabled + label {
  opacity: 0.7;
  cursor: auto;
}
.customized-checkbox input:disabled + label:before {
  opacity: 0.5;
}
.customized-checkbox input:checked + label:after {
  content: "";
  position: absolute;
  left: 4.5px;
  top: 9px;
  width: 2.45px;
  height: 2.45px;
  transform: rotate(45deg);
}
.customized-checkbox.checkbox-default input + label:before {
  border-color: #adb5bd;
}
.customized-checkbox.checkbox-default input:checked + label:before {
  border-color: #019AFF;
}
.customized-checkbox.checkbox-default input:checked + label:after {
  background: #019AFF;
  box-shadow: 2px 0 0 #019AFF, 4px 0 0 #019AFF, 4px -2px 0 #019AFF, 4px -4px 0 #019AFF, 4px -6px 0 #019AFF, 4px -8px 0 #019AFF;
}
.customized-checkbox.checkbox-success input + label:before {
  border-color: #46c35f;
}
.customized-checkbox.checkbox-success input:checked + label:before {
  border-color: #46c35f;
}
.customized-checkbox.checkbox-success input:checked + label:after {
  background: #46c35f;
  box-shadow: 2px 0 0 #46c35f, 4px 0 0 #46c35f, 4px -2px 0 #46c35f, 4px -4px 0 #46c35f, 4px -6px 0 #46c35f, 4px -8px 0 #46c35f;
}
.customized-checkbox.checkbox-warning input + label:before {
  border-color: #FC6510;
}
.customized-checkbox.checkbox-warning input:checked + label:before {
  border-color: #FC6510;
}
.customized-checkbox.checkbox-warning input:checked + label:after {
  background: #FC6510;
  box-shadow: 2px 0 0 #FC6510, 4px 0 0 #FC6510, 4px -2px 0 #FC6510, 4px -4px 0 #FC6510, 4px -6px 0 #FC6510, 4px -8px 0 #FC6510;
}
.customized-checkbox.checkbox-info input + label:before {
  border-color: #38a4f8;
}
.customized-checkbox.checkbox-info input:checked + label:before {
  border-color: #38a4f8;
}
.customized-checkbox.checkbox-info input:checked + label:after {
  background: #38a4f8;
  box-shadow: 2px 0 0 #38a4f8, 4px 0 0 #38a4f8, 4px -2px 0 #38a4f8, 4px -4px 0 #38a4f8, 4px -6px 0 #38a4f8, 4px -8px 0 #38a4f8;
}

.customized-radio {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin: 0 1rem 0 0;
  display: inline-block;
}
.customized-radio.disabled {
  opacity: 0.7;
}
.customized-radio.disabled .outside {
  background-color: var(--bg-input-readonly);
}
.customized-radio input {
  opacity: 0;
  width: 1px;
  height: 1px;
}
.customized-radio .outside {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  border: 1px solid;
  border-radius: 50%;
  background: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.customized-radio .outside .inside {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
}
.customized-radio input:checked + .outside .inside {
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  animation: radio-select 0.1s linear;
  -webkit-animation: radio-select 0.1s linear;
}
.customized-radio.mini {
  padding-left: 20px;
}
.customized-radio.mini .outside {
  width: 16px;
  height: 16px;
  margin-top: -8px;
}
.customized-radio.mini .outside .inside {
  top: 2.8px;
  left: 3px;
  width: 8px;
  height: 8px;
}
.customized-radio.radio-default .outside {
  border-color: #019AFF;
}
.customized-radio.radio-default .outside .inside {
  background: #019AFF;
}
.customized-radio.radio-primary .outside {
  border-color: #019AFF;
}
.customized-radio.radio-primary .outside .inside {
  background: #019AFF;
}
.customized-radio.radio-success .outside {
  border-color: #46c35f;
}
.customized-radio.radio-success .outside .inside {
  background: #46c35f;
}
.customized-radio.radio-warning .outside {
  border-color: #FC6510;
}
.customized-radio.radio-warning .outside .inside {
  background: #FC6510;
}
.customized-radio.radio-info .outside {
  border-color: #38a4f8;
}
.customized-radio.radio-info .outside .inside {
  background: #38a4f8;
}

.radio-button-group .btn-group {
  border-radius: 0.3rem;
  display: initial !important;
}
.radio-button-group .btn-group:focus {
  outline: none;
}
.radio-button-group .btn-group .btn {
  padding: 10px 1.2rem;
  background: transparent;
}
.radio-button-group .btn-group .btn.focus {
  box-shadow: none;
}
.radio-button-group .btn-group .btn.active {
  box-shadow: none;
  border-radius: 0.3rem;
  background-color: #019AFF;
  border: 1px solid #019AFF !important;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.radio-button-group .btn-group .btn.active span {
  color: #ffffff;
}

.border-switch {
  min-height: initial;
  color: #019AFF;
  padding-left: 0;
}
.border-switch .border-switch-control-input {
  display: none;
}
.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator {
  border-color: #019AFF;
}
.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator::after {
  left: 14px;
  background-color: #019AFF;
}
.border-switch .border-switch-control-indicator {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 20px;
  border-radius: 16px;
  transition: 0.3s;
  border: 2px solid #ccc;
}
.border-switch .border-switch-control-indicator::after {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.3s;
  top: 2px;
  left: 2px;
  background: #ccc;
}

.chips-container {
  cursor: pointer;
  overflow-y: auto;
  min-height: 70px;
  max-height: 350px;
  border-radius: 5px;
  color: #898989;
  background-color: var(--base-color);
}
.chips-container .add {
  transition: color 0.25s ease-in-out;
}
.chips-container .add:hover {
  color: #565656;
}
.chips-container .chips {
  font-size: 90%;
  border-radius: 22px;
  background-color: var(--chips-bg);
  box-shadow: var(--default-box-shadow);
  padding: 0.35rem 0.45rem 0.35rem 1rem;
}
.chips-container .chips .delete-chips {
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--base-color);
}

.chips-dropdown .dropdown-menu {
  top: auto !important;
}

.wizard-steps {
  font-size: 90% !important;
}
.wizard-steps hr {
  width: 100% !important;
  margin: 0 !important;
}
.wizard-steps .nav-link {
  padding: 0;
}
.wizard-steps .tab-step {
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: var(--base-color);
  position: relative;
  color: #9397A0;
  padding-right: 16px;
}
.wizard-steps .tab-step:hover {
  color: #019AFF;
}
.wizard-steps .tab-step-init {
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: var(--base-color);
  position: relative;
  color: #9397A0;
  padding: 9px 16px 0 16px;
}
.wizard-steps .tab-step-init:hover {
  color: #019AFF;
}
.wizard-steps .tab-step:before {
  width: 20px;
  height: 42px;
  border-radius: 0 20px 20px 0;
  background-color: var(--tab-step-before-bg);
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  margin-top: -1px !important;
  content: "";
}
.wizard-steps .nav-pills .nav-link.active, .wizard-steps .nav-pills .show > .nav-link {
  background: none;
}
.wizard-steps .nav-link.active .tab-step, .wizard-steps .nav-link.active .tab-step-init {
  color: #ffffff;
  background-color: #019AFF;
}
.wizard-steps .nav-pills .nav-item {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: -13px !important;
}
.wizard-steps .nav-pills .nav-item:first-child {
  margin-left: 0 !important;
  z-index: 105;
}
.wizard-steps .nav-pills .nav-item:nth-child(2) {
  z-index: 18;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(3) {
  z-index: 17;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(4) {
  z-index: 16;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(5) {
  z-index: 15;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(6) {
  z-index: 14;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(7) {
  z-index: 13;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(8) {
  z-index: 12;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(9) {
  z-index: 11;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(10) {
  z-index: 10;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(11) {
  z-index: 9;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(12) {
  z-index: 8;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(13) {
  z-index: 7;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(14) {
  z-index: 6;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(15) {
  z-index: 5;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(16) {
  z-index: 4;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(17) {
  z-index: 3;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(18) {
  z-index: 2;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(19) {
  z-index: 1;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(20) {
  z-index: 0;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(21) {
  z-index: -1;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(22) {
  z-index: -2;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(23) {
  z-index: -3;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(24) {
  z-index: -4;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(25) {
  z-index: -5;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(26) {
  z-index: -6;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(27) {
  z-index: -7;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(28) {
  z-index: -8;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(29) {
  z-index: -9;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(30) {
  z-index: -10;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(31) {
  z-index: -11;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(32) {
  z-index: -12;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(33) {
  z-index: -13;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(34) {
  z-index: -14;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(35) {
  z-index: -15;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(36) {
  z-index: -16;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(37) {
  z-index: -17;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(38) {
  z-index: -18;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(39) {
  z-index: -19;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(40) {
  z-index: -20;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(41) {
  z-index: -21;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(42) {
  z-index: -22;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(43) {
  z-index: -23;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(44) {
  z-index: -24;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(45) {
  z-index: -25;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(46) {
  z-index: -26;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(47) {
  z-index: -27;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(48) {
  z-index: -28;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(49) {
  z-index: -29;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(50) {
  z-index: -30;
  margin-left: -10px;
}

.note-frame {
  height: initial !important;
  border: 1px solid var(--default-border-color) !important;
}
.note-frame.card {
  height: initial !important;
}
.note-frame .card-header {
  padding: 1rem;
  background: transparent;
  border-color: var(--default-border-color);
}
.note-frame .card-header.note-toolbar {
  padding: 5px;
}
.note-frame .card-header .note-btn-group {
  margin-top: 0 !important;
}
.note-frame .card-header .note-btn-group .dropdown-toggle, .note-frame .card-header .note-btn-group .note-btn {
  color: var(--default-font-color);
  border-color: var(--default-border-color);
  background-color: var(--btn-light-bg) !important;
}
.note-frame .card-header .note-btn-group .note-dropdown-menu {
  border-color: var(--default-border-color);
  background-color: var(--btn-light-bg) !important;
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item pre {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-palette .note-palette-title {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-palette .note-color-reset:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-dimension-display {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .dropdown-item .note-icon-menu-check {
  visibility: initial;
  font-style: initial;
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .dropdown-item.checked {
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .note-icon-menu-check::before {
  display: none;
}
.note-frame .note-palette .note-color-reset:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .note-editable {
  padding: 1rem !important;
  color: var(--default-font-color) !important;
  background-color: var(--form-control-bg) !important;
}
.note-frame .note-editable.card-block::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.note-frame .note-editable.card-block::-webkit-scrollbar-track {
  background: transparent;
}
.note-frame .note-editable.card-block::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.note-frame .note-editable.card-block:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.note-frame .note-editable.card-block:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}
.note-frame .note-codable {
  height: 300px !important;
}
.note-frame .note-codable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.note-frame .note-codable::-webkit-scrollbar-track {
  background: transparent;
}
.note-frame .note-codable::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.note-frame .note-codable:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.note-frame .note-codable:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}
.note-frame .note-placeholder {
  padding: 1rem !important;
}
.note-frame .note-statusbar {
  background-color: var(--default-border-color) !important;
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top: 1px solid var(--default-border-color) !important;
}

.custom-file:focus {
  outline: none !important;
}
.custom-file .custom-file-input {
  z-index: 1;
  height: auto;
}
.custom-file .custom-file-input:focus ~ .custom-file-label {
  border-color: var(--default-border-color) !important;
}
.custom-file .custom-file-input:disabled ~ .custom-file-label {
  pointer-events: none;
  background-color: var(--bg-input-readonly);
}
.custom-file .custom-file-label {
  z-index: 2;
  height: auto;
  padding: 8px 14px !important;
  line-height: 1.5;
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--default-border-color);
  font-size: 100% !important;
  background-color: var(--form-control-bg);
}
.custom-file .custom-file-label:focus {
  border-color: var(--default-border-color);
}
.custom-file .custom-file-label::after {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 10px 14px !important;
  line-height: 1.5;
  height: auto;
  background: #019AFF;
  color: #ffffff;
}

.vue-tel-input {
  border-radius: 5px !important;
  border-color: var(--default-border-color) !important;
}
.vue-tel-input:focus, .vue-tel-input:focus-within {
  box-shadow: none !important;
  border-color: #019AFF !important;
}
.vue-tel-input .vti__dropdown {
  border-radius: 5px 0 0 5px !important;
  padding: 10px 14px !important;
  background-color: var(--form-control-bg) !important;
}
.vue-tel-input .vti__dropdown:focus {
  outline: none !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list {
  border-color: var(--default-border-color) !important;
  background-color: var(--form-control-bg) !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar-track {
  background: transparent;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--scrollbar-thumb-color);
}
.vue-tel-input .vti__dropdown .vti__dropdown-list .vti__dropdown-item {
  font-size: 95% !important;
  color: #898989 !important;
  padding: 1rem 2rem 1rem 2rem !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list .vti__dropdown-item.highlighted {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.vue-tel-input .vti__input {
  border-radius: 0 5px 5px 0 !important;
  background-color: var(--form-control-bg) !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.btn-filter {
  font-size: 95%;
  color: #8A8A8A;
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.btn-filter:hover {
  color: #019AFF;
  background: var(--base-color);
}
.btn-filter:focus {
  box-shadow: var(--default-box-shadow) !important;
}
.btn-filter.btn-list-grid {
  padding: 0.5rem;
  border-radius: 5px;
}
.btn-filter.btn-list-grid svg {
  width: 20px;
  height: 20px;
}

.filters-wrapper .single-filter {
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
}
.filters-wrapper .single-filter:last-child {
  margin-right: 0;
}
.filters-wrapper .single-filter .dropdown .btn-filter {
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
}
.filters-wrapper .single-filter .dropdown .btn-filter .clear-icon {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: -1rem;
  font-size: 95% !important;
}
.filters-wrapper .single-filter .dropdown .btn-filter .clear-icon svg {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.filters-wrapper .single-filter .dropdown .btn-filter.applied {
  color: #019AFF;
  background: var(--base-color);
}
.filters-wrapper .single-filter .dropdown .dropdown-menu {
  padding: 0;
  font-size: 95%;
  margin-top: 5px !important;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item {
  padding: 2rem;
  font-size: 95%;
  white-space: normal;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--default-font-color);
  background: transparent;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item .filter-action-button-wrapper .btn-clear {
  color: #9397A0;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item .filter-action-button-wrapper .btn-clear:hover {
  color: var(--default-font-color);
}
.filters-wrapper .single-filter .dropdown.show .btn-filter {
  color: #019AFF;
  background: var(--base-color);
}
.filters-wrapper .single-filter.column-filter .btn-filter {
  padding: 0.5rem;
  border-radius: 5px;
}
.filters-wrapper .single-filter.column-filter .btn-filter svg {
  width: 20px;
  height: 20px;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu {
  z-index: 1040 !important;
  width: 352px;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .content-type svg {
  cursor: grab;
  box-sizing: border-box;
  border-radius: 5px;
  color: #dce6ff;
  padding: 2px;
  margin-right: 10px;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch {
  box-shadow: none;
  transform: scale(1.5) !important;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-label:before {
  border: none;
  background-color: #9397A0;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-label:after {
  background-color: #ffffff;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #019AFF;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .manage-column-options {
  overflow-y: auto;
  max-height: 240px;
}
.filters-wrapper .single-filter.column-filter .ghost {
  background: #c8ebfb !important;
}
.filters-wrapper .single-filter.column-filter .catch-item {
  background: var(--base-color) !important;
}
.filters-wrapper .single-filter.checkbox-filter .dropdown .dropdown-menu {
  width: 435px;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.filters-wrapper .single-filter.checkbox-filter .dropdown .dropdown-menu .dropdown-item .row .col-6:last-child .form-group, .filters-wrapper .single-filter.checkbox-filter .dropdown .dropdown-menu .dropdown-item .row .col-6:nth-last-child(2) .form-group {
  margin-bottom: 0;
}
.filters-wrapper .single-filter.radio-filter .dropdown .dropdown-menu {
  width: 378px;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.filters-wrapper .single-filter.search-filter-dropdown .dropdown .dropdown-menu {
  width: 332px;
}
.filters-wrapper .mobile-filters-wrapper {
  display: contents;
}
.filters-wrapper .mobile-filters-wrapper .filters-loop-wrapper {
  display: contents;
}
.filters-wrapper .btn-close-filter-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.2rem;
}

.range-filter-dropdown .dropdown .dropdown-menu {
  width: 408px;
  font-size: 95%;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status .min-rate, .range-filter-dropdown .dropdown .dropdown-menu .rate-status .max-rate {
  width: 124px;
  height: 54px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: var(--base-color);
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status .min-rate p, .range-filter-dropdown .dropdown .dropdown-menu .rate-status .max-rate p {
  margin: 6px 0;
  font-size: 12px;
  color: #8A8A8A;
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status .min-rate h5, .range-filter-dropdown .dropdown .dropdown-menu .rate-status .max-rate h5 {
  margin: 0;
  font-size: 14px;
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status hr {
  width: 10px !important;
  margin: 26px !important;
  border: 0.5px solid #8A8A8A !important;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-target {
  box-shadow: none;
  border-radius: 18px;
  border-color: var(--default-border-color);
  background-color: var(--range-slider-baseline-bg);
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-horizontal {
  height: 6px;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-horizontal .noUi-handle {
  top: -11px;
  right: -13px;
  cursor: pointer;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
  background-color: #ffffff;
  box-shadow: none !important;
  border: 1px solid #019AFF;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-horizontal .noUi-handle:focus {
  outline: none !important;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:after, .range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:before {
  height: 12px;
  width: 1px;
  position: absolute;
  top: 5px;
  background-color: #9397A0;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:after {
  left: 9px;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:before {
  left: 13px;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-connect {
  background: #019AFF !important;
}

.search-filter-dropdown .btn-filter {
  background: var(--btn-filter-bg);
}
.search-filter-dropdown .btn-filter .clear-icon {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: -1rem;
  font-size: 95% !important;
}
.search-filter-dropdown .btn-filter .clear-icon svg {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.search-filter-dropdown .dropdown-menu {
  z-index: 1030;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
  box-shadow: var(--default-box-shadow);
}
.search-filter-dropdown .dropdown-menu .form-group-with-search {
  padding: 1rem 2rem 1rem 2rem !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper {
  padding: 0.8rem 0;
  max-height: 280px;
  overflow-y: auto;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item {
  font-size: 95% !important;
  color: #898989;
  padding: 1rem 2rem 1rem 2rem !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item.active {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item.selected {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item.selected .check-sign {
  display: block;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item:hover {
  background-color: var(--base-color) !important;
  color: #898989 !important;
}
.search-filter-dropdown .dropdown-menu a .check-sign {
  display: none;
}
.search-filter-dropdown .btn-filter {
  border-radius: 20px;
  color: #8A8A8A;
  box-shadow: var(--default-box-shadow);
  padding: 0.5rem 1.5rem !important;
}
.search-filter-dropdown .btn-filter:hover {
  color: #019AFF;
  background: var(--base-color);
}

.form-group-with-search {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}
.form-group-with-search .form-control {
  border-radius: 20px !important;
  background-color: var(--base-color);
  padding: 0.5rem 1rem 0.5rem 2.2rem !important;
}
.form-group-with-search .form-control-feedback {
  display: block;
  position: absolute;
  pointer-events: none;
  padding: 0 0 0 0.7rem;
  color: #898989;
}
.form-group-with-search .form-control-feedback svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.single-search-wrapper {
  margin-bottom: 0.6rem;
}
.single-search-wrapper .form-group-with-search .form-control {
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.single-search-wrapper .form-group-with-search .form-control:focus {
  border-color: #019AFF !important;
}

.tab-filter-menu .nav-item .nav-link {
  font-size: 80%;
  padding-right: 0;
  padding-left: 1.5rem;
  color: var(--default-font-color);
  transition: color 0.25s ease-in-out;
}
.tab-filter-menu .nav-item .nav-link.active {
  color: #4466F2;
}
.tab-filter-menu .nav-item .nav-link:hover {
  color: #4466F2;
}
.tab-filter-menu .nav-item:first-child .nav-link {
  padding-left: 0;
}
.tab-filter-menu .nav-item:last-child .nav-link {
  padding-right: 0;
}

.dropdown-menu-filter .dropdown .menu-filter-btn {
  font-size: 95%;
  border-radius: 5px;
  color: #8A8A8A;
  padding: 0.5rem 1.5rem;
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.dropdown-menu-filter .dropdown .menu-filter-btn:hover {
  color: #019AFF !important;
  background: var(--base-color);
}
.dropdown-menu-filter .dropdown .menu-filter-btn:focus {
  box-shadow: var(--default-box-shadow) !important;
}
.dropdown-menu-filter .dropdown .menu-filter-btn img {
  height: 16px;
  width: 16px;
  margin-left: 1rem;
}
.dropdown-menu-filter .dropdown .dropdown-menu {
  margin-top: 5px !important;
  font-size: 95%;
}
.dropdown-menu-filter .dropdown .dropdown-menu .dropdown-item {
  padding: 1rem 2rem 1rem 2rem !important;
}
.dropdown-menu-filter .dropdown .dropdown-menu .dropdown-item.active {
  color: #019AFF !important;
  background-color: transparent !important;
}

.filter-subtitle-text {
  color: #707887 !important;
}

.date-filter .btn-filter {
  border-radius: 20px !important;
  padding: 0.5rem 1.5rem !important;
}
.date-filter .btn-filter .clear-icon {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: -1rem;
  font-size: 95% !important;
}
.date-filter .btn-filter .clear-icon svg {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.date-filter .btn-filter.applied {
  color: #019AFF !important;
  background: var(--base-color) !important;
}

.avatars-filter {
  display: inline-flex;
  align-items: center;
}
.avatars-filter .indicator-left,
.avatars-filter .indicator-right {
  width: 10px;
  height: 10px;
  padding: 4px;
  margin: 0 4px;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  border-top: 2px solid #019AFF;
  border-right: 2px solid #019AFF;
  transition: transform 150ms linear;
}
.avatars-filter .indicator-left[disabled],
.avatars-filter .indicator-right[disabled] {
  opacity: 0.35;
}
.avatars-filter .indicator-left {
  transform: rotate(-135deg);
}
.avatars-filter .indicator-left:active {
  transform: rotate(-135deg) scale(0.9);
}
.avatars-filter .indicator-right {
  transform: rotate(45deg);
}
.avatars-filter .indicator-right:active {
  transform: rotate(45deg) scale(0.9);
}
.avatars-filter .avatar-group .avatar-carousel-wrapper {
  width: 280px;
  display: flex;
  transform: translatex(0px);
  transition: transform 150ms ease-out;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar {
  margin-left: -10px;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar:nth-child(1) {
  margin-left: 0;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar img {
  width: 37px;
  height: 37px;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar img.active-avatar {
  border-color: #019AFF !important;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .no-img {
  width: 37px;
  height: 37px;
  font-size: 14px;
  margin-left: -15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .no-img.active-avatar {
  border-color: #019AFF !important;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .no-img:nth-child(1) {
  margin-left: 0;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .status {
  height: 8px;
  width: 8px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

label {
  color: var(--default-font-color) !important;
}

.col-form-label {
  padding-top: calc(0.375rem + 4px);
  padding-bottom: calc(0.375rem + 3px);
}

::placeholder {
  color: #afb1b6 !important;
}

select {
  height: auto !important;
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
  background-position-x: 97% !important;
  background-position-y: 50% !important;
  color: var(--default-font-color) !important;
}
select.custom-select {
  color: var(--default-font-color) !important;
  background-color: var(--form-control-bg) !important;
}
select:focus {
  box-shadow: none !important;
  border-color: #4466F2 !important;
}

textarea {
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  display: block;
  width: 100%;
  outline: none;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus {
  box-shadow: none;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  border-color: #4466F2 !important;
}

.form-control {
  height: auto !important;
  font-size: 100% !important;
  border-radius: 5px !important;
  padding: 10px 14px !important;
  border-color: var(--default-border-color) !important;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
}
.form-control:focus {
  box-shadow: none;
  color: var(--default-font-color);
  background-color: var(--form-control-bg);
  border-color: #4466F2 !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: var(--bg-input-readonly);
}

.form-group-with-icon {
  display: flex;
  align-items: center;
  position: relative;
}
.form-group-with-icon .form-control {
  background-color: var(--form-control-bg);
}
.form-group-with-icon .form-control.text-right-padding {
  padding-right: 44px !important;
}
.form-group-with-icon .form-control-feedback {
  display: inline-block;
  position: absolute;
  right: 0;
  padding: 0 14px;
  color: #bcbec2;
  transition: all 0.25s ease-in-out;
}
.form-group-with-icon .form-control-feedback.disabled {
  color: #ecedee;
}
.form-group-with-icon .form-control-feedback:hover {
  color: #019AFF;
}

.custom-control .custom-control-label {
  color: #8A8A8A !important;
  padding-top: 1px !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.18rem;
}

.custom-checkbox-default .custom-control-label:before {
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='blue' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-default .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-default .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-default .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-primary .custom-control-label:before {
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='blue' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #019AFF;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label {
  color: #019AFF !important;
}
.custom-checkbox-primary .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-primary .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-primary .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-success .custom-control-label:before {
  border-color: #46c35f;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='green' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #46c35f;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-control-input:checked ~ .custom-control-label {
  color: #46c35f !important;
}
.custom-checkbox-success .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-success .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-success .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-warning .custom-control-label:before {
  border-color: #f2a654;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='orange' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #f2a654;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-label {
  color: #f2a654 !important;
}
.custom-checkbox-warning .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-warning .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-warning .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.custom-checkbox-info .custom-control-label:before {
  border-color: rebeccapurple;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='rebeccapurple' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: rebeccapurple;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-control-input:checked ~ .custom-control-label {
  color: rebeccapurple !important;
}
.custom-checkbox-info .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  background-color: var(--default-custom-control-bg);
}
.custom-checkbox-info .custom-control-label:before {
  width: 1rem;
  height: 1rem;
  margin-left: 4.4px;
  transform: scale(1.35) !important;
}
.custom-checkbox-info .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  transform: scale(1.55) !important;
}

.customized-checkbox input {
  opacity: 0;
  position: absolute;
}
.customized-checkbox input + label {
  padding: 0;
  cursor: pointer;
  position: relative;
}
.customized-checkbox input + label:before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  background: transparent;
  border: 1px solid;
  border-radius: 0.18rem;
}
.customized-checkbox input:disabled + label {
  opacity: 0.7;
  cursor: auto;
}
.customized-checkbox input:disabled + label:before {
  opacity: 0.5;
}
.customized-checkbox input:checked + label:after {
  content: "";
  position: absolute;
  left: 4.5px;
  top: 9px;
  width: 2.45px;
  height: 2.45px;
  transform: rotate(45deg);
}
.customized-checkbox.checkbox-default input + label:before {
  border-color: #adb5bd;
}
.customized-checkbox.checkbox-default input:checked + label:before {
  border-color: #019AFF;
}
.customized-checkbox.checkbox-default input:checked + label:after {
  background: #019AFF;
  box-shadow: 2px 0 0 #019AFF, 4px 0 0 #019AFF, 4px -2px 0 #019AFF, 4px -4px 0 #019AFF, 4px -6px 0 #019AFF, 4px -8px 0 #019AFF;
}
.customized-checkbox.checkbox-success input + label:before {
  border-color: #46c35f;
}
.customized-checkbox.checkbox-success input:checked + label:before {
  border-color: #46c35f;
}
.customized-checkbox.checkbox-success input:checked + label:after {
  background: #46c35f;
  box-shadow: 2px 0 0 #46c35f, 4px 0 0 #46c35f, 4px -2px 0 #46c35f, 4px -4px 0 #46c35f, 4px -6px 0 #46c35f, 4px -8px 0 #46c35f;
}
.customized-checkbox.checkbox-warning input + label:before {
  border-color: #FC6510;
}
.customized-checkbox.checkbox-warning input:checked + label:before {
  border-color: #FC6510;
}
.customized-checkbox.checkbox-warning input:checked + label:after {
  background: #FC6510;
  box-shadow: 2px 0 0 #FC6510, 4px 0 0 #FC6510, 4px -2px 0 #FC6510, 4px -4px 0 #FC6510, 4px -6px 0 #FC6510, 4px -8px 0 #FC6510;
}
.customized-checkbox.checkbox-info input + label:before {
  border-color: #38a4f8;
}
.customized-checkbox.checkbox-info input:checked + label:before {
  border-color: #38a4f8;
}
.customized-checkbox.checkbox-info input:checked + label:after {
  background: #38a4f8;
  box-shadow: 2px 0 0 #38a4f8, 4px 0 0 #38a4f8, 4px -2px 0 #38a4f8, 4px -4px 0 #38a4f8, 4px -6px 0 #38a4f8, 4px -8px 0 #38a4f8;
}

.customized-radio {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin: 0 1rem 0 0;
  display: inline-block;
}
.customized-radio.disabled {
  opacity: 0.7;
}
.customized-radio.disabled .outside {
  background-color: var(--bg-input-readonly);
}
.customized-radio input {
  opacity: 0;
  width: 1px;
  height: 1px;
}
.customized-radio .outside {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  border: 1px solid;
  border-radius: 50%;
  background: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.customized-radio .outside .inside {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
}
.customized-radio input:checked + .outside .inside {
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  animation: radio-select 0.1s linear;
  -webkit-animation: radio-select 0.1s linear;
}
.customized-radio.mini {
  padding-left: 20px;
}
.customized-radio.mini .outside {
  width: 16px;
  height: 16px;
  margin-top: -8px;
}
.customized-radio.mini .outside .inside {
  top: 2.8px;
  left: 3px;
  width: 8px;
  height: 8px;
}
.customized-radio.radio-default .outside {
  border-color: #019AFF;
}
.customized-radio.radio-default .outside .inside {
  background: #019AFF;
}
.customized-radio.radio-primary .outside {
  border-color: #019AFF;
}
.customized-radio.radio-primary .outside .inside {
  background: #019AFF;
}
.customized-radio.radio-success .outside {
  border-color: #46c35f;
}
.customized-radio.radio-success .outside .inside {
  background: #46c35f;
}
.customized-radio.radio-warning .outside {
  border-color: #FC6510;
}
.customized-radio.radio-warning .outside .inside {
  background: #FC6510;
}
.customized-radio.radio-info .outside {
  border-color: #38a4f8;
}
.customized-radio.radio-info .outside .inside {
  background: #38a4f8;
}

.radio-button-group .btn-group {
  border-radius: 0.3rem;
  display: initial !important;
}
.radio-button-group .btn-group:focus {
  outline: none;
}
.radio-button-group .btn-group .btn {
  padding: 10px 1.2rem;
  background: transparent;
}
.radio-button-group .btn-group .btn.focus {
  box-shadow: none;
}
.radio-button-group .btn-group .btn.active {
  box-shadow: none;
  border-radius: 0.3rem;
  background-color: #019AFF;
  border: 1px solid #019AFF !important;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.radio-button-group .btn-group .btn.active span {
  color: #ffffff;
}

.border-switch {
  min-height: initial;
  color: #019AFF;
  padding-left: 0;
}
.border-switch .border-switch-control-input {
  display: none;
}
.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator {
  border-color: #019AFF;
}
.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator::after {
  left: 14px;
  background-color: #019AFF;
}
.border-switch .border-switch-control-indicator {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 20px;
  border-radius: 16px;
  transition: 0.3s;
  border: 2px solid #ccc;
}
.border-switch .border-switch-control-indicator::after {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.3s;
  top: 2px;
  left: 2px;
  background: #ccc;
}

.chips-container {
  cursor: pointer;
  overflow-y: auto;
  min-height: 70px;
  max-height: 350px;
  border-radius: 5px;
  color: #898989;
  background-color: var(--base-color);
}
.chips-container .add {
  transition: color 0.25s ease-in-out;
}
.chips-container .add:hover {
  color: #565656;
}
.chips-container .chips {
  font-size: 90%;
  border-radius: 22px;
  background-color: var(--chips-bg);
  box-shadow: var(--default-box-shadow);
  padding: 0.35rem 0.45rem 0.35rem 1rem;
}
.chips-container .chips .delete-chips {
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--base-color);
}

.chips-dropdown .dropdown-menu {
  top: auto !important;
}

.wizard-steps {
  font-size: 90% !important;
}
.wizard-steps hr {
  width: 100% !important;
  margin: 0 !important;
}
.wizard-steps .nav-link {
  padding: 0;
}
.wizard-steps .tab-step {
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: var(--base-color);
  position: relative;
  color: #9397A0;
  padding-right: 16px;
}
.wizard-steps .tab-step:hover {
  color: #019AFF;
}
.wizard-steps .tab-step-init {
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: var(--base-color);
  position: relative;
  color: #9397A0;
  padding: 9px 16px 0 16px;
}
.wizard-steps .tab-step-init:hover {
  color: #019AFF;
}
.wizard-steps .tab-step:before {
  width: 20px;
  height: 42px;
  border-radius: 0 20px 20px 0;
  background-color: var(--tab-step-before-bg);
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  margin-top: -1px !important;
  content: "";
}
.wizard-steps .nav-pills .nav-link.active, .wizard-steps .nav-pills .show > .nav-link {
  background: none;
}
.wizard-steps .nav-link.active .tab-step, .wizard-steps .nav-link.active .tab-step-init {
  color: #ffffff;
  background-color: #019AFF;
}
.wizard-steps .nav-pills .nav-item {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: -13px !important;
}
.wizard-steps .nav-pills .nav-item:first-child {
  margin-left: 0 !important;
  z-index: 105;
}
.wizard-steps .nav-pills .nav-item:nth-child(2) {
  z-index: 18;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(3) {
  z-index: 17;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(4) {
  z-index: 16;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(5) {
  z-index: 15;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(6) {
  z-index: 14;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(7) {
  z-index: 13;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(8) {
  z-index: 12;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(9) {
  z-index: 11;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(10) {
  z-index: 10;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(11) {
  z-index: 9;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(12) {
  z-index: 8;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(13) {
  z-index: 7;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(14) {
  z-index: 6;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(15) {
  z-index: 5;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(16) {
  z-index: 4;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(17) {
  z-index: 3;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(18) {
  z-index: 2;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(19) {
  z-index: 1;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(20) {
  z-index: 0;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(21) {
  z-index: -1;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(22) {
  z-index: -2;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(23) {
  z-index: -3;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(24) {
  z-index: -4;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(25) {
  z-index: -5;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(26) {
  z-index: -6;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(27) {
  z-index: -7;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(28) {
  z-index: -8;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(29) {
  z-index: -9;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(30) {
  z-index: -10;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(31) {
  z-index: -11;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(32) {
  z-index: -12;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(33) {
  z-index: -13;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(34) {
  z-index: -14;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(35) {
  z-index: -15;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(36) {
  z-index: -16;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(37) {
  z-index: -17;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(38) {
  z-index: -18;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(39) {
  z-index: -19;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(40) {
  z-index: -20;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(41) {
  z-index: -21;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(42) {
  z-index: -22;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(43) {
  z-index: -23;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(44) {
  z-index: -24;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(45) {
  z-index: -25;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(46) {
  z-index: -26;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(47) {
  z-index: -27;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(48) {
  z-index: -28;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(49) {
  z-index: -29;
  margin-left: -10px;
}
.wizard-steps .nav-pills .nav-item:nth-child(50) {
  z-index: -30;
  margin-left: -10px;
}

.note-frame {
  height: initial !important;
  border: 1px solid var(--default-border-color) !important;
}
.note-frame.card {
  height: initial !important;
}
.note-frame .card-header {
  padding: 1rem;
  background: transparent;
  border-color: var(--default-border-color);
}
.note-frame .card-header.note-toolbar {
  padding: 5px;
}
.note-frame .card-header .note-btn-group {
  margin-top: 0 !important;
}
.note-frame .card-header .note-btn-group .dropdown-toggle, .note-frame .card-header .note-btn-group .note-btn {
  color: var(--default-font-color);
  border-color: var(--default-border-color);
  background-color: var(--btn-light-bg) !important;
}
.note-frame .card-header .note-btn-group .note-dropdown-menu {
  border-color: var(--default-border-color);
  background-color: var(--btn-light-bg) !important;
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item pre {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .dropdown-item:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-palette .note-palette-title {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-palette .note-color-reset:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu .note-dimension-display {
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .dropdown-item .note-icon-menu-check {
  visibility: initial;
  font-style: initial;
  color: var(--default-font-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .dropdown-item.checked {
  background-color: var(--base-color);
}
.note-frame .card-header .note-btn-group .note-dropdown-menu.dropdown-fontname .note-icon-menu-check::before {
  display: none;
}
.note-frame .note-palette .note-color-reset:hover {
  color: var(--default-font-color);
  background-color: var(--base-color);
}
.note-frame .note-editable {
  padding: 1rem !important;
  color: var(--default-font-color) !important;
  background-color: var(--form-control-bg) !important;
}
.note-frame .note-editable.card-block::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.note-frame .note-editable.card-block::-webkit-scrollbar-track {
  background: transparent;
}
.note-frame .note-editable.card-block::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.note-frame .note-editable.card-block:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.note-frame .note-editable.card-block:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}
.note-frame .note-codable {
  height: 300px !important;
}
.note-frame .note-codable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.note-frame .note-codable::-webkit-scrollbar-track {
  background: transparent;
}
.note-frame .note-codable::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.note-frame .note-codable:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
.note-frame .note-codable:hover::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}
.note-frame .note-placeholder {
  padding: 1rem !important;
}
.note-frame .note-statusbar {
  background-color: var(--default-border-color) !important;
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top: 1px solid var(--default-border-color) !important;
}

.custom-file:focus {
  outline: none !important;
}
.custom-file .custom-file-input {
  z-index: 1;
  height: auto;
}
.custom-file .custom-file-input:focus ~ .custom-file-label {
  border-color: var(--default-border-color) !important;
}
.custom-file .custom-file-input:disabled ~ .custom-file-label {
  pointer-events: none;
  background-color: var(--bg-input-readonly);
}
.custom-file .custom-file-label {
  z-index: 2;
  height: auto;
  padding: 8px 14px !important;
  line-height: 1.5;
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--default-border-color);
  font-size: 100% !important;
  background-color: var(--form-control-bg);
}
.custom-file .custom-file-label:focus {
  border-color: var(--default-border-color);
}
.custom-file .custom-file-label::after {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 10px 14px !important;
  line-height: 1.5;
  height: auto;
  background: #019AFF;
  color: #ffffff;
}

.vue-tel-input {
  border-radius: 5px !important;
  border-color: var(--default-border-color) !important;
}
.vue-tel-input:focus, .vue-tel-input:focus-within {
  box-shadow: none !important;
  border-color: #019AFF !important;
}
.vue-tel-input .vti__dropdown {
  border-radius: 5px 0 0 5px !important;
  padding: 10px 14px !important;
  background-color: var(--form-control-bg) !important;
}
.vue-tel-input .vti__dropdown:focus {
  outline: none !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list {
  border-color: var(--default-border-color) !important;
  background-color: var(--form-control-bg) !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar-track {
  background: transparent;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--scrollbar-thumb-color);
}
.vue-tel-input .vti__dropdown .vti__dropdown-list .vti__dropdown-item {
  font-size: 95% !important;
  color: #898989 !important;
  padding: 1rem 2rem 1rem 2rem !important;
}
.vue-tel-input .vti__dropdown .vti__dropdown-list .vti__dropdown-item.highlighted {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.vue-tel-input .vti__input {
  border-radius: 0 5px 5px 0 !important;
  background-color: var(--form-control-bg) !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.btn-filter {
  font-size: 95%;
  color: #8A8A8A;
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.btn-filter:hover {
  color: #019AFF;
  background: var(--base-color);
}
.btn-filter:focus {
  box-shadow: var(--default-box-shadow) !important;
}
.btn-filter.btn-list-grid {
  padding: 0.5rem;
  border-radius: 5px;
}
.btn-filter.btn-list-grid svg {
  width: 20px;
  height: 20px;
}

.filters-wrapper .single-filter {
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
}
.filters-wrapper .single-filter:last-child {
  margin-right: 0;
}
.filters-wrapper .single-filter .dropdown .btn-filter {
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
}
.filters-wrapper .single-filter .dropdown .btn-filter .clear-icon {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: -1rem;
  font-size: 95% !important;
}
.filters-wrapper .single-filter .dropdown .btn-filter .clear-icon svg {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.filters-wrapper .single-filter .dropdown .btn-filter.applied {
  color: #019AFF;
  background: var(--base-color);
}
.filters-wrapper .single-filter .dropdown .dropdown-menu {
  padding: 0;
  font-size: 95%;
  margin-top: 5px !important;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item {
  padding: 2rem;
  font-size: 95%;
  white-space: normal;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--default-font-color);
  background: transparent;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item .filter-action-button-wrapper .btn-clear {
  color: #9397A0;
}
.filters-wrapper .single-filter .dropdown .dropdown-menu .dropdown-item .filter-action-button-wrapper .btn-clear:hover {
  color: var(--default-font-color);
}
.filters-wrapper .single-filter .dropdown.show .btn-filter {
  color: #019AFF;
  background: var(--base-color);
}
.filters-wrapper .single-filter.column-filter .btn-filter {
  padding: 0.5rem;
  border-radius: 5px;
}
.filters-wrapper .single-filter.column-filter .btn-filter svg {
  width: 20px;
  height: 20px;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu {
  z-index: 1040 !important;
  width: 352px;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .content-type svg {
  cursor: grab;
  box-sizing: border-box;
  border-radius: 5px;
  color: #dce6ff;
  padding: 2px;
  margin-right: 10px;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch {
  box-shadow: none;
  transform: scale(1.5) !important;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-label:before {
  border: none;
  background-color: #9397A0;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-label:after {
  background-color: #ffffff;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #019AFF;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .manage-column-options {
  overflow-y: auto;
  max-height: 240px;
}
.filters-wrapper .single-filter.column-filter .ghost {
  background: #c8ebfb !important;
}
.filters-wrapper .single-filter.column-filter .catch-item {
  background: var(--base-color) !important;
}
.filters-wrapper .single-filter.checkbox-filter .dropdown .dropdown-menu {
  width: 435px;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.filters-wrapper .single-filter.checkbox-filter .dropdown .dropdown-menu .dropdown-item .row .col-6:last-child .form-group, .filters-wrapper .single-filter.checkbox-filter .dropdown .dropdown-menu .dropdown-item .row .col-6:nth-last-child(2) .form-group {
  margin-bottom: 0;
}
.filters-wrapper .single-filter.radio-filter .dropdown .dropdown-menu {
  width: 378px;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.filters-wrapper .single-filter.search-filter-dropdown .dropdown .dropdown-menu {
  width: 332px;
}
.filters-wrapper .mobile-filters-wrapper {
  display: contents;
}
.filters-wrapper .mobile-filters-wrapper .filters-loop-wrapper {
  display: contents;
}
.filters-wrapper .btn-close-filter-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.2rem;
}

.range-filter-dropdown .dropdown .dropdown-menu {
  width: 408px;
  font-size: 95%;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status .min-rate, .range-filter-dropdown .dropdown .dropdown-menu .rate-status .max-rate {
  width: 124px;
  height: 54px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: var(--base-color);
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status .min-rate p, .range-filter-dropdown .dropdown .dropdown-menu .rate-status .max-rate p {
  margin: 6px 0;
  font-size: 12px;
  color: #8A8A8A;
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status .min-rate h5, .range-filter-dropdown .dropdown .dropdown-menu .rate-status .max-rate h5 {
  margin: 0;
  font-size: 14px;
}
.range-filter-dropdown .dropdown .dropdown-menu .rate-status hr {
  width: 10px !important;
  margin: 26px !important;
  border: 0.5px solid #8A8A8A !important;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-target {
  box-shadow: none;
  border-radius: 18px;
  border-color: var(--default-border-color);
  background-color: var(--range-slider-baseline-bg);
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-horizontal {
  height: 6px;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-horizontal .noUi-handle {
  top: -11px;
  right: -13px;
  cursor: pointer;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
  background-color: #ffffff;
  box-shadow: none !important;
  border: 1px solid #019AFF;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-horizontal .noUi-handle:focus {
  outline: none !important;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:after, .range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:before {
  height: 12px;
  width: 1px;
  position: absolute;
  top: 5px;
  background-color: #9397A0;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:after {
  left: 9px;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-handle:before {
  left: 13px;
}
.range-filter-dropdown .dropdown .dropdown-menu .noUi-connect {
  background: #019AFF !important;
}

.search-filter-dropdown .btn-filter {
  background: var(--btn-filter-bg);
}
.search-filter-dropdown .btn-filter .clear-icon {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: -1rem;
  font-size: 95% !important;
}
.search-filter-dropdown .btn-filter .clear-icon svg {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.search-filter-dropdown .dropdown-menu {
  z-index: 1030;
  position: initial;
  right: initial;
  box-sizing: border-box;
  border-radius: 0.25rem;
  box-shadow: var(--default-box-shadow);
}
.search-filter-dropdown .dropdown-menu .form-group-with-search {
  padding: 1rem 2rem 1rem 2rem !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper {
  padding: 0.8rem 0;
  max-height: 280px;
  overflow-y: auto;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item {
  font-size: 95% !important;
  color: #898989;
  padding: 1rem 2rem 1rem 2rem !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item.active {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item.selected {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item.selected .check-sign {
  display: block;
}
.search-filter-dropdown .dropdown-menu .dropdown-search-result-wrapper .dropdown-item:hover {
  background-color: var(--base-color) !important;
  color: #898989 !important;
}
.search-filter-dropdown .dropdown-menu a .check-sign {
  display: none;
}
.search-filter-dropdown .btn-filter {
  border-radius: 20px;
  color: #8A8A8A;
  box-shadow: var(--default-box-shadow);
  padding: 0.5rem 1.5rem !important;
}
.search-filter-dropdown .btn-filter:hover {
  color: #019AFF;
  background: var(--base-color);
}

.form-group-with-search {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}
.form-group-with-search .form-control {
  border-radius: 20px !important;
  background-color: var(--base-color);
  padding: 0.5rem 1rem 0.5rem 2.2rem !important;
}
.form-group-with-search .form-control-feedback {
  display: block;
  position: absolute;
  pointer-events: none;
  padding: 0 0 0 0.7rem;
  color: #898989;
}
.form-group-with-search .form-control-feedback svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.single-search-wrapper {
  margin-bottom: 0.6rem;
}
.single-search-wrapper .form-group-with-search .form-control {
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.single-search-wrapper .form-group-with-search .form-control:focus {
  border-color: #019AFF !important;
}

.tab-filter-menu .nav-item .nav-link {
  font-size: 80%;
  padding-right: 0;
  padding-left: 1.5rem;
  color: var(--default-font-color);
  transition: color 0.25s ease-in-out;
}
.tab-filter-menu .nav-item .nav-link.active {
  color: #4466F2;
}
.tab-filter-menu .nav-item .nav-link:hover {
  color: #4466F2;
}
.tab-filter-menu .nav-item:first-child .nav-link {
  padding-left: 0;
}
.tab-filter-menu .nav-item:last-child .nav-link {
  padding-right: 0;
}

.dropdown-menu-filter .dropdown .menu-filter-btn {
  font-size: 95%;
  border-radius: 5px;
  color: #8A8A8A;
  padding: 0.5rem 1.5rem;
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.dropdown-menu-filter .dropdown .menu-filter-btn:hover {
  color: #019AFF !important;
  background: var(--base-color);
}
.dropdown-menu-filter .dropdown .menu-filter-btn:focus {
  box-shadow: var(--default-box-shadow) !important;
}
.dropdown-menu-filter .dropdown .menu-filter-btn img {
  height: 16px;
  width: 16px;
  margin-left: 1rem;
}
.dropdown-menu-filter .dropdown .dropdown-menu {
  margin-top: 5px !important;
  font-size: 95%;
}
.dropdown-menu-filter .dropdown .dropdown-menu .dropdown-item {
  padding: 1rem 2rem 1rem 2rem !important;
}
.dropdown-menu-filter .dropdown .dropdown-menu .dropdown-item.active {
  color: #019AFF !important;
  background-color: transparent !important;
}

.filter-subtitle-text {
  color: #707887 !important;
}

.date-filter .btn-filter {
  border-radius: 20px !important;
  padding: 0.5rem 1.5rem !important;
}
.date-filter .btn-filter .clear-icon {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: -1rem;
  font-size: 95% !important;
}
.date-filter .btn-filter .clear-icon svg {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.date-filter .btn-filter.applied {
  color: #019AFF !important;
  background: var(--base-color) !important;
}

.avatars-filter {
  display: inline-flex;
  align-items: center;
}
.avatars-filter .indicator-left,
.avatars-filter .indicator-right {
  width: 10px;
  height: 10px;
  padding: 4px;
  margin: 0 4px;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  border-top: 2px solid #019AFF;
  border-right: 2px solid #019AFF;
  transition: transform 150ms linear;
}
.avatars-filter .indicator-left[disabled],
.avatars-filter .indicator-right[disabled] {
  opacity: 0.35;
}
.avatars-filter .indicator-left {
  transform: rotate(-135deg);
}
.avatars-filter .indicator-left:active {
  transform: rotate(-135deg) scale(0.9);
}
.avatars-filter .indicator-right {
  transform: rotate(45deg);
}
.avatars-filter .indicator-right:active {
  transform: rotate(45deg) scale(0.9);
}
.avatars-filter .avatar-group .avatar-carousel-wrapper {
  width: 280px;
  display: flex;
  transform: translatex(0px);
  transition: transform 150ms ease-out;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar {
  margin-left: -10px;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar:nth-child(1) {
  margin-left: 0;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar img {
  width: 37px;
  height: 37px;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar img.active-avatar {
  border-color: #019AFF !important;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .no-img {
  width: 37px;
  height: 37px;
  font-size: 14px;
  margin-left: -15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .no-img.active-avatar {
  border-color: #019AFF !important;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .no-img:nth-child(1) {
  margin-left: 0;
}
.avatars-filter .avatar-group .avatar-carousel-wrapper .single-avatar .status {
  height: 8px;
  width: 8px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.calendar-root .input-date {
  font-size: 95%;
  cursor: pointer;
  border-radius: 20px;
  color: #8A8A8A;
  padding: 0.5rem 1.5rem;
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.calendar-root .input-date:hover {
  border-radius: 20px;
  color: #019AFF;
  background: var(--base-color);
}
.calendar-root .input-date .clear-icon {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: -1rem;
  font-size: 95% !important;
}
.calendar-root .input-date .clear-icon svg {
  width: 16px;
  height: 16px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.calendar-root .input-date.applied {
  color: #019AFF;
  background: var(--base-color);
}
.calendar-root .months-text {
  text-align: center;
  font-weight: bold;
  color: #8A8A8A;
}
.calendar-root .months-text .left {
  float: left;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMxLjQ5NCAzMS40OTQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMxLjQ5NCAzMS40OTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTEwLjI3Myw1LjAwOWMwLjQ0NC0wLjQ0NCwxLjE0My0wLjQ0NCwxLjU4NywwYzAuNDI5LDAuNDI5LDAuNDI5LDEuMTQzLDAsMS41NzFsLTguMDQ3LDguMDQ3aDI2LjU1NCAgYzAuNjE5LDAsMS4xMjcsMC40OTIsMS4xMjcsMS4xMTFjMCwwLjYxOS0wLjUwOCwxLjEyNy0xLjEyNywxLjEyN0gzLjgxM2w4LjA0Nyw4LjAzMmMwLjQyOSwwLjQ0NCwwLjQyOSwxLjE1OSwwLDEuNTg3ICBjLTAuNDQ0LDAuNDQ0LTEuMTQzLDAuNDQ0LTEuNTg3LDBsLTkuOTUyLTkuOTUyYy0wLjQyOS0wLjQyOS0wLjQyOS0xLjE0MywwLTEuNTcxTDEwLjI3Myw1LjAwOXoiIGZpbGw9IiMwMDZERjAiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
}
.calendar-root .months-text .right {
  float: right;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMxLjQ5IDMxLjQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMS40OSAzMS40OTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cGF0aCBkPSJNMjEuMjA1LDUuMDA3Yy0wLjQyOS0wLjQ0NC0xLjE0My0wLjQ0NC0xLjU4NywwYy0wLjQyOSwwLjQyOS0wLjQyOSwxLjE0MywwLDEuNTcxbDguMDQ3LDguMDQ3SDEuMTExICBDMC40OTIsMTQuNjI2LDAsMTUuMTE4LDAsMTUuNzM3YzAsMC42MTksMC40OTIsMS4xMjcsMS4xMTEsMS4xMjdoMjYuNTU0bC04LjA0Nyw4LjAzMmMtMC40MjksMC40NDQtMC40MjksMS4xNTksMCwxLjU4NyAgYzAuNDQ0LDAuNDQ0LDEuMTU5LDAuNDQ0LDEuNTg3LDBsOS45NTItOS45NTJjMC40NDQtMC40MjksMC40NDQtMS4xNDMsMC0xLjU3MUwyMS4yMDUsNS4wMDd6IiBmaWxsPSIjMDA2REYwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=");
}
.calendar-root .calendar {
  z-index: 9;
  width: 730px;
  margin-top: 5px;
  background: var(--calendar-bg);
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.25rem;
  box-shadow: var(--default-box-shadow);
  /*
    radio button from preset-range filter is replaced with span element &
    button of [clear] is replaced with div
    we also need these styling
  */
}
.calendar-root .calendar ul {
  list-style-type: none;
}
.calendar-root .calendar .calendar-wrap {
  width: 72%;
  float: left;
  font-size: 12px;
  display: inline-block;
  padding: 0 10px 10px 10px;
}
.calendar-root .calendar .calendar-wrap .calendar_month_left, .calendar-root .calendar .calendar-wrap .calendar_month_right {
  width: 46%;
  margin: 5px;
  float: left;
  padding: 10px;
}
.calendar-root .calendar .clearfix {
  border-bottom: 1px solid var(--default-border-color);
}
.calendar-root .calendar .calendar-range {
  float: left;
  margin: -2px;
  padding: 0 12px;
  font-size: 12px;
  border-left: 1px solid var(--default-border-color);
}
.calendar-root .calendar .calendar-range .calendar_preset {
  padding: 0;
}
.calendar-root .calendar .calendar-range .calendar_preset li {
  line-height: 1.6em;
  width: auto;
  display: block;
  margin: 15px 0 !important;
}
.calendar-root .calendar .calendar-range .calendar_preset li.calendar_preset-ranges {
  padding: 0 15px 0 20px;
  margin-bottom: 5px;
  cursor: pointer;
  margin-top: 1px;
}
.calendar-root .calendar .calendar-left-mobile {
  width: 100% !important;
}
.calendar-root .calendar .calendar_weeks {
  margin: 0;
  padding: 10px 0;
  width: auto;
}
.calendar-root .calendar .calendar_weeks li {
  width: 13.6%;
  text-align: center;
  display: inline-block;
  color: #8A8A8A;
}
.calendar-root .calendar .calendar_days {
  margin: 0;
  padding: 0;
}
.calendar-root .calendar .calendar_days li {
  width: 13.6%;
  cursor: pointer;
  line-height: 2em;
  text-align: center;
  display: inline-block;
  color: #8A8A8A;
}
.calendar-root .calendar .custom-radio .custom-control-label:before {
  transform: scale(1.3);
}
.calendar-root .calendar .custom-radio .custom-control-label:after {
  transform: scale(1.4);
}
.calendar-root .calendar .calendar_days li:hover {
  background: var(--base-color);
  color: #8A8A8A;
}
.calendar-root .calendar li.calendar_days--disabled {
  pointer-events: none;
}
.calendar-root .calendar li.calendar_days_selected {
  background: #019AFF;
  color: #ffffff;
}
.calendar-root .calendar li.calendar_days_selected:hover {
  background-color: #0b9eff;
  color: #ffffff;
}
.calendar-root .calendar li.calendar_days_in-range {
  background-color: #1ba4ff;
  color: #ffffff;
}
.calendar-root .calendar li.calendar_days_in-range:hover {
  background-color: #10a0ff;
  color: #ffffff;
}
.calendar-root .calendar .calendar-mobile {
  width: 260px;
  z-index: 1;
  box-shadow: none;
}
.calendar-root .calendar .calendar-range-mobile {
  width: 90%;
  padding: 2px;
  border-left: none;
  margin: -20px 0;
}
.calendar-root .calendar .filter-action-button-wrapper {
  padding: 2rem;
  font-size: 95%;
}
.calendar-root .calendar .filter-action-button-wrapper .btn-clear {
  color: #9397A0;
}
.calendar-root .calendar .filter-action-button-wrapper .btn-clear:hover {
  color: var(--default-font-color);
}
.calendar-root .calendar .preset-active-input > .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #019AFF;
}
.calendar-root .calendar .preset-active-input > .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='blue'/%3e%3c/svg%3e");
}
.calendar-root .calendar .selected:after {
  content: none !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.avatars-w-100 {
  position: relative;
}
.avatars-w-100 .no-img, .avatars-w-100 img {
  height: 100px;
  width: 100px;
}
.avatars-w-100 .no-img {
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-100 .status {
  height: 16px;
  width: 16px;
  right: 5px;
  bottom: 5px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-90 {
  position: relative;
}
.avatars-w-90 .no-img, .avatars-w-90 img {
  height: 90px;
  width: 90px;
}
.avatars-w-90 .no-img {
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-90 .status {
  height: 15px;
  width: 15px;
  right: 3.9px;
  bottom: 3.9px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-80 {
  position: relative;
}
.avatars-w-80 .no-img, .avatars-w-80 img {
  height: 80px;
  width: 80px;
}
.avatars-w-80 .no-img {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-80 .status {
  height: 14px;
  width: 14px;
  right: 2.8px;
  bottom: 2.8px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-70 {
  position: relative;
}
.avatars-w-70 .no-img, .avatars-w-70 img {
  height: 70px;
  width: 70px;
}
.avatars-w-70 .no-img {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-70 .status {
  height: 13px;
  width: 13px;
  right: 1.9px;
  bottom: 1.9px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-60 {
  position: relative;
}
.avatars-w-60 .no-img, .avatars-w-60 img {
  height: 60px;
  width: 60px;
}
.avatars-w-60 .no-img {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-60 .status {
  height: 12px;
  width: 12px;
  right: 1.2px;
  bottom: 1.2px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-50 {
  position: relative;
}
.avatars-w-50 .no-img, .avatars-w-50 img {
  height: 50px;
  width: 50px;
}
.avatars-w-50 .no-img {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-50 .status {
  height: 11px;
  width: 11px;
  right: 0.7px;
  bottom: 0.7px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-40 {
  position: relative;
}
.avatars-w-40 .no-img, .avatars-w-40 img {
  height: 40px;
  width: 40px;
}
.avatars-w-40 .no-img {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-40 .status {
  height: 10px;
  width: 10px;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-30 {
  position: relative;
}
.avatars-w-30 .no-img, .avatars-w-30 img {
  height: 30px;
  width: 30px;
}
.avatars-w-30 .no-img {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-30 .status {
  height: 9px;
  width: 9px;
  right: -0.5px;
  bottom: -0.5px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

.avatars-w-20 {
  position: relative;
}
.avatars-w-20 .no-img, .avatars-w-20 img {
  height: 20px;
  width: 20px;
}
.avatars-w-20 .no-img {
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-20 .status {
  height: 8px;
  width: 8px;
  right: -1px;
  bottom: -1px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--default-card-bg);
}

/* Avatars Group */
.avatar-group {
  cursor: pointer;
}
.avatar-group .rounded-circle:hover {
  transform: translateY(-2px) scale(1.02);
  transition: 0.5s;
}

.avatars-group-w-50 .no-img, .avatars-group-w-50 img {
  height: 50px;
  width: 50px;
  margin-left: -15px;
}
.avatars-group-w-50 .no-img:nth-child(1), .avatars-group-w-50 img:nth-child(1) {
  margin-left: 0;
}
.avatars-group-w-50 .no-img {
  display: inline-flex !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
  font-size: 14px;
}

.avatars-group-w-60 .no-img, .avatars-group-w-60 img {
  height: 60px;
  width: 60px;
  margin-left: -15px;
}
.avatars-group-w-60 .no-img:nth-child(1), .avatars-group-w-60 img:nth-child(1) {
  margin-left: 0;
}
.avatars-group-w-60 .no-img {
  display: inline-flex !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
  font-size: 16px;
}

@media (max-width: 767px) {
  .avatars-with-shadow {
    display: inline-block;
  }
}
.avatar-bordered {
  border: 2px solid var(--avaters-border-color) !important;
}

.avatar-shadow {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.dropzone {
  min-height: 150px !important;
  padding: 20px 20px !important;
  border-radius: 0.25rem !important;
  border: 1px dashed #019AFF !important;
  background: var(--form-control-bg) !important;
}
.dropzone svg {
  width: 80px;
  height: 80px;
}
.dropzone .dz-preview.dz-image-preview {
  border-radius: 20px;
  background: transparent !important;
}
.dropzone .dz-preview .dz-details .dz-filename {
  border-radius: 20px;
  overflow: hidden !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.time-picker-input {
  cursor: pointer;
}
.time-picker-input .form-control {
  text-align: left;
  border: none !important;
}
.time-picker-input .input-group-text {
  border: none !important;
  background-color: var(--form-control-bg);
}
.time-picker-input .input-group-text svg {
  stroke-width: 1;
  color: #019AFF;
}
.time-picker-input .input-group-text .clear-icon {
  cursor: pointer;
  margin-right: 0.75rem;
}
.time-picker-input .input-group-text .clear-icon svg {
  width: 20px;
  height: 20px;
  stroke-width: 1;
  padding: 0.1rem;
  border-radius: 50%;
  box-shadow: var(--shadow-x-icon);
  background-color: var(--bg-x-icon);
}
.time-picker-input .input-group {
  border: 1px solid var(--btn-filter-bg) !important;
  border-color: var(--default-border-color) !important;
  border-radius: 5px !important;
}
.time-picker-input .keep-inside-clicks-open.show .input-group {
  border-color: #019AFF !important;
}
.time-picker-input .dropdown .dropdown-menu {
  min-height: 50px;
  min-width: max-content;
  border: none !important;
  background-color: transparent;
  box-shadow: var(--default-box-shadow) !important;
}
.time-picker-input .dropdown .dropdown-menu.show {
  top: 100% !important;
}
.time-picker-input .dropdown .dropdown-menu:before, .time-picker-input .dropdown .dropdown-menu:after {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  position: absolute;
}
.time-picker-input .dropdown .dropdown-menu .dropdown-item.active {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.time-picker-input .dropdown .dropdown-menu .dropdown-item.selected {
  color: #898989 !important;
  background-color: var(--base-color) !important;
}
.time-picker-input .dropdown .dropdown-menu .dropdown-item.selected .check-sign {
  display: block;
}
.time-picker-input .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--base-color) !important;
  color: #898989 !important;
}
.time-picker-input .dropdown .dropdown-menu .hour, .time-picker-input .dropdown .dropdown-menu .minute, .time-picker-input .dropdown .dropdown-menu .am-pm {
  float: left;
  height: 250px;
  min-width: 73px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--time-picker-bg);
}
.time-picker-input .dropdown .dropdown-menu .hour::-webkit-scrollbar-thumb, .time-picker-input .dropdown .dropdown-menu .minute::-webkit-scrollbar-thumb, .time-picker-input .dropdown .dropdown-menu .am-pm::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}
.time-picker-input .dropdown .dropdown-menu .hour::-webkit-scrollbar-thumb:hover, .time-picker-input .dropdown .dropdown-menu .minute::-webkit-scrollbar-thumb:hover, .time-picker-input .dropdown .dropdown-menu .am-pm::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}
.time-picker-input .dropdown .dropdown-menu .hour::-webkit-scrollbar-track, .time-picker-input .dropdown .dropdown-menu .minute::-webkit-scrollbar-track, .time-picker-input .dropdown .dropdown-menu .am-pm::-webkit-scrollbar-track {
  background-color: var(--base-color);
}
.time-picker-input .dropdown .dropdown-menu .hour li, .time-picker-input .dropdown .dropdown-menu .minute li, .time-picker-input .dropdown .dropdown-menu .am-pm li {
  font-size: 90% !important;
  color: var(--default-font-color) !important;
}
.time-picker-input .dropdown .dropdown-menu .hour li:hover, .time-picker-input .dropdown .dropdown-menu .minute li:hover, .time-picker-input .dropdown .dropdown-menu .am-pm li:hover {
  background-color: var(--base-color) !important;
}
.time-picker-input .dropdown .dropdown-menu .hour {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.time-picker-input .dropdown .dropdown-menu .am-pm {
  overflow: hidden !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
@media (max-width: 399px) {
  .time-picker-input .dropdown .dropdown-menu .hour, .time-picker-input .dropdown .dropdown-menu .minute {
    width: 50%;
    height: 120px;
  }
  .time-picker-input .dropdown .dropdown-menu .am-pm {
    width: 100%;
    height: 96px;
  }
}
@media (min-width: 500px) {
  .time-picker-input .dropdown .dropdown-menu .hour, .time-picker-input .dropdown .dropdown-menu .minute, .time-picker-input .dropdown .dropdown-menu .am-pm {
    min-width: 120px;
  }
}
.time-picker-input .dropdown .dropdown-menu[x-placement^=left],
.time-picker-input .dropdown .dropdown-menu[x-placement^=right],
.time-picker-input .dropdown .dropdown-menu[x-placement^=top] {
  top: auto !important;
  -webkit-animation: none !important;
  animation: none !important;
}
.time-picker-input .dropdown .dropdown-menu[x-placement^=bottom-start] {
  margin-top: 7px !important;
}
.time-picker-input .dropdown .dropdown-menu[x-placement^=bottom-start]:before {
  top: -7px;
  left: 15px;
  border-bottom: 7px solid var(--dropdown-menu-bg);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.time-picker-input .dropdown .dropdown-menu[x-placement^=top-start] {
  top: 0 !important;
  margin-bottom: 7px !important;
}
.time-picker-input .dropdown .dropdown-menu[x-placement^=top-start]:after {
  bottom: -7px;
  left: 15px;
  border-top: 7px solid var(--dropdown-menu-bg);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.date-picker-input input {
  padding: 10px 14px !important;
  text-align: start;
  color: var(--default-font-color);
  border: none !important;
  background-color: var(--form-control-bg) !important;
}
.date-picker-input input:focus {
  box-shadow: none;
  border-color: var(--default-border-color) !important;
}
.date-picker-input .input-group-text {
  width: 50px;
  border: none !important;
  background-color: var(--form-control-bg);
}
.date-picker-input .input-group-text svg {
  stroke-width: 1;
  color: #019AFF;
}
.date-picker-input .vc-popover-content-wrapper {
  z-index: 21 !important;
}
.date-picker-input .input-group {
  border: 1px solid var(--default-border-color) !important;
  border-radius: 5px;
}

.date-picker-input-group .input-group {
  border-color: #019AFF !important;
}

.vc-popover-content.vc-container {
  border: none !important;
  border-radius: 0.25rem !important;
  box-shadow: var(--default-box-shadow) !important;
}

.vc-container.vc-is-dark {
  background-color: var(--default-card-bg) !important;
}

.vc-nav-popover-container {
  background-color: var(--default-card-bg) !important;
  color: var(--default-font-color) !important;
  border-color: var(--default-border-color) !important;
}
.vc-nav-popover-container .vc-nav-title {
  color: var(--default-font-color) !important;
}
.vc-nav-popover-container .vc-nav-title:hover {
  background-color: var(--default-card-bg) !important;
}

.vc-is-dark .vc-nav-item:hover {
  background-color: var(--base-color) !important;
  color: var(--default-font-color) !important;
}

.date-filter .vc-popover-content {
  margin-top: 5px !important;
}
.date-filter .vc-container .vc-popover-caret.align-left {
  display: none !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.circular-progress-bar {
  /*Progressbar Animations*/
}
.circular-progress-bar .progress {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.circular-progress-bar .progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid var(--base-color);
  position: absolute;
  top: 0;
  left: 0;
}
.circular-progress-bar .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.circular-progress-bar .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 5px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #019AFF;
}
.circular-progress-bar .progress .progress-left {
  left: 0;
}
.circular-progress-bar .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
  animation: loading-2 1.5s linear forwards 1.8s;
}
.circular-progress-bar .progress .progress-right {
  right: 0;
}
.circular-progress-bar .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.circular-progress-bar .progress .progress-value {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
}
.circular-progress-bar small {
  color: #9397A0;
}
.circular-progress-bar .progress[data-percentage="1"] .progress-right .progress-bar {
  animation: loading-1 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="1"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="2"] .progress-right .progress-bar {
  animation: loading-2 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="2"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="3"] .progress-right .progress-bar {
  animation: loading-3 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="3"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="4"] .progress-right .progress-bar {
  animation: loading-4 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="4"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="5"] .progress-right .progress-bar {
  animation: loading-5 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="5"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="6"] .progress-right .progress-bar {
  animation: loading-6 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="6"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="7"] .progress-right .progress-bar {
  animation: loading-7 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="7"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="8"] .progress-right .progress-bar {
  animation: loading-8 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="8"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="9"] .progress-right .progress-bar {
  animation: loading-9 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="9"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-10 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="11"] .progress-right .progress-bar {
  animation: loading-11 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="11"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="12"] .progress-right .progress-bar {
  animation: loading-12 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="12"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="13"] .progress-right .progress-bar {
  animation: loading-13 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="13"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="14"] .progress-right .progress-bar {
  animation: loading-14 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="14"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="15"] .progress-right .progress-bar {
  animation: loading-15 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="15"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="16"] .progress-right .progress-bar {
  animation: loading-16 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="16"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="17"] .progress-right .progress-bar {
  animation: loading-17 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="17"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="18"] .progress-right .progress-bar {
  animation: loading-18 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="18"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="19"] .progress-right .progress-bar {
  animation: loading-19 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="19"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-20 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="21"] .progress-right .progress-bar {
  animation: loading-21 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="21"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="22"] .progress-right .progress-bar {
  animation: loading-22 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="22"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="23"] .progress-right .progress-bar {
  animation: loading-23 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="23"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="24"] .progress-right .progress-bar {
  animation: loading-24 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="24"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="25"] .progress-right .progress-bar {
  animation: loading-25 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="25"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="26"] .progress-right .progress-bar {
  animation: loading-26 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="26"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="27"] .progress-right .progress-bar {
  animation: loading-27 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="27"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="28"] .progress-right .progress-bar {
  animation: loading-28 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="28"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="29"] .progress-right .progress-bar {
  animation: loading-29 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="29"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-30 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="31"] .progress-right .progress-bar {
  animation: loading-31 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="31"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="32"] .progress-right .progress-bar {
  animation: loading-32 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="32"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="33"] .progress-right .progress-bar {
  animation: loading-33 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="33"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="34"] .progress-right .progress-bar {
  animation: loading-34 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="34"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="35"] .progress-right .progress-bar {
  animation: loading-35 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="35"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="36"] .progress-right .progress-bar {
  animation: loading-36 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="36"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="37"] .progress-right .progress-bar {
  animation: loading-37 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="37"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="38"] .progress-right .progress-bar {
  animation: loading-38 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="38"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="39"] .progress-right .progress-bar {
  animation: loading-39 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="39"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-40 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="41"] .progress-right .progress-bar {
  animation: loading-41 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="41"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="42"] .progress-right .progress-bar {
  animation: loading-42 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="42"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="43"] .progress-right .progress-bar {
  animation: loading-43 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="43"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="44"] .progress-right .progress-bar {
  animation: loading-44 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="44"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="45"] .progress-right .progress-bar {
  animation: loading-45 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="45"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="46"] .progress-right .progress-bar {
  animation: loading-46 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="46"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="47"] .progress-right .progress-bar {
  animation: loading-47 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="47"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="48"] .progress-right .progress-bar {
  animation: loading-48 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="48"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="49"] .progress-right .progress-bar {
  animation: loading-49 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="49"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}
.circular-progress-bar .progress[data-percentage="51"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="51"] .progress-left .progress-bar {
  animation: loading-1 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="52"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="52"] .progress-left .progress-bar {
  animation: loading-2 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="53"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="53"] .progress-left .progress-bar {
  animation: loading-3 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="54"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="54"] .progress-left .progress-bar {
  animation: loading-4 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="55"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="55"] .progress-left .progress-bar {
  animation: loading-5 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="56"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="56"] .progress-left .progress-bar {
  animation: loading-6 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="57"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="57"] .progress-left .progress-bar {
  animation: loading-7 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="58"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="58"] .progress-left .progress-bar {
  animation: loading-8 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="59"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="59"] .progress-left .progress-bar {
  animation: loading-9 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-10 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="61"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="61"] .progress-left .progress-bar {
  animation: loading-11 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="62"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="62"] .progress-left .progress-bar {
  animation: loading-12 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="63"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="63"] .progress-left .progress-bar {
  animation: loading-13 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="64"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="64"] .progress-left .progress-bar {
  animation: loading-14 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="65"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="65"] .progress-left .progress-bar {
  animation: loading-15 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="66"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="66"] .progress-left .progress-bar {
  animation: loading-16 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="67"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="67"] .progress-left .progress-bar {
  animation: loading-17 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="68"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="68"] .progress-left .progress-bar {
  animation: loading-18 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="69"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="69"] .progress-left .progress-bar {
  animation: loading-19 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-20 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="71"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="71"] .progress-left .progress-bar {
  animation: loading-21 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="72"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="72"] .progress-left .progress-bar {
  animation: loading-22 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="73"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="73"] .progress-left .progress-bar {
  animation: loading-23 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="74"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="74"] .progress-left .progress-bar {
  animation: loading-24 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="75"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="75"] .progress-left .progress-bar {
  animation: loading-25 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="76"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="76"] .progress-left .progress-bar {
  animation: loading-26 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="77"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="77"] .progress-left .progress-bar {
  animation: loading-27 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="78"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="78"] .progress-left .progress-bar {
  animation: loading-28 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="79"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="79"] .progress-left .progress-bar {
  animation: loading-29 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-30 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="81"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="81"] .progress-left .progress-bar {
  animation: loading-31 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="82"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="82"] .progress-left .progress-bar {
  animation: loading-32 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="83"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="83"] .progress-left .progress-bar {
  animation: loading-33 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="84"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="84"] .progress-left .progress-bar {
  animation: loading-34 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="85"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="85"] .progress-left .progress-bar {
  animation: loading-35 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="86"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="86"] .progress-left .progress-bar {
  animation: loading-36 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="87"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="87"] .progress-left .progress-bar {
  animation: loading-37 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="88"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="88"] .progress-left .progress-bar {
  animation: loading-38 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="89"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="89"] .progress-left .progress-bar {
  animation: loading-39 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-40 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="91"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="91"] .progress-left .progress-bar {
  animation: loading-41 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="92"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="92"] .progress-left .progress-bar {
  animation: loading-42 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="93"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="93"] .progress-left .progress-bar {
  animation: loading-43 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="94"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="94"] .progress-left .progress-bar {
  animation: loading-44 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="95"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="95"] .progress-left .progress-bar {
  animation: loading-45 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="96"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="96"] .progress-left .progress-bar {
  animation: loading-46 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="97"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="97"] .progress-left .progress-bar {
  animation: loading-47 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="98"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="98"] .progress-left .progress-bar {
  animation: loading-48 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="99"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="99"] .progress-left .progress-bar {
  animation: loading-49 1.1s linear forwards 1.1s;
}
.circular-progress-bar .progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-50 1.1s linear forwards;
}
.circular-progress-bar .progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-50 1.1s linear forwards 1.1s;
}
@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(3.6);
    transform: rotate(3.6deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(7.2);
    transform: rotate(7.2deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10.8);
    transform: rotate(10.8deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(14.4);
    transform: rotate(14.4deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(18);
    transform: rotate(18deg);
  }
}
@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(21.6);
    transform: rotate(21.6deg);
  }
}
@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(25.2);
    transform: rotate(25.2deg);
  }
}
@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(28.8);
    transform: rotate(28.8deg);
  }
}
@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(32.4);
    transform: rotate(32.4deg);
  }
}
@keyframes loading-10 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-11 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(39.6);
    transform: rotate(39.6deg);
  }
}
@keyframes loading-12 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(43.2);
    transform: rotate(43.2deg);
  }
}
@keyframes loading-13 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(46.8);
    transform: rotate(46.8deg);
  }
}
@keyframes loading-14 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(50.4);
    transform: rotate(50.4deg);
  }
}
@keyframes loading-15 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(54);
    transform: rotate(54deg);
  }
}
@keyframes loading-16 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(57.6);
    transform: rotate(57.6deg);
  }
}
@keyframes loading-17 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(61.2);
    transform: rotate(61.2deg);
  }
}
@keyframes loading-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(64.8);
    transform: rotate(64.8deg);
  }
}
@keyframes loading-19 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(68.4);
    transform: rotate(68.4deg);
  }
}
@keyframes loading-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-21 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(75.6);
    transform: rotate(75.6deg);
  }
}
@keyframes loading-22 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(79.2);
    transform: rotate(79.2deg);
  }
}
@keyframes loading-23 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(82.8);
    transform: rotate(82.8deg);
  }
}
@keyframes loading-24 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(86.4);
    transform: rotate(86.4deg);
  }
}
@keyframes loading-25 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90);
    transform: rotate(90deg);
  }
}
@keyframes loading-26 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(93.6);
    transform: rotate(93.6deg);
  }
}
@keyframes loading-27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(97.2);
    transform: rotate(97.2deg);
  }
}
@keyframes loading-28 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(100.8);
    transform: rotate(100.8deg);
  }
}
@keyframes loading-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(104.4);
    transform: rotate(104.4deg);
  }
}
@keyframes loading-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-31 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(111.6);
    transform: rotate(111.6deg);
  }
}
@keyframes loading-32 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(115.2);
    transform: rotate(115.2deg);
  }
}
@keyframes loading-33 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(118.8);
    transform: rotate(118.8deg);
  }
}
@keyframes loading-34 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(122.4);
    transform: rotate(122.4deg);
  }
}
@keyframes loading-35 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(126);
    transform: rotate(126deg);
  }
}
@keyframes loading-36 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(129.6);
    transform: rotate(129.6deg);
  }
}
@keyframes loading-37 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(133.2);
    transform: rotate(133.2deg);
  }
}
@keyframes loading-38 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(136.8);
    transform: rotate(136.8deg);
  }
}
@keyframes loading-39 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(140.4);
    transform: rotate(140.4deg);
  }
}
@keyframes loading-40 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(147.6);
    transform: rotate(147.6deg);
  }
}
@keyframes loading-42 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(151.2);
    transform: rotate(151.2deg);
  }
}
@keyframes loading-43 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(154.8);
    transform: rotate(154.8deg);
  }
}
@keyframes loading-44 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(158.4);
    transform: rotate(158.4deg);
  }
}
@keyframes loading-45 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(162);
    transform: rotate(162deg);
  }
}
@keyframes loading-46 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(165.6);
    transform: rotate(165.6deg);
  }
}
@keyframes loading-47 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(169.2);
    transform: rotate(169.2deg);
  }
}
@keyframes loading-48 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(172.8);
    transform: rotate(172.8deg);
  }
}
@keyframes loading-49 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(176.4);
    transform: rotate(176.4deg);
  }
}
@keyframes loading-50 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.root-preloader {
  z-index: 9999 !important;
  background: var(--base-color) !important;
}

.spinner-bounce > span {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  display: inline-block;
  background-color: #e6e6e6;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out both;
  animation: bounceDelay 1.4s infinite ease-in-out both;
}
.spinner-bounce > span.bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner-bounce > span.bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.overlay-loader-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.25rem;
  z-index: 1;
}
.overlay-loader-wrapper .spinner-bounce > span {
  width: 11px;
  height: 11px;
  background-color: #019AFF;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.toast-container {
  width: 280px !important;
}
.toast-container.toast-top-right {
  right: 8px;
  top: 78px;
}
.toast-container .vueToaster {
  opacity: 1 !important;
  border: 0 !important;
  width: 280px !important;
  border-radius: 0 !important;
  font-family: "Poppins", sans-serif !important;
  border-left: 6px solid !important;
  padding: 15px 15px 15px 15px !important;
  background-position: -100% center !important;
  background-color: var(--vue-toaster-bg) !important;
  box-shadow: var(--default-box-shadow) !important;
  animation: slideInRight 0.6s, slideOutRight 0.6s 2.7s;
  -webkit-animation: slideInRight 0.6s, slideOutRight 0.6s 2.7s;
}
.toast-container .vueToaster .toast-title {
  font-weight: 400;
  font-size: 0.9rem;
}
.toast-container .vueToaster.toast-success {
  border-color: #46c35f !important;
}
.toast-container .vueToaster.toast-error {
  border-color: #fc2c10 !important;
}
.toast-container .vueToaster.toast-info {
  border-color: #38a4f8 !important;
}
.toast-container .vueToaster.toast-warning {
  border-color: #FC6510 !important;
}
.toast-container .vueToaster .toast-progress {
  height: 0 !important;
  opacity: 0 !important;
  display: none !important;
}

.swal2-popup {
  min-height: 16em;
  font-family: "Poppins", sans-serif !important;
  background: var(--modal-body-bg) !important;
}
.swal2-popup .swal2-title, .swal2-popup .swal2-content {
  color: var(--default-font-color) !important;
}
.swal2-popup .swal2-actions button {
  padding: 0.45rem 1.2rem;
  font-size: inherit !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.swal2-popup .swal2-actions button.swal2-cancel {
  background-color: var(--btn-secondary-bg);
}
.swal2-popup .swal2-actions button:focus {
  box-shadow: none;
}

@media (min-width: 576px) and (max-width: 768px) {
  .filters-wrapper .mobile-filters-wrapper {
    display: contents;
  }

  .calendar-root .calendar {
    width: 500px;
  }
  .calendar-root .calendar .calendar_preset {
    margin-bottom: 0.8rem;
    display: inline-block;
  }
  .calendar-root .calendar .calendar_preset li {
    width: auto;
    display: block;
    line-height: 1.6em;
    padding: 0 0 0 18px;
    margin: 5px 0 !important;
  }
  .calendar-root .calendar .calendar-wrap {
    width: 100%;
    float: none;
  }
  .calendar-root .calendar .calendar_month_left, .calendar-root .calendar .calendar_month_right {
    margin: 0 !important;
    float: left;
    padding-bottom: 0 !important;
    width: 50% !important;
  }
  .calendar-root .calendar .calendar-range {
    border: 0;
    margin: 0;
    width: 100%;
    float: none;
    text-align: center;
  }
  .calendar-root .calendar .calendar_preset-ranges {
    float: left;
  }
  .calendar-root .calendar .calendar_preset-ranges .custom-radio-default .custom-control-inline {
    display: table-cell;
  }
}
:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.tag-manager .chips-container {
  overflow-y: auto;
  min-height: auto;
  max-height: 120px;
  background-color: transparent;
}
.tag-manager .chips-container .chips {
  color: #ffffff;
  background-color: #8bd2eb;
  padding: 0.19rem 0.45rem 0.19rem 1rem;
}
.tag-manager .chips-container .chips .chips-label {
  font-size: 95%;
}
.tag-manager .chips-container .chips .delete-chips {
  background-color: transparent;
}
.tag-manager .dropdown-menu {
  max-width: 18rem;
}
.tag-manager .dropdown-menu .color-picker-wrapper {
  position: absolute;
  right: 35px;
}
.tag-manager .dropdown-menu .color-picker-wrapper .input-color-container {
  width: 35px;
  height: 35px;
  overflow: hidden;
  position: relative;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tag-manager .dropdown-menu .color-picker-wrapper .input-color-container .input-color {
  opacity: 0;
  border: none;
  position: absolute;
  top: -5px;
  right: -12px;
  width: 50px;
  height: 44px;
}
.tag-manager .dropdown-menu .color-picker-wrapper .input-color-container .input-color-label {
  margin-bottom: 0;
}
.tag-manager .dropdown-menu .color-picker-wrapper .input-color-container .input-color-label i {
  font-size: 21px;
}
.tag-manager .dropdown-menu .chip {
  color: #ffffff;
  max-width: 160px;
  border-radius: 25px;
  padding: 0.18rem 1.2rem;
  background-color: #8bd2eb;
}
.tag-manager .dropdown-menu .chip .chips-label {
  font-size: 95%;
}

.table .tag-manager {
  width: 320px;
  max-width: 320px;
}

@media (max-width: 767px) {
  .table-view-responsive .table .tag-manager {
    width: 100%;
    max-width: 100%;
  }
}
:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

@media (max-width: 575px) {
  body, html {
    font-size: 0.9rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  /*** Start mixins for mobile view ***/
  @keyframes dropdownMenuAnimation {
    from {
      opacity: 0;
      transform: translate3d(0, -30px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0px, 0);
    }
  }
  .dropdownMenuAnimation, .filters-wrapper .dropdown-menu-filter .dropdown .dropdown-menu, .filters-wrapper .mobile-filters-wrapper, .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar, .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu, .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu {
    animation-name: dropdownMenuAnimation;
    animation-duration: 0.25s;
    animation-fill-mode: both;
  }

  /*** End mixins for mobile view ***/
  .p-primary {
    padding: 20px !important;
  }

  .px-primary {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .py-primary {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-primary {
    padding-top: 20px !important;
  }

  .pr-primary {
    padding-right: 20px !important;
  }

  .pt-primary {
    padding-bottom: 20px !important;
  }

  .pt-primary {
    padding-left: 20px !important;
  }

  .mt-primary {
    margin-top: 20px !important;
  }

  .mr-primary {
    margin-right: 20px !important;
  }

  .mb-primary {
    margin-bottom: 20px !important;
  }

  .ml-primary {
    margin-left: 20px !important;
  }

  .my-primary {
    padding: 20px 0 !important;
  }

  .content-wrapper {
    padding: 20px;
  }

  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
    position: fixed;
    min-width: 100%;
    border-radius: 0;
  }
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown:before {
    display: none;
  }

  /*** Start Breadcrumbs Design for Mobile View ***/
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.3rem;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0;
  }

  .breadcrumb-item h4 {
    max-width: 300px;
  }

  /*** End Breadcrumbs Design for Mobile View ***/
  /*** Start Cards Design for Mobile View ***/
  .card .card-body {
    padding: 15px;
  }

  /*** End Cards Design for Mobile View ***/
  /*** Start Tabs Design for Mobile View ***/
  .vertical-tab .header-icon {
    height: 80px;
  }
  .vertical-tab .header-icon .icon-position {
    margin-top: 50px;
  }
  .vertical-tab .tab-content h4 {
    height: 80px;
  }
  .vertical-tab .tab-content hr {
    margin: 0 -20px;
  }

  /*** End Tabs Design for Mobile View ***/
  /*** Start Modals Design for Mobile View ***/
  .modal-dialog {
    margin: 0;
    max-width: 100% !important;
  }
  .modal-dialog.modal-dialog-centered {
    max-height: 100% !important;
  }
  .modal-dialog .modal-content {
    outline: 0;
    display: flex;
    position: relative;
    pointer-events: auto;
    flex-direction: column;
    min-width: 100% !important;
    min-height: 100% !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background-clip: padding-box;
    background-color: var(--modal-body-bg);
  }

  /*** Start Filters Design for Mobile View ***/
  .btn-toggle-filters {
    border: 0;
    font-size: 95%;
    padding: 0.5rem 0.7rem;
    border-radius: 20px;
    margin: 0 0 1.14rem 0;
    color: #8A8A8A;
    background: var(--btn-filter-bg);
    box-shadow: var(--default-box-shadow);
  }
  .btn-toggle-filters svg {
    width: 20px;
    height: 20px;
  }

  .btn-filter.btn-list-grid {
    border-radius: 20px;
  }

  .filters-wrapper .single-filter.column-filter .dropdown .btn-filter {
    border-radius: 20px;
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu {
    z-index: 1031;
    position: fixed !important;
    transform: inherit !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    will-change: initial !important;
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .btn-dropdown-close {
    display: block;
    padding: 1rem 1.9rem;
    background-color: #019AFF;
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .btn-dropdown-close .title {
    color: #ffffff;
    font-size: 14px;
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .btn-dropdown-close svg {
    color: #ffffff;
    width: 20px;
    height: 20px;
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .filter-action-button-wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    box-shadow: var(--default-box-shadow);
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .filter-action-button-wrapper .col-12 {
    padding: 0;
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .filter-action-button-wrapper .col-12 .btn {
    width: 50%;
    border-radius: 0;
    padding: 1rem 1.2rem;
  }
  .filters-wrapper .single-filter.column-filter .dropdown .dropdown-menu .filter-action-button-wrapper .col-12 .btn.btn-clear {
    background-color: var(--base-color);
  }
  .filters-wrapper .mobile-filters-wrapper {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1031;
    width: 100%;
    padding: 15px;
    background-color: var(--base-color);
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter:last-child {
    margin-right: 0;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .btn-filter {
    width: 100%;
    text-align: left;
    font-size: 100%;
    padding: 0.6rem 1.5rem;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .btn-filter .clear-icon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 20px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .btn-filter .clear-icon svg {
    width: 22px;
    height: 22px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu {
    position: fixed !important;
    transform: inherit !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    will-change: initial !important;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu .btn-dropdown-close {
    display: block;
    padding: 1rem 1.9rem;
    background-color: #019AFF;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu .btn-dropdown-close .title {
    color: #ffffff;
    font-size: 14px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu .btn-dropdown-close svg {
    color: #ffffff;
    width: 20px;
    height: 20px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu .filter-action-button-wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    box-shadow: var(--default-box-shadow);
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu .filter-action-button-wrapper .col-12 {
    padding: 0;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu .filter-action-button-wrapper .col-12 .btn {
    width: 50%;
    border-radius: 0;
    padding: 1rem 1.2rem;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter .dropdown .dropdown-menu .filter-action-button-wrapper .col-12 .btn.btn-clear {
    background-color: var(--base-color);
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .input-date {
    font-size: 100%;
    position: relative;
    padding: 0.6rem 1.5rem;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .input-date .clear-icon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 20px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .input-date .clear-icon svg {
    width: 22px;
    height: 22px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    padding-top: 0 !important;
    border-radius: 0;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .btn-dropdown-close {
    display: block;
    padding: 1rem 1.9rem;
    background-color: #019AFF;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .btn-dropdown-close .title {
    color: #ffffff;
    font-size: 14px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .btn-dropdown-close svg {
    color: #ffffff;
    width: 20px;
    height: 20px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .calendar-wrap {
    width: 100%;
    padding: 10px 10px 10px 10px;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .calendar-wrap .calendar_month_left, .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .calendar-wrap .calendar_month_right {
    margin: 0;
    width: 50%;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .filter-action-button-wrapper {
    padding: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    box-shadow: var(--default-box-shadow);
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .filter-action-button-wrapper .col-12 {
    padding: 0;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .filter-action-button-wrapper .col-12 .btn {
    width: 50%;
    border-radius: 0;
    padding: 1rem 1.2rem;
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.calendar-root .calendar .filter-action-button-wrapper .col-12 .btn.btn-clear {
    background-color: var(--base-color);
  }
  .filters-wrapper .mobile-filters-wrapper .single-filter.search-filter-dropdown .dropdown .btn-filter {
    padding: 0.6rem 1.5rem !important;
  }
  .filters-wrapper .dropdown-menu-filter .menu-filter-btn {
    width: 100%;
    text-align: left;
    font-size: 100%;
    padding: 0.6rem 1.5rem;
    margin-bottom: 1rem;
  }
  .filters-wrapper .dropdown-menu-filter .dropdown .dropdown-menu {
    position: fixed !important;
    transform: inherit !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    will-change: initial !important;
  }
  .filters-wrapper .dropdown-menu-filter .dropdown .dropdown-menu .btn-dropdown-close {
    display: block;
    padding: 1rem 1.9rem;
    background-color: #019AFF;
  }
  .filters-wrapper .dropdown-menu-filter .dropdown .dropdown-menu .btn-dropdown-close .title {
    color: #ffffff;
    font-size: 14px;
  }
  .filters-wrapper .dropdown-menu-filter .dropdown .dropdown-menu .btn-dropdown-close svg {
    color: #ffffff;
    width: 20px;
    height: 20px;
  }

  /*** End Filters Design for Mobile View ***/
  /*** Chips With Dropdown Search & Select ***/
  .chips-dropdown-menu {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
  }
  .chips-dropdown-menu .dropdown-item {
    white-space: initial;
  }
}
.calendar-view .fc-event {
  background-color: #a3b4f9 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  border: none !important;
  font-weight: inherit;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
  margin-right: 0.5rem;
}
.calendar-view .fc-button-group {
  border-radius: 0.3rem !important;
  background: var(--btn-filter-bg) !important;
}
.calendar-view .fc-button-group .fc-button {
  border: 1px solid var(--default-border-color) !important;
  background: var(--btn-filter-bg) !important;
  color: #919191 !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
.calendar-view .fc-button-group .fc-button:hover {
  color: #019AFF !important;
}
.calendar-view .fc-button-group .fc-button.fc-button-active {
  box-shadow: none !important;
  border-radius: 0.3rem;
  background-color: #019AFF !important;
  border: 1px solid #019AFF !important;
  color: #ffffff !important;
}
.calendar-view .fc-today-button {
  border: 1px solid var(--default-border-color) !important;
  text-transform: capitalize !important;
  background: var(--btn-filter-bg) !important;
  color: #919191 !important;
  border-radius: 0.3rem !important;
}
.calendar-view .fc-today-button:focus {
  box-shadow: none !important;
}
.calendar-view .fc-prev-button.fc-button, .calendar-view .fc-next-button.fc-button {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
  background: transparent !important;
  color: #919191 !important;
}
.calendar-view .fc-prev-button.fc-button:hover, .calendar-view .fc-next-button.fc-button:hover {
  color: #019AFF !important;
  background: transparent !important;
}
.calendar-view .fc-timegrid .fc-scrollgrid tbody .fc-scrollgrid-section:nth-child(1), .calendar-view .fc-timegrid .fc-scrollgrid tbody .fc-scrollgrid-section:nth-child(2) {
  display: none !important;
}
.calendar-view .fc-timegrid .fc-timegrid-slot {
  height: 60px !important;
}
.calendar-view .fc-timegrid .fc-timegrid-body {
  width: 100% !important;
}
.calendar-view .fc-timegrid .fc-timegrid-body table {
  width: 100% !important;
}
.calendar-view .fc-timegrid .fc-col-header {
  width: 100% !important;
}
.calendar-view .fc-timegrid .fc-timegrid-axis-frame {
  justify-content: center !important;
}
.calendar-view .fc-theme-standard td, .calendar-view .fc-theme-standard th {
  border-color: var(--default-border-color) !important;
}
.calendar-view .fc-scrollgrid {
  border-color: var(--default-border-color) !important;
}
.calendar-view .fc-daygrid .fc-day-today {
  background: #019AFF !important;
}
.calendar-view .fc-timeGridWeek-view .fc-day-today {
  background: none !important;
}
.calendar-view .fc-timeGridWeek-view .fc-day-today.fc-col-header-cell {
  background: #019AFF !important;
  color: #ffffff !important;
}
.calendar-view .fc-timeGridDay-view .fc-day-today {
  background: none !important;
}
.calendar-view .fc-header-toolbar h2 {
  color: #919191 !important;
  font-size: 20px !important;
}
.calendar-view .fc-scroller::-webkit-scrollbar {
  width: 6px !important;
}
.calendar-view .fc-scroller::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color) !important;
  border-radius: 5px !important;
}
.calendar-view .fc-scroller::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color) !important;
}
.calendar-view .fc-col-header-cell-cushion {
  padding: 10px 5px !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.accordion .card {
  border-color: var(--default-border-color);
}
.accordion .card .card-header {
  background-color: var(--accordion-header-bg);
}
.accordion .card .card-header .collapsible-link {
  color: var(--default-font-color);
}
.accordion .card .card-header .collapsible-link::before {
  content: "";
  width: 14px;
  height: 2px;
  background: var(--default-font-color);
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
  opacity: 0.5;
}
.accordion .card .card-header .collapsible-link::after {
  content: "";
  width: 2px;
  height: 14px;
  background: var(--default-font-color);
  position: absolute;
  top: calc(50% - 7px);
  right: calc(1rem + 6px);
  display: block;
  transition: all 0.3s;
  opacity: 0.5;
}
.accordion .card .card-header .collapsible-link[aria-expanded=true]::after {
  transform: rotate(90deg) translateX(-1px);
}
.accordion .card .card-header .collapsible-link[aria-expanded=true]::before {
  transform: rotate(180deg);
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.font-size-default {
  font-size: 0.935rem !important;
}

.font-size-70 {
  font-size: 70% !important;
}

.font-size-75 {
  font-size: 75% !important;
}

.font-size-80 {
  font-size: 80% !important;
}

.font-size-90 {
  font-size: 90% !important;
}

.text-size-1 {
  font-size: 1px !important;
}

.text-size-2 {
  font-size: 2px !important;
}

.text-size-3 {
  font-size: 3px !important;
}

.text-size-4 {
  font-size: 4px !important;
}

.text-size-5 {
  font-size: 5px !important;
}

.text-size-6 {
  font-size: 6px !important;
}

.text-size-7 {
  font-size: 7px !important;
}

.text-size-8 {
  font-size: 8px !important;
}

.text-size-9 {
  font-size: 9px !important;
}

.text-size-10 {
  font-size: 10px !important;
}

.text-size-11 {
  font-size: 11px !important;
}

.text-size-12 {
  font-size: 12px !important;
}

.text-size-13 {
  font-size: 13px !important;
}

.text-size-14 {
  font-size: 14px !important;
}

.text-size-15 {
  font-size: 15px !important;
}

.text-size-16 {
  font-size: 16px !important;
}

.text-size-17 {
  font-size: 17px !important;
}

.text-size-18 {
  font-size: 18px !important;
}

.text-size-19 {
  font-size: 19px !important;
}

.text-size-20 {
  font-size: 20px !important;
}

.text-size-21 {
  font-size: 21px !important;
}

.text-size-22 {
  font-size: 22px !important;
}

.text-size-23 {
  font-size: 23px !important;
}

.text-size-24 {
  font-size: 24px !important;
}

.text-size-25 {
  font-size: 25px !important;
}

.text-size-26 {
  font-size: 26px !important;
}

.text-size-27 {
  font-size: 27px !important;
}

.text-size-28 {
  font-size: 28px !important;
}

.text-size-29 {
  font-size: 29px !important;
}

.text-size-30 {
  font-size: 30px !important;
}

.text-size-31 {
  font-size: 31px !important;
}

.text-size-32 {
  font-size: 32px !important;
}

.text-size-33 {
  font-size: 33px !important;
}

.text-size-34 {
  font-size: 34px !important;
}

.text-size-35 {
  font-size: 35px !important;
}

.text-size-36 {
  font-size: 36px !important;
}

.text-size-37 {
  font-size: 37px !important;
}

.text-size-38 {
  font-size: 38px !important;
}

.text-size-39 {
  font-size: 39px !important;
}

.text-size-40 {
  font-size: 40px !important;
}

.text-size-41 {
  font-size: 41px !important;
}

.text-size-42 {
  font-size: 42px !important;
}

.text-size-43 {
  font-size: 43px !important;
}

.text-size-44 {
  font-size: 44px !important;
}

.text-size-45 {
  font-size: 45px !important;
}

.text-size-46 {
  font-size: 46px !important;
}

.text-size-47 {
  font-size: 47px !important;
}

.text-size-48 {
  font-size: 48px !important;
}

.text-size-49 {
  font-size: 49px !important;
}

.text-size-50 {
  font-size: 50px !important;
}

.text-size-51 {
  font-size: 51px !important;
}

.text-size-52 {
  font-size: 52px !important;
}

.text-size-53 {
  font-size: 53px !important;
}

.text-size-54 {
  font-size: 54px !important;
}

.text-size-55 {
  font-size: 55px !important;
}

.text-size-56 {
  font-size: 56px !important;
}

.text-size-57 {
  font-size: 57px !important;
}

.text-size-58 {
  font-size: 58px !important;
}

.text-size-59 {
  font-size: 59px !important;
}

.text-size-60 {
  font-size: 60px !important;
}

.text-size-61 {
  font-size: 61px !important;
}

.text-size-62 {
  font-size: 62px !important;
}

.text-size-63 {
  font-size: 63px !important;
}

.text-size-64 {
  font-size: 64px !important;
}

.text-size-65 {
  font-size: 65px !important;
}

.text-size-66 {
  font-size: 66px !important;
}

.text-size-67 {
  font-size: 67px !important;
}

.text-size-68 {
  font-size: 68px !important;
}

.text-size-69 {
  font-size: 69px !important;
}

.text-size-70 {
  font-size: 70px !important;
}

.text-size-71 {
  font-size: 71px !important;
}

.text-size-72 {
  font-size: 72px !important;
}

.text-size-73 {
  font-size: 73px !important;
}

.text-size-74 {
  font-size: 74px !important;
}

.text-size-75 {
  font-size: 75px !important;
}

.text-size-76 {
  font-size: 76px !important;
}

.text-size-77 {
  font-size: 77px !important;
}

.text-size-78 {
  font-size: 78px !important;
}

.text-size-79 {
  font-size: 79px !important;
}

.text-size-80 {
  font-size: 80px !important;
}

.text-size-81 {
  font-size: 81px !important;
}

.text-size-82 {
  font-size: 82px !important;
}

.text-size-83 {
  font-size: 83px !important;
}

.text-size-84 {
  font-size: 84px !important;
}

.text-size-85 {
  font-size: 85px !important;
}

.text-size-86 {
  font-size: 86px !important;
}

.text-size-87 {
  font-size: 87px !important;
}

.text-size-88 {
  font-size: 88px !important;
}

.text-size-89 {
  font-size: 89px !important;
}

.text-size-90 {
  font-size: 90px !important;
}

.text-size-91 {
  font-size: 91px !important;
}

.text-size-92 {
  font-size: 92px !important;
}

.text-size-93 {
  font-size: 93px !important;
}

.text-size-94 {
  font-size: 94px !important;
}

.text-size-95 {
  font-size: 95px !important;
}

.text-size-96 {
  font-size: 96px !important;
}

.text-size-97 {
  font-size: 97px !important;
}

.text-size-98 {
  font-size: 98px !important;
}

.text-size-99 {
  font-size: 99px !important;
}

.text-size-100 {
  font-size: 100px !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.dot-icon {
  border-radius: 100%;
  display: inline-block;
  background-color: #afb1b6;
}
.dot-icon.size-1 {
  width: 1px !important;
  height: 1px !important;
}
.dot-icon.size-2 {
  width: 2px !important;
  height: 2px !important;
}
.dot-icon.size-3 {
  width: 3px !important;
  height: 3px !important;
}
.dot-icon.size-4 {
  width: 4px !important;
  height: 4px !important;
}
.dot-icon.size-5 {
  width: 5px !important;
  height: 5px !important;
}
.dot-icon.size-6 {
  width: 6px !important;
  height: 6px !important;
}
.dot-icon.size-7 {
  width: 7px !important;
  height: 7px !important;
}
.dot-icon.size-8 {
  width: 8px !important;
  height: 8px !important;
}
.dot-icon.size-9 {
  width: 9px !important;
  height: 9px !important;
}
.dot-icon.size-10 {
  width: 10px !important;
  height: 10px !important;
}
.dot-icon.size-11 {
  width: 11px !important;
  height: 11px !important;
}
.dot-icon.size-12 {
  width: 12px !important;
  height: 12px !important;
}
.dot-icon.size-13 {
  width: 13px !important;
  height: 13px !important;
}
.dot-icon.size-14 {
  width: 14px !important;
  height: 14px !important;
}
.dot-icon.size-15 {
  width: 15px !important;
  height: 15px !important;
}
.dot-icon.size-16 {
  width: 16px !important;
  height: 16px !important;
}
.dot-icon.size-17 {
  width: 17px !important;
  height: 17px !important;
}
.dot-icon.size-18 {
  width: 18px !important;
  height: 18px !important;
}
.dot-icon.size-19 {
  width: 19px !important;
  height: 19px !important;
}
.dot-icon.size-20 {
  width: 20px !important;
  height: 20px !important;
}
.dot-icon.size-21 {
  width: 21px !important;
  height: 21px !important;
}
.dot-icon.size-22 {
  width: 22px !important;
  height: 22px !important;
}
.dot-icon.size-23 {
  width: 23px !important;
  height: 23px !important;
}
.dot-icon.size-24 {
  width: 24px !important;
  height: 24px !important;
}
.dot-icon.size-25 {
  width: 25px !important;
  height: 25px !important;
}
.dot-icon.size-26 {
  width: 26px !important;
  height: 26px !important;
}
.dot-icon.size-27 {
  width: 27px !important;
  height: 27px !important;
}
.dot-icon.size-28 {
  width: 28px !important;
  height: 28px !important;
}
.dot-icon.size-29 {
  width: 29px !important;
  height: 29px !important;
}
.dot-icon.size-30 {
  width: 30px !important;
  height: 30px !important;
}
.dot-icon.size-31 {
  width: 31px !important;
  height: 31px !important;
}
.dot-icon.size-32 {
  width: 32px !important;
  height: 32px !important;
}
.dot-icon.size-33 {
  width: 33px !important;
  height: 33px !important;
}
.dot-icon.size-34 {
  width: 34px !important;
  height: 34px !important;
}
.dot-icon.size-35 {
  width: 35px !important;
  height: 35px !important;
}
.dot-icon.size-36 {
  width: 36px !important;
  height: 36px !important;
}
.dot-icon.size-37 {
  width: 37px !important;
  height: 37px !important;
}
.dot-icon.size-38 {
  width: 38px !important;
  height: 38px !important;
}
.dot-icon.size-39 {
  width: 39px !important;
  height: 39px !important;
}
.dot-icon.size-40 {
  width: 40px !important;
  height: 40px !important;
}
.dot-icon.size-41 {
  width: 41px !important;
  height: 41px !important;
}
.dot-icon.size-42 {
  width: 42px !important;
  height: 42px !important;
}
.dot-icon.size-43 {
  width: 43px !important;
  height: 43px !important;
}
.dot-icon.size-44 {
  width: 44px !important;
  height: 44px !important;
}
.dot-icon.size-45 {
  width: 45px !important;
  height: 45px !important;
}
.dot-icon.size-46 {
  width: 46px !important;
  height: 46px !important;
}
.dot-icon.size-47 {
  width: 47px !important;
  height: 47px !important;
}
.dot-icon.size-48 {
  width: 48px !important;
  height: 48px !important;
}
.dot-icon.size-49 {
  width: 49px !important;
  height: 49px !important;
}
.dot-icon.size-50 {
  width: 50px !important;
  height: 50px !important;
}
.dot-icon.size-51 {
  width: 51px !important;
  height: 51px !important;
}
.dot-icon.size-52 {
  width: 52px !important;
  height: 52px !important;
}
.dot-icon.size-53 {
  width: 53px !important;
  height: 53px !important;
}
.dot-icon.size-54 {
  width: 54px !important;
  height: 54px !important;
}
.dot-icon.size-55 {
  width: 55px !important;
  height: 55px !important;
}
.dot-icon.size-56 {
  width: 56px !important;
  height: 56px !important;
}
.dot-icon.size-57 {
  width: 57px !important;
  height: 57px !important;
}
.dot-icon.size-58 {
  width: 58px !important;
  height: 58px !important;
}
.dot-icon.size-59 {
  width: 59px !important;
  height: 59px !important;
}
.dot-icon.size-60 {
  width: 60px !important;
  height: 60px !important;
}
.dot-icon.size-61 {
  width: 61px !important;
  height: 61px !important;
}
.dot-icon.size-62 {
  width: 62px !important;
  height: 62px !important;
}
.dot-icon.size-63 {
  width: 63px !important;
  height: 63px !important;
}
.dot-icon.size-64 {
  width: 64px !important;
  height: 64px !important;
}
.dot-icon.size-65 {
  width: 65px !important;
  height: 65px !important;
}
.dot-icon.size-66 {
  width: 66px !important;
  height: 66px !important;
}
.dot-icon.size-67 {
  width: 67px !important;
  height: 67px !important;
}
.dot-icon.size-68 {
  width: 68px !important;
  height: 68px !important;
}
.dot-icon.size-69 {
  width: 69px !important;
  height: 69px !important;
}
.dot-icon.size-70 {
  width: 70px !important;
  height: 70px !important;
}
.dot-icon.size-71 {
  width: 71px !important;
  height: 71px !important;
}
.dot-icon.size-72 {
  width: 72px !important;
  height: 72px !important;
}
.dot-icon.size-73 {
  width: 73px !important;
  height: 73px !important;
}
.dot-icon.size-74 {
  width: 74px !important;
  height: 74px !important;
}
.dot-icon.size-75 {
  width: 75px !important;
  height: 75px !important;
}
.dot-icon.size-76 {
  width: 76px !important;
  height: 76px !important;
}
.dot-icon.size-77 {
  width: 77px !important;
  height: 77px !important;
}
.dot-icon.size-78 {
  width: 78px !important;
  height: 78px !important;
}
.dot-icon.size-79 {
  width: 79px !important;
  height: 79px !important;
}
.dot-icon.size-80 {
  width: 80px !important;
  height: 80px !important;
}
.dot-icon.size-81 {
  width: 81px !important;
  height: 81px !important;
}
.dot-icon.size-82 {
  width: 82px !important;
  height: 82px !important;
}
.dot-icon.size-83 {
  width: 83px !important;
  height: 83px !important;
}
.dot-icon.size-84 {
  width: 84px !important;
  height: 84px !important;
}
.dot-icon.size-85 {
  width: 85px !important;
  height: 85px !important;
}
.dot-icon.size-86 {
  width: 86px !important;
  height: 86px !important;
}
.dot-icon.size-87 {
  width: 87px !important;
  height: 87px !important;
}
.dot-icon.size-88 {
  width: 88px !important;
  height: 88px !important;
}
.dot-icon.size-89 {
  width: 89px !important;
  height: 89px !important;
}
.dot-icon.size-90 {
  width: 90px !important;
  height: 90px !important;
}
.dot-icon.size-91 {
  width: 91px !important;
  height: 91px !important;
}
.dot-icon.size-92 {
  width: 92px !important;
  height: 92px !important;
}
.dot-icon.size-93 {
  width: 93px !important;
  height: 93px !important;
}
.dot-icon.size-94 {
  width: 94px !important;
  height: 94px !important;
}
.dot-icon.size-95 {
  width: 95px !important;
  height: 95px !important;
}
.dot-icon.size-96 {
  width: 96px !important;
  height: 96px !important;
}
.dot-icon.size-97 {
  width: 97px !important;
  height: 97px !important;
}
.dot-icon.size-98 {
  width: 98px !important;
  height: 98px !important;
}
.dot-icon.size-99 {
  width: 99px !important;
  height: 99px !important;
}
.dot-icon.size-100 {
  width: 100px !important;
  height: 100px !important;
}

svg.size-1 {
  width: 1px !important;
  height: 1px !important;
}
svg.size-2 {
  width: 2px !important;
  height: 2px !important;
}
svg.size-3 {
  width: 3px !important;
  height: 3px !important;
}
svg.size-4 {
  width: 4px !important;
  height: 4px !important;
}
svg.size-5 {
  width: 5px !important;
  height: 5px !important;
}
svg.size-6 {
  width: 6px !important;
  height: 6px !important;
}
svg.size-7 {
  width: 7px !important;
  height: 7px !important;
}
svg.size-8 {
  width: 8px !important;
  height: 8px !important;
}
svg.size-9 {
  width: 9px !important;
  height: 9px !important;
}
svg.size-10 {
  width: 10px !important;
  height: 10px !important;
}
svg.size-11 {
  width: 11px !important;
  height: 11px !important;
}
svg.size-12 {
  width: 12px !important;
  height: 12px !important;
}
svg.size-13 {
  width: 13px !important;
  height: 13px !important;
}
svg.size-14 {
  width: 14px !important;
  height: 14px !important;
}
svg.size-15 {
  width: 15px !important;
  height: 15px !important;
}
svg.size-16 {
  width: 16px !important;
  height: 16px !important;
}
svg.size-17 {
  width: 17px !important;
  height: 17px !important;
}
svg.size-18 {
  width: 18px !important;
  height: 18px !important;
}
svg.size-19 {
  width: 19px !important;
  height: 19px !important;
}
svg.size-20 {
  width: 20px !important;
  height: 20px !important;
}
svg.size-21 {
  width: 21px !important;
  height: 21px !important;
}
svg.size-22 {
  width: 22px !important;
  height: 22px !important;
}
svg.size-23 {
  width: 23px !important;
  height: 23px !important;
}
svg.size-24 {
  width: 24px !important;
  height: 24px !important;
}
svg.size-25 {
  width: 25px !important;
  height: 25px !important;
}
svg.size-26 {
  width: 26px !important;
  height: 26px !important;
}
svg.size-27 {
  width: 27px !important;
  height: 27px !important;
}
svg.size-28 {
  width: 28px !important;
  height: 28px !important;
}
svg.size-29 {
  width: 29px !important;
  height: 29px !important;
}
svg.size-30 {
  width: 30px !important;
  height: 30px !important;
}
svg.size-31 {
  width: 31px !important;
  height: 31px !important;
}
svg.size-32 {
  width: 32px !important;
  height: 32px !important;
}
svg.size-33 {
  width: 33px !important;
  height: 33px !important;
}
svg.size-34 {
  width: 34px !important;
  height: 34px !important;
}
svg.size-35 {
  width: 35px !important;
  height: 35px !important;
}
svg.size-36 {
  width: 36px !important;
  height: 36px !important;
}
svg.size-37 {
  width: 37px !important;
  height: 37px !important;
}
svg.size-38 {
  width: 38px !important;
  height: 38px !important;
}
svg.size-39 {
  width: 39px !important;
  height: 39px !important;
}
svg.size-40 {
  width: 40px !important;
  height: 40px !important;
}
svg.size-41 {
  width: 41px !important;
  height: 41px !important;
}
svg.size-42 {
  width: 42px !important;
  height: 42px !important;
}
svg.size-43 {
  width: 43px !important;
  height: 43px !important;
}
svg.size-44 {
  width: 44px !important;
  height: 44px !important;
}
svg.size-45 {
  width: 45px !important;
  height: 45px !important;
}
svg.size-46 {
  width: 46px !important;
  height: 46px !important;
}
svg.size-47 {
  width: 47px !important;
  height: 47px !important;
}
svg.size-48 {
  width: 48px !important;
  height: 48px !important;
}
svg.size-49 {
  width: 49px !important;
  height: 49px !important;
}
svg.size-50 {
  width: 50px !important;
  height: 50px !important;
}
svg.size-51 {
  width: 51px !important;
  height: 51px !important;
}
svg.size-52 {
  width: 52px !important;
  height: 52px !important;
}
svg.size-53 {
  width: 53px !important;
  height: 53px !important;
}
svg.size-54 {
  width: 54px !important;
  height: 54px !important;
}
svg.size-55 {
  width: 55px !important;
  height: 55px !important;
}
svg.size-56 {
  width: 56px !important;
  height: 56px !important;
}
svg.size-57 {
  width: 57px !important;
  height: 57px !important;
}
svg.size-58 {
  width: 58px !important;
  height: 58px !important;
}
svg.size-59 {
  width: 59px !important;
  height: 59px !important;
}
svg.size-60 {
  width: 60px !important;
  height: 60px !important;
}
svg.size-61 {
  width: 61px !important;
  height: 61px !important;
}
svg.size-62 {
  width: 62px !important;
  height: 62px !important;
}
svg.size-63 {
  width: 63px !important;
  height: 63px !important;
}
svg.size-64 {
  width: 64px !important;
  height: 64px !important;
}
svg.size-65 {
  width: 65px !important;
  height: 65px !important;
}
svg.size-66 {
  width: 66px !important;
  height: 66px !important;
}
svg.size-67 {
  width: 67px !important;
  height: 67px !important;
}
svg.size-68 {
  width: 68px !important;
  height: 68px !important;
}
svg.size-69 {
  width: 69px !important;
  height: 69px !important;
}
svg.size-70 {
  width: 70px !important;
  height: 70px !important;
}
svg.size-71 {
  width: 71px !important;
  height: 71px !important;
}
svg.size-72 {
  width: 72px !important;
  height: 72px !important;
}
svg.size-73 {
  width: 73px !important;
  height: 73px !important;
}
svg.size-74 {
  width: 74px !important;
  height: 74px !important;
}
svg.size-75 {
  width: 75px !important;
  height: 75px !important;
}
svg.size-76 {
  width: 76px !important;
  height: 76px !important;
}
svg.size-77 {
  width: 77px !important;
  height: 77px !important;
}
svg.size-78 {
  width: 78px !important;
  height: 78px !important;
}
svg.size-79 {
  width: 79px !important;
  height: 79px !important;
}
svg.size-80 {
  width: 80px !important;
  height: 80px !important;
}
svg.size-81 {
  width: 81px !important;
  height: 81px !important;
}
svg.size-82 {
  width: 82px !important;
  height: 82px !important;
}
svg.size-83 {
  width: 83px !important;
  height: 83px !important;
}
svg.size-84 {
  width: 84px !important;
  height: 84px !important;
}
svg.size-85 {
  width: 85px !important;
  height: 85px !important;
}
svg.size-86 {
  width: 86px !important;
  height: 86px !important;
}
svg.size-87 {
  width: 87px !important;
  height: 87px !important;
}
svg.size-88 {
  width: 88px !important;
  height: 88px !important;
}
svg.size-89 {
  width: 89px !important;
  height: 89px !important;
}
svg.size-90 {
  width: 90px !important;
  height: 90px !important;
}
svg.size-91 {
  width: 91px !important;
  height: 91px !important;
}
svg.size-92 {
  width: 92px !important;
  height: 92px !important;
}
svg.size-93 {
  width: 93px !important;
  height: 93px !important;
}
svg.size-94 {
  width: 94px !important;
  height: 94px !important;
}
svg.size-95 {
  width: 95px !important;
  height: 95px !important;
}
svg.size-96 {
  width: 96px !important;
  height: 96px !important;
}
svg.size-97 {
  width: 97px !important;
  height: 97px !important;
}
svg.size-98 {
  width: 98px !important;
  height: 98px !important;
}
svg.size-99 {
  width: 99px !important;
  height: 99px !important;
}
svg.size-100 {
  width: 100px !important;
  height: 100px !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.bg-brand-color {
  background-color: #019AFF !important;
}

.bg-disabled {
  background-color: #afb1b6 !important;
}

.bg-inactive {
  background-color: #afb1b6 !important;
}

.primary-text-color {
  color: #019AFF !important;
}

.primary-card-color {
  background-color: var(--default-card-bg) !important;
}

.default-font-color {
  color: var(--default-font-color) !important;
}

.default-base-color {
  background-color: var(--base-color);
}

.white-base-color {
  background-color: var(--white-base-color);
}

.text-muted {
  color: #afb1b6 !important;
}

.bg-muted {
  background-color: #afb1b6 !important;
}

.text-success {
  color: #46c35f !important;
}

.bg-success {
  background-color: #46c35f !important;
}

.text-info {
  color: #38a4f8 !important;
}

.bg-info {
  background-color: #38a4f8 !important;
}

.text-warning {
  color: #FC6510 !important;
}

.bg-warning {
  background-color: #FC6510 !important;
}

.text-danger {
  color: #fc2c10 !important;
}

.bg-danger {
  background-color: #fc2c10 !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.p-primary {
  padding: 2rem !important;
}

.px-primary {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-primary {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pt-primary {
  padding-top: 2rem !important;
}

.pr-primary {
  padding-right: 2rem !important;
}

.pb-primary {
  padding-bottom: 2rem !important;
}

.pl-primary {
  padding-left: 2rem !important;
}

.py-half-primary {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.m-primary {
  margin: 2rem !important;
}

.mt-primary {
  margin-top: 2rem !important;
}

.mr-primary {
  margin-right: 2rem !important;
}

.mb-primary {
  margin-bottom: 2rem !important;
}

.ml-primary {
  margin-left: 2rem !important;
}

.mx-primary {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-primary {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-minus-primary {
  margin-left: -2rem;
  margin-right: -2rem;
}

.mb-5rem {
  margin-bottom: 5rem;
}

.margin-1 {
  margin: 1px !important;
}

.margin-top-1 {
  margin-top: 1px !important;
}

.margin-right-1 {
  margin-right: 1px !important;
}

.margin-bottom-1 {
  margin-bottom: 1px !important;
}

.margin-left-1 {
  margin-left: 1px !important;
}

.margin-x-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.margin-y-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.padding-1 {
  padding: 1px !important;
}

.padding-top-1 {
  padding-top: 1px !important;
}

.padding-right-1 {
  padding-right: 1px !important;
}

.padding-bottom-1 {
  padding-bottom: 1px !important;
}

.padding-left-1 {
  padding-left: 1px !important;
}

.padding-x-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.padding-y-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.margin-2 {
  margin: 2px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-right-2 {
  margin-right: 2px !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-left-2 {
  margin-left: 2px !important;
}

.margin-x-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.margin-y-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.padding-2 {
  padding: 2px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-right-2 {
  padding-right: 2px !important;
}

.padding-bottom-2 {
  padding-bottom: 2px !important;
}

.padding-left-2 {
  padding-left: 2px !important;
}

.padding-x-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.padding-y-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.margin-3 {
  margin: 3px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-right-3 {
  margin-right: 3px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.margin-left-3 {
  margin-left: 3px !important;
}

.margin-x-3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.margin-y-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.padding-3 {
  padding: 3px !important;
}

.padding-top-3 {
  padding-top: 3px !important;
}

.padding-right-3 {
  padding-right: 3px !important;
}

.padding-bottom-3 {
  padding-bottom: 3px !important;
}

.padding-left-3 {
  padding-left: 3px !important;
}

.padding-x-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.padding-y-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.margin-4 {
  margin: 4px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-right-4 {
  margin-right: 4px !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-left-4 {
  margin-left: 4px !important;
}

.margin-x-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.margin-y-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.padding-4 {
  padding: 4px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-right-4 {
  padding-right: 4px !important;
}

.padding-bottom-4 {
  padding-bottom: 4px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}

.padding-x-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.padding-y-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-x-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.margin-y-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-x-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.padding-y-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.margin-6 {
  margin: 6px !important;
}

.margin-top-6 {
  margin-top: 6px !important;
}

.margin-right-6 {
  margin-right: 6px !important;
}

.margin-bottom-6 {
  margin-bottom: 6px !important;
}

.margin-left-6 {
  margin-left: 6px !important;
}

.margin-x-6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.margin-y-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.padding-6 {
  padding: 6px !important;
}

.padding-top-6 {
  padding-top: 6px !important;
}

.padding-right-6 {
  padding-right: 6px !important;
}

.padding-bottom-6 {
  padding-bottom: 6px !important;
}

.padding-left-6 {
  padding-left: 6px !important;
}

.padding-x-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.padding-y-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.margin-7 {
  margin: 7px !important;
}

.margin-top-7 {
  margin-top: 7px !important;
}

.margin-right-7 {
  margin-right: 7px !important;
}

.margin-bottom-7 {
  margin-bottom: 7px !important;
}

.margin-left-7 {
  margin-left: 7px !important;
}

.margin-x-7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.margin-y-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.padding-7 {
  padding: 7px !important;
}

.padding-top-7 {
  padding-top: 7px !important;
}

.padding-right-7 {
  padding-right: 7px !important;
}

.padding-bottom-7 {
  padding-bottom: 7px !important;
}

.padding-left-7 {
  padding-left: 7px !important;
}

.padding-x-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.padding-y-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.margin-8 {
  margin: 8px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-x-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.margin-y-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.padding-left-8 {
  padding-left: 8px !important;
}

.padding-x-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.padding-y-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.margin-9 {
  margin: 9px !important;
}

.margin-top-9 {
  margin-top: 9px !important;
}

.margin-right-9 {
  margin-right: 9px !important;
}

.margin-bottom-9 {
  margin-bottom: 9px !important;
}

.margin-left-9 {
  margin-left: 9px !important;
}

.margin-x-9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.margin-y-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.padding-9 {
  padding: 9px !important;
}

.padding-top-9 {
  padding-top: 9px !important;
}

.padding-right-9 {
  padding-right: 9px !important;
}

.padding-bottom-9 {
  padding-bottom: 9px !important;
}

.padding-left-9 {
  padding-left: 9px !important;
}

.padding-x-9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.padding-y-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-x-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.margin-y-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.margin-11 {
  margin: 11px !important;
}

.margin-top-11 {
  margin-top: 11px !important;
}

.margin-right-11 {
  margin-right: 11px !important;
}

.margin-bottom-11 {
  margin-bottom: 11px !important;
}

.margin-left-11 {
  margin-left: 11px !important;
}

.margin-x-11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.margin-y-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.padding-11 {
  padding: 11px !important;
}

.padding-top-11 {
  padding-top: 11px !important;
}

.padding-right-11 {
  padding-right: 11px !important;
}

.padding-bottom-11 {
  padding-bottom: 11px !important;
}

.padding-left-11 {
  padding-left: 11px !important;
}

.padding-x-11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.padding-y-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.margin-12 {
  margin: 12px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-right-12 {
  margin-right: 12px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.margin-x-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.margin-y-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.padding-12 {
  padding: 12px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.padding-right-12 {
  padding-right: 12px !important;
}

.padding-bottom-12 {
  padding-bottom: 12px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-x-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.padding-y-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.margin-13 {
  margin: 13px !important;
}

.margin-top-13 {
  margin-top: 13px !important;
}

.margin-right-13 {
  margin-right: 13px !important;
}

.margin-bottom-13 {
  margin-bottom: 13px !important;
}

.margin-left-13 {
  margin-left: 13px !important;
}

.margin-x-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.margin-y-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.padding-13 {
  padding: 13px !important;
}

.padding-top-13 {
  padding-top: 13px !important;
}

.padding-right-13 {
  padding-right: 13px !important;
}

.padding-bottom-13 {
  padding-bottom: 13px !important;
}

.padding-left-13 {
  padding-left: 13px !important;
}

.padding-x-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.padding-y-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.margin-14 {
  margin: 14px !important;
}

.margin-top-14 {
  margin-top: 14px !important;
}

.margin-right-14 {
  margin-right: 14px !important;
}

.margin-bottom-14 {
  margin-bottom: 14px !important;
}

.margin-left-14 {
  margin-left: 14px !important;
}

.margin-x-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.margin-y-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.padding-14 {
  padding: 14px !important;
}

.padding-top-14 {
  padding-top: 14px !important;
}

.padding-right-14 {
  padding-right: 14px !important;
}

.padding-bottom-14 {
  padding-bottom: 14px !important;
}

.padding-left-14 {
  padding-left: 14px !important;
}

.padding-x-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.padding-y-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.margin-15 {
  margin: 15px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-x-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.margin-y-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-x-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.padding-y-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.margin-16 {
  margin: 16px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-right-16 {
  margin-right: 16px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-x-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.margin-y-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-top-16 {
  padding-top: 16px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-x-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.padding-y-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.margin-17 {
  margin: 17px !important;
}

.margin-top-17 {
  margin-top: 17px !important;
}

.margin-right-17 {
  margin-right: 17px !important;
}

.margin-bottom-17 {
  margin-bottom: 17px !important;
}

.margin-left-17 {
  margin-left: 17px !important;
}

.margin-x-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.margin-y-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.padding-17 {
  padding: 17px !important;
}

.padding-top-17 {
  padding-top: 17px !important;
}

.padding-right-17 {
  padding-right: 17px !important;
}

.padding-bottom-17 {
  padding-bottom: 17px !important;
}

.padding-left-17 {
  padding-left: 17px !important;
}

.padding-x-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.padding-y-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.margin-18 {
  margin: 18px !important;
}

.margin-top-18 {
  margin-top: 18px !important;
}

.margin-right-18 {
  margin-right: 18px !important;
}

.margin-bottom-18 {
  margin-bottom: 18px !important;
}

.margin-left-18 {
  margin-left: 18px !important;
}

.margin-x-18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.margin-y-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.padding-18 {
  padding: 18px !important;
}

.padding-top-18 {
  padding-top: 18px !important;
}

.padding-right-18 {
  padding-right: 18px !important;
}

.padding-bottom-18 {
  padding-bottom: 18px !important;
}

.padding-left-18 {
  padding-left: 18px !important;
}

.padding-x-18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.padding-y-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.margin-19 {
  margin: 19px !important;
}

.margin-top-19 {
  margin-top: 19px !important;
}

.margin-right-19 {
  margin-right: 19px !important;
}

.margin-bottom-19 {
  margin-bottom: 19px !important;
}

.margin-left-19 {
  margin-left: 19px !important;
}

.margin-x-19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}

.margin-y-19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.padding-19 {
  padding: 19px !important;
}

.padding-top-19 {
  padding-top: 19px !important;
}

.padding-right-19 {
  padding-right: 19px !important;
}

.padding-bottom-19 {
  padding-bottom: 19px !important;
}

.padding-left-19 {
  padding-left: 19px !important;
}

.padding-x-19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.padding-y-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-x-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.margin-y-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.padding-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.margin-21 {
  margin: 21px !important;
}

.margin-top-21 {
  margin-top: 21px !important;
}

.margin-right-21 {
  margin-right: 21px !important;
}

.margin-bottom-21 {
  margin-bottom: 21px !important;
}

.margin-left-21 {
  margin-left: 21px !important;
}

.margin-x-21 {
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.margin-y-21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.padding-21 {
  padding: 21px !important;
}

.padding-top-21 {
  padding-top: 21px !important;
}

.padding-right-21 {
  padding-right: 21px !important;
}

.padding-bottom-21 {
  padding-bottom: 21px !important;
}

.padding-left-21 {
  padding-left: 21px !important;
}

.padding-x-21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.padding-y-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.margin-22 {
  margin: 22px !important;
}

.margin-top-22 {
  margin-top: 22px !important;
}

.margin-right-22 {
  margin-right: 22px !important;
}

.margin-bottom-22 {
  margin-bottom: 22px !important;
}

.margin-left-22 {
  margin-left: 22px !important;
}

.margin-x-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.margin-y-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.padding-22 {
  padding: 22px !important;
}

.padding-top-22 {
  padding-top: 22px !important;
}

.padding-right-22 {
  padding-right: 22px !important;
}

.padding-bottom-22 {
  padding-bottom: 22px !important;
}

.padding-left-22 {
  padding-left: 22px !important;
}

.padding-x-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.padding-y-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.margin-23 {
  margin: 23px !important;
}

.margin-top-23 {
  margin-top: 23px !important;
}

.margin-right-23 {
  margin-right: 23px !important;
}

.margin-bottom-23 {
  margin-bottom: 23px !important;
}

.margin-left-23 {
  margin-left: 23px !important;
}

.margin-x-23 {
  margin-left: 23px !important;
  margin-right: 23px !important;
}

.margin-y-23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}

.padding-23 {
  padding: 23px !important;
}

.padding-top-23 {
  padding-top: 23px !important;
}

.padding-right-23 {
  padding-right: 23px !important;
}

.padding-bottom-23 {
  padding-bottom: 23px !important;
}

.padding-left-23 {
  padding-left: 23px !important;
}

.padding-x-23 {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.padding-y-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.margin-24 {
  margin: 24px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-left-24 {
  margin-left: 24px !important;
}

.margin-x-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.margin-y-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.padding-24 {
  padding: 24px !important;
}

.padding-top-24 {
  padding-top: 24px !important;
}

.padding-right-24 {
  padding-right: 24px !important;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

.padding-x-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.padding-y-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.margin-25 {
  margin: 25px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-right-25 {
  margin-right: 25px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

.margin-x-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.margin-y-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.padding-25 {
  padding: 25px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.padding-x-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.padding-y-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.margin-26 {
  margin: 26px !important;
}

.margin-top-26 {
  margin-top: 26px !important;
}

.margin-right-26 {
  margin-right: 26px !important;
}

.margin-bottom-26 {
  margin-bottom: 26px !important;
}

.margin-left-26 {
  margin-left: 26px !important;
}

.margin-x-26 {
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.margin-y-26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.padding-26 {
  padding: 26px !important;
}

.padding-top-26 {
  padding-top: 26px !important;
}

.padding-right-26 {
  padding-right: 26px !important;
}

.padding-bottom-26 {
  padding-bottom: 26px !important;
}

.padding-left-26 {
  padding-left: 26px !important;
}

.padding-x-26 {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.padding-y-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.margin-27 {
  margin: 27px !important;
}

.margin-top-27 {
  margin-top: 27px !important;
}

.margin-right-27 {
  margin-right: 27px !important;
}

.margin-bottom-27 {
  margin-bottom: 27px !important;
}

.margin-left-27 {
  margin-left: 27px !important;
}

.margin-x-27 {
  margin-left: 27px !important;
  margin-right: 27px !important;
}

.margin-y-27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important;
}

.padding-27 {
  padding: 27px !important;
}

.padding-top-27 {
  padding-top: 27px !important;
}

.padding-right-27 {
  padding-right: 27px !important;
}

.padding-bottom-27 {
  padding-bottom: 27px !important;
}

.padding-left-27 {
  padding-left: 27px !important;
}

.padding-x-27 {
  padding-left: 27px !important;
  padding-right: 27px !important;
}

.padding-y-27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}

.margin-28 {
  margin: 28px !important;
}

.margin-top-28 {
  margin-top: 28px !important;
}

.margin-right-28 {
  margin-right: 28px !important;
}

.margin-bottom-28 {
  margin-bottom: 28px !important;
}

.margin-left-28 {
  margin-left: 28px !important;
}

.margin-x-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.margin-y-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.padding-28 {
  padding: 28px !important;
}

.padding-top-28 {
  padding-top: 28px !important;
}

.padding-right-28 {
  padding-right: 28px !important;
}

.padding-bottom-28 {
  padding-bottom: 28px !important;
}

.padding-left-28 {
  padding-left: 28px !important;
}

.padding-x-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.padding-y-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.margin-29 {
  margin: 29px !important;
}

.margin-top-29 {
  margin-top: 29px !important;
}

.margin-right-29 {
  margin-right: 29px !important;
}

.margin-bottom-29 {
  margin-bottom: 29px !important;
}

.margin-left-29 {
  margin-left: 29px !important;
}

.margin-x-29 {
  margin-left: 29px !important;
  margin-right: 29px !important;
}

.margin-y-29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important;
}

.padding-29 {
  padding: 29px !important;
}

.padding-top-29 {
  padding-top: 29px !important;
}

.padding-right-29 {
  padding-right: 29px !important;
}

.padding-bottom-29 {
  padding-bottom: 29px !important;
}

.padding-left-29 {
  padding-left: 29px !important;
}

.padding-x-29 {
  padding-left: 29px !important;
  padding-right: 29px !important;
}

.padding-y-29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.margin-30 {
  margin: 30px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-x-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.margin-y-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.padding-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.margin-31 {
  margin: 31px !important;
}

.margin-top-31 {
  margin-top: 31px !important;
}

.margin-right-31 {
  margin-right: 31px !important;
}

.margin-bottom-31 {
  margin-bottom: 31px !important;
}

.margin-left-31 {
  margin-left: 31px !important;
}

.margin-x-31 {
  margin-left: 31px !important;
  margin-right: 31px !important;
}

.margin-y-31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important;
}

.padding-31 {
  padding: 31px !important;
}

.padding-top-31 {
  padding-top: 31px !important;
}

.padding-right-31 {
  padding-right: 31px !important;
}

.padding-bottom-31 {
  padding-bottom: 31px !important;
}

.padding-left-31 {
  padding-left: 31px !important;
}

.padding-x-31 {
  padding-left: 31px !important;
  padding-right: 31px !important;
}

.padding-y-31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important;
}

.margin-32 {
  margin: 32px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}

.margin-right-32 {
  margin-right: 32px !important;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

.margin-left-32 {
  margin-left: 32px !important;
}

.margin-x-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.margin-y-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.padding-32 {
  padding: 32px !important;
}

.padding-top-32 {
  padding-top: 32px !important;
}

.padding-right-32 {
  padding-right: 32px !important;
}

.padding-bottom-32 {
  padding-bottom: 32px !important;
}

.padding-left-32 {
  padding-left: 32px !important;
}

.padding-x-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.padding-y-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.margin-33 {
  margin: 33px !important;
}

.margin-top-33 {
  margin-top: 33px !important;
}

.margin-right-33 {
  margin-right: 33px !important;
}

.margin-bottom-33 {
  margin-bottom: 33px !important;
}

.margin-left-33 {
  margin-left: 33px !important;
}

.margin-x-33 {
  margin-left: 33px !important;
  margin-right: 33px !important;
}

.margin-y-33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important;
}

.padding-33 {
  padding: 33px !important;
}

.padding-top-33 {
  padding-top: 33px !important;
}

.padding-right-33 {
  padding-right: 33px !important;
}

.padding-bottom-33 {
  padding-bottom: 33px !important;
}

.padding-left-33 {
  padding-left: 33px !important;
}

.padding-x-33 {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.padding-y-33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important;
}

.margin-34 {
  margin: 34px !important;
}

.margin-top-34 {
  margin-top: 34px !important;
}

.margin-right-34 {
  margin-right: 34px !important;
}

.margin-bottom-34 {
  margin-bottom: 34px !important;
}

.margin-left-34 {
  margin-left: 34px !important;
}

.margin-x-34 {
  margin-left: 34px !important;
  margin-right: 34px !important;
}

.margin-y-34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.padding-34 {
  padding: 34px !important;
}

.padding-top-34 {
  padding-top: 34px !important;
}

.padding-right-34 {
  padding-right: 34px !important;
}

.padding-bottom-34 {
  padding-bottom: 34px !important;
}

.padding-left-34 {
  padding-left: 34px !important;
}

.padding-x-34 {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.padding-y-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.margin-35 {
  margin: 35px !important;
}

.margin-top-35 {
  margin-top: 35px !important;
}

.margin-right-35 {
  margin-right: 35px !important;
}

.margin-bottom-35 {
  margin-bottom: 35px !important;
}

.margin-left-35 {
  margin-left: 35px !important;
}

.margin-x-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.margin-y-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.padding-35 {
  padding: 35px !important;
}

.padding-top-35 {
  padding-top: 35px !important;
}

.padding-right-35 {
  padding-right: 35px !important;
}

.padding-bottom-35 {
  padding-bottom: 35px !important;
}

.padding-left-35 {
  padding-left: 35px !important;
}

.padding-x-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.padding-y-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.margin-36 {
  margin: 36px !important;
}

.margin-top-36 {
  margin-top: 36px !important;
}

.margin-right-36 {
  margin-right: 36px !important;
}

.margin-bottom-36 {
  margin-bottom: 36px !important;
}

.margin-left-36 {
  margin-left: 36px !important;
}

.margin-x-36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.margin-y-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.padding-36 {
  padding: 36px !important;
}

.padding-top-36 {
  padding-top: 36px !important;
}

.padding-right-36 {
  padding-right: 36px !important;
}

.padding-bottom-36 {
  padding-bottom: 36px !important;
}

.padding-left-36 {
  padding-left: 36px !important;
}

.padding-x-36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.padding-y-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.margin-37 {
  margin: 37px !important;
}

.margin-top-37 {
  margin-top: 37px !important;
}

.margin-right-37 {
  margin-right: 37px !important;
}

.margin-bottom-37 {
  margin-bottom: 37px !important;
}

.margin-left-37 {
  margin-left: 37px !important;
}

.margin-x-37 {
  margin-left: 37px !important;
  margin-right: 37px !important;
}

.margin-y-37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important;
}

.padding-37 {
  padding: 37px !important;
}

.padding-top-37 {
  padding-top: 37px !important;
}

.padding-right-37 {
  padding-right: 37px !important;
}

.padding-bottom-37 {
  padding-bottom: 37px !important;
}

.padding-left-37 {
  padding-left: 37px !important;
}

.padding-x-37 {
  padding-left: 37px !important;
  padding-right: 37px !important;
}

.padding-y-37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}

.margin-38 {
  margin: 38px !important;
}

.margin-top-38 {
  margin-top: 38px !important;
}

.margin-right-38 {
  margin-right: 38px !important;
}

.margin-bottom-38 {
  margin-bottom: 38px !important;
}

.margin-left-38 {
  margin-left: 38px !important;
}

.margin-x-38 {
  margin-left: 38px !important;
  margin-right: 38px !important;
}

.margin-y-38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important;
}

.padding-38 {
  padding: 38px !important;
}

.padding-top-38 {
  padding-top: 38px !important;
}

.padding-right-38 {
  padding-right: 38px !important;
}

.padding-bottom-38 {
  padding-bottom: 38px !important;
}

.padding-left-38 {
  padding-left: 38px !important;
}

.padding-x-38 {
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.padding-y-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

.margin-39 {
  margin: 39px !important;
}

.margin-top-39 {
  margin-top: 39px !important;
}

.margin-right-39 {
  margin-right: 39px !important;
}

.margin-bottom-39 {
  margin-bottom: 39px !important;
}

.margin-left-39 {
  margin-left: 39px !important;
}

.margin-x-39 {
  margin-left: 39px !important;
  margin-right: 39px !important;
}

.margin-y-39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important;
}

.padding-39 {
  padding: 39px !important;
}

.padding-top-39 {
  padding-top: 39px !important;
}

.padding-right-39 {
  padding-right: 39px !important;
}

.padding-bottom-39 {
  padding-bottom: 39px !important;
}

.padding-left-39 {
  padding-left: 39px !important;
}

.padding-x-39 {
  padding-left: 39px !important;
  padding-right: 39px !important;
}

.padding-y-39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-x-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.margin-y-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-right-40 {
  padding-right: 40px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.padding-x-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.padding-y-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.margin-41 {
  margin: 41px !important;
}

.margin-top-41 {
  margin-top: 41px !important;
}

.margin-right-41 {
  margin-right: 41px !important;
}

.margin-bottom-41 {
  margin-bottom: 41px !important;
}

.margin-left-41 {
  margin-left: 41px !important;
}

.margin-x-41 {
  margin-left: 41px !important;
  margin-right: 41px !important;
}

.margin-y-41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important;
}

.padding-41 {
  padding: 41px !important;
}

.padding-top-41 {
  padding-top: 41px !important;
}

.padding-right-41 {
  padding-right: 41px !important;
}

.padding-bottom-41 {
  padding-bottom: 41px !important;
}

.padding-left-41 {
  padding-left: 41px !important;
}

.padding-x-41 {
  padding-left: 41px !important;
  padding-right: 41px !important;
}

.padding-y-41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important;
}

.margin-42 {
  margin: 42px !important;
}

.margin-top-42 {
  margin-top: 42px !important;
}

.margin-right-42 {
  margin-right: 42px !important;
}

.margin-bottom-42 {
  margin-bottom: 42px !important;
}

.margin-left-42 {
  margin-left: 42px !important;
}

.margin-x-42 {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

.margin-y-42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}

.padding-42 {
  padding: 42px !important;
}

.padding-top-42 {
  padding-top: 42px !important;
}

.padding-right-42 {
  padding-right: 42px !important;
}

.padding-bottom-42 {
  padding-bottom: 42px !important;
}

.padding-left-42 {
  padding-left: 42px !important;
}

.padding-x-42 {
  padding-left: 42px !important;
  padding-right: 42px !important;
}

.padding-y-42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important;
}

.margin-43 {
  margin: 43px !important;
}

.margin-top-43 {
  margin-top: 43px !important;
}

.margin-right-43 {
  margin-right: 43px !important;
}

.margin-bottom-43 {
  margin-bottom: 43px !important;
}

.margin-left-43 {
  margin-left: 43px !important;
}

.margin-x-43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

.margin-y-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

.padding-43 {
  padding: 43px !important;
}

.padding-top-43 {
  padding-top: 43px !important;
}

.padding-right-43 {
  padding-right: 43px !important;
}

.padding-bottom-43 {
  padding-bottom: 43px !important;
}

.padding-left-43 {
  padding-left: 43px !important;
}

.padding-x-43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

.padding-y-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

.margin-44 {
  margin: 44px !important;
}

.margin-top-44 {
  margin-top: 44px !important;
}

.margin-right-44 {
  margin-right: 44px !important;
}

.margin-bottom-44 {
  margin-bottom: 44px !important;
}

.margin-left-44 {
  margin-left: 44px !important;
}

.margin-x-44 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.margin-y-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.padding-44 {
  padding: 44px !important;
}

.padding-top-44 {
  padding-top: 44px !important;
}

.padding-right-44 {
  padding-right: 44px !important;
}

.padding-bottom-44 {
  padding-bottom: 44px !important;
}

.padding-left-44 {
  padding-left: 44px !important;
}

.padding-x-44 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.padding-y-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.margin-45 {
  margin: 45px !important;
}

.margin-top-45 {
  margin-top: 45px !important;
}

.margin-right-45 {
  margin-right: 45px !important;
}

.margin-bottom-45 {
  margin-bottom: 45px !important;
}

.margin-left-45 {
  margin-left: 45px !important;
}

.margin-x-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.margin-y-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.padding-45 {
  padding: 45px !important;
}

.padding-top-45 {
  padding-top: 45px !important;
}

.padding-right-45 {
  padding-right: 45px !important;
}

.padding-bottom-45 {
  padding-bottom: 45px !important;
}

.padding-left-45 {
  padding-left: 45px !important;
}

.padding-x-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.padding-y-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.margin-46 {
  margin: 46px !important;
}

.margin-top-46 {
  margin-top: 46px !important;
}

.margin-right-46 {
  margin-right: 46px !important;
}

.margin-bottom-46 {
  margin-bottom: 46px !important;
}

.margin-left-46 {
  margin-left: 46px !important;
}

.margin-x-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

.margin-y-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

.padding-46 {
  padding: 46px !important;
}

.padding-top-46 {
  padding-top: 46px !important;
}

.padding-right-46 {
  padding-right: 46px !important;
}

.padding-bottom-46 {
  padding-bottom: 46px !important;
}

.padding-left-46 {
  padding-left: 46px !important;
}

.padding-x-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.padding-y-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

.margin-47 {
  margin: 47px !important;
}

.margin-top-47 {
  margin-top: 47px !important;
}

.margin-right-47 {
  margin-right: 47px !important;
}

.margin-bottom-47 {
  margin-bottom: 47px !important;
}

.margin-left-47 {
  margin-left: 47px !important;
}

.margin-x-47 {
  margin-left: 47px !important;
  margin-right: 47px !important;
}

.margin-y-47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important;
}

.padding-47 {
  padding: 47px !important;
}

.padding-top-47 {
  padding-top: 47px !important;
}

.padding-right-47 {
  padding-right: 47px !important;
}

.padding-bottom-47 {
  padding-bottom: 47px !important;
}

.padding-left-47 {
  padding-left: 47px !important;
}

.padding-x-47 {
  padding-left: 47px !important;
  padding-right: 47px !important;
}

.padding-y-47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important;
}

.margin-48 {
  margin: 48px !important;
}

.margin-top-48 {
  margin-top: 48px !important;
}

.margin-right-48 {
  margin-right: 48px !important;
}

.margin-bottom-48 {
  margin-bottom: 48px !important;
}

.margin-left-48 {
  margin-left: 48px !important;
}

.margin-x-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.margin-y-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.padding-48 {
  padding: 48px !important;
}

.padding-top-48 {
  padding-top: 48px !important;
}

.padding-right-48 {
  padding-right: 48px !important;
}

.padding-bottom-48 {
  padding-bottom: 48px !important;
}

.padding-left-48 {
  padding-left: 48px !important;
}

.padding-x-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.padding-y-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.margin-49 {
  margin: 49px !important;
}

.margin-top-49 {
  margin-top: 49px !important;
}

.margin-right-49 {
  margin-right: 49px !important;
}

.margin-bottom-49 {
  margin-bottom: 49px !important;
}

.margin-left-49 {
  margin-left: 49px !important;
}

.margin-x-49 {
  margin-left: 49px !important;
  margin-right: 49px !important;
}

.margin-y-49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important;
}

.padding-49 {
  padding: 49px !important;
}

.padding-top-49 {
  padding-top: 49px !important;
}

.padding-right-49 {
  padding-right: 49px !important;
}

.padding-bottom-49 {
  padding-bottom: 49px !important;
}

.padding-left-49 {
  padding-left: 49px !important;
}

.padding-x-49 {
  padding-left: 49px !important;
  padding-right: 49px !important;
}

.padding-y-49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important;
}

.margin-50 {
  margin: 50px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-right-50 {
  margin-right: 50px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-x-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.margin-y-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-right-50 {
  padding-right: 50px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.padding-x-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.padding-y-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.margin-51 {
  margin: 51px !important;
}

.margin-top-51 {
  margin-top: 51px !important;
}

.margin-right-51 {
  margin-right: 51px !important;
}

.margin-bottom-51 {
  margin-bottom: 51px !important;
}

.margin-left-51 {
  margin-left: 51px !important;
}

.margin-x-51 {
  margin-left: 51px !important;
  margin-right: 51px !important;
}

.margin-y-51 {
  margin-top: 51px !important;
  margin-bottom: 51px !important;
}

.padding-51 {
  padding: 51px !important;
}

.padding-top-51 {
  padding-top: 51px !important;
}

.padding-right-51 {
  padding-right: 51px !important;
}

.padding-bottom-51 {
  padding-bottom: 51px !important;
}

.padding-left-51 {
  padding-left: 51px !important;
}

.padding-x-51 {
  padding-left: 51px !important;
  padding-right: 51px !important;
}

.padding-y-51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important;
}

.margin-52 {
  margin: 52px !important;
}

.margin-top-52 {
  margin-top: 52px !important;
}

.margin-right-52 {
  margin-right: 52px !important;
}

.margin-bottom-52 {
  margin-bottom: 52px !important;
}

.margin-left-52 {
  margin-left: 52px !important;
}

.margin-x-52 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.margin-y-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.padding-52 {
  padding: 52px !important;
}

.padding-top-52 {
  padding-top: 52px !important;
}

.padding-right-52 {
  padding-right: 52px !important;
}

.padding-bottom-52 {
  padding-bottom: 52px !important;
}

.padding-left-52 {
  padding-left: 52px !important;
}

.padding-x-52 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.padding-y-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.margin-53 {
  margin: 53px !important;
}

.margin-top-53 {
  margin-top: 53px !important;
}

.margin-right-53 {
  margin-right: 53px !important;
}

.margin-bottom-53 {
  margin-bottom: 53px !important;
}

.margin-left-53 {
  margin-left: 53px !important;
}

.margin-x-53 {
  margin-left: 53px !important;
  margin-right: 53px !important;
}

.margin-y-53 {
  margin-top: 53px !important;
  margin-bottom: 53px !important;
}

.padding-53 {
  padding: 53px !important;
}

.padding-top-53 {
  padding-top: 53px !important;
}

.padding-right-53 {
  padding-right: 53px !important;
}

.padding-bottom-53 {
  padding-bottom: 53px !important;
}

.padding-left-53 {
  padding-left: 53px !important;
}

.padding-x-53 {
  padding-left: 53px !important;
  padding-right: 53px !important;
}

.padding-y-53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important;
}

.margin-54 {
  margin: 54px !important;
}

.margin-top-54 {
  margin-top: 54px !important;
}

.margin-right-54 {
  margin-right: 54px !important;
}

.margin-bottom-54 {
  margin-bottom: 54px !important;
}

.margin-left-54 {
  margin-left: 54px !important;
}

.margin-x-54 {
  margin-left: 54px !important;
  margin-right: 54px !important;
}

.margin-y-54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important;
}

.padding-54 {
  padding: 54px !important;
}

.padding-top-54 {
  padding-top: 54px !important;
}

.padding-right-54 {
  padding-right: 54px !important;
}

.padding-bottom-54 {
  padding-bottom: 54px !important;
}

.padding-left-54 {
  padding-left: 54px !important;
}

.padding-x-54 {
  padding-left: 54px !important;
  padding-right: 54px !important;
}

.padding-y-54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important;
}

.margin-55 {
  margin: 55px !important;
}

.margin-top-55 {
  margin-top: 55px !important;
}

.margin-right-55 {
  margin-right: 55px !important;
}

.margin-bottom-55 {
  margin-bottom: 55px !important;
}

.margin-left-55 {
  margin-left: 55px !important;
}

.margin-x-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.margin-y-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.padding-55 {
  padding: 55px !important;
}

.padding-top-55 {
  padding-top: 55px !important;
}

.padding-right-55 {
  padding-right: 55px !important;
}

.padding-bottom-55 {
  padding-bottom: 55px !important;
}

.padding-left-55 {
  padding-left: 55px !important;
}

.padding-x-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.padding-y-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.margin-56 {
  margin: 56px !important;
}

.margin-top-56 {
  margin-top: 56px !important;
}

.margin-right-56 {
  margin-right: 56px !important;
}

.margin-bottom-56 {
  margin-bottom: 56px !important;
}

.margin-left-56 {
  margin-left: 56px !important;
}

.margin-x-56 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.margin-y-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.padding-56 {
  padding: 56px !important;
}

.padding-top-56 {
  padding-top: 56px !important;
}

.padding-right-56 {
  padding-right: 56px !important;
}

.padding-bottom-56 {
  padding-bottom: 56px !important;
}

.padding-left-56 {
  padding-left: 56px !important;
}

.padding-x-56 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.padding-y-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.margin-57 {
  margin: 57px !important;
}

.margin-top-57 {
  margin-top: 57px !important;
}

.margin-right-57 {
  margin-right: 57px !important;
}

.margin-bottom-57 {
  margin-bottom: 57px !important;
}

.margin-left-57 {
  margin-left: 57px !important;
}

.margin-x-57 {
  margin-left: 57px !important;
  margin-right: 57px !important;
}

.margin-y-57 {
  margin-top: 57px !important;
  margin-bottom: 57px !important;
}

.padding-57 {
  padding: 57px !important;
}

.padding-top-57 {
  padding-top: 57px !important;
}

.padding-right-57 {
  padding-right: 57px !important;
}

.padding-bottom-57 {
  padding-bottom: 57px !important;
}

.padding-left-57 {
  padding-left: 57px !important;
}

.padding-x-57 {
  padding-left: 57px !important;
  padding-right: 57px !important;
}

.padding-y-57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important;
}

.margin-58 {
  margin: 58px !important;
}

.margin-top-58 {
  margin-top: 58px !important;
}

.margin-right-58 {
  margin-right: 58px !important;
}

.margin-bottom-58 {
  margin-bottom: 58px !important;
}

.margin-left-58 {
  margin-left: 58px !important;
}

.margin-x-58 {
  margin-left: 58px !important;
  margin-right: 58px !important;
}

.margin-y-58 {
  margin-top: 58px !important;
  margin-bottom: 58px !important;
}

.padding-58 {
  padding: 58px !important;
}

.padding-top-58 {
  padding-top: 58px !important;
}

.padding-right-58 {
  padding-right: 58px !important;
}

.padding-bottom-58 {
  padding-bottom: 58px !important;
}

.padding-left-58 {
  padding-left: 58px !important;
}

.padding-x-58 {
  padding-left: 58px !important;
  padding-right: 58px !important;
}

.padding-y-58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important;
}

.margin-59 {
  margin: 59px !important;
}

.margin-top-59 {
  margin-top: 59px !important;
}

.margin-right-59 {
  margin-right: 59px !important;
}

.margin-bottom-59 {
  margin-bottom: 59px !important;
}

.margin-left-59 {
  margin-left: 59px !important;
}

.margin-x-59 {
  margin-left: 59px !important;
  margin-right: 59px !important;
}

.margin-y-59 {
  margin-top: 59px !important;
  margin-bottom: 59px !important;
}

.padding-59 {
  padding: 59px !important;
}

.padding-top-59 {
  padding-top: 59px !important;
}

.padding-right-59 {
  padding-right: 59px !important;
}

.padding-bottom-59 {
  padding-bottom: 59px !important;
}

.padding-left-59 {
  padding-left: 59px !important;
}

.padding-x-59 {
  padding-left: 59px !important;
  padding-right: 59px !important;
}

.padding-y-59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important;
}

.margin-60 {
  margin: 60px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-right-60 {
  margin-right: 60px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-left-60 {
  margin-left: 60px !important;
}

.margin-x-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.margin-y-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.padding-60 {
  padding: 60px !important;
}

.padding-top-60 {
  padding-top: 60px !important;
}

.padding-right-60 {
  padding-right: 60px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.padding-left-60 {
  padding-left: 60px !important;
}

.padding-x-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.padding-y-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.margin-61 {
  margin: 61px !important;
}

.margin-top-61 {
  margin-top: 61px !important;
}

.margin-right-61 {
  margin-right: 61px !important;
}

.margin-bottom-61 {
  margin-bottom: 61px !important;
}

.margin-left-61 {
  margin-left: 61px !important;
}

.margin-x-61 {
  margin-left: 61px !important;
  margin-right: 61px !important;
}

.margin-y-61 {
  margin-top: 61px !important;
  margin-bottom: 61px !important;
}

.padding-61 {
  padding: 61px !important;
}

.padding-top-61 {
  padding-top: 61px !important;
}

.padding-right-61 {
  padding-right: 61px !important;
}

.padding-bottom-61 {
  padding-bottom: 61px !important;
}

.padding-left-61 {
  padding-left: 61px !important;
}

.padding-x-61 {
  padding-left: 61px !important;
  padding-right: 61px !important;
}

.padding-y-61 {
  padding-top: 61px !important;
  padding-bottom: 61px !important;
}

.margin-62 {
  margin: 62px !important;
}

.margin-top-62 {
  margin-top: 62px !important;
}

.margin-right-62 {
  margin-right: 62px !important;
}

.margin-bottom-62 {
  margin-bottom: 62px !important;
}

.margin-left-62 {
  margin-left: 62px !important;
}

.margin-x-62 {
  margin-left: 62px !important;
  margin-right: 62px !important;
}

.margin-y-62 {
  margin-top: 62px !important;
  margin-bottom: 62px !important;
}

.padding-62 {
  padding: 62px !important;
}

.padding-top-62 {
  padding-top: 62px !important;
}

.padding-right-62 {
  padding-right: 62px !important;
}

.padding-bottom-62 {
  padding-bottom: 62px !important;
}

.padding-left-62 {
  padding-left: 62px !important;
}

.padding-x-62 {
  padding-left: 62px !important;
  padding-right: 62px !important;
}

.padding-y-62 {
  padding-top: 62px !important;
  padding-bottom: 62px !important;
}

.margin-63 {
  margin: 63px !important;
}

.margin-top-63 {
  margin-top: 63px !important;
}

.margin-right-63 {
  margin-right: 63px !important;
}

.margin-bottom-63 {
  margin-bottom: 63px !important;
}

.margin-left-63 {
  margin-left: 63px !important;
}

.margin-x-63 {
  margin-left: 63px !important;
  margin-right: 63px !important;
}

.margin-y-63 {
  margin-top: 63px !important;
  margin-bottom: 63px !important;
}

.padding-63 {
  padding: 63px !important;
}

.padding-top-63 {
  padding-top: 63px !important;
}

.padding-right-63 {
  padding-right: 63px !important;
}

.padding-bottom-63 {
  padding-bottom: 63px !important;
}

.padding-left-63 {
  padding-left: 63px !important;
}

.padding-x-63 {
  padding-left: 63px !important;
  padding-right: 63px !important;
}

.padding-y-63 {
  padding-top: 63px !important;
  padding-bottom: 63px !important;
}

.margin-64 {
  margin: 64px !important;
}

.margin-top-64 {
  margin-top: 64px !important;
}

.margin-right-64 {
  margin-right: 64px !important;
}

.margin-bottom-64 {
  margin-bottom: 64px !important;
}

.margin-left-64 {
  margin-left: 64px !important;
}

.margin-x-64 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.margin-y-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.padding-64 {
  padding: 64px !important;
}

.padding-top-64 {
  padding-top: 64px !important;
}

.padding-right-64 {
  padding-right: 64px !important;
}

.padding-bottom-64 {
  padding-bottom: 64px !important;
}

.padding-left-64 {
  padding-left: 64px !important;
}

.padding-x-64 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.padding-y-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.margin-65 {
  margin: 65px !important;
}

.margin-top-65 {
  margin-top: 65px !important;
}

.margin-right-65 {
  margin-right: 65px !important;
}

.margin-bottom-65 {
  margin-bottom: 65px !important;
}

.margin-left-65 {
  margin-left: 65px !important;
}

.margin-x-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.margin-y-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.padding-65 {
  padding: 65px !important;
}

.padding-top-65 {
  padding-top: 65px !important;
}

.padding-right-65 {
  padding-right: 65px !important;
}

.padding-bottom-65 {
  padding-bottom: 65px !important;
}

.padding-left-65 {
  padding-left: 65px !important;
}

.padding-x-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.padding-y-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.margin-66 {
  margin: 66px !important;
}

.margin-top-66 {
  margin-top: 66px !important;
}

.margin-right-66 {
  margin-right: 66px !important;
}

.margin-bottom-66 {
  margin-bottom: 66px !important;
}

.margin-left-66 {
  margin-left: 66px !important;
}

.margin-x-66 {
  margin-left: 66px !important;
  margin-right: 66px !important;
}

.margin-y-66 {
  margin-top: 66px !important;
  margin-bottom: 66px !important;
}

.padding-66 {
  padding: 66px !important;
}

.padding-top-66 {
  padding-top: 66px !important;
}

.padding-right-66 {
  padding-right: 66px !important;
}

.padding-bottom-66 {
  padding-bottom: 66px !important;
}

.padding-left-66 {
  padding-left: 66px !important;
}

.padding-x-66 {
  padding-left: 66px !important;
  padding-right: 66px !important;
}

.padding-y-66 {
  padding-top: 66px !important;
  padding-bottom: 66px !important;
}

.margin-67 {
  margin: 67px !important;
}

.margin-top-67 {
  margin-top: 67px !important;
}

.margin-right-67 {
  margin-right: 67px !important;
}

.margin-bottom-67 {
  margin-bottom: 67px !important;
}

.margin-left-67 {
  margin-left: 67px !important;
}

.margin-x-67 {
  margin-left: 67px !important;
  margin-right: 67px !important;
}

.margin-y-67 {
  margin-top: 67px !important;
  margin-bottom: 67px !important;
}

.padding-67 {
  padding: 67px !important;
}

.padding-top-67 {
  padding-top: 67px !important;
}

.padding-right-67 {
  padding-right: 67px !important;
}

.padding-bottom-67 {
  padding-bottom: 67px !important;
}

.padding-left-67 {
  padding-left: 67px !important;
}

.padding-x-67 {
  padding-left: 67px !important;
  padding-right: 67px !important;
}

.padding-y-67 {
  padding-top: 67px !important;
  padding-bottom: 67px !important;
}

.margin-68 {
  margin: 68px !important;
}

.margin-top-68 {
  margin-top: 68px !important;
}

.margin-right-68 {
  margin-right: 68px !important;
}

.margin-bottom-68 {
  margin-bottom: 68px !important;
}

.margin-left-68 {
  margin-left: 68px !important;
}

.margin-x-68 {
  margin-left: 68px !important;
  margin-right: 68px !important;
}

.margin-y-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}

.padding-68 {
  padding: 68px !important;
}

.padding-top-68 {
  padding-top: 68px !important;
}

.padding-right-68 {
  padding-right: 68px !important;
}

.padding-bottom-68 {
  padding-bottom: 68px !important;
}

.padding-left-68 {
  padding-left: 68px !important;
}

.padding-x-68 {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.padding-y-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.margin-69 {
  margin: 69px !important;
}

.margin-top-69 {
  margin-top: 69px !important;
}

.margin-right-69 {
  margin-right: 69px !important;
}

.margin-bottom-69 {
  margin-bottom: 69px !important;
}

.margin-left-69 {
  margin-left: 69px !important;
}

.margin-x-69 {
  margin-left: 69px !important;
  margin-right: 69px !important;
}

.margin-y-69 {
  margin-top: 69px !important;
  margin-bottom: 69px !important;
}

.padding-69 {
  padding: 69px !important;
}

.padding-top-69 {
  padding-top: 69px !important;
}

.padding-right-69 {
  padding-right: 69px !important;
}

.padding-bottom-69 {
  padding-bottom: 69px !important;
}

.padding-left-69 {
  padding-left: 69px !important;
}

.padding-x-69 {
  padding-left: 69px !important;
  padding-right: 69px !important;
}

.padding-y-69 {
  padding-top: 69px !important;
  padding-bottom: 69px !important;
}

.margin-70 {
  margin: 70px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-right-70 {
  margin-right: 70px !important;
}

.margin-bottom-70 {
  margin-bottom: 70px !important;
}

.margin-left-70 {
  margin-left: 70px !important;
}

.margin-x-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.margin-y-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.padding-70 {
  padding: 70px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-right-70 {
  padding-right: 70px !important;
}

.padding-bottom-70 {
  padding-bottom: 70px !important;
}

.padding-left-70 {
  padding-left: 70px !important;
}

.padding-x-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.padding-y-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.margin-71 {
  margin: 71px !important;
}

.margin-top-71 {
  margin-top: 71px !important;
}

.margin-right-71 {
  margin-right: 71px !important;
}

.margin-bottom-71 {
  margin-bottom: 71px !important;
}

.margin-left-71 {
  margin-left: 71px !important;
}

.margin-x-71 {
  margin-left: 71px !important;
  margin-right: 71px !important;
}

.margin-y-71 {
  margin-top: 71px !important;
  margin-bottom: 71px !important;
}

.padding-71 {
  padding: 71px !important;
}

.padding-top-71 {
  padding-top: 71px !important;
}

.padding-right-71 {
  padding-right: 71px !important;
}

.padding-bottom-71 {
  padding-bottom: 71px !important;
}

.padding-left-71 {
  padding-left: 71px !important;
}

.padding-x-71 {
  padding-left: 71px !important;
  padding-right: 71px !important;
}

.padding-y-71 {
  padding-top: 71px !important;
  padding-bottom: 71px !important;
}

.margin-72 {
  margin: 72px !important;
}

.margin-top-72 {
  margin-top: 72px !important;
}

.margin-right-72 {
  margin-right: 72px !important;
}

.margin-bottom-72 {
  margin-bottom: 72px !important;
}

.margin-left-72 {
  margin-left: 72px !important;
}

.margin-x-72 {
  margin-left: 72px !important;
  margin-right: 72px !important;
}

.margin-y-72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.padding-72 {
  padding: 72px !important;
}

.padding-top-72 {
  padding-top: 72px !important;
}

.padding-right-72 {
  padding-right: 72px !important;
}

.padding-bottom-72 {
  padding-bottom: 72px !important;
}

.padding-left-72 {
  padding-left: 72px !important;
}

.padding-x-72 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.padding-y-72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.margin-73 {
  margin: 73px !important;
}

.margin-top-73 {
  margin-top: 73px !important;
}

.margin-right-73 {
  margin-right: 73px !important;
}

.margin-bottom-73 {
  margin-bottom: 73px !important;
}

.margin-left-73 {
  margin-left: 73px !important;
}

.margin-x-73 {
  margin-left: 73px !important;
  margin-right: 73px !important;
}

.margin-y-73 {
  margin-top: 73px !important;
  margin-bottom: 73px !important;
}

.padding-73 {
  padding: 73px !important;
}

.padding-top-73 {
  padding-top: 73px !important;
}

.padding-right-73 {
  padding-right: 73px !important;
}

.padding-bottom-73 {
  padding-bottom: 73px !important;
}

.padding-left-73 {
  padding-left: 73px !important;
}

.padding-x-73 {
  padding-left: 73px !important;
  padding-right: 73px !important;
}

.padding-y-73 {
  padding-top: 73px !important;
  padding-bottom: 73px !important;
}

.margin-74 {
  margin: 74px !important;
}

.margin-top-74 {
  margin-top: 74px !important;
}

.margin-right-74 {
  margin-right: 74px !important;
}

.margin-bottom-74 {
  margin-bottom: 74px !important;
}

.margin-left-74 {
  margin-left: 74px !important;
}

.margin-x-74 {
  margin-left: 74px !important;
  margin-right: 74px !important;
}

.margin-y-74 {
  margin-top: 74px !important;
  margin-bottom: 74px !important;
}

.padding-74 {
  padding: 74px !important;
}

.padding-top-74 {
  padding-top: 74px !important;
}

.padding-right-74 {
  padding-right: 74px !important;
}

.padding-bottom-74 {
  padding-bottom: 74px !important;
}

.padding-left-74 {
  padding-left: 74px !important;
}

.padding-x-74 {
  padding-left: 74px !important;
  padding-right: 74px !important;
}

.padding-y-74 {
  padding-top: 74px !important;
  padding-bottom: 74px !important;
}

.margin-75 {
  margin: 75px !important;
}

.margin-top-75 {
  margin-top: 75px !important;
}

.margin-right-75 {
  margin-right: 75px !important;
}

.margin-bottom-75 {
  margin-bottom: 75px !important;
}

.margin-left-75 {
  margin-left: 75px !important;
}

.margin-x-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.margin-y-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.padding-75 {
  padding: 75px !important;
}

.padding-top-75 {
  padding-top: 75px !important;
}

.padding-right-75 {
  padding-right: 75px !important;
}

.padding-bottom-75 {
  padding-bottom: 75px !important;
}

.padding-left-75 {
  padding-left: 75px !important;
}

.padding-x-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.padding-y-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.margin-76 {
  margin: 76px !important;
}

.margin-top-76 {
  margin-top: 76px !important;
}

.margin-right-76 {
  margin-right: 76px !important;
}

.margin-bottom-76 {
  margin-bottom: 76px !important;
}

.margin-left-76 {
  margin-left: 76px !important;
}

.margin-x-76 {
  margin-left: 76px !important;
  margin-right: 76px !important;
}

.margin-y-76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important;
}

.padding-76 {
  padding: 76px !important;
}

.padding-top-76 {
  padding-top: 76px !important;
}

.padding-right-76 {
  padding-right: 76px !important;
}

.padding-bottom-76 {
  padding-bottom: 76px !important;
}

.padding-left-76 {
  padding-left: 76px !important;
}

.padding-x-76 {
  padding-left: 76px !important;
  padding-right: 76px !important;
}

.padding-y-76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.margin-77 {
  margin: 77px !important;
}

.margin-top-77 {
  margin-top: 77px !important;
}

.margin-right-77 {
  margin-right: 77px !important;
}

.margin-bottom-77 {
  margin-bottom: 77px !important;
}

.margin-left-77 {
  margin-left: 77px !important;
}

.margin-x-77 {
  margin-left: 77px !important;
  margin-right: 77px !important;
}

.margin-y-77 {
  margin-top: 77px !important;
  margin-bottom: 77px !important;
}

.padding-77 {
  padding: 77px !important;
}

.padding-top-77 {
  padding-top: 77px !important;
}

.padding-right-77 {
  padding-right: 77px !important;
}

.padding-bottom-77 {
  padding-bottom: 77px !important;
}

.padding-left-77 {
  padding-left: 77px !important;
}

.padding-x-77 {
  padding-left: 77px !important;
  padding-right: 77px !important;
}

.padding-y-77 {
  padding-top: 77px !important;
  padding-bottom: 77px !important;
}

.margin-78 {
  margin: 78px !important;
}

.margin-top-78 {
  margin-top: 78px !important;
}

.margin-right-78 {
  margin-right: 78px !important;
}

.margin-bottom-78 {
  margin-bottom: 78px !important;
}

.margin-left-78 {
  margin-left: 78px !important;
}

.margin-x-78 {
  margin-left: 78px !important;
  margin-right: 78px !important;
}

.margin-y-78 {
  margin-top: 78px !important;
  margin-bottom: 78px !important;
}

.padding-78 {
  padding: 78px !important;
}

.padding-top-78 {
  padding-top: 78px !important;
}

.padding-right-78 {
  padding-right: 78px !important;
}

.padding-bottom-78 {
  padding-bottom: 78px !important;
}

.padding-left-78 {
  padding-left: 78px !important;
}

.padding-x-78 {
  padding-left: 78px !important;
  padding-right: 78px !important;
}

.padding-y-78 {
  padding-top: 78px !important;
  padding-bottom: 78px !important;
}

.margin-79 {
  margin: 79px !important;
}

.margin-top-79 {
  margin-top: 79px !important;
}

.margin-right-79 {
  margin-right: 79px !important;
}

.margin-bottom-79 {
  margin-bottom: 79px !important;
}

.margin-left-79 {
  margin-left: 79px !important;
}

.margin-x-79 {
  margin-left: 79px !important;
  margin-right: 79px !important;
}

.margin-y-79 {
  margin-top: 79px !important;
  margin-bottom: 79px !important;
}

.padding-79 {
  padding: 79px !important;
}

.padding-top-79 {
  padding-top: 79px !important;
}

.padding-right-79 {
  padding-right: 79px !important;
}

.padding-bottom-79 {
  padding-bottom: 79px !important;
}

.padding-left-79 {
  padding-left: 79px !important;
}

.padding-x-79 {
  padding-left: 79px !important;
  padding-right: 79px !important;
}

.padding-y-79 {
  padding-top: 79px !important;
  padding-bottom: 79px !important;
}

.margin-80 {
  margin: 80px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.margin-right-80 {
  margin-right: 80px !important;
}

.margin-bottom-80 {
  margin-bottom: 80px !important;
}

.margin-left-80 {
  margin-left: 80px !important;
}

.margin-x-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.margin-y-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.padding-80 {
  padding: 80px !important;
}

.padding-top-80 {
  padding-top: 80px !important;
}

.padding-right-80 {
  padding-right: 80px !important;
}

.padding-bottom-80 {
  padding-bottom: 80px !important;
}

.padding-left-80 {
  padding-left: 80px !important;
}

.padding-x-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.padding-y-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.margin-81 {
  margin: 81px !important;
}

.margin-top-81 {
  margin-top: 81px !important;
}

.margin-right-81 {
  margin-right: 81px !important;
}

.margin-bottom-81 {
  margin-bottom: 81px !important;
}

.margin-left-81 {
  margin-left: 81px !important;
}

.margin-x-81 {
  margin-left: 81px !important;
  margin-right: 81px !important;
}

.margin-y-81 {
  margin-top: 81px !important;
  margin-bottom: 81px !important;
}

.padding-81 {
  padding: 81px !important;
}

.padding-top-81 {
  padding-top: 81px !important;
}

.padding-right-81 {
  padding-right: 81px !important;
}

.padding-bottom-81 {
  padding-bottom: 81px !important;
}

.padding-left-81 {
  padding-left: 81px !important;
}

.padding-x-81 {
  padding-left: 81px !important;
  padding-right: 81px !important;
}

.padding-y-81 {
  padding-top: 81px !important;
  padding-bottom: 81px !important;
}

.margin-82 {
  margin: 82px !important;
}

.margin-top-82 {
  margin-top: 82px !important;
}

.margin-right-82 {
  margin-right: 82px !important;
}

.margin-bottom-82 {
  margin-bottom: 82px !important;
}

.margin-left-82 {
  margin-left: 82px !important;
}

.margin-x-82 {
  margin-left: 82px !important;
  margin-right: 82px !important;
}

.margin-y-82 {
  margin-top: 82px !important;
  margin-bottom: 82px !important;
}

.padding-82 {
  padding: 82px !important;
}

.padding-top-82 {
  padding-top: 82px !important;
}

.padding-right-82 {
  padding-right: 82px !important;
}

.padding-bottom-82 {
  padding-bottom: 82px !important;
}

.padding-left-82 {
  padding-left: 82px !important;
}

.padding-x-82 {
  padding-left: 82px !important;
  padding-right: 82px !important;
}

.padding-y-82 {
  padding-top: 82px !important;
  padding-bottom: 82px !important;
}

.margin-83 {
  margin: 83px !important;
}

.margin-top-83 {
  margin-top: 83px !important;
}

.margin-right-83 {
  margin-right: 83px !important;
}

.margin-bottom-83 {
  margin-bottom: 83px !important;
}

.margin-left-83 {
  margin-left: 83px !important;
}

.margin-x-83 {
  margin-left: 83px !important;
  margin-right: 83px !important;
}

.margin-y-83 {
  margin-top: 83px !important;
  margin-bottom: 83px !important;
}

.padding-83 {
  padding: 83px !important;
}

.padding-top-83 {
  padding-top: 83px !important;
}

.padding-right-83 {
  padding-right: 83px !important;
}

.padding-bottom-83 {
  padding-bottom: 83px !important;
}

.padding-left-83 {
  padding-left: 83px !important;
}

.padding-x-83 {
  padding-left: 83px !important;
  padding-right: 83px !important;
}

.padding-y-83 {
  padding-top: 83px !important;
  padding-bottom: 83px !important;
}

.margin-84 {
  margin: 84px !important;
}

.margin-top-84 {
  margin-top: 84px !important;
}

.margin-right-84 {
  margin-right: 84px !important;
}

.margin-bottom-84 {
  margin-bottom: 84px !important;
}

.margin-left-84 {
  margin-left: 84px !important;
}

.margin-x-84 {
  margin-left: 84px !important;
  margin-right: 84px !important;
}

.margin-y-84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}

.padding-84 {
  padding: 84px !important;
}

.padding-top-84 {
  padding-top: 84px !important;
}

.padding-right-84 {
  padding-right: 84px !important;
}

.padding-bottom-84 {
  padding-bottom: 84px !important;
}

.padding-left-84 {
  padding-left: 84px !important;
}

.padding-x-84 {
  padding-left: 84px !important;
  padding-right: 84px !important;
}

.padding-y-84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.margin-85 {
  margin: 85px !important;
}

.margin-top-85 {
  margin-top: 85px !important;
}

.margin-right-85 {
  margin-right: 85px !important;
}

.margin-bottom-85 {
  margin-bottom: 85px !important;
}

.margin-left-85 {
  margin-left: 85px !important;
}

.margin-x-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.margin-y-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.padding-85 {
  padding: 85px !important;
}

.padding-top-85 {
  padding-top: 85px !important;
}

.padding-right-85 {
  padding-right: 85px !important;
}

.padding-bottom-85 {
  padding-bottom: 85px !important;
}

.padding-left-85 {
  padding-left: 85px !important;
}

.padding-x-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.padding-y-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.margin-86 {
  margin: 86px !important;
}

.margin-top-86 {
  margin-top: 86px !important;
}

.margin-right-86 {
  margin-right: 86px !important;
}

.margin-bottom-86 {
  margin-bottom: 86px !important;
}

.margin-left-86 {
  margin-left: 86px !important;
}

.margin-x-86 {
  margin-left: 86px !important;
  margin-right: 86px !important;
}

.margin-y-86 {
  margin-top: 86px !important;
  margin-bottom: 86px !important;
}

.padding-86 {
  padding: 86px !important;
}

.padding-top-86 {
  padding-top: 86px !important;
}

.padding-right-86 {
  padding-right: 86px !important;
}

.padding-bottom-86 {
  padding-bottom: 86px !important;
}

.padding-left-86 {
  padding-left: 86px !important;
}

.padding-x-86 {
  padding-left: 86px !important;
  padding-right: 86px !important;
}

.padding-y-86 {
  padding-top: 86px !important;
  padding-bottom: 86px !important;
}

.margin-87 {
  margin: 87px !important;
}

.margin-top-87 {
  margin-top: 87px !important;
}

.margin-right-87 {
  margin-right: 87px !important;
}

.margin-bottom-87 {
  margin-bottom: 87px !important;
}

.margin-left-87 {
  margin-left: 87px !important;
}

.margin-x-87 {
  margin-left: 87px !important;
  margin-right: 87px !important;
}

.margin-y-87 {
  margin-top: 87px !important;
  margin-bottom: 87px !important;
}

.padding-87 {
  padding: 87px !important;
}

.padding-top-87 {
  padding-top: 87px !important;
}

.padding-right-87 {
  padding-right: 87px !important;
}

.padding-bottom-87 {
  padding-bottom: 87px !important;
}

.padding-left-87 {
  padding-left: 87px !important;
}

.padding-x-87 {
  padding-left: 87px !important;
  padding-right: 87px !important;
}

.padding-y-87 {
  padding-top: 87px !important;
  padding-bottom: 87px !important;
}

.margin-88 {
  margin: 88px !important;
}

.margin-top-88 {
  margin-top: 88px !important;
}

.margin-right-88 {
  margin-right: 88px !important;
}

.margin-bottom-88 {
  margin-bottom: 88px !important;
}

.margin-left-88 {
  margin-left: 88px !important;
}

.margin-x-88 {
  margin-left: 88px !important;
  margin-right: 88px !important;
}

.margin-y-88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}

.padding-88 {
  padding: 88px !important;
}

.padding-top-88 {
  padding-top: 88px !important;
}

.padding-right-88 {
  padding-right: 88px !important;
}

.padding-bottom-88 {
  padding-bottom: 88px !important;
}

.padding-left-88 {
  padding-left: 88px !important;
}

.padding-x-88 {
  padding-left: 88px !important;
  padding-right: 88px !important;
}

.padding-y-88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.margin-89 {
  margin: 89px !important;
}

.margin-top-89 {
  margin-top: 89px !important;
}

.margin-right-89 {
  margin-right: 89px !important;
}

.margin-bottom-89 {
  margin-bottom: 89px !important;
}

.margin-left-89 {
  margin-left: 89px !important;
}

.margin-x-89 {
  margin-left: 89px !important;
  margin-right: 89px !important;
}

.margin-y-89 {
  margin-top: 89px !important;
  margin-bottom: 89px !important;
}

.padding-89 {
  padding: 89px !important;
}

.padding-top-89 {
  padding-top: 89px !important;
}

.padding-right-89 {
  padding-right: 89px !important;
}

.padding-bottom-89 {
  padding-bottom: 89px !important;
}

.padding-left-89 {
  padding-left: 89px !important;
}

.padding-x-89 {
  padding-left: 89px !important;
  padding-right: 89px !important;
}

.padding-y-89 {
  padding-top: 89px !important;
  padding-bottom: 89px !important;
}

.margin-90 {
  margin: 90px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-right-90 {
  margin-right: 90px !important;
}

.margin-bottom-90 {
  margin-bottom: 90px !important;
}

.margin-left-90 {
  margin-left: 90px !important;
}

.margin-x-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.margin-y-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.padding-90 {
  padding: 90px !important;
}

.padding-top-90 {
  padding-top: 90px !important;
}

.padding-right-90 {
  padding-right: 90px !important;
}

.padding-bottom-90 {
  padding-bottom: 90px !important;
}

.padding-left-90 {
  padding-left: 90px !important;
}

.padding-x-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.padding-y-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.margin-91 {
  margin: 91px !important;
}

.margin-top-91 {
  margin-top: 91px !important;
}

.margin-right-91 {
  margin-right: 91px !important;
}

.margin-bottom-91 {
  margin-bottom: 91px !important;
}

.margin-left-91 {
  margin-left: 91px !important;
}

.margin-x-91 {
  margin-left: 91px !important;
  margin-right: 91px !important;
}

.margin-y-91 {
  margin-top: 91px !important;
  margin-bottom: 91px !important;
}

.padding-91 {
  padding: 91px !important;
}

.padding-top-91 {
  padding-top: 91px !important;
}

.padding-right-91 {
  padding-right: 91px !important;
}

.padding-bottom-91 {
  padding-bottom: 91px !important;
}

.padding-left-91 {
  padding-left: 91px !important;
}

.padding-x-91 {
  padding-left: 91px !important;
  padding-right: 91px !important;
}

.padding-y-91 {
  padding-top: 91px !important;
  padding-bottom: 91px !important;
}

.margin-92 {
  margin: 92px !important;
}

.margin-top-92 {
  margin-top: 92px !important;
}

.margin-right-92 {
  margin-right: 92px !important;
}

.margin-bottom-92 {
  margin-bottom: 92px !important;
}

.margin-left-92 {
  margin-left: 92px !important;
}

.margin-x-92 {
  margin-left: 92px !important;
  margin-right: 92px !important;
}

.margin-y-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important;
}

.padding-92 {
  padding: 92px !important;
}

.padding-top-92 {
  padding-top: 92px !important;
}

.padding-right-92 {
  padding-right: 92px !important;
}

.padding-bottom-92 {
  padding-bottom: 92px !important;
}

.padding-left-92 {
  padding-left: 92px !important;
}

.padding-x-92 {
  padding-left: 92px !important;
  padding-right: 92px !important;
}

.padding-y-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.margin-93 {
  margin: 93px !important;
}

.margin-top-93 {
  margin-top: 93px !important;
}

.margin-right-93 {
  margin-right: 93px !important;
}

.margin-bottom-93 {
  margin-bottom: 93px !important;
}

.margin-left-93 {
  margin-left: 93px !important;
}

.margin-x-93 {
  margin-left: 93px !important;
  margin-right: 93px !important;
}

.margin-y-93 {
  margin-top: 93px !important;
  margin-bottom: 93px !important;
}

.padding-93 {
  padding: 93px !important;
}

.padding-top-93 {
  padding-top: 93px !important;
}

.padding-right-93 {
  padding-right: 93px !important;
}

.padding-bottom-93 {
  padding-bottom: 93px !important;
}

.padding-left-93 {
  padding-left: 93px !important;
}

.padding-x-93 {
  padding-left: 93px !important;
  padding-right: 93px !important;
}

.padding-y-93 {
  padding-top: 93px !important;
  padding-bottom: 93px !important;
}

.margin-94 {
  margin: 94px !important;
}

.margin-top-94 {
  margin-top: 94px !important;
}

.margin-right-94 {
  margin-right: 94px !important;
}

.margin-bottom-94 {
  margin-bottom: 94px !important;
}

.margin-left-94 {
  margin-left: 94px !important;
}

.margin-x-94 {
  margin-left: 94px !important;
  margin-right: 94px !important;
}

.margin-y-94 {
  margin-top: 94px !important;
  margin-bottom: 94px !important;
}

.padding-94 {
  padding: 94px !important;
}

.padding-top-94 {
  padding-top: 94px !important;
}

.padding-right-94 {
  padding-right: 94px !important;
}

.padding-bottom-94 {
  padding-bottom: 94px !important;
}

.padding-left-94 {
  padding-left: 94px !important;
}

.padding-x-94 {
  padding-left: 94px !important;
  padding-right: 94px !important;
}

.padding-y-94 {
  padding-top: 94px !important;
  padding-bottom: 94px !important;
}

.margin-95 {
  margin: 95px !important;
}

.margin-top-95 {
  margin-top: 95px !important;
}

.margin-right-95 {
  margin-right: 95px !important;
}

.margin-bottom-95 {
  margin-bottom: 95px !important;
}

.margin-left-95 {
  margin-left: 95px !important;
}

.margin-x-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.margin-y-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.padding-95 {
  padding: 95px !important;
}

.padding-top-95 {
  padding-top: 95px !important;
}

.padding-right-95 {
  padding-right: 95px !important;
}

.padding-bottom-95 {
  padding-bottom: 95px !important;
}

.padding-left-95 {
  padding-left: 95px !important;
}

.padding-x-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.padding-y-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.margin-96 {
  margin: 96px !important;
}

.margin-top-96 {
  margin-top: 96px !important;
}

.margin-right-96 {
  margin-right: 96px !important;
}

.margin-bottom-96 {
  margin-bottom: 96px !important;
}

.margin-left-96 {
  margin-left: 96px !important;
}

.margin-x-96 {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.margin-y-96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.padding-96 {
  padding: 96px !important;
}

.padding-top-96 {
  padding-top: 96px !important;
}

.padding-right-96 {
  padding-right: 96px !important;
}

.padding-bottom-96 {
  padding-bottom: 96px !important;
}

.padding-left-96 {
  padding-left: 96px !important;
}

.padding-x-96 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.padding-y-96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.margin-97 {
  margin: 97px !important;
}

.margin-top-97 {
  margin-top: 97px !important;
}

.margin-right-97 {
  margin-right: 97px !important;
}

.margin-bottom-97 {
  margin-bottom: 97px !important;
}

.margin-left-97 {
  margin-left: 97px !important;
}

.margin-x-97 {
  margin-left: 97px !important;
  margin-right: 97px !important;
}

.margin-y-97 {
  margin-top: 97px !important;
  margin-bottom: 97px !important;
}

.padding-97 {
  padding: 97px !important;
}

.padding-top-97 {
  padding-top: 97px !important;
}

.padding-right-97 {
  padding-right: 97px !important;
}

.padding-bottom-97 {
  padding-bottom: 97px !important;
}

.padding-left-97 {
  padding-left: 97px !important;
}

.padding-x-97 {
  padding-left: 97px !important;
  padding-right: 97px !important;
}

.padding-y-97 {
  padding-top: 97px !important;
  padding-bottom: 97px !important;
}

.margin-98 {
  margin: 98px !important;
}

.margin-top-98 {
  margin-top: 98px !important;
}

.margin-right-98 {
  margin-right: 98px !important;
}

.margin-bottom-98 {
  margin-bottom: 98px !important;
}

.margin-left-98 {
  margin-left: 98px !important;
}

.margin-x-98 {
  margin-left: 98px !important;
  margin-right: 98px !important;
}

.margin-y-98 {
  margin-top: 98px !important;
  margin-bottom: 98px !important;
}

.padding-98 {
  padding: 98px !important;
}

.padding-top-98 {
  padding-top: 98px !important;
}

.padding-right-98 {
  padding-right: 98px !important;
}

.padding-bottom-98 {
  padding-bottom: 98px !important;
}

.padding-left-98 {
  padding-left: 98px !important;
}

.padding-x-98 {
  padding-left: 98px !important;
  padding-right: 98px !important;
}

.padding-y-98 {
  padding-top: 98px !important;
  padding-bottom: 98px !important;
}

.margin-99 {
  margin: 99px !important;
}

.margin-top-99 {
  margin-top: 99px !important;
}

.margin-right-99 {
  margin-right: 99px !important;
}

.margin-bottom-99 {
  margin-bottom: 99px !important;
}

.margin-left-99 {
  margin-left: 99px !important;
}

.margin-x-99 {
  margin-left: 99px !important;
  margin-right: 99px !important;
}

.margin-y-99 {
  margin-top: 99px !important;
  margin-bottom: 99px !important;
}

.padding-99 {
  padding: 99px !important;
}

.padding-top-99 {
  padding-top: 99px !important;
}

.padding-right-99 {
  padding-right: 99px !important;
}

.padding-bottom-99 {
  padding-bottom: 99px !important;
}

.padding-left-99 {
  padding-left: 99px !important;
}

.padding-x-99 {
  padding-left: 99px !important;
  padding-right: 99px !important;
}

.padding-y-99 {
  padding-top: 99px !important;
  padding-bottom: 99px !important;
}

.margin-100 {
  margin: 100px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-right-100 {
  margin-right: 100px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

.margin-left-100 {
  margin-left: 100px !important;
}

.margin-x-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.margin-y-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.padding-100 {
  padding: 100px !important;
}

.padding-top-100 {
  padding-top: 100px !important;
}

.padding-right-100 {
  padding-right: 100px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

.padding-left-100 {
  padding-left: 100px !important;
}

.padding-x-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.padding-y-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.back-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-form {
  min-height: 100vh;
  overflow-y: auto;
  box-shadow: var(--default-box-shadow);
  background-color: var(--authentication-form-bg);
}
.login-form .sign-in-sign-up-form {
  padding: 4rem;
}
.login-form .sign-in-sign-up-form .logo {
  max-width: 170px;
  max-height: 70px;
}
.login-form .sign-in-sign-up-form .form-row .form-group {
  margin-bottom: 2rem;
}
.login-form .sign-in-sign-up-form .form-row .bluish-text svg {
  height: 25px;
  width: 25px;
}

.user-profile .user-header-media .profile-pic-wrapper {
  width: 265px;
}
.user-profile .user-header-media .profile-pic-wrapper .custom-image-upload-wrapper {
  width: 131px;
  height: 131px;
  box-shadow: var(--default-box-shadow);
}
.user-profile .user-header-media .profile-pic-wrapper .custom-image-upload-wrapper .image-area {
  width: 131px;
  height: 131px;
}
.user-profile .user-header-media .profile-pic-wrapper .custom-image-upload-wrapper .image-area img {
  object-fit: cover;
  height: 131px !important;
  width: 131px !important;
}
.user-profile .user-header-media .user-info-header h4 .user-status {
  font-size: 10px;
  margin-left: auto;
  display: inline-block;
}
.user-profile .user-details .media svg {
  width: 30px;
  height: 30px;
  stroke-width: 1px;
  color: #019AFF;
}
@media (max-width: 1200px) {
  .user-profile .user-header-media {
    display: block;
  }
  .user-profile .user-header-media .profile-pic-wrapper {
    width: initial;
  }
  .user-profile .user-header-media .profile-pic-wrapper .custom-image-upload-wrapper {
    margin: 0 auto 2rem auto;
  }
  .user-profile .border-right.custom {
    border: 0 !important;
  }
}
@media (max-width: 992px) {
  .user-profile .user-header-media {
    display: block;
  }
  .user-profile .user-header-media .profile-pic-wrapper {
    width: initial;
  }
  .user-profile .user-header-media .profile-pic-wrapper .custom-image-upload-wrapper {
    margin: 0 auto 2rem auto;
  }
  .user-profile .border-right.custom {
    border: 0 !important;
  }
}
@media (max-width: 767px) {
  .user-profile .user-header-media {
    display: flex;
  }
  .user-profile .user-header-media .profile-pic-wrapper {
    width: 200px;
  }
  .user-profile .user-header-media .profile-pic-wrapper .custom-image-upload-wrapper {
    margin-bottom: 0;
  }
  .user-profile .border-right.user-header-media, .user-profile .border-right.user-info-header, .user-profile .border-right.custom {
    border: 0 !important;
  }
}
@media (max-width: 575px) {
  .user-profile .user-header-media {
    display: block;
    text-align: center;
  }
  .user-profile .user-header-media .profile-pic-wrapper {
    width: initial;
  }
  .user-profile .user-header-media .profile-pic-wrapper .custom-image-upload-wrapper {
    margin: 0 auto 2rem auto;
  }
  .user-profile .user-header-media .user-info-header h4 .user-status {
    margin-left: 5px;
  }
  .user-profile .border-right.user-header-media, .user-profile .border-right.user-info-header, .user-profile .border-right.custom {
    border: 0 !important;
  }
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.dashboard-widgets.dashboard-icon-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard-widgets.dashboard-icon-widget .col-3 {
  max-width: fit-content;
}
.dashboard-widgets.dashboard-icon-widget .icon-wrapper {
  width: 50px;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background-color: #019AFF;
}
.dashboard-widgets.dashboard-icon-widget .widget-data {
  margin-left: 0.75rem;
}
.dashboard-widgets .widget-data h6 {
  margin-bottom: 0;
}
.dashboard-widgets .widget-data p {
  font-size: 80%;
  margin-bottom: 0;
  color: #9397A0;
}

.roles-table .minimizing svg {
  height: 18px;
  width: 18px;
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.unsubscriber-page {
  height: 100vh;
}
.unsubscriber-page .main-panel {
  width: 100%;
  margin-left: 0;
}

[dir=rtl] .navbar .dropdown-menu {
  right: initial;
  left: 0;
}
[dir=rtl] .navbar .dropdown-menu:before {
  left: 15px !important;
  right: inherit !important;
}
[dir=rtl] .navbar .navbar-menu-wrapper {
  box-shadow: var(--default-box-shadow);
  background: var(--navbar-bg);
  position: absolute;
  left: 0;
  top: 0;
}
[dir=rtl] .navbar .navbar-menu-wrapper .navbar-nav {
  margin-right: auto !important;
  margin-left: 0 !important;
}
[dir=rtl] .navbar .navbar-menu-wrapper .navbar-nav .nav-profile-text {
  margin-right: 0;
  margin-left: 1.25rem;
}
[dir=rtl] .navbar .navbar-menu-wrapper .navbar-toggler {
  margin-left: 0.5rem;
}
@media (max-width: 991px) {
  [dir=rtl] .sidebar {
    right: -230px !important;
  }
  [dir=rtl] .sidebar.active {
    right: 0 !important;
  }
}
[dir=rtl] .sidebar .nav.nav-scrolling {
  padding-right: 0 !important;
}
[dir=rtl] .sidebar .nav.nav-scrolling .nav-item .nav-link .menu-title {
  margin-right: 8px !important;
  border-radius: 5px 0 0 5px !important;
}
[dir=rtl] .sidebar .nav.nav-scrolling .nav-item .nav-link i.menu-arrow {
  margin-left: initial;
  margin-right: auto;
}
[dir=rtl] .sidebar .nav.nav-scrolling .nav-item .collapse {
  border-radius: 0 0 0 5px !important;
}
[dir=rtl] .sidebar .nav.nav-scrolling .nav-item .sub-menu {
  padding-right: 0 !important;
}
[dir=rtl] .main-panel {
  margin-left: 0;
  margin-right: 230px;
}
@media (max-width: 991px) {
  [dir=rtl] .main-panel {
    margin-right: 0 !important;
  }
}
[dir=rtl] .sidebar-icon-only .main-panel {
  margin-left: 0;
  margin-right: 70px;
}
@media (max-width: 575px) {
  [dir=rtl] .sidebar-icon-only .main-panel {
    margin-left: 0;
    margin-right: 0;
  }
}
[dir=rtl] .sidebar-hover-only .main-panel {
  margin-left: 0;
  margin-right: 70px;
}
@media (max-width: 575px) {
  [dir=rtl] .sidebar-hover-only .main-panel {
    margin-left: 0;
    margin-right: 0;
  }
}
[dir=rtl] .sidebar-icon-only .nav-item.hover-open .collapse {
  right: 70px !important;
  top: 50px !important;
  box-shadow: none !important;
}
[dir=rtl] .sidebar-icon-only .nav-item.hover-open .menu-title {
  box-shadow: none !important;
  right: 62px !important;
}
[dir=rtl] .ml-0 {
  margin-right: 0 !important;
}
[dir=rtl] .ml-1 {
  margin-left: initial !important;
  margin-right: 0.25rem !important;
}
[dir=rtl] .ml-2 {
  margin-left: initial !important;
  margin-right: 0.5rem !important;
}
[dir=rtl] .ml-3 {
  margin-left: initial !important;
  margin-right: 1rem !important;
}
[dir=rtl] .ml-4 {
  margin-left: initial !important;
  margin-right: 1.5rem !important;
}
[dir=rtl] .ml-5 {
  margin-left: initial !important;
  margin-right: 3rem !important;
}
[dir=rtl] .ml-primary {
  margin-left: initial !important;
  margin-right: 2rem !important;
}
[dir=rtl] .ml-0 {
  margin-left: 0 !important;
}
[dir=rtl] .mr-1 {
  margin-right: initial !important;
  margin-left: 0.25rem !important;
}
[dir=rtl] .mr-2 {
  margin-right: initial !important;
  margin-left: 0.5rem !important;
}
[dir=rtl] .mr-3 {
  margin-right: initial !important;
  margin-left: 1rem !important;
}
[dir=rtl] .mr-4 {
  margin-right: initial !important;
  margin-left: 1.5rem !important;
}
[dir=rtl] .mr-5 {
  margin-right: initial !important;
  margin-left: 3rem !important;
}
[dir=rtl] .mr-primary {
  margin-right: initial !important;
  margin-left: 2rem !important;
}
[dir=rtl] .pl-0 {
  padding-right: 0 !important;
}
[dir=rtl] .pl-1 {
  padding-left: initial !important;
  padding-right: 0.25rem !important;
}
[dir=rtl] .pl-2 {
  padding-left: initial !important;
  padding-right: 0.5rem !important;
}
[dir=rtl] .pl-3 {
  padding-left: initial !important;
  padding-right: 1rem !important;
}
[dir=rtl] .pl-4 {
  padding-left: initial !important;
  padding-right: 1.5rem !important;
}
[dir=rtl] .pl-5 {
  padding-left: initial !important;
  padding-right: 3rem !important;
}
[dir=rtl] .pl-primary {
  padding-left: initial !important;
  padding-right: 2rem !important;
}
[dir=rtl] .pr-0 {
  padding-left: 0 !important;
}
[dir=rtl] .pr-1 {
  padding-right: initial !important;
  padding-left: 0.25rem !important;
}
[dir=rtl] .pr-2 {
  padding-right: initial !important;
  padding-left: 0.5rem !important;
}
[dir=rtl] .pr-3 {
  padding-right: initial !important;
  padding-left: 1rem !important;
}
[dir=rtl] .pr-4 {
  padding-right: initial !important;
  padding-left: 1.5rem !important;
}
[dir=rtl] .pr-5 {
  padding-right: initial !important;
  padding-left: 3rem !important;
}
[dir=rtl] .pr-primary {
  padding-right: initial !important;
  padding-left: 2rem !important;
}
@media (min-width: 768px) {
  [dir=rtl] .pl-md-0, [dir=rtl] .pr-md-0, [dir=rtl] .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
[dir=rtl] .form-group-with-search .form-control {
  padding: 0.5rem 2.2rem 0.5rem 1rem !important;
}
[dir=rtl] .form-group-with-search .form-control-feedback {
  padding: 0 0.7rem 0 0;
}
[dir=rtl] .customized-checkbox input:checked + label:after {
  right: 23.5px;
}
[dir=rtl] .calendar-root .calendar {
  display: block !important;
}
[dir=rtl] .filters-wrapper .single-filter {
  margin-right: 0 !important;
  margin-left: 0.6rem !important;
}
[dir=rtl] .filters-wrapper .clear-icon {
  margin-left: -1rem !important;
  margin-right: 0 !important;
}
[dir=rtl] .filters-wrapper .dropdown-menu {
  left: initial !important;
  transform: none !important;
  top: 37px !important;
  right: 0 !important;
}
[dir=rtl] .btn-filter .clear-icon {
  margin-left: -1rem !important;
  margin-right: 0 !important;
}
[dir=rtl] .search-filter-dropdown .dropdown-menu {
  left: initial !important;
  right: 0 !important;
}
[dir=rtl] .column-filter .dropdown .dropdown-menu {
  transform: none !important;
  top: 37px !important;
  right: 0 !important;
}
[dir=rtl] .range-filter-dropdown .dropdown .dropdown-menu .noUi-horizontal .noUi-handle {
  right: 22px !important;
}
[dir=rtl] .checkbox-filter .form-group {
  display: flex;
}
[dir=rtl] .tab-filter-menu .nav-item:first-child {
  margin-left: 1.25rem;
}
[dir=rtl] .vertical-tab .no-gutters .pr-md-3 {
  padding-right: initial !important;
  padding-left: 1rem !important;
}
[dir=rtl] .vertical-tab .no-gutters .pl-md-3 {
  padding-left: initial !important;
  padding-right: 1rem !important;
}
[dir=rtl] .vertical-tab .no-gutters .nav-pills .active-icon {
  transform: rotate(180deg);
}
[dir=rtl] .avatar-group .rounded-circle:nth-child(1) {
  margin-left: -15px !important;
}
[dir=rtl] .template-preview-card .template-preview-wrapper .card-overlay-options ul {
  align-items: end;
  padding-right: 0;
}
[dir=rtl] .dashboard-widgets .widget-data {
  margin-right: 0.75rem;
  text-align: start;
}
[dir=rtl] select {
  background-position-x: 3% !important;
}
[dir=rtl] .note-editor .note-editing-area .note-editable {
  text-align: start;
}
[dir=rtl] .vue-tel-input .vti__dropdown {
  border-radius: 0 5px 5px 0 !important;
}
[dir=rtl] .vue-tel-input .vti__input {
  border-radius: 5px 0 0 5px !important;
}
[dir=rtl] .app-radio-group {
  display: flex;
}
[dir=rtl] .app-radio-group .customized-radio {
  margin: 0 0 0 1rem !important;
}
[dir=rtl] .customized-checkbox {
  display: flex;
}
[dir=rtl] .customized-checkbox label:before {
  margin-left: 10px !important;
  margin-right: 0 !important;
}
[dir=rtl] .customized-checkbox input:checked + label:after {
  right: 12.5px;
}
[dir=rtl] .radio-button-group {
  display: flex;
}
[dir=rtl] .radio-button-group .btn-group label:first-child {
  border-radius: 0 5px 5px 0;
  border-left: 0 !important;
}
[dir=rtl] .radio-button-group .btn-group label:last-child {
  border-radius: 5px 0 0 5px;
}
[dir=rtl] .time-picker-input .dropdown-menu {
  left: initial !important;
}
[dir=rtl] .time-picker-input .input-group .form-control {
  border-radius: 0 5px 5px 0 !important;
  text-align: start;
}
[dir=rtl] .time-picker-input .input-group .input-group-append {
  margin-left: 0;
}
[dir=rtl] .time-picker-input .input-group .input-group-append .input-group-text {
  border-radius: 5px 0 0 5px !important;
}
[dir=rtl] .date-picker-input .vc-popover-content-wrapper {
  left: initial !important;
  right: 50px !important;
}
[dir=rtl] .date-picker-input .input-group .form-control {
  border-radius: 0 5px 5px 0 !important;
  text-align: start;
}
[dir=rtl] .date-picker-input .input-group .input-group-append {
  margin-left: 0;
}
[dir=rtl] .date-picker-input .input-group .input-group-append .input-group-text {
  border-radius: 5px 0 0 5px !important;
}
[dir=rtl] .custom-file .custom-file-label {
  text-align: start;
}
[dir=rtl] .custom-file .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: 0.25rem 0 0 0.25rem;
}
[dir=rtl] .wizard-steps .tab-step {
  border-radius: 20px 0 0 20px;
  padding-right: initial;
  padding-left: 16px;
}
[dir=rtl] .wizard-steps .tab-step-init {
  border-radius: 20px 0 0 20px;
  padding: 9px 16px 0 16px;
}
[dir=rtl] .wizard-steps .tab-step:before {
  border-radius: 20px 0 0 20px;
  margin-right: initial !important;
  margin-left: 16px;
  margin-top: -1px !important;
}
[dir=rtl] .wizard-steps .nav-pills {
  padding-right: 0;
}
[dir=rtl] .wizard-steps .nav-pills .nav-item:first-child {
  margin-left: -13px !important;
}
[dir=rtl] .datatable table tbody tr td:last-child {
  padding-left: 0;
  padding-right: 1rem;
}
[dir=rtl] .pagination .page-item .page-link svg {
  transform: rotate(180deg);
}
[dir=rtl] .user-profile .border-right {
  border-left: 1px solid;
  border-right: none !important;
}
[dir=rtl] .user-profile .card {
  padding-left: 2rem;
  padding-right: 2rem;
}
[dir=rtl] .user-profile .card .user-header-media {
  padding-right: 0 !important;
  padding-left: 30px !important;
}
[dir=rtl] .user-profile .card .user-header-media .user-info-header {
  text-align: start !important;
}
[dir=rtl] .user-profile .card .user-details .media .media-body {
  text-align: start;
}
[dir=rtl] .user-profile .card .user-details .custom {
  padding-left: 30px;
}
[dir=rtl] .accordion .card .card-header .collapsible-link:before {
  right: initial;
  left: 1rem;
}
[dir=rtl] .accordion .card .card-header .collapsible-link:after {
  right: initial;
  left: calc(1rem + 6px);
}
[dir=rtl] .modal-header .close {
  margin: -1rem auto -1rem -1rem;
}
[dir=rtl] .modal-footer {
  justify-content: flex-start;
}
[dir=rtl] h1, [dir=rtl] h2, [dir=rtl] h3, [dir=rtl] h4, [dir=rtl] h5, [dir=rtl] h6, [dir=rtl] p {
  text-align: start;
}
@media (min-width: 768px) {
  [dir=rtl] .text-md-left {
    text-align: right !important;
  }
  [dir=rtl] .text-md-right {
    text-align: left !important;
  }
}
[dir=rtl] .text-left {
  text-align: right !important;
}
[dir=rtl] .text-right {
  text-align: left !important;
}
[dir=rtl] .float-right {
  float: left !important;
}
[dir=rtl] .float-left {
  float: right !important;
}
[dir=rtl] .card-title {
  text-align: start;
}
[dir=rtl] .form-group > label {
  display: block;
  text-align: start;
}
[dir=rtl] .card .card-header .form-group-with-search {
  margin-bottom: 0;
  margin-left: initial !important;
  margin-right: auto;
}
[dir=rtl] .general-setting legend {
  text-align: start;
}
[dir=rtl] .form-group .vc-popover-content-wrapper {
  left: initial !important;
  right: 107px !important;
}

[dir=rtl][theme=dark] .navbar .navbar-menu-wrapper {
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.5);
}

:root {
  --base-color: #F9F9F9;
  --default-font-color: #313131;
  --default-border-color: #F0F2F5;
  --dropdown-menu-bg: #ffffff;
  --default-card-bg: #ffffff;
  --default-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  --vertical-tab-header-bg: #fbfcff;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #ffffff;
  --accordion-header-bg: #F9F9F9;
  --default-custom-control-bg: #ffffff;
  --authentication-form-bg: #ffffff;
  --range-slider-baseline-bg: #9397A0;
  --tab-step-before-bg: #ffffff;
  --modal-header-footer-bg: #ffffff;
  --modal-body-bg: #ffffff;
  --note-font-color: var(--default-font-color);
  --note-bg-color: rgba(255, 204, 23, 0.09);
  --avaters-border-color: #ffffff;
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #9ad7ff;
  --scrollbar-thumb-hover-color: #67c3ff;
  --not-found-opacity: 1;
  --kanban-scrollbar-track: #f0f2f4;
  --kanban-scrollbar-thumb: #d3d7df;
  --vue-toaster-bg: rgba(0, 0, 0, 0.8);
  --white-base-color: #ffffff;
  --avatar-no-image-bg: #898989;
  --avatar-no-image-font: #ffffff;
  --navbar-bg: #ffffff;
  --sidebar-bg: #2B303B;
  --navbar-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  --navbar-brand-bg: #1e2229;
  --sidebar-box-shadow: none;
  --menu-title-shadow: none;
  --submenu-shadow: none;
  --btn-light-bg: #ffffff;
  --btn-light-color: #f8f9fa;
  --btn-dark-color: #343a40;
  --btn-dark-hover-color: #292d32;
  --btn-secondary-bg: #9397A0;
  --btn-secondary-hover-bg: #787d88;
  --icon-hover-bg: #b4e1ff;
  --btn-filter-bg: #ffffff;
  --input-search-box-border: #F5F8FF;
  --form-control-bg: #ffffff;
  --calendar-bg: #ffffff;
  --bg-x-icon: #b4e1ff;
  --shadow-x-icon: none;
  --search-box-bg: #ffffff;
  --bg-input-readonly: #e9ecef;
  --chips-bg: #ffffff;
  --time-picker-bg: #ffffff;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

[theme=dark] {
  --base-color: #1c1f26;
  --default-font-color: #cccccc;
  --default-border-color: #2f3541;
  --dropdown-menu-bg: #252932;
  --default-card-bg: #272b35;
  --default-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --vertical-tab-header-bg: #2b303c;
  --vertical-tab-icon-shadow: none;
  --vertical-tab-icon-bg: #272b35;
  --accordion-header-bg: #20242c;
  --default-custom-control-bg: #272b35;
  --icon-hover-bg: var(--base-color);
  --authentication-form-bg: #272b35;
  --range-slider-baseline-bg: var(--base-color);
  --calendar-bg: #272b35;
  --tab-step-before-bg: #272b35;
  --modal-header-footer-bg: #292e38;
  --modal-body-bg: #272b35;
  --note-font-color: #000;
  --note-bg-color: rgba(255, 204, 23, 0.05);
  --avaters-border-color: var(--sidebar-bg);
  --avatars-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, .2);
  --success-toast-bg: #2B303B;
  --scrollbar-thumb-color: #566076;
  --scrollbar-thumb-hover-color: #566076;
  --not-found-opacity: .7;
  --search-result-dropdown-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  --kanban-scrollbar-track: #6c7894;
  --kanban-scrollbar-thumb: #4b5467;
  --vue-toaster-bg: rgba(39, 43, 53, 0.9);
  --white-base-color: var(--base-color);
  --avatar-no-image-bg: #3A3C43;
  --avatar-no-image-font: #797D8D;
  --navbar-bg: #252932;
  --sidebar-bg: #252932;
  --navbar-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.09);
  --navbar-brand-bg: #2B303B;
  --sidebar-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  --menu-title-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.3);
  --submenu-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.3);
  --btn-light-bg: #272b35;
  --btn-light-color: #2f3541;
  --btn-dark-color: #181a20;
  --btn-dark-hover-color: #1e2229;
  --btn-secondary-bg: #414244;
  --btn-secondary-hover-bg: #28292a;
  --btn-filter-bg: #272b35;
  --input-search-box-border: transparent;
  --form-control-bg: #20242c;
  --bg-x-icon: #272b35;
  --shadow-x-icon: 0px 0px 10px rgba(0, 0, 0, 0.5);
  --search-box-bg: #20242c;
  --bg-input-readonly: #292e38;
  --chips-bg: #272b35;
  --time-picker-bg: #272b35;
}

.list-bullet::before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 14px;
  border-radius: 50%;
  background-color: #707887;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown {
  width: 350px;
  max-width: 350px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-dropdown h6 {
  padding-bottom: 0.5rem !important;
}

.vc-popover-content-wrapper {
  z-index: 9999 !important;
}

.custom-date-range-calendar {
  margin-top: -3px;
}
.custom-date-range-calendar .date-picker-input .input-group {
  position: relative;
  border: 0 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  transition: 0.25s;
}
.custom-date-range-calendar .date-picker-input .input-group .form-control {
  z-index: 2;
  width: 38px;
  height: 38px !important;
  cursor: pointer;
  padding: 0 !important;
  font-size: 0 !important;
  background-color: transparent !important;
}
.custom-date-range-calendar .date-picker-input .input-group .input-group-append {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}
.custom-date-range-calendar .date-picker-input .input-group .input-group-append .input-group-text {
  width: 38px;
  height: 38px;
  padding: 0 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
  background-color: transparent;
}
.custom-date-range-calendar .date-picker-input .input-group:hover {
  background-color: var(--base-color);
}

.progress.animate-progress .progress-bar {
  animation-name: animateProgressBar;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

.dropdown-employee-termination .btn svg {
  width: 24px;
  height: 24px;
}
.dropdown-employee-termination .dropdown-menu {
  width: 350px;
}
.dropdown-employee-termination .dropdown-menu .note {
  overflow-y: auto;
  max-height: 200px;
}

.employee-preview-card .dropdown.options-dropdown {
  right: 2rem;
}
.employee-preview-card .dropdown.options-dropdown .btn-option {
  width: 35px;
  height: 15px;
  border-radius: 0.5rem;
  background-color: var(--icon-hover-bg);
}
.employee-preview-card .dropdown.options-dropdown .btn-option svg {
  width: 18px;
  height: 18px;
}

.timeline .timeline-title {
  width: 100px;
  min-width: 100px;
}
.timeline .timeline-icon {
  position: relative;
}
.timeline .timeline-icon .svg-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #019AFF;
  background-color: var(--base-color);
}
.timeline .timeline-icon .svg-wrapper svg {
  width: 17px;
  height: 17px;
  stroke-width: 2;
}
.timeline .timeline-icon:before {
  content: "";
  position: absolute;
  top: 40px;
  left: calc(50% - 1px);
  right: auto;
  height: 100%;
  border: 1px solid var(--base-color);
  background-color: var(--base-color);
}
.timeline .timeline-content .single-record {
  z-index: 1;
  padding: 1rem;
  width: fit-content;
  position: relative;
  border-radius: 0.25rem;
  background-color: var(--base-color);
}
.timeline .timeline-content .single-record:before {
  z-index: -1;
  content: "";
  position: absolute;
  height: 100%;
  border: 1px solid var(--base-color);
  top: 40px;
  left: 2rem;
  right: auto;
}
.timeline .timeline-content .single-record:last-child:before {
  height: 0;
}
.timeline.timeline-change-log .timeline-title {
  width: 170px;
  min-width: 170px;
}
.timeline.timeline-response-log .timeline-title {
  width: 200px;
  min-width: 200px;
}
.timeline.timeline-response-log .timeline-content .single-record:before {
  content: "";
  position: absolute;
  height: 100%;
  border: 1px solid var(--base-color);
  top: 40px;
  left: 2rem;
  right: auto;
}
.timeline.timeline-response-log .timeline-content .single-record:last-child:before {
  height: 0;
}

.icon-box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--base-color);
}
.icon-box svg {
  width: 22px;
  height: 22px;
  color: #019AFF;
}
.icon-box i {
  font-size: 22px;
  color: #019AFF;
}

.summery-calendar .profile-column {
  border-left: 1px solid var(--base-color);
}
.summery-calendar .profile-column .column-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--base-color);
}
.summery-calendar .profile-column .profile-box {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--base-color);
  border-bottom: 1px solid var(--base-color);
}
.summery-calendar .total-hour-column .column-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--base-color);
  border-bottom: 1px solid var(--base-color);
}
.summery-calendar .total-hour-column .column-content {
  height: 200px;
  display: flex;
  justify-content: center;
  border-right: 1px solid var(--base-color);
  border-bottom: 1px solid var(--base-color);
}
.summery-calendar .total-hour-column .column-content .hour-title, .summery-calendar .total-hour-column .column-content .hour-value {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.summery-calendar .total-hour-column .column-content .hour-title .hour-value, .summery-calendar .total-hour-column .column-content .hour-value .hour-value {
  height: 100%;
  padding: 0 2rem;
  color: #afb1b6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summery-calendar .total-hour-column .column-content .hour-title {
  border-right: 1px solid var(--base-color);
}
.summery-calendar .date-column {
  flex-grow: 1;
  max-width: 100%;
  overflow-x: auto;
}
.summery-calendar .date-column .date {
  height: 50px;
  padding: 0 1.2rem;
  display: flex;
  color: #787d88;
  align-items: center;
  justify-content: center;
  background-color: var(--base-color);
  white-space: nowrap;
}
.summery-calendar .date-column .date-hour-wrapper {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--base-color);
  border-bottom: 5px solid var(--base-color);
}
.summery-calendar .date-column .date-hour-wrapper .date-hour {
  height: 100%;
  color: #afb1b6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--base-color);
}
.summery-calendar.leave-calendar .profile-column .profile-box {
  width: 240px;
  height: 100px;
  flex-direction: row;
}
.summery-calendar.leave-calendar .age-column .column-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--base-color);
  border-bottom: 1px solid var(--base-color);
}
.summery-calendar.leave-calendar .age-column .column-content {
  width: 140px;
  height: 100px;
  border-right: 1px solid var(--base-color);
  border-bottom: 1px solid var(--base-color);
}
.summery-calendar.leave-calendar .date-column .date {
  height: 50px;
  padding: 0 1.2rem;
  display: flex;
  color: #787d88;
  align-items: center;
  justify-content: center;
  background-color: var(--base-color);
  white-space: nowrap;
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper {
  height: 100px;
  border-right: 1px solid var(--base-color);
  border-bottom: 1px solid var(--base-color);
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper .popover-leave-age {
  height: 100%;
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper .popover-leave-age .leave-age {
  border: 0;
  width: 100%;
  display: block;
  background-color: var(--avatar-no-image-bg);
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper .popover-leave-age .leave-age:focus {
  outline: 0;
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper .popover-leave-age .leave-age.age-length-full {
  height: 100%;
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper .popover-leave-age .leave-age.age-length-half {
  height: 50%;
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper .popover-leave-age .leave-age.age-length-quarter {
  height: 30%;
}
.summery-calendar.leave-calendar .date-column .date-hour-wrapper .popover-leave-age .leave-age.bg-pending {
  background-color: rgba(255, 204, 153, 0.5);
}

.month-year-picker .dropdown .dropdown-menu {
  min-width: 18rem;
}
.month-year-picker .dropdown .dropdown-menu .single-month {
  display: block;
  cursor: pointer;
  padding: 10px 12px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.25rem;
  transition: all 0.25s ease-in-out;
}
.month-year-picker .dropdown .dropdown-menu .single-month.active {
  background-color: var(--base-color);
}
.month-year-picker .dropdown .dropdown-menu .single-month:hover {
  background-color: var(--base-color);
}
.month-year-picker .dropdown .dropdown-menu .years-dropdown .dropdown-menu-years {
  min-width: 7rem;
  top: 15px !important;
  box-shadow: none !important;
  border: 1px solid var(--default-border-color);
}
.month-year-picker .dropdown .dropdown-menu .years-dropdown .dropdown-menu-years .caret {
  position: absolute;
  top: -1px;
  left: 50%;
  width: 12px;
  height: 12px;
  z-index: -1;
  content: "";
  display: block;
  border-top: inherit;
  border-left: inherit;
  background-color: inherit;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.month-year-picker .dropdown .dropdown-menu .years-dropdown .dropdown-menu-years .years-wrapper {
  overflow-y: auto;
  max-height: 160px;
}

.disabled-section {
  opacity: 0.8;
  pointer-events: none;
}

@keyframes animateProgressBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.g-1 {
  gap: 10px;
}
.g-2 {
  gap: 20px;
}
.g-3 {
  gap: 30px;
}
.g-4 {
  gap: 40px;
}
.g-5 {
  gap: 50px;
}

.arrow-icon {
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}
.arrow-icon svg {
  height: 20px;
}
.arrow-icon--expanded {
  transform: rotate(-90deg);
}

.big-icon svg {
  stroke-width: 1.5 !important;
  height: 61px;
  width: 61px;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
}

.btn.btn-primary {
  background-color: #019AFF;
  border-color: #019AFF;
}
.btn.btn-primary:hover {
  background-color: #0088e1;
}
.btn.btn-outline-primary {
  color: #019AFF;
  border-color: #019AFF;
}
.btn.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #019AFF;
}
.btn.btn-outline-primary:disabled:hover {
  color: #019AFF;
}
.btn.btn-transparent-primary {
  color: #019AFF;
}
.btn.btn-transparent-primary:hover {
  color: #ffffff;
  background-color: #019AFF;
}

.tab-filter-menu .nav-item .nav-link.active {
  color: #019AFF;
}
.tab-filter-menu .nav-item .nav-link:hover {
  color: #019AFF;
}

a {
  color: #019AFF;
}
a:hover {
  color: #0088e1;
}

.sidebar .nav .nav-item.active .nav-link .menu-title {
  color: #ffffff;
}
.sidebar .nav .nav-item.active .nav-link .icon-wrapper svg {
  color: #ffffff;
}

.text-primary {
  color: #019AFF !important;
}