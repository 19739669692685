@import "filters";
@import "variables";
@import "theme-colors";

.calendar-root {
  .input-date {
    font-size: 95%;
    cursor: pointer;
    border-radius: 20px;
    color: $filter-btn-text-color;
    padding: $filter-button-padding;
    background: var(--btn-filter-bg);
    box-shadow: var(--default-box-shadow);
    border: 1px solid var(--btn-filter-bg) !important;

    &:hover {
      border-radius: 20px;
      color: $brand-color;
      background: var(--base-color);
    }

    .clear-icon {
      @include clear-icon-mixin;
    }

    &.applied {
      color: $brand-color;
      background: var(--base-color);
    }
  }

  .months-text {
    text-align: center;
    font-weight: bold;
    color: $light-layout-secondary-font-color;

    .left {
      float: left;
      width: 16px;
      height: 16px;
      cursor: pointer;
      background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMxLjQ5NCAzMS40OTQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMxLjQ5NCAzMS40OTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTEwLjI3Myw1LjAwOWMwLjQ0NC0wLjQ0NCwxLjE0My0wLjQ0NCwxLjU4NywwYzAuNDI5LDAuNDI5LDAuNDI5LDEuMTQzLDAsMS41NzFsLTguMDQ3LDguMDQ3aDI2LjU1NCAgYzAuNjE5LDAsMS4xMjcsMC40OTIsMS4xMjcsMS4xMTFjMCwwLjYxOS0wLjUwOCwxLjEyNy0xLjEyNywxLjEyN0gzLjgxM2w4LjA0Nyw4LjAzMmMwLjQyOSwwLjQ0NCwwLjQyOSwxLjE1OSwwLDEuNTg3ICBjLTAuNDQ0LDAuNDQ0LTEuMTQzLDAuNDQ0LTEuNTg3LDBsLTkuOTUyLTkuOTUyYy0wLjQyOS0wLjQyOS0wLjQyOS0xLjE0MywwLTEuNTcxTDEwLjI3Myw1LjAwOXoiIGZpbGw9IiMwMDZERjAiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
    }

    .right {
      float: right;
      width: 16px;
      height: 16px;
      cursor: pointer;
      background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMxLjQ5IDMxLjQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMS40OSAzMS40OTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cGF0aCBkPSJNMjEuMjA1LDUuMDA3Yy0wLjQyOS0wLjQ0NC0xLjE0My0wLjQ0NC0xLjU4NywwYy0wLjQyOSwwLjQyOS0wLjQyOSwxLjE0MywwLDEuNTcxbDguMDQ3LDguMDQ3SDEuMTExICBDMC40OTIsMTQuNjI2LDAsMTUuMTE4LDAsMTUuNzM3YzAsMC42MTksMC40OTIsMS4xMjcsMS4xMTEsMS4xMjdoMjYuNTU0bC04LjA0Nyw4LjAzMmMtMC40MjksMC40NDQtMC40MjksMS4xNTksMCwxLjU4NyAgYzAuNDQ0LDAuNDQ0LDEuMTU5LDAuNDQ0LDEuNTg3LDBsOS45NTItOS45NTJjMC40NDQtMC40MjksMC40NDQtMS4xNDMsMC0xLjU3MUwyMS4yMDUsNS4wMDd6IiBmaWxsPSIjMDA2REYwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=");
    }
  }

  .calendar {
    z-index: 9;
    width: 730px;
    margin-top: 5px;
    background: var(--calendar-bg);
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    border-radius: $default-radius;
    box-shadow: var(--default-box-shadow);

    ul {
      list-style-type: none;
    }

    .calendar-wrap {
      width: 72%;
      float: left;
      font-size: 12px;
      display: inline-block;
      padding: 0 10px 10px 10px;

      .calendar_month_left, .calendar_month_right {
        width: 46%;
        margin: 5px;
        float: left;
        padding: 10px;
      }
    }

    .clearfix {
      border-bottom: 1px solid var(--default-border-color);
    }

    .calendar-range {
      float: left;
      margin: -2px;
      padding: 0 12px;
      font-size: 12px;
      border-left: 1px solid var(--default-border-color);

      .calendar_preset {
        padding: 0;

        li {
          line-height: 1.6em;
          width: auto;
          display: block;
          margin: 15px 0 !important;

          &.calendar_preset-ranges {
            padding: 0 15px 0 20px;
            margin-bottom: 5px;
            cursor: pointer;
            margin-top: 1px;
          }
        }
      }
    }

    .calendar-left-mobile {
      width: 100% !important;
    }

    .calendar_weeks {
      margin: 0;
      padding: 10px 0;
      width: auto;

      li {
        width: 13.6%;
        text-align: center;
        display: inline-block;
        color: $light-layout-secondary-font-color;
      }
    }

    .calendar_days {
      margin: 0;
      padding: 0;

      li {
        width: 13.6%;
        cursor: pointer;
        line-height: 2em;
        text-align: center;
        display: inline-block;
        color: $light-layout-secondary-font-color;
      }
    }

    .custom-radio {
      .custom-control-label {
        &:before {
          transform: scale(1.3);
        }

        &:after {
          transform: scale(1.4);
        }
      }
    }

    .calendar_days {
      li {
        &:hover {
          background: var(--base-color);
          color: $light-layout-secondary-font-color;
        }
      }
    }

    li {
      &.calendar_days--disabled {
        pointer-events: none;
      }

      &.calendar_days_selected {
        background: $brand-color;
        color: $white;

        &:hover {
          background-color: lighten($brand-color, 2);
          color: $white;
        }
      }

      &.calendar_days_in-range {
        background-color: lighten($brand-color, 5);
        color: $white;

        &:hover {
          background-color: lighten($brand-color, 3);
          color: $white;
        }
      }
    }

    .calendar-mobile {
      width: 260px;
      z-index: 1;
      box-shadow: none;
    }

    .calendar-range-mobile {
      width: 90%;
      padding: 2px;
      border-left: none;
      margin: -20px 0;
    }

    .filter-action-button-wrapper {
      padding: 2rem;
      font-size: 95%;

      .btn-clear {
        color: $default-secondary-color;

        &:hover {
          color: var(--default-font-color);
        }
      }
    }

    /*
      radio button from preset-range filter is replaced with span element &
      button of [clear] is replaced with div
      we also need these styling
    */
    .preset-active-input > .custom-control-label::before {
      color: $white;
      background-color: $white;
      border-color: $brand-color;
    }

    .preset-active-input > .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='blue'/%3e%3c/svg%3e");
    }

    .selected:after {
      content: none !important;
    }
  }
}
